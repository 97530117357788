import * as React from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

const CustomMuiSelectInput = ({ item, applyValue, options }) => {
  const [selectValue, setSelectValue] = React.useState(item?.value || "");
  const [operator, setOperator] = React.useState(item?.operator || "eq");
  React.useEffect(() => {
    if (item?.operator !== operator) {
      setOperator(item?.operator);
      setSelectValue("");
    }
  }, [item?.value, item?.operator, operator]);

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectValue(newValue);

    if (newValue !== "") {
      applyValue({
        ...item,
        value:
          newValue === "true" ? true : newValue === "false" ? false : newValue,
      });
    } else {
      applyValue({
        ...item,
        value: null,
      });
    }
  };

  // Render the select box for exists operator
  const renderSelectField = () => {
    if (item?.operator === "exists") {
      return (
        <FormControl
          fullWidth
          variant="standard"
          sx={{ marginTop: "0.2625rem" }}
        >
          <InputLabel>Filter Value</InputLabel>
          <Select
            value={selectValue}
            onChange={handleSelectChange}
            label="Filter Value"
            sx={{
              fontSize: "0.8rem",
            }}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </FormControl>
      );
    }

    return (
      <FormControl fullWidth variant="standard" sx={{ marginTop: "0.2625rem" }}>
        <InputLabel>Filter Value</InputLabel>
        <Select
          value={selectValue}
          onChange={handleSelectChange}
          label="Filter Value"
          sx={{
            fontSize: "0.8rem",
          }}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return <>{renderSelectField()}</>;
};

export default CustomMuiSelectInput;
