import React, { useState } from "react";
import { FaPaperclip, FaTrash } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";

const EmailComposerModal = ({
  isOpen,
  closeModal,
  handleSubmit,
  onChangeCc,
  onChangeBcc,
  fileChange,
  removeFile,
  selectedFiles,
  ...props
}) => {
  const [ccemails, setccEmails] = useState([]);
  const [bccemails, setbccEmails] = useState([]);
  const [isCCVisible, setIsCCVisible] = useState(false); // For CC field visibility
  const [isBCCVisible, setIsBCCVisible] = useState(false); // For BCC field visibility
  if (!isOpen) return null; // Don't render if the modal is not open

  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
      <div className="relative">
         {/*  className={`relat z-10 w-auto w-full md:max-w-[1524px] md:min-w-[1090px] max-h-[calc(250vh-30px)] overflow-y-auto bg-white  border-white ${props.modalClass}`}*/}
        <div className={`relat z-10 w-auto w-full md:max-w-[1524px] md:min-w-[1090px] max-h-[calc(250vh-30px)] md:min-h-[390px] overflow-y-auto bg-white  border-white ${props.modalClass}`}
        > 
          <div className={`pt-0 h-full m-auto ${props.modalBodyClass}`}>
            <div onClick={closeModal}>
              <div
                className="bg-white rounded-lg w-300"
                onClick={(e) => e.stopPropagation()} // Prevent closing modal on inside click
              >
                {!props.hideHeader && (
                  <header
                    className={`bg-[#f7f7f7] px-4 py-2 flex justify-between items-center border-b border-[#bbb2b2]`}
                  >
                    <span className="material-icons-outlined text-black">
                      {props.title}
                    </span>
                    <div>
                      <button onClick={() => closeModal()}>
                        <span className="material-icons-outlined text-menu">
                          <AiOutlineCloseCircle size={25} />
                        </span>
                      </button>
                    </div>
                  </header>
                )}
                <form onSubmit={handleSubmit}>
                  {/* To Field */}
                  {/* <div className="mb-2 pt-4">
                    <div className="flex justify-between pl-4 pr-4"> */}
                    <div className="mb-2 pt-2  ml-4 mr-4">
                    <div className="flex justify-between items-center border-b">
                      <label htmlFor="to" className="block text-gray-600">
                        To
                      </label>
                      <input
                        type="email"
                        name="to"
                        id="to"
                        className="w-full border-gray-300 focus:border-blue-500 p-1 outline-none"
                        required
                        placeholder="Recipient Email"
                      />
                      <div className="flex justify-between">
                        {/* <label
                          className="block pr-2 text-gray-600 cursor-pointer"
                          onClick={() => setIsCCVisible(true)}
                        >
                          Cc
                        </label>
                        <label
                          className="block text-gray-600 cursor-pointer"
                          onClick={() => setIsBCCVisible(true)}
                        >
                          Bcc
                        </label> */}
                        {!isCCVisible && (
                          <label
                            className="block pr-2 text-gray-600 cursor-pointer"
                            onClick={() => setIsCCVisible(!isCCVisible)}
                          >
                            Cc
                          </label>
                        )}
                        {!isBCCVisible && (
                          <label
                            className="block text-gray-600 cursor-pointer"
                            onClick={() => setIsBCCVisible(!isBCCVisible)}
                          >
                            Bcc
                          </label>
                        )}
                      </div>
                    </div>
                    {/* <input
                      type="email"
                      name="to"
                      id="to"
                      className="w-full border-b-2 border-gray-300 focus:border-blue-500 p-2 outline-none pl-4 pr-4"
                      required
                      placeholder="Recipient Email"
                    /> */}
                  </div>

                  {/* CC Field */}
                  {isCCVisible && (
                    // <div className="mb-2 pt-2">
                    //   <label
                    //     htmlFor="cc"
                    //     className="block text-gray-600 pl-4 pr-4"
                    //   >
                    //     Cc
                    //   </label>
                    //   <input
                    //     type="email"
                    //     name="cc"
                    //     id="cc"
                    //     className="w-full border-b-2 border-gray-300 focus:border-blue-500  pl-4 pr-4 outline-none"
                    //     placeholder="Cc Email"
                    <div className="mb-2 pt-1 ml-4 mr-4 border-b">
                      <ReactMultiEmail
                        style={{ border: "none", padding: "0" }}
                        placeholder="Cc"
                        emails={ccemails}
                        onChange={(_emails) => {
                          setccEmails(_emails);
                          onChangeCc(_emails);
                        }}
                        autoFocus={false}
                        getLabel={(email, index, removeEmail) => {
                          return (
                            <div data-tag key={index}>
                              <div data-tag-item>{email}</div>
                              <span
                                data-tag-handle
                                onClick={() => removeEmail(index)}
                              >
                                ×
                              </span>
                            </div>
                          );
                        }}
                      />
                    </div>
                  )}

                  {/* BCC Field */}
                  {isBCCVisible && (
                    // <div className="mb-2 pt-2">
                    //   <label
                    //     htmlFor="cc"
                    //     className="block text-gray-600 pl-4 pr-4"
                    //   >
                    //     Bcc
                    //   </label>
                    //   <input
                    //     type="email"
                    //     name="bcc"
                    //     id="bcc"
                    //     className="w-full border-b-2 border-gray-300 focus:border-blue-500  pl-4 pr-4 outline-none"
                    //     placeholder="Bcc Email"
                    <div className="mb-2 pt-1 ml-4 mr-4 border-b">
                            <ReactMultiEmail
                              style={{ border: "none", padding: "0" }}
                              placeholder="Bcc"
                              emails={bccemails}
                              onChange={(_emails) => {
                                setbccEmails(_emails);
                                onChangeBcc(_emails);
                              }}
                              autoFocus={false}
                              getLabel={(email, index, removeEmail) => {
                                return (
                                  <div data-tag key={index}>
                                    <div data-tag-item>{email}</div>
                                    <span
                                      data-tag-handle
                                      onClick={() => removeEmail(index)}
                                    >
                                      ×
                                    </span>
                                  </div>
                                );
                              }}
                      />
                    </div>
                  )}

                  {/* Subject Field */}
                  {/* <div className="mb-2 pt-2">
                    <label
                      htmlFor="subject"
                      className="block text-gray-600 pl-4 pr-4"
                    >
                      Subject
                    </label> */}
                    <div className="mb-2 pt-1 ml-4 mr-4">
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      className="w-full border-b border-gray-300 focus:border-blue-500 outline-none"
                      required
                      placeholder="Subject"
                    />
                  </div>

                  {/* Description Field */}
                  <div className="mb-2 pt-1 ml-4 mr-4">
                    <textarea
                      name="description"
                      id="description"
                      className="w-full border-b-2 border-gray-300 focus:border-blue-500  outline-none"
                      required
                      rows="10"
                      placeholder="Write your email here..."
                    />
                  </div>

                  {/* Buttons */}
                  <div className="flex justify-between pb-4">
                    <div className="flex justify-between pl-4 items-start">
                      <label
                        htmlFor="attachment"
                        className="block pt-1 text-gray-600"
                      >
                        Attachment:
                      </label>
                      {/* Hidden File Input */}
                      <input
                        type="file"
                        name="attachment"
                        id="attachment"
                        className="hidden"
                        multiple
                        onChange={fileChange}
                      />
                      {/* Icon that triggers the file input */}
                      <label
                        htmlFor="attachment"
                        className="cursor-pointer text-blue-500 hover:text-blue-600"
                      >
                        <FaPaperclip className="w-6 h-6" />
                      </label>
                      {/* Displaying the selected files */}
                      {selectedFiles.length > 0 && (
                        <div className="max-h-[200px] overflow-scroll ">
                          <ul className="list-none pl-5">
                            {selectedFiles.map((file, index) => (
                              // <li key={index} className="mb-2 pr-1">
                              //   {/* Display image preview if the file is an image */}
                              //   {file.type.startsWith("image") && (
                              //     <div className="mt-2">
                              //       <img
                              //         src={URL.createObjectURL(file)}
                              //         alt={file.name}
                              //         className="max-w-full max-h-12 rounded-lg mt-2"
                              //       />
                              <li key={index} className="mb-2 pr-1 relative">
                                <div className="flex items-center justify-between">
                                  <div className="md:min-w-[290px]">
                                    {file.name}
                                  </div>
                                {/* )} */}
                                <FaTrash
                                    className="cursor-pointer"
                                    onClick={() => removeFile(index)}
                                  />
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="h-[40px] bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 mr-4"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailComposerModal;
