import React, { useEffect, useState, useRef } from "react";
import { FaCaretRight, FaCaretDown } from "react-icons/fa";
import { FormInput } from "../../../components";

const CustomFilterSelect = ({
  id,
  name,
  label,
  formik,
  options = [],
  valueProp,
  labelProp,
  childValueProp,
  childLabelProp,
  customStyle,
  value,
  onChange,
  onChangeChild,
  isFloat,
  disabled,
  className,
  applyFilter,
  rules,
  parentField,
  childField,
}) => {

  const [openParent, setOpenParent] = useState(false);
  const [openChild, setOpenChild] = useState([]);
  const [parentSearch, setParentSearch] = useState("");
  const [childSearch, setChildSearch] = useState("");

  const filterRef = useRef();
  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setOpenParent(false)
    }
  }
  document.addEventListener("click", handleClickOutside, true);
  return (
    <div className={`relative ${customStyle}`} 
    ref={filterRef}>
      <div className="border h-[40px] rounded pl-2 pt-1" onClick={() => setOpenParent(true)}><label
        htmlFor={name}
        className={[
          `small`,
          isFloat
            ? "text-[rgba(0,_0,_0,_0.6)] absolute left-[0] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] translate-x- -translate-y-[12px] scale-75 px-1"
            : "",
        ].join(" ")}
        style={{
          background: "linear-gradient(0deg, white 50%, transparent 50%)",
        }}
      >
        {label}
      </label></div>
      {openParent && options.length > 0 &&
        <div className="absolute left-[0px] z-10 shadow bg-white w-[250px]">

        <FormInput 
          onChange={(e)=>{setParentSearch(e.target.value)}}
          name="parent"
          label="Search"
          className="text-xs"
          value={parentSearch}
        />
          {options.map((option) =>
            (!parentSearch || (parentSearch!=="" && option[labelProp].toLowerCase().includes(parentSearch.toLowerCase()) === true))?<div className="px-3 py-2 relative flex items-top justify-between">
              <div className="flex">
                <input
                  id={id}
                  type="checkbox"
                  name={name}
                  val={option[valueProp]}
                  className={`form-checkbox h-5 w-5 text-primary-100 !rounded-sm focus:ring-0 cursor-pointer mr-2 ${className}`}
                  value={value ? value : ""}
                  checked={rules?.find((rule) => rule.field === parentField)?.data.includes(option[valueProp])}
                  onChange={
                    onChange
                      ? onChange
                      : () => formik.setFieldValue(name, !formik.values[name])
                  }
                  disabled={disabled}
                />
                <p className="text-[12px] pl-2">{option[labelProp]}</p>
              </div>
              {openChild != option.affiliate_settings ?
                <FaCaretRight className="cursor-pointer" onClick={() => {
                  if (openChild != option.affiliate_settings) {
                    setOpenChild(option.affiliate_settings)
                  }
                }} /> :
                <FaCaretDown className="cursor-pointer" onClick={() => {setOpenChild([])}} />}

              {openChild === option.affiliate_settings && option.affiliate_settings.length>0 && <div className="absolute left-[250px] z-11 shadow bg-white w-[250px] h-[300px]">

                <FormInput 
                  onChange={(e)=>{setChildSearch(e.target.value)}}
                  name="child"
                  label="Search"
                  className="text-xs"
                  value={childSearch}
                />
                <div className="p-2 h-[250px] overflow-scroll">
                {openChild.map((child) => (!childSearch || (childSearch!=="" && child?.campaign_id?.title.toLowerCase().includes(childSearch.toLowerCase()) === true))?<div className="flex pb-2">
                  <input
                    id={id}
                    type="checkbox"
                    name={name}
                    val={child.campaign_id._id}
                    parentVal={option[valueProp]}
                    className={`form-checkbox h-5 w-5 text-primary-100 !rounded-sm focus:ring-0 cursor-pointer mr-2 pb-2 ${className}`}
                    value={value ? value : ""}
                    checked={rules?.find((rule) => rule.field === childField)?.data.includes(child.campaign_id._id)}
                    onChange={
                      onChangeChild
                        ? onChangeChild
                        : () => formik.setFieldValue(name, !formik.values[name])
                    }
                    disabled={disabled}
                  />
                  <p className="text-[12px] pl-2">{child?.campaign_id?.title}</p>
                </div>:null
                )}</div></div>}
            </div>:null
          )}

          <div className="flex justify-between">
            <button
              className="bg-secondary text-[12px] flex items-center justify-center text-white m-2 p-2" onClick={()=>{
                setOpenParent(false)
              }}
            >
              Cancel
            </button>
            <button
              className="bg-primary-100 text-[12px] flex items-center justify-center text-white m-2 p-2" onClick={()=>{
                setOpenParent(false)
                applyFilter()
              }}
            >
              Apply Filter
            </button>
          </div>
        </div>
      }
    </div>
  );
};

export default CustomFilterSelect;
