import React, { useState } from "react";
import { DotsLoader, FormSelectInput } from "../../../../components";
import { FormTextArea } from "../../../../components";

import { toast } from "react-toastify";
import CustomModal from "../../../../components/molecules/Modal/customModal";
import api from "../../../../services/api";
import { FaInfoCircle } from "react-icons/fa";
import { useFormik } from "formik";

function AddDidToMsgCenter({ onCancelForm, modalTitle, selectedData, campaignList }) {
  const [isLoader, setIsLoader] = useState(false);
  const formik = useFormik({
    initialValues: {
      numbers: selectedData?.map(({ did }) => did),
      description: "",
      message_campaign: "",
    },
    // validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setIsLoader(true);
      try {
        const res = await api.post("/api/cc/dids/enable_did_for_sms", values);
        if (res.status === 200 || res.status === 201) {
          toast.success("DIDs synced for message center successfully.");
          onCancelForm();
          // onFormSubmit();
        } else {
          toast.error("DID couldn't be synced for message center!");
        }
      } catch (err) {
        toast.error(
          err?.response?.data?.error ||
            err?.response?.data?.error ||
            "DID couldn't be synced for message center "
        );
        console.log(err);
      }
      setIsLoader(false);
    },
  });

  return (
    <CustomModal
      handleModal={onCancelForm}
      title={modalTitle}
      isLoading={isLoader}
      onClick={onCancelForm}
      className="max-w-7xl"
      modalType={true}
      onSubmit={formik.handleSubmit}
    >
      {isLoader ? <DotsLoader /> : null}

      <div className="mb-0 p-2 mt-3">
        {selectedData && selectedData?.length > 0 ? (
          <div className="grid md:grid-cols-2 ">
            <div className="space-y-4 mt-2">
              <div className="mb-2 w-full">
                <FormSelectInput
                  placeholder="Select SMS Campaign"
                  options={campaignList}
                  valueProp={"externalCampaignId"}
                  labelProp={"messagingBrandName"}
                  value={formik.values.message_campaign || "Testing"}
                  onChange={(e) => formik.setFieldValue("message_campaign", e)}
                />
                {formik.errors.message_campaign &&
                formik.touched.message_campaign ? (
                  <div className="text-xs text-red-500">
                    {formik.errors.message_campaign}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-4 h-full">
                <div className="w-full">
                  <label className="font-semibold text-sm">Description</label>
                  <FormTextArea
                    rows={3}
                    name="description"
                    formik={formik}
                    type="text"
                  />
                </div>
              </div>
            </div>

            {selectedData && selectedData?.length > 0 && (
              <div className="mt-0 bg-gray-10 p-2 border-l ml-3 mr-2">
                <div
                  className="flex items-center p-2 text-lg text-yellow-800 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
                  role="alert"
                >
                  <FaInfoCircle />
                  <span className="font-medium ml-2">
                    You have selected {selectedData?.length} DIDs
                  </span>
                </div>
                <div
                  className="overflow-y-auto pl-2"
                  style={{ maxHeight: "calc(40vh - 2rem - 2.5rem)" }}
                >
                  {selectedData?.map((item, index) => (
                    <div key={index} className="mb-1 text-blue-600">
                      {item?.did}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div
            className="flex items-center p-2 mb-3 text-sm text-yellow-800 border border-yellow-300 rounded-sm bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
            role="alert"
          >
            <FaInfoCircle />
            <span className="font-medium text-sm ml-2 text-center">
              No DIDs selected!
            </span>
          </div>
        )}
      </div>
    </CustomModal>
  );
}

export default AddDidToMsgCenter;
