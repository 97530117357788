import React from "react";
import { getFormattedDate } from "../../util/common";
import { FaCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import labels from "../Lead/Components/leads_labels.json";
const SummaryModal = ({ isOpen, onClose, formik }) => {
  const {
    job_number = "",
    name = "",
    /* extra_fields = {}, */
    phone,
    complete_pickup_address,
    /* call_payload, */
    scheduled_end,
    scheduled_on,
    industry_id,
    pickup_city,
    pickup_state,
    pickup_zip,
    special_instruction,
  } = formik?.values || {};
  /*   const {
    job_type,
    occupant_type = "",
    property_type = "",
  } = extra_fields || {}; */
  const formatValue = (val) => {
    if (val === undefined) return "";
    if (val === "false" || val === false) {
      return "No";
    }
    if (typeof val !== "boolean" && !val) return "";
    if (typeof val === "boolean") {
      return val ? "Yes" : "No";
    }
    if (val && !val?.includes("_")) return val;
    return val
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const renderInfo = (label, value) => {
    const formattedLabel = label?.endsWith(":") ? label.slice(0, -1) : label;
    return (
      <div className="text-sm">
        {label ? (
          <label className="inline-block whitespace-nowrap">
            {formattedLabel}:
          </label>
        ) : null}
        <span className="inline-block ml-1 break-words">
          {formatValue(value)}
        </span>
      </div>
    );
  };

  const renderHeader = (label, value) => {
    const formattedLabel = label?.endsWith(":") ? label.slice(0, -1) : label;
    return label ? (
      <div className="flex gap-x-1 text-sm mt-2">
        <label className="font-semibold">
          {value ? `${formattedLabel}:` : formattedLabel}
        </label>
        {value && <span>{formatValue(value)}</span>}
      </div>
    ) : null;
  };

  const formatAddress = (completeAddress, city, state, zip) => {
    const normalizeValue = (value) => value?.toLowerCase().trim() || "";

    // Normalize inputs for case-insensitive comparison
    const normalizedAddress = normalizeValue(completeAddress);
    const normalizedCity = normalizeValue(city);
    const normalizedState = normalizeValue(state);
    const normalizedZip = normalizeValue(zip);

    let formattedAddress = completeAddress || "";

    // Handle zip: Check if the zip exists or if it's a substring (not the exact match)
    if (
      zip &&
      (!normalizedAddress.includes(normalizedZip) || // Zip isn't in the address
        !new RegExp(`\\b${normalizedZip}\\b`).test(normalizedAddress)) // Exact zip is not present as a separate word
    ) {
      formattedAddress += ` ${zip}`;
    }

    // Handle city: Append if not already in the address
    if (city && !normalizedAddress.includes(normalizedCity)) {
      formattedAddress += ` ${city}`;
    }

    // Handle state: Append if not already in the address
    if (state && !normalizedAddress.includes(normalizedState)) {
      formattedAddress += ` ${state}`;
    }

    return formattedAddress.trim();
  };
  const getElementIdsWithExtraFields = () => {
    const elements = document.querySelectorAll('[id*="extra_fields."]');
    const ids = Array.from(elements).map((el) => el.id); // Extract ids
    return ids;
  };
  const populateExtraFields = (userInput, fieldArray) => {
    const extraFields = {};
    // Helper function to determine if a field is boolean
    const isBooleanField = (fieldName) => {
      // Define the boolean fields or use logic to infer them
      const booleanFields = [
        "extra_fields.upholstery_cleaning.couch",
        "extra_fields.upholstery_cleaning.chair",
        "extra_fields.upholstery_cleaning.other",
        "extra_fields.rug_cleaning.rug_in_ft",
        "extra_fields.rug_cleaning.ad",
        "extra_fields.specific_spot",
        "extra_fields.is_inspection",
        "extra_fields.is_installation",
        "extra_fields.accessible",
        "extra_fields.damage",
      ];

      // Check if the fieldName matches any boolean field
      return booleanFields.some((booleanField) =>
        fieldName.endsWith(booleanField)
      );
    };

    fieldArray.forEach((field) => {
      const keys = field.split(".").slice(1); // Extract keys after "extra_fields"
      let currentField = extraFields;

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          // If it's the last key, set the value
          if (isBooleanField(field)) {
            // Handle boolean fields: default to false if empty
            currentField[key] = !!userInput?.[keys.join(".")];
          } else {
            // Handle non-boolean fields: default to empty string if empty
            currentField[key] = userInput?.[keys.join(".")] ?? "";
          }
        } else {
          // If it's not the last key, ensure the key exists as an object
          currentField[key] = currentField[key] || {};
          currentField = currentField[key];
        }
      });
    });

    return extraFields;
  };
  const fields = getElementIdsWithExtraFields();
  const populatedExtraFields = populateExtraFields(
    formik?.values?.extra_fields,
    fields
  );
  const copyToClipboard = () => {
    const formattedText = [
      `Hi, New job ${job_number ? `${job_number}` : ""} - ${name || ""}`,
      `*Industry: ${industry_id?.name || ""}*`,
      "",
      `*Customer Details*`,
      `Name: ${formatValue(name) || ""}`,
      `Phone: ${formatValue(phone) || ""}`,
      `Scheduled: ${scheduled_on ? `${getFormattedDate(scheduled_on)}` : ""}${
        scheduled_end ? ` - ${getFormattedDate(scheduled_end)}` : ""
      }`,
      `Address: ${
        formatAddress(
          complete_pickup_address,
          pickup_city,
          pickup_state,
          pickup_zip
        ) || ""
      }`,
      // `City: ${formatValue(pickup_city) || ""}`,
      // `State: ${formatValue(pickup_state) || ""}`,
      // `Zip: ${formatValue(pickup_zip) || ""}`,
      "",
      `*Job Details*`,
    ];

    // Handle extra fields

    if (populatedExtraFields || formik?.values?.extra_fields) {
      Object.keys(populatedExtraFields || formik?.values?.extra_fields).forEach(
        (key) => {
          const label = labels[key] || key;
          const innerObject =
            (populatedExtraFields && populatedExtraFields[key]) ||
            (formik?.values?.extra_fields && formik.values.extra_fields[key]) ||
            "";

          if (typeof innerObject === "object" && innerObject !== null) {
            formattedText.push("");
            formattedText.push(`*${label}*`);

            Object.keys(innerObject).forEach((innerKey) => {
              const innerLabel = labels[innerKey] || innerKey;
              formattedText.push(
                `${innerLabel?.endsWith(":") ? innerLabel : `${innerLabel}:`} ${
                  formatValue(innerObject[innerKey]) || ""
                }`
              );
            });
          } else {
            formattedText.push(
              `${label?.endsWith(":") ? label : `${label}:`} ${
                formatValue(innerObject) || ""
              }`
            );
          }
        }
      );
    }
    formattedText.push("");
    formattedText.push(
      `*Additional Information*: ${formatValue(special_instruction) || ""}`
    );

    // Join the array into a string and copy to clipboard
    navigator.clipboard.writeText(formattedText.join("\n")).then(() => {
      toast.success("Copied to clipboard!");
    });
  };
  const getValueOrDefault = (value, defaultValue) => {
    return value !== undefined && value !== null ? value : defaultValue;
  };
  return (
    <div
      className={`fixed inset-0 z-50 transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div
        className="bg-black opacity-50 absolute inset-0"
        onClick={onClose}
      ></div>
      <div className="bg-white w-full md:w-96 lg:w-1/3 h-full shadow-lg absolute right-0">
        <span
          className="absolute top-2 right-2 cursor-pointer"
          onClick={onClose}
        >
          &times;
        </span>
        <div className="flex items-center justify-between !mt-6 !px-4">
          <h2 className="!p-2 text-lg font-bold">Summary</h2>
          <button onClick={copyToClipboard} type="button">
            <FaCopy />
          </button>
        </div>

        <div className="flex w-full items-start justify-between">
          <div>
            <div className="!px-3">
              {/* <p className="text-[16px] font-bold">Whats App Format</p> */}
              <p className="text-base">{`Hi, New job ${
                job_number ? `${job_number}` : ``
              } - ${name || ""}`}</p>
              <div className="pl-2 pt-2">
                {renderHeader(`Industry: ${industry_id?.name || ""}`)}
                {renderHeader("Customer Details")}
                {renderInfo("Name", name || "")}
                {renderInfo("Phone", phone || "")}
                {renderInfo(
                  "Scheduled",
                  `${getFormattedDate(scheduled_on)}${
                    scheduled_end ? ` - ${getFormattedDate(scheduled_end)}` : ""
                  }`
                )}
                {renderInfo(
                  "Address",
                  formatAddress(
                    complete_pickup_address,
                    pickup_city,
                    pickup_state,
                    pickup_zip
                  ) || ""
                )}
                {populatedExtraFields || formik?.values?.extra_fields ? (
                  <div>
                    {Object.keys(
                      populatedExtraFields || formik?.values?.extra_fields
                    )?.map((key) => {
                      const label = labels[key] || key;
                      const innerObject =
                        (populatedExtraFields &&
                          getValueOrDefault(populatedExtraFields[key], "")) ??
                        (formik?.values?.extra_fields &&
                          getValueOrDefault(
                            formik.values.extra_fields[key],
                            ""
                          ));

                      if (
                        typeof innerObject === "object" &&
                        innerObject !== null
                      ) {
                        return (
                          <div key={key}>
                            {renderHeader(label)}{" "}
                            {Object.keys(innerObject).map((innerKey) => {
                              const innerLabel = labels[innerKey] || innerKey;
                              const incrementedLabel =
                                !isNaN(innerLabel) && innerLabel.trim() !== ""
                                  ? (parseInt(innerLabel) + 1).toString()
                                  : innerLabel;
                              return renderInfo(
                                incrementedLabel,
                                innerObject[innerKey]
                              );
                            })}
                          </div>
                        );
                      }
                      return renderInfo(label, innerObject);
                    })}
                  </div>
                ) : null}
                {renderHeader("Additional Information", "")}
                {renderInfo("", special_instruction || "")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryModal;
