import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  DotsLoader,
  VirtualizedMultiSelectBox,
} from "../../../components";
import api from "../../../services/api";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { get_countries } from "../../../features/countriesSlice";
import { useSelector, useDispatch } from "react-redux";

const AssignZipToDomain = ({ editingRecord, onCancelForm, site_id }) => {
  const [editingState, setEditingState] = useState(editingRecord || {});
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.countries);
  const [isLoading, setIsLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [zipList, setZipList] = useState([]);
  const [selected, setSelected] = useState({
    country_id: editingState.country_id || null,
    state_id: editingState.state_id || [],
    city_id: editingState.city_id || [],
    zip_codes: editingState.zip_codes || [],
    site_id: editingState.site_id || site_id,
  });

  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredZips, setFilteredZips] = useState([]);

  useEffect(() => {
    dispatch(get_countries());
  }, [dispatch]);

  useEffect(() => {
    if (!editingState.country_id) {
      const defaultCountry = countries.find((country) => country.code === "US");
      if (defaultCountry) {
        setSelected((prev) => ({
          ...prev,
          country_id: defaultCountry._id,
        }));
      }
    } else {
      setSelected((prev) => {
        const isSameCountry = prev.country_id === editingState.country_id;

        return {
          ...prev,
          country_id: editingState.country_id || null,
          state_id: isSameCountry ? editingState.state_id || [] : [],
          city_id: isSameCountry ? editingState.city_id || [] : [],
          zip_codes: isSameCountry ? editingState.zip_codes || [] : [],
          site_id: editingState.site_id || site_id,
        };
      });
    }
  }, [editingState, countries, site_id]);

  const handleSelectChange = (e, field) => {
    setSelected((prev) => {
      const newCountryId = field === "country_id" ? e.value : prev.country_id;

      return {
        ...prev,
        [field]: e.value,
        ...(field === "country_id" && newCountryId !== editingState?.country_id
          ? { state_id: [], city_id: [], zip_codes: [] }
          : {}),
      };
    });
  };

  useEffect(() => {
    if (selected.country_id) {
      setIsLoading(true);
      Promise.all([
        fetchStateList(selected.country_id),
        fetchCityList(selected.country_id),
        fetchZipList(selected.country_id),
      ])
        .then(([states, cities, zips]) => {
          setStateList(states);
          setCityList(cities);
          setZipList(zips);

          if (
            !editingState ||
            editingState.country_id !== selected.country_id
          ) {
            setSelected((prev) => ({
              ...prev,
              state_id: [],
              city_id: [],
              zip_codes: [],
            }));
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error || "An error occurred");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selected.country_id, editingState]);

  useEffect(() => {
    if (!selected.state_id.length) {
      setFilteredCities([]);
      setFilteredZips([]);
      setSelected((prev) => ({
        ...prev,
        city_id: [],
        zip_codes: [],
      }));
      return;
    }

    const selectedStateSet = new Set(selected.state_id);

    const filteredCities = cityList
      .filter((city) => selectedStateSet.has(city.state_id))
      .map((city) => {
        const state = stateList.find((state) => state._id === city.state_id);
        return {
          ...city,
          name: `${city.name}-${state?.short_name || ""}`,
        };
      });

    // Remove cities from selected.city_id that are no longer associated with the selected states
    const updatedCityId = selected.city_id.filter((cityId) =>
      filteredCities.some((city) => city._id === cityId)
    );

    setFilteredCities(filteredCities);

    setSelected((prev) => ({
      ...prev,
      city_id: updatedCityId,
      zip_codes: [], // Reset zip codes when cities are updated
    }));
    // eslint-disable-next-line
  }, [selected.state_id, cityList, stateList, editingState?.city_id]);

  useEffect(() => {
    if (!selected.city_id.length) {
      setFilteredZips([]);
      setSelected((prev) => ({
        ...prev,
        zip_codes: [],
      }));
      return;
    }

    const selectedCitySet = new Set(selected.city_id);

    const filteredZips = zipList.filter((zip) =>
      selectedCitySet.has(zip.city_id)
    );

    // Remove zip codes from selected.zip_codes that are no longer associated with the selected cities
    const updatedZipCodes = selected.zip_codes.filter((zipCode) =>
      filteredZips.some((zip) => zip.zip === zipCode)
    );

    setFilteredZips(filteredZips);

    setSelected((prev) => ({
      ...prev,
      zip_codes: updatedZipCodes,
    }));
    // eslint-disable-next-line
  }, [selected.city_id, zipList]);

  const fetchStateList = async (countryId) => {
    try {
      const res = await api.post("api/location_db/state_list", {
        filters: {
          groupOp: "AND",
          rules: [{ field: "country_id", op: "eq", data: countryId }],
        },
      });
      const updatedStates = (res.data.records || []).map((state) => ({
        ...state,
        name: `${state.name}-${state.short_name}`,
      }));

      return updatedStates;
    } catch (err) {
      return [];
    }
  };

  const fetchCityList = async (countryId) => {
    try {
      const res = await api.get(`api/location_db/cityDrd/${countryId}`);
      return res.data || [];
    } catch (err) {
      return [];
    }
  };

  const fetchZipList = async (countryId) => {
    try {
      const res = await api.get(`api/location_db/zipDrd/${countryId}`);
      return res.data || [];
    } catch (err) {
      return [];
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await api.post("/api/nation/national_site_zip", selected);
      if (res.status === 200 || res.status === 201) {
        toast.success("Zip assigned successfully.");
        onCancelForm();
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (err) {
      console.log(err);
      toast.error(
        "An error occurred while assigning the Zip. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    const {
      country_id,
      state_id = [],
      city_id = [],
      zip_codes = [],
    } = editingRecord || {};
    setSelected({
      country_id: country_id || null,
      state_id,
      city_id,
      zip_codes,
      site_id,
    });
    setEditingState(editingRecord || {});
  };

  return (
    <div className="min-h-screen bg-white w-full z-[100] fixed top-0 left-0 overflow-y-auto">
      {isLoading && <DotsLoader />}
      <div className="overflow-y-auto h-screen">
        <div className="overflow-y-auto flex items-center justify-between w-full text-client-50 py-2.5 px-5 bg-white shadow-client">
          <h4>{editingState?._id ? "Update Zip" : "Assign Zip"}</h4>
          <Button
            text="X"
            className="mr-2"
            onClick={onCancelForm}
            variant="btn_danger"
          />
        </div>
        <Backdrop onClick={onCancelForm} />
        <div className="!p-5 grid gap-5 mb-[15vh]">
          <div className="w-full grid gap-5 md:grid-cols-2 mb-0 px-6 md:px-12">
            <div className="!py-5 !px-4 md:px-12 bg-white shadow-client">
              <Dropdown
                placeholder="Select Country"
                options={countries}
                optionLabel="name"
                optionValue="_id"
                value={selected.country_id}
                onChange={(e) => handleSelectChange(e, "country_id")}
                className="h-[38px] mb-2 w-full !rounded-[4px] border-[#e5e7eb] hover:border-[#ced4da]"
              />
            </div>

            <div className="!py-5 !px-4 md:px-12 bg-white shadow-client">
              <MultiSelect
                placeholder="Select State"
                options={stateList}
                optionLabel="name"
                optionValue="_id"
                value={stateList?.length ? selected.state_id : ""}
                onChange={(e) => handleSelectChange(e, "state_id")}
                className="h-[38px] mb-2 w-full !rounded-[4px] border-[#e5e7eb] hover:border-[#ced4da]"
                multiple={true}
                filter
                filterPlaceholder="Search for a State..."
                maxSelectedLabels={5}
              />
            </div>
          </div>

          <div className="w-full grid gap-5 md:grid-cols-2 mb-2 px-6 md:px-12">
            <div className="!py-2 !px-2 md:px-12 bg-white shadow-client flex justify-center items-center">
              <VirtualizedMultiSelectBox
                label="Cities"
                labelProp="name"
                valueProp="_id"
                data={filteredCities}
                selected={selected?.city_id}
                onSelect={(values) =>
                  setSelected((prev) => ({ ...prev, city_id: values }))
                }
                className="w-full px-4"
                itemsPerRow={3}
                innerWidth={600}
                innerHeight={250}
              />
            </div>

            <div className="!py-2 !px-2 md:px-12 bg-white shadow-client flex justify-center items-center">
              <VirtualizedMultiSelectBox
                label="Zips"
                labelProp="zip"
                valueProp="zip"
                data={filteredZips}
                selected={selected?.zip_codes}
                onSelect={(values) =>
                  setSelected((prev) => ({ ...prev, zip_codes: values }))
                }
                className="w-full px-4"
                itemsPerRow={3}
                truncateText={false}
                innerWidth={600}
                innerHeight={250}
              />
            </div>
          </div>
        </div>
        <div className="w-full absolute flex justify-center bottom-0 !p-5 bg-white border-t">
          <Button
            text="Reset"
            title="Reset"
            className="py-1.5 px-3 bg-gray-300 text-black mr-2"
            onClick={handleReset}
          />
          <Button
            text="Cancel"
            className="py-1.5 px-3 bg-menu text-white mr-2"
            onClick={onCancelForm}
          />
          <Button
            text="Submit"
            className={`py-1.5 px-3 bg-primary-100 text-white mr-2 ${
              !selected?.zip_codes?.length
                ? "bg-gray-300 cursor-not-allowed"
                : ""
            }`}
            onClick={handleSubmit}
            disabled={!selected?.zip_codes?.length}
          />
        </div>
      </div>
    </div>
  );
};

export default AssignZipToDomain;
