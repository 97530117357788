import React, { useState } from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

function AddMenuOptionForm({
  editingRecord,
  onCancelForm,
  modalTitle,
  setSelectedOptions,
  selectedOptions,
  callManu,
}) {
  const { isLoading, record } = useSelector((state) => state.inbound);
  const [errors] = useState([]);
  const [loader, setLoader] = useState(false);
  let initialValues = {
    option_value: "",
    option_route: "",
    option_route_value: "",
  };
  if (editingRecord) {
    const { option_value, option_route, option_route_value } = editingRecord;
    initialValues = {
      option_value,
      option_route,
      option_route_value: option_route_value || "",
    };
  }
  const optionMenu = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "#", label: "#" },
    { value: "*", label: "*" },
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
    { value: "TIMEOUT", label: "TIMEOUT" },
    { value: "INVALID", label: "INVALID" },
  ];
  const uniqueOptionValues = Array.from(
    new Set(selectedOptions.map((item) => item.option_value))
  );
  const filteredOptions = optionMenu?.filter(
    (option) => !uniqueOptionValues.includes(option.value)
  );
  const selectedOption = optionMenu?.filter(
    ({ value }) => value === editingRecord?.option_value
  );

  const routeOption = [
    { value: "NONE", label: "NONE" },
    { value: "CALLMENU", label: "Call Menu" },
    { value: "INGROUP", label: "Ring Group" },
    { value: "DID", label: "DID" },
    { value: "HANGUP", label: "HANGUP" },
    { value: "EXTENSION", label: "External Number" },
    // { value: "AGI", label: "AGI" },
  ];

  const handleSubmit = async (values) => {
    setLoader(true);
    if (!!editingRecord) {
      const index = selectedOptions?.findIndex(
        ({ option_value }) => option_value === editingRecord?.option_value
      );
      if (index > -1) {
        setSelectedOptions(
          selectedOptions?.map((obj) => {
            if (obj.option_value === editingRecord?.option_value) {
              return { ...obj, ...values };
            }
            return obj;
          })
        );
        onCancelForm();
      }
    } else {
      setSelectedOptions([...selectedOptions, values]);
      onCancelForm();
    }
    try {
      setLoader(false);
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
      modalClass={"!min-w-[950px]"}
    >
      {loader ? <DotsLoader /> : null}
      <div className="grid md:grid-cols-2 gap-3 mt-3 mx-2.5 w-ful">
        <div className="">
          <FormSelectInput
            errors={errors}
            name="option_value"
            label="Options"
            formik={formik}
            options={[...selectedOption, ...filteredOptions]}
            valueProp="value"
            labelProp="label"
          />
        </div>
        <div className="">
          <FormSelectInput
            errors={errors}
            name="option_route"
            label="Routes"
            formik={formik}
            options={routeOption}
            valueProp="value"
            labelProp="label"
            onChange={(e) => {
              formik.setFieldValue("option_route", e);
              formik.setFieldValue("option_route_value", "");
            }}
          />
        </div>
        {formik.values.option_route === "INGROUP" ? (
          <div>
            <FormSelectInput
              errors={errors}
              name="option_route_value"
              label="Forward to Ring Group"
              formik={formik}
              options={record}
              valueProp="group_id"
              labelProp="group_name"
            />
          </div>
        ) : null}
        {formik.values.option_route === "EXTENSION" ? (
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="option_route_value"
              label="Forward to External Number"
              formik={formik}
            />
          </div>
        ) : null}
        {formik.values.option_route === "DID" ? (
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="option_route_value"
              label="Forward to DID"
              formik={formik}
            />
          </div>
        ) : null}
        {formik.values.option_route === "AGI" ? (
          <div className="mb-3 col-span-2">
            <FormInput
              errors={errors}
              name="option_route_value"
              label="AGI"
              formik={formik}
            />
          </div>
        ) : null}
        {formik.values.option_route === "CALLMENU" ? (
          <div>
            <FormSelectInput
              errors={errors}
              name="option_route_value"
              label="Forward to Call Menu"
              formik={formik}
              options={callManu}
              valueProp="menu_id"
              labelProp="menu_name"
            />
          </div>
        ) : null}
      </div>
    </Modal>
  );
}

export default AddMenuOptionForm;
