import React from "react";
import PropertyOccupancy from "../Common/Sections/PropertyOccupancy";
import LocationSection from "../Common/Sections/LocationSection";
import SchedulerSection from "../Common/Sections/SchedulerSection";
import FormRadioGroup from "../../../components/molecules/FormRadioGroup";
import { FormTextArea } from "../../../components";
import TechNote from "../Common/Sections/TechNote";

const AirDuctSection = ({
  formik,
  setIsLoader,
  callSellLeadToServiceDirect,
  checkServiceDirect,
  checkServiceMess,
}) => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-3">
        <PropertyOccupancy formik={formik} fieldsToShow={[]} />
        <div>
          <LocationSection
            formik={formik}
            setIsLoader={setIsLoader}
            callSellLeadToServiceDirect={callSellLeadToServiceDirect}
            checkServiceMess={checkServiceMess}
            checkServiceDirect={checkServiceDirect}
          />
          <SchedulerSection formik={formik} />
        </div>

        <div className="mb-2 flex justify-between border border-[#F0F0F0] p-2 col-span-2">
          <div className="flex items-center">
            <FormRadioGroup
              id="extra_fields.subtype_of_service"
              name="subtype_of_service"
              labelProp="label"
              valueProp="value"
              options={[
                { label: "Repair", value: "Repair" },
                {
                  label: "Clean",
                  value: "Clean",
                },
                {
                  label: "Both",
                  value: "Both",
                },
              ]}
              isOnChange={(e) => {
                formik.setFieldValue("extra_fields.subtype_of_service", e);
              }}
              isCheck={formik.values?.extra_fields?.subtype_of_service || null}
              isRadio={true}
              isHorizontal
            />
          </div>
          <div className="flex items-center">
            <div className="flex items-center">
              <input
                id="extra_fields.is_installation"
                name="extra_fields.is_installation"
                type="checkbox"
                checked={formik.values?.extra_fields?.is_installation}
                onChange={() => {
                  formik.setFieldValue(
                    "extra_fields.is_installation",
                    formik.values?.extra_fields?.is_installation ? false : true
                  );
                }}
              />
              <label className="pl-2 text-[15px]">Installation</label>
            </div>
            <div className="flex items-center pl-3">
              <input
                id="extra_fields.is_inspection"
                name="extra_fields.is_inspection"
                type="checkbox"
                checked={formik.values?.extra_fields?.is_inspection}
                onChange={() => {
                  formik.setFieldValue(
                    "extra_fields.is_inspection",
                    formik.values?.extra_fields?.is_inspection ? false : true
                  );
                }}
              />
              <label className="pl-2 text-[15px]">Inspection</label>
            </div>
          </div>
        </div>

        <TechNote formik={formik} />
        <div className="border border-[#F0F0F0] p-3">
          <label className="mb-2 font-semibold text-sm">Comments</label>
          <FormTextArea
            rows={5}
            name="special_instruction"
            formik={formik}
            type="text"
            value={formik.values?.special_instruction}
          />
        </div>
      </div>
    </div>
  );
};

export default AirDuctSection;
