import React from "react";
import { NavLink } from "react-router-dom";
function SubDropdown(props) {
  const { options, show_navlabel, to } = props;
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
/*   const isAllowOld = (item) => {
    if (user?.tasks?.length > 0 && user?.role) {
      if (user?.tasks?.some((obj) => obj?.name?.includes(item?.task))) {
        return true;
      }
      return false;
    }
  }; */
  const isAllow = (item) => {
    if (user?.menu_permissions?.length > 0 && user?.role) {
      if (user?.menu_permissions?.some((obj) => obj === item?.label)) {
        return true;
      }
      return false;
    }
  };
  return (
    <div className="w-full transition-all">
      <div className="w-full transition-all">
        {options.map((item, key) => {
          let Icon = item?.Icon;
          return isAllow(item) ? (
            <div className="w-full transition-all" key={key}>
              <NavLink
                title={item.label}
                to={`${to}/${item.to}`}
                className="hover:no-underline no-underline hover:text-[#828282] page_link group text-[#828282] flex items-center py-[3px] pl-10 w-full outline-none border-0 cursor-pointer transition-all"
              >
                <Icon className="group-hover:text-primary-100 icons" />
                {show_navlabel && <span className="ml-1">{item.label}</span>}
              </NavLink>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
}

export default SubDropdown;
