import React, { useState, useEffect } from "react";
import ReportHead from "../../../reports/Components/Header";
import Picker from "../../../reports/Components/Picker";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import api from "../../../services/api";
import qs from "qs";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { FaDownload } from "react-icons/fa";
import { /* Button, */ DotsLoader } from "../../../components";
const InBoundReport = () => {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [records, setRecords] = useState([]);
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });

  const currentDate = new Date();
  const initialFilters = {
    from_date:
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
        0
      )
        .toISOString()
        .slice(0, 23) + "Z",
    to_date: new Date().toISOString().slice(0, 23) + "Z",
    size: paginationModel.pageSize,
    page: 1,
  };
  const [filters, setFilters] = useState(initialFilters);

  const getReports = async (filter) => {
    setIsLoading(true);
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const queryParams = qs.stringify(payload);
      const res = await api.post(
        `api/reports/three_way_call_report?${queryParams}`
      );
      if (res.status === 200) {
        setRecords(res.data);
      }
    } catch (err) {
      console.error("Error fetching lead sources:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReports({
      ...paginationModel,
      size: paginationModel.pageSize,
      ...filters,
    });
    // eslint-disable-next-line
  }, []);

  const handlePlay = async (user_call_log_id) => {
    const audioElement = document.getElementById(user_call_log_id);
    if (audioElement) {
      if (currentlyPlayingAudio && currentlyPlayingAudio !== audioElement) {
        currentlyPlayingAudio.pause();
      }
      audioElement.play();
      setCurrentlyPlayingAudio(audioElement);
    }
  };

  const columnDefs = [
    {
      field: "counter",
      filterable: false,
      headerName: "Sr.",
      width: 60,
    },
    { headerName: "Transferred To", field: "xfer_number", flex: 1, minWidth: 150 },
    { headerName: "Customer No.", field: "callerid", flex: 1, minWidth: 150 },
    { headerName: "Length", field: "length_in_sec" },
    {
      field: "lead",
      headerName: "Lead Detail",
      filterable: false,
      width: 180,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>
            Lead Number:
            {params.row.vendor_lead_code ? (
              <span
                onClick={() => handleLeadClick(params.row.vendor_lead_code)}
                className="text-blue-600 hover:underline cursor-pointer ml-1"
              >
                {params.row.vendor_lead_code}
              </span>
            ) : null}
          </span>
        </div>
      ),
    },
    {
      headerName: "Recording",
      filterable: false,
      field: "location",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <>
          <audio
            id={params.row.user_call_log_id}
            controls
            className="h-10 w-[250px] relative"
            onPlay={() => handlePlay(params.row.user_call_log_id)}
          >
            {params.row.location && (
              <source src={params.row.location} type="audio/mpeg" />
            )}
          </audio>
          <a
            href={params.row.location}
            download
            className="h-[30px] w-[30px] z-[100] bg-primary-100 ml-2 flex items-center justify-center rounded-full text-white"
          >
            <FaDownload />
          </a>
        </>
      ),
    },
    {
      field: "callDate",
      type: "date",
      headerName: "Call Date",
      width: 180,
      valueGetter: (params) => new Date(params.row.callDate),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.callDate;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
  ];

  const getRowHeight = (params) => {
    const data = params?.model;
    const rowHeight = data?.callDate ? 60 : 40;
    const cellContentHeight = 0;
    const addressHeight = rowHeight + 10;
    if (data?.tags && data?.tags.length > 0) {
      const tagHeight = 20;
      const numTags = data.tags.length;
      if (addressHeight > rowHeight + tagHeight * numTags + cellContentHeight) {
        return addressHeight;
      } else {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      }
    }
    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    } else {
      return rowHeight + cellContentHeight;
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getReports({
      ...filters,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    const hrs = Math.floor(mins / 60);
    const remainingMins = mins % 60;
    const formattedHours = hrs.toString().padStart(2, "0");
    const formattedMinutes = remainingMins.toString().padStart(2, "0");
    const formattedSeconds = secs.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const fetchLeadData = async (leadCode) => {
    const payload = {
      filters: {
        groupOp: "AND",
        rules: [
          {
            field: "lead_num",
            op: "eq",
            data: leadCode,
          },
        ],
      },
    };
    setIsLoader(true);
    try {
      const res = await api.post("api/leads/report", payload);
      if (res.status === 200 && res.data?.data) {
        const fetchedLeadId = res?.data?.data[0]?._id;
        setIsLoader(false);
        return fetchedLeadId;
      } else {
        toast.error("No data found for the provided lead code");
      }
    } catch (err) {
      toast.error("Error fetching lead data");
      console.error("Error fetching lead data:", err);
    }
    setIsLoader(false);
  };

  const handleLeadClick = async (leadCode) => {
    const fetchedLeadId = await fetchLeadData(leadCode);
    if (fetchedLeadId) {
      const url = `/jobs/update/preview/${fetchedLeadId}`;
      window.open(url, "_blank");
    } else {
      console.error("Lead ID could not be fetched.");
    }
  };

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;

  return (
    <div className="container-fluid">
      {isLoader && <DotsLoader />}
      <ReportHead route="Reports > Commission Report" heading="Login IP" />
      <div className="grid grid-cols-1 gap-1 mb-0">
        <div className="md:col-span-5 !px-0.5 ml-auto">
          <Picker
            filterData={filters}
            setFiltersData={setFilters}
            isDropdown="No"
            seachButtonOnClick={() =>
              getReports({
                ...paginationModel,
                size: paginationModel.pageSize,
                ...filters,
              })
            }
            seachButton={true}
          />
         {/*  <div className="mt-1 flex justify-end">
            <Button
              text="Search"
              variant="btn_submit"
              onClick={() =>
                getReports({
                  ...paginationModel,
                  size: paginationModel.pageSize,
                  ...filters,
                })
              }
            />
          </div> */}
        </div>
      </div>

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={records?.data?.map((record, index) => {
            const {
              full_name,
              user_call_log_id,
              call_date,
              phone_number,
              length_in_sec,
              vendor_lead_code,
              location,
              xfer_number,
              callerid,
            } = record;
            const formattedTimer = formatTime(length_in_sec);
            return {
              full_name,
              _id: index + 1,
              counter: index + offset + 1,
              records: { ...record, _id: index + 1 },
              user_call_log_id,
              phone_number,
              length_in_sec: formattedTimer,
              location,
              vendor_lead_code,
              callDate: dayjs(call_date).format("ddd, MMM D, YYYY h:mm A"),
              xfer_number,
              callerid,
            };
          })}
          totalItems={records?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          gridOptions={getRowHeight}
          searchable="No"
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
        />
      </div>
      <div className="d-none"></div>
    </div>
  );
};

export default InBoundReport;
