import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/molecules/PageHeader";
import api from "../../../services/api";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton } from "@mui/material";
/* import { get_merchants } from "../../../features/merchantSlice"; */
import { FaSearch } from "react-icons/fa";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import dayjs from "dayjs";
function VendorsJobs() {
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [merchant, setMerchant] = useState([]);
  console.log("👊 ~ VendorsInvoice ~ merchant:", merchant);
  const [vendorInvoices, setVendorInvoices] = useState(null);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const get_vendor_jobs = async (filters) => {
    let payload = { ...filters };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    setIsLoading(true);
    try {
      const res = await api.post(`/vendor_api/vendor/merchant_jobs`, {
        ...payload,
      });
      if (res.status === 200) {
        if (payload?.merchant_id && merchant?.length) {
          setVendorInvoices(res.data);
        } else {
          setMerchant(res.data);
        }
      }
    } catch (err) {
      console.log("👊 ~ VendorJobs ~ err:", err);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    // dispatch(
    //   get_merchants({
    //     page: 1,
    //     size: 1000,
    //     ...sortingModel,
    //   })
    // );
    get_vendor_jobs({ page: 1, size: paginationModel?.pageSize });
    // eslint-disable-next-line
  }, []);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    get_vendor_jobs({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
      merchant_id: selectedMerchant,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      get_vendor_jobs({
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
        merchant_id: selectedMerchant,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : // : rule.operator === "isEmpty"
                // ? "eq"
                rule.operator,
          data: rule.value ? rule.value : rule.value === 0 ? rule.value : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    get_vendor_jobs({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      merchant_id: selectedMerchant,
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  /* const paymentStatus = [
    { value: "Created", label: "Created" },
    { value: "Sent", label: "Sent" },
    { value: "Partially Paid", label: "Partially Paid" },
    { value: "Paid", label: "Paid" },
    { value: "Cancelled", label: "Cancelled" },
  ]; */
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    get_vendor_jobs({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      merchant_id: selectedMerchant,
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };
  const columnDefs = [
    {
      field: "job_number",
      header: "Job Numbers",
      align: "left",
      minWidth: 150,
      // renderCell: (params) => (
      //   <div>
      //     <a
      //       target="_blank"
      //       rel="noopener noreferrer"
      //       href={`/jobs/update/${params.row._id}`}
      //       className={`text-[#1976D2] hover:underline`}
      //     >
      //       {params.row.job_number}
      //     </a>
      //     {params?.row.records?.vendor_invoice_id ? (
      //       <small className="text-red-600 ml-2 font-medium">
      //         Invoice Created
      //       </small>
      //     ) : null}
      //   </div>
      // ),
    },
    {
      field: "industry_id",
      headerName: "Industry",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Service Cost",
      field: "service_cost",
      flex: 1,
      minWidth: 100,
      align: "center",
      type: "number",
      renderCell: (params) => (
        <span>{params.row.service_cost?.toFixed(2)}</span>
      ),
    },
    {
      field: "parts_charge",
      headerName: "Parts Charge",
      flex: 1,
      minWidth: 150,
      type: "number",
    },
    {
      field: "net_amount",
      headerName: "Net Amount",
      renderCell: (params) => (
        <span>
          {(params.row.service_cost - params.row.parts_charge)?.toFixed(2)}
        </span>
      ),
      flex: 1,
      minWidth: 150,
      type: "number",
    },
    {
      headerName: "Tech %",
      field: "tech_percentage",
      flex: 1,
      minWidth: 100,
      align: "center",
      renderCell: (params) => {
        return (
          <span>
            {params.row.service_cost === 0 && params.row.tech_fee === 0
              ? 0?.toFixed(2)
              : (
                  (params.row.tech_fee /
                    (params.row.service_cost - params.row.parts_charge)) *
                  100
                )?.toFixed(2)}
          </span>
        );
      },
    },
    {
      headerName: "Tech Share",
      field: "tech_fee",
      flex: 1,
      minWidth: 100,
      align: "center",
      renderCell: (params) => {
        return params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.vendor_profit?.toFixed(2)}
          </span>
        ) : (
          <span>{params.row.tech_fee?.toFixed(2)}</span>
        );
      },
    },
    {
      headerName: "Company Share",
      field: "company_profit",
      flex: 1,
      minWidth: 130,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">{params.row.vendor_owe?.toFixed(2)}</span>
        ) : (
          <span>
            {(
              Math.round(
                (params.row.service_cost - params.row.parts_charge) * 100 -
                  params.row.tech_fee * 100
              ) / 100
            )?.toFixed(2)}
          </span>
        ),
    },
    {
      headerName: "Invoice Created",
      field: "vendor_invoice_id",
      flex: 1,
      type: "singleSelect",
      minWidth: 120,
      align: "center",
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        vendor_invoice_id: value?.value,
      }),
      filterOperators: [
        {
          label: "Yes",
          value: "!=",
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
        {
          label: "No",
          value: "=",
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 120,
      sortable: true,
      // valueGetter: (params) => new Date(params.row.createdAt),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
    },
    { headerName: "Status", field: "job_status_id" },
    {
      headerName: "Scheduled On",
      field: "scheduled_on",
      sortable: true,
      valueGetter: (params) => new Date(params.row.scheduled_on),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
    },
    {
      headerName: "Closed",
      field: "is_closed",
      renderCell: (params) =>
        params.rowNode.type === "group" ? null : <span>{params.value}</span>,
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        is_closed: value?.value,
      }),
      filterOperators: [
        {
          label: "Yes",
          value: true,
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
        {
          label: "No",
          value: false,
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
    },
    {
      headerName: "Closed At",
      field: "closed_date",
      flex: 1,
      minWidth: 120,
      sortable: false,
      // valueGetter: (params) => new Date(params.row.closed_date??null),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
    },
    // { headerName: "Industry", field: "industry_id", flex: 1, minWidth: 100 },
    {
      headerName: "Client Type",
      field: "payment_type",
      flex: 1,
      minWidth: 120,
    },
  ];
  // const columnDefs = [
  //   {
  //     headerName: "Client Name",
  //     field: "name",
  //     flex: 1,
  //     minWidth: 120,
  //   },
  //   {
  //     headerName: "Jobs Total",
  //     field: "service_cost",
  //     flex: 1,
  //     minWidth: 100,
  //     type: "number",
  //   },
  //   {
  //     headerName: "Part Charge",
  //     field: "parts_charge",
  //     flex: 1,
  //     minWidth: 100,
  //     type: "number",
  //   },
  //   {
  //     headerName: "Tech %",
  //     field: "tech_percent",
  //     flex: 1,
  //     minWidth: 100,
  //     type: "number",
  //     renderCell: (params) => (
  //       <span>
  //         {(
  //           (params.row.tech_fee /
  //             (params.row.service_cost - params.row.parts_charge)) *
  //           100
  //         )?.toFixed(2)}
  //       </span>
  //     ),
  //   },
  //   {
  //     headerName: "Tech Share",
  //     field: "tech_fee",
  //     flex: 1,
  //     minWidth: 100,
  //     type: "number",
  //   },
  //   {
  //     headerName: "Company Share",
  //     field: "company_profit",
  //     flex: 1,
  //     minWidth: 100,
  //     type: "number",
  //     renderCell: (params) => (
  //       <span>
  //         {(
  //           Math.round(
  //             params.row.service_cost * 100 - params.row.tech_fee * 100
  //           ) / 100
  //         )?.toFixed(2)}
  //       </span>
  //     ),
  //   },
  //   {
  //     headerName: "Created At",
  //     field: "createdAt",
  //     flex: 1,
  //     minWidth: 120,
  //     sortable: true,
  //     type: "date",
  //     valueGetter: (params) => new Date(params.row.createdAt),
  //     sortComparator: (v1, v2, row1, row2) => {
  //       const date1 = new Date(row1.value);
  //       const date2 = new Date(row2.value);
  //       return date1 - date2;
  //     },
  //     renderCell: (params) => {
  //       const rawDate = params.row.createdAt;
  //       if (params.rowNode.type !== "group" && rawDate) {
  //         return rawDate;
  //       }
  //       return "";
  //     },
  //   },
  //   { headerName: "Status", field: "job_status_id" },
  //   {
  //     headerName: "Scheduled On",
  //     field: "scheduled_on",
  //     sortable: true,
  //     valueGetter: (params) => new Date(params.row.scheduled_on),
  //     sortComparator: (v1, v2, row1, row2) => {
  //       const date1 = new Date(row1.value);
  //       const date2 = new Date(row2.value);
  //       return date1 - date2;
  //     },
  //     type: "date",
  //     renderCell: (params) => {
  //       const rawDate = params.row.scheduled_on;
  //       if (params.rowNode.type !== "group" && rawDate) {
  //         return rawDate;
  //       }
  //       return "";
  //     },
  //   },
  //   {
  //     headerName: "Closed",
  //     field: "is_closed",
  //     renderCell: (params) =>
  //       params.rowNode.type === "group" ? null : <span>{params.value}</span>,
  //     disableColumnMenu: true,
  //     valueGetter: (value) => value?.value,
  //     valueSetter: (value) => ({
  //       ...value?.row,
  //       is_closed: value?.value,
  //     }),
  //     filterOperators: [
  //       {
  //         label: "Yes",
  //         value: true,
  //         getApplyFilterFn: (filterItem) => {
  //           // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
  //           return (params) => {
  //             return params.value === filterItem.value;
  //           };
  //         },
  //       },
  //       {
  //         label: "No",
  //         value: false,
  //         getApplyFilterFn: (filterItem) => {
  //           // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
  //           return (params) => {
  //             return params.value === filterItem.value;
  //           };
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     headerName: "Closed At",
  //     field: "closed_date",
  //     flex: 1,
  //     minWidth: 120,
  //     sortable: false,
  //     valueGetter: (params) => new Date(params.row.closed_date),
  //     sortComparator: (v1, v2, row1, row2) => {
  //       const date1 = new Date(row1.value);
  //       const date2 = new Date(row2.value);
  //       return date1 - date2;
  //     },
  //     type: "date",
  //     renderCell: (params) => {
  //       const rawDate = params.row.closed_date;
  //       if (params.rowNode.type !== "group" && rawDate) {
  //         return rawDate;
  //       }
  //       return "";
  //     },
  //   },
  //   // { headerName: "Industry", field: "industry_id", flex: 1, minWidth: 100 },
  //   {
  //     field: "industry_id",
  //     headerName: "Industry",
  //     type: "singleSelect",
  //     flex: 1,
  //     minWidth: 150,
  //     // getOptionValue: (value) => value?._id,
  //     // getOptionLabel: (value) => value?.name,
  //     // valueOptions: industryDrd,
  //     // renderCell: (params) => params.value?.industry_id,
  //     // valueGetter: (params) => params.row?.industry_id,
  //     // valueFormatter: (params) => params.value?.industry_id,
  //   },
  //   {
  //     headerName: "Client Type",
  //     field: "payment_type",
  //     flex: 1,
  //     minWidth: 120,
  //   },
  // ];
  return (
    <div>
      <PageHeader route="Setting > Jobs" heading="" />
      <div className="mt-2">
        <div className="flex grid grid-cols-5 gap-2">
          {(merchant?.filter(({ recordCount }) => recordCount > 0) || [])?.map(
            (merchant, index) => {
              return (
                <button
                  key={index}
                  className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          selectedMerchant === merchant?.merchant?._id
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
                  onClick={() => {
                    setSelectedMerchant(merchant?.merchant?._id);
                    get_vendor_jobs({
                      ...sortingModel,
                      filters: queryOptions,
                      page: 1,
                      size: paginationModel.pageSize,
                      merchant_id: merchant?.merchant?._id,
                    });
                  }}
                >
                  <span className="text-sm font-pop">
                    <span className="text-sm font-pop font-semibold mr-1">
                      {merchant?.recordCount}
                    </span>
                    {` ${merchant?.merchant?.company_name}`}
                  </span>
                </button>
              );
            }
          )}
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={vendorInvoices?.data?.map((record, index) => {
            const counter = index + 1;
            const {
              job_number,
              createdBy,
              createdAt,
              complete_pickup_address,
              job_status_id,
              industry_id,
              is_closed,
              tech_fee = 0,
              goa_price = 0,
              refund_amount = 0,
              company_profit = 0,
              service_cost = 0,
              vendor_invoice_id,
              scheduled_on,
              closed_date,
              payment_type,
              vendor_id,
              parts_charge,
            } = record;
            const { tech_percentage = 0 } = vendor_id;
            return {
              counter,
              records: { ...record},
              job_number,
              parts_charge,
              createdBy: createdBy?.username,
              job_status_id: job_status_id?.name,
              createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
              industry_id: industry_id?.name,
              complete_pickup_address,
              company_profit: company_profit ? company_profit?.toFixed(2) : 0,
              is_closed: is_closed ? "Yes" : "No",
              payment_type,
              refund_amount: refund_amount ? refund_amount : 0,
              tech_fee: tech_fee ? tech_fee : 0,
              service_cost: service_cost ? service_cost : 0,
              goa_price: goa_price ? goa_price : 0,
              closed_date: closed_date
                ? dayjs(closed_date).format("ddd, MMM D, YYYY h:mm A")
                : "",
              scheduled_on: scheduled_on
                ? dayjs(scheduled_on).format("ddd, MMM D, YYYY h:mm A")
                : "",
              vendor_invoice_id: vendor_invoice_id ? "Yes" : "No",
              tech_percentage,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          paginationModel={paginationModel}
          totalItems={vendorInvoices?.totalItems}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          topPagination
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          columnVisibilityModel={{ "vendors.vendor_type": false }}
        />
      </div>
    </div>
  );
}

export default VendorsJobs;
