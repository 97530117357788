import React, { useState, useEffect, useCallback, useRef } from "react";
import api from "../../../../../src/services/api";
const Autocomplete = ({
  apiUrl,
  onSelect,
  queryParam = "query",
  getLabel = (item) => item.name,
  getValue = (item) => item.phone,
  placeholder = "Search...",
  debounceDelay = 500,
  chatFilter,
  didList,
  selectBox,
  selectBoxOnChange,
  defaultIsLoading,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [isDataStatus, setDataStatus] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const inputRef = useRef(null);
  let userTtype;
  if (chatFilter === "vendor") {
    userTtype = "Vendor";
  } else if (chatFilter === "client") {
    userTtype = "Client";
  } else {
    userTtype = "";
  }

  const fetchSuggestions = useCallback(async () => {
    if (inputValue.trim() === "" || isDataStatus) {
      setSuggestions([]);
      return;
    }

    setIsLoading(true);
    const payload = {
      pattern: inputValue,
      type: userTtype,
    };
    try {
      const response = await api.post(apiUrl, payload);
      if (response.status === 200 || response.status === 201) {
        const apiData = response?.data?.data || [];
        setSuggestions(apiData);

        if (
          apiData.length === 0 &&
          inputValue.length >= 10 &&
          !isNaN(inputValue)
        ) {
          setSuggestions([{ name: "", phone: inputValue }]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [inputValue, apiUrl, userTtype, isDataStatus]);

  useEffect(() => {
    if (isSelecting) return;

    const timeoutId = setTimeout(() => {
      fetchSuggestions();
    }, debounceDelay);

    return () => clearTimeout(timeoutId);
  }, [inputValue, fetchSuggestions, debounceDelay, isSelecting]);

  const handleSelect = (suggestion) => {
    setIsSelecting(true);
    setInputValue(getLabel(suggestion));
    setSuggestions([]);
    onSelect(suggestion);
    setDataStatus(true);
    setIsSelecting(false);
  };

  const handleInputChange = (e) => {
    const newInputValue = e.target.value;
    setInputValue(newInputValue);
    setDataStatus(false);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    if (cleaned.length === 10) {
      return `1 (${cleaned.substring(0, 3)}) ${cleaned.substring(
        3,
        6
      )}-${cleaned.substring(6)}`;
    }
    return phoneNumber;
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    selectBoxOnChange(value);
  };
  return (
    <div
      className={`${
        selectBox
          ? "relative w-full flex items-center px-2 !my-1"
          : "relative w-full px-3 !my-2"
      }`}
    >
      <input
        ref={inputRef}
        type="text"
        className={`shadow-sm appearance-none border rounded-sm w-full !py-1 !pr-1 pl-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm h-[31px] ${
          isLoading || defaultIsLoading ? "opacity-50" : ""
        }`}
        style={{ minHeight: 31, fontSize: "12px" }}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
      />

      {selectBox ? (
        <>
          <div className="relative w-full flex items-center px-3.0">
            {isLoading || defaultIsLoading ? (
              <div className="mx-1 w-5 h-5 border-2 border-t-transparent border-blue-500 animate-spin rounded-full"></div>
            ) : (
              <div className="mx-1 text-xs text-primary-100">OR</div>
            )}
            <select
              className={`ml-1 shadow-sm appearance-none border rounded-sm py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm text-sm ${
                isLoading || defaultIsLoading ? "opacity-50" : ""
              }`}
              style={{ minHeight: 28, fontSize: "12px", color: "#4a5568" }}
              onChange={handleSelectChange}
              value={selectedValue}
            >
              <option value="">Select From No</option>
              {didList?.map((option, index) => (
                <option key={index} value={"1" + option?.did}>
                  {formatPhoneNumber(option?.did)}
                </option>
              ))}
            </select>
          </div>
        </>
      ) : null}

      {suggestions?.length > 0 && (
        <ul className="absolute top-full left-4 w-full bg-white border mt-1 max-h-60 overflow-y-auto z-10">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="p-2 cursor-pointer border-b border-b-gray-300 text-sm bg-orange-50 text-black"
              onClick={() => handleSelect(suggestion)}
            >
              {suggestion?.name ? `${suggestion.name} | ` : ""}{" "}
              {suggestion?.phone ? `${suggestion.phone}` : ""}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
