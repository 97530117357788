import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../../components/molecules/PageHeader";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import api from "../../../services/api";
import { get_industry_drd } from "../../../features/IndustrySlice";
import { DotsLoader } from "../../../components";
import dayjs from "dayjs";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { toast } from "react-toastify";
import { FaTrash, FaRegFileExcel, FaEye, FaEyeSlash } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";
import { HiOutlineRefresh } from "react-icons/hi";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import { MdDownload, MdAddCircleOutline } from "react-icons/md";
import exportReport from "../../../components/molecules/ExcelReport";
import CreateBatchModal from "./CreateBatchModal";
import BatchModal from "./BatchModal";
import utc from "dayjs/plugin/utc";
import AddUpdateToGscServer from "./AddUpdateToGscServer";
import { Tooltip, IconButton } from "@mui/material";
const SeoStatusReport = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { industryDrd } = useSelector((state) => state.industry);
  const [statusRecords, setStatusRecords] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [gscEmaiList, setGscEmilList] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  dayjs.extend(utc);
  const [queryOptions] = React.useState({
    groupOp: "AND",
    rules: [
      {
        field: "is_expired",
        op: "eq",
        data: false,
      },
    ],
  });
  const toggleBatchModal = () => {
    setViewBatchModal((prevState) => !prevState);
  };
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isBatch, setIsBatch] = React.useState(false);
  const [isGscServer, setIsGscServer] = useState(false);
  const [viewBatchModal, setViewBatchModal] = React.useState(false);
  const getStatusReport = async (payload) => {
    setStatusRecords([]);
    setIsLoading(true);
    try {
      const res = await api.post("api/seo_batches/list", payload);
      if (res.status === 200 || res.status === 201) {
        setStatusRecords(res.data);
        if (res.data.records.length > 0) {
          const sortedData = processDomains(res.data.records[0]);
          setSelectedBatch(sortedData);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    getStatusReport({
      ...paginationModel,
      size: paginationModel.pageSize,
    });
    dispatch(get_industry_drd());
    getGscEmailList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateBacklinkColumns = (data) => {
    if (!data || data.length === 0) return [];
    const maxBacklinks = Math.max(
      ...data.map((item) =>
        Array.isArray(item.ahref_domain_Backlink)
          ? item.ahref_domain_Backlink.length
          : 0
      )
    );
    return Array.from({ length: maxBacklinks }, (_, index) => ({
      headerName: `Backlink (${dayjs(
        data[0].ahref_domain_Backlink[index]?.log_date
      )
        .utc()
        .format("YYYY-MM-DD")})`,
      field: `Backlink_${index + 1}`,
      width: 90,
      renderHeader: () => (
        <div class="flex flex-col items-center text-gray-600 pt-2 pb-2 font-medium">
          <span class="text-sm">Backlink</span>
          <span class="text-xs">
            {dayjs(data[0].ahref_domain_Backlink[index]?.log_date)
              .utc()
              .format("YYYY-MM-DD")}
          </span>
        </div>
      ),
      renderCell: (params) => {
        const backlinkCount = params.value;
        let bgColor = "";
        return <div className={`p-2 ${bgColor}`}>{backlinkCount}</div>;
      },
    }));
  };

  const generatePositionColumns = (data) => {
    if (!data || data.length === 0) return [];
    const maxPositions = Math.max(
      ...data.map((item) =>
        Array.isArray(item.pk_position_logs) ? item.pk_position_logs.length : 0
      )
    );
    return Array.from({ length: maxPositions }, (_, index) => ({
      headerName: `Position (${dayjs(data[0].pk_position_logs[index]?.on_date)
        .utc()
        .format("YYYY-MM-DD")})`,
      field: `Position_${index + 1}`,
      width: 90,
      renderHeader: () => (
        <div class="flex flex-col items-center text-gray-600 pt-2 pb-2 font-medium">
          <span class="text-sm">Position</span>
          <span class="text-xs">
            {dayjs(data[0].pk_position_logs[index]?.on_date)
              .utc()
              .format("YYYY-MM-DD")}
          </span>
        </div>
      ),
    }));
  };

  const generateDRColumns = (data) => {
    if (!data || data.length === 0) return [];
    const maxDrs = Math.max(
      ...data.map((item) =>
        Array.isArray(item.ahref_domain_DR) ? item.ahref_domain_DR.length : 0
      )
    );
    return Array.from({ length: maxDrs }, (_, index) => ({
      headerName: `DR (${dayjs(data[0].ahref_domain_DR[index]?.log_date)
        .utc()
        .format("YYYY-MM-DD")})`,
      field: `DR_${index + 1}`,
      width: 90,
      renderHeader: () => (
        <div class="flex flex-col items-center text-gray-600 pt-2 pb-2 font-medium">
          <span class="text-sm">DR</span>
          <span class="text-xs">
            {dayjs(data[0].ahref_domain_DR[index]?.log_date)
              .utc()
              .format("YYYY-MM-DD")}
          </span>
        </div>
      ),
    }));
  };
  const transformData = (data) => {
    const maxBacklinks = generateBacklinkColumns(data).length;
    const maxPositions = generatePositionColumns(data).length;
    const maxDomainRatins = generateDRColumns(data).length;
    return data.map((record, index) => {
      const drArray = Array.isArray(record.ahref_domain_DR)
        ? record.ahref_domain_DR
        : [];
      const positionsArray = Array.isArray(record.pk_position_logs)
        ? record.pk_position_logs
        : [];
      const backlinksArray = Array.isArray(record.ahref_domain_Backlink)
        ? record.ahref_domain_Backlink
        : [];
      const positions = {};
      const backlinks = {};
      const domain_ratings = {};
      let positionDifference = "";
      let backlinkDifference = "";
      let drDifference = "";

      for (let i = 0; i < maxDomainRatins; i++) {
        domain_ratings[`DR_${i + 1}`] = drArray[i]?.DR || 0;
      }

      for (let i = 0; i < maxPositions; i++) {
        positions[`Position_${i + 1}`] = positionsArray[i]?.position || 0;
      }

      for (let i = 0; i < maxBacklinks; i++) {
        backlinks[`Backlink_${i + 1}`] = backlinksArray[i]?.Backlinks || 0;
      }

      if (drArray.length > 1) {
        const lastDomainRating = drArray[drArray.length - 1].DR;
        const previousDomainRating = drArray[drArray.length - 2].DR;
        drDifference = (previousDomainRating - lastDomainRating).toFixed(2);
      }
      if (positionsArray.length > 1) {
        const lastPosition = positionsArray[positionsArray.length - 1].position;
        const previousBeforeLastPosition =
          positionsArray[positionsArray.length - 2].position;
        positionDifference = previousBeforeLastPosition - lastPosition;
      }

      if (backlinksArray.length > 1) {
        const lastBacklink =
          backlinksArray[backlinksArray.length - 1].Backlinks;
        const previousBacklink =
          backlinksArray[backlinksArray.length - 2].Backlinks;
        backlinkDifference = lastBacklink - previousBacklink;
      }
      return {
        counter: index + 1,
        domain: record?.domain,
        phone: record?.phone,
        state: record?.state,
        city: record?.city,
        generate_domain_by: record?.generate_domain_by,
        population: record?.population,
        industry_id: record?.industry_id,
        GSC_server_id: record?.GSC_server_id,
        industry_name: industryDrd?.find(
          (ind) => ind._id === record?.industry_id
        )?.name,
        latest_PK_ahref_keyword_difficulty:
          record?.latest_PK_ahref_keyword_difficulty?.difficulty || "N/A",
        latest_PK_ahref_keyword_volume:
          record?.latest_PK_ahref_keyword_volume?.volume || "N/A",
        ...domain_ratings,
        ...backlinks,
        ...positions,
        drDifference: drDifference || 0,
        positionDifference: positionDifference || 0,
        backlinkDifference: backlinkDifference || 0,
      };
    });
  };

  const staticColumns = [
    { headerName: "#", field: "counter", filterable: false, width: 40 },
    {
      headerName: "Domain",
      field: "domain",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="flex flex-col">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://${params?.value}`}
              className="no-underline hover:underline cursor-pointer"
            >
              {params?.row?.GSC_server_id ? (
                <Tooltip title="added in GSC Server" className="mr-1">
                  <IconButton size="small">
                    <BsGraphUpArrow size="18" style={{ color: "#000000" }} />
                  </IconButton>
                </Tooltip>
              ) : null}
              {params?.value}
            </a>
          </div>
        );
      },
    },
    { headerName: "Phone", field: "phone", width: 120 },
    { headerName: "State", field: "state", width: 120 },
    { headerName: "City", field: "city", width: 120 },
    { headerName: "Generated By", field: "generate_domain_by", width: 120 },
    { headerName: "Population", field: "population", width: 120 },
    {
      field: "industry_id",
      headerName: "Industry",
      column: "industry_id",
      type: "singleSelect",
      minWidth: 120,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      valueGetter: (params) => params.row.industry_id,
      renderCell: (params) => params.row.industry_name,
      valueSetter: (params) => {
        const industry = industryDrd.find((ind) => ind._id === params.value);
        return { ...params.row, industry_id: industry ? industry._id : "" };
      },
    },
    {
      headerName: "PK Difficulty",
      field: "latest_PK_ahref_keyword_difficulty",
      width: 120,
    },
    {
      headerName: "PK Volume",
      field: "latest_PK_ahref_keyword_volume",
      width: 120,
    },
  ];
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };

  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };

  const handleTabChange = (index) => {
    setSelectedRows([]);
    setActiveTabIndex(index);
    const sortedData = processDomains(statusRecords?.records[index]);
    setSelectedBatch(sortedData);
    setViewBatchModal(false);
  };
  const fetchKeywords = async () => {
    if (!selectedRows?.length) {
      toast.error("Domains not selected!");
      return;
    }
    const c = window.confirm(
      `Are you sure you want to fetch the data for ${selectedRows?.length} domains?`
    );
    if (!c) return;
    setIsLoading(true);
    try {
      const payload = {
        domains: selectedRows,
      };
      const res = await api.post("api/domains/fetch_domain_kd_and_kv", payload);
      if (res.status === 200 || res.status === 201) {
        toast.success("Keywords and Volume fetched successfully");
        await getStatusReport({
          ...paginationModel,
          size: paginationModel.pageSize,
        });
        const sortedData = processDomains(
          statusRecords?.records[activeTabIndex]
        );
        setSelectedBatch(sortedData);
      } else {
        toast.error("Keywords and Volume couldn't be fetched");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Keywords and Volume couldn't be fetched");
      console.log(err);
    }
  };

  const fetchAhref = async (param) => {
    if (!selectedRows?.length) {
      toast.error("Domains not selected!");
      return;
    }
    let apiUrl;
    let successMessage;
    let errorMessage;
    switch (param) {
      case "fetch_dr":
        apiUrl = "api/domains/fetch_pk_DR_by_domains";
        successMessage = "Domain ratings fetched successfully";
        errorMessage = "Domain ratings couldn't be fetched";
        break;
      case "fetch_backlink_status":
        apiUrl = "api/domains/fetch_pk_backlink_status_by_domains";
        successMessage = "Backlink status fetched successfully";
        errorMessage = "Backlink status couldn't be fetched";
        break;
      default:
        toast.error("Invalid parameter provided");
        return;
    }
    const confirmFetch = window.confirm(
      `Are you sure you want to fetch the data for ${selectedRows?.length} domains?`
    );
    if (!confirmFetch) return;
    setIsLoading(true);
    try {
      const payload = {
        domains: selectedRows,
      };
      const res = await api.post(apiUrl, payload);
      if (res.status === 200 || res.status === 201) {
        await getStatusReport({
          ...paginationModel,
          size: paginationModel.pageSize,
        });
        const sortedData = processDomains(
          statusRecords?.records[activeTabIndex]
        );
        setSelectedBatch(sortedData);
        toast.success(successMessage);
      } else {
        toast.error(errorMessage);
      }
    } catch (err) {
      toast.error(errorMessage);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="flex items-center space-x-1 my-0">
        <div className="flex space-x-1">
          <GridToolbarColumnsButton className="!text-[#042a42]" />
          <GridToolbarDensitySelector className="!text-[#042a42]" />
          <GridToolbarFilterButton
            ref={setFilterButtonEl}
            className="!text-[#042a42]"
          />
          <div className="w-fit flex items-center">
            <Dropdown drop="down">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="rounded-0 w-full md:w-auto py-1. px- align-middle border-0 bg-transparent text-black focus:!shadow-none text-[13px] font-medium"
              >
                ACTIONS
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-fit min-w-[400px] transform -translate-x-1/2 flex flex-row px-1.5">
                <button
                  onClick={fetchKeywords}
                  className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white font-normal"
                >
                  <div className="flex flex-row items-center pl-1">
                    <MdDownload size={18} />
                    <span className="ml-2">Fetch KD & KV</span>
                  </div>
                </button>
                <button
                  onClick={(e) => fetchAhref("fetch_dr")}
                  className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                >
                  <div className="flex flex-row items-center pl-1">
                    <MdDownload size={18} />
                    <span className="ml-2">Fetch Ahref DR</span>
                  </div>
                </button>
                <button
                  variant="text"
                  onClick={(e) => fetchAhref("fetch_backlink_status")}
                  className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                >
                  <div className="flex flex-row items-center pl-1">
                    <MdDownload size={18} />
                    <span className="ml-2">Fetch Ahref backlink status</span>
                  </div>
                </button>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {!selectedBatch.hasOwnProperty("valueserp_id") ||
          selectedBatch?.valueserp_id === "null" ||
          !selectedBatch?.valueserp_id ? (
            <Button
              variant="text"
              startIcon={<MdAddCircleOutline size={16} />}
              onClick={(e) => setIsBatch(true)}
              sx={{
                borderRadius: "6px",
                marginRight: "5px",
                border: "1px solid",
                borderColor: "#e8eaee",
                height: "26px",
                fontSize: "0.8125rem",
                paddingLeft: 1,
                paddingRight: 1,
              }}
            >
              Create valueSERP Batch
            </Button>
          ) : (
            <Button
              variant="text"
              startIcon={
                viewBatchModal ? <FaEyeSlash size={16} /> : <FaEye size={16} />
              }
              onClick={toggleBatchModal}
              sx={{
                borderRadius: "6px",
                marginRight: "5px",
                border: "1px solid",
                borderColor: "#e8eaee",
                height: "26px",
                fontSize: "0.8125rem",
                paddingLeft: 1,
                paddingRight: 1,
              }}
            >
              {viewBatchModal ? "Hide Batch" : "Show Batch"}
            </Button>
          )}
          <Button
            variant="text"
            startIcon={<MdAddCircleOutline size={16} />}
            onClick={(e) => openGscServerFormHandler(0)}
            sx={{
              borderRadius: "6px",
              marginRight: "5px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              fontSize: "0.8125rem",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Add To GSC
          </Button>
        </div>
        <div className="ml-auto -mt-2">
          <Button
            variant="text"
            startIcon={<FaRegFileExcel size={16} />}
            onClick={(e) => handleExport()}
            sx={{
              borderRadius: "6px",
              marginRight: "5px",
              border: "0px solid",
              borderColor: "#e8eaee",
              height: "26px",
              fontSize: "0.8125rem",
              paddingLeft: 1,
              paddingRight: 1,
              color: "#000",
            }}
          >
            Export Excel
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }
  const generateCheckboxColumns = (data) => {
    if (!data || data.length === 0) return [];

    const handleSelectAll = (event) => {
      const isChecked = event.target.checked;
      setSelectedRows(isChecked ? data.map((item) => item.domain) : []);
    };

    const handleRowSelect = (domain) => (event) => {
      const isChecked = event.target.checked;
      setSelectedRows((prev) => {
        if (isChecked) {
          return [...prev, domain];
        } else {
          return prev.filter((item) => item !== domain);
        }
      });
    };
    return [
      {
        field: "checkbox",
        renderHeader: (params) => (
          <input
            type="checkbox"
            onChange={handleSelectAll}
            checked={
              data.length > 0 &&
              data.every((item) => selectedRows.includes(item.domain))
            }
            className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          />
        ),
        filterable: false,
        sortable: false,
        width: 60,
        renderCell: (params) => (
          <input
            type="checkbox"
            className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
            checked={selectedRows.includes(params.row.domain)}
            onChange={handleRowSelect(params.row.domain)}
          />
        ),
        disableColumnMenu: true,
      },
    ];
  };

  const handleDelete = async (batch_id, batch_name) => {
    const c = window.confirm(
      `Are you sure want to delete ${batch_name} batch?`
    );
    if (!c) return;
    setIsLoading(true);
    try {
      const res = await api.delete(`/api/seo_batches/${batch_id}`);
      if (res.status === 200) {
        getStatusReport({
          ...paginationModel,
          size: paginationModel.pageSize,
        });
        setActiveTabIndex(0);
        toast.success("SEO Batch deleted successfully");
      } else {
        toast.error("SEO Batch couldn't be deleted");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("SEO Batch couldn't be deleted");
      console.log(err);
    }
  };

  const handleExport = async () => {
    if (!selectedBatch || !selectedBatch.domain_ids) return;
    const generateDynamicHeaders = (data) => {
      const backlinkColumns = generateBacklinkColumns(data);
      const positionColumns = generatePositionColumns(data);
      const domainRatingColumns = generateDRColumns(data);
      const headers = [
        "Sr",
        "Domain",
        "Phone",
        "State",
        "City",
        "Generated By",
        "Population",
        "Industry",
        "PK Difficulty",
        "PK Volume",
      ];
      headers.push(...domainRatingColumns.map((col) => col.headerName));
      if (domainRatingColumns.length > 0) {
        headers.push("DR Change");
      }
      headers.push(...backlinkColumns.map((col) => col.headerName));
      if (backlinkColumns.length > 0) {
        headers.push("Backlink Change");
      }
      headers.push(...positionColumns.map((col) => col.headerName));
      if (positionColumns.length > 0) {
        headers.push("Position Change");
      }
      return headers;
    };

    const generateDRColumns = (data) => {
      const maxDomainRating = Math.max(
        ...data.map((item) =>
          Array.isArray(item.ahref_domain_DR) ? item.ahref_domain_DR.length : 0
        )
      );
      return Array.from({ length: maxDomainRating }, (_, index) => ({
        headerName: `Backlink (${dayjs(data[0].ahref_domain_DR[index]?.log_date)
          .utc()
          .format("YYYY-MM-DD")})`,
      }));
    };
    const generateBacklinkColumns = (data) => {
      const maxBacklinks = Math.max(
        ...data.map((item) =>
          Array.isArray(item.ahref_domain_Backlink)
            ? item.ahref_domain_Backlink.length
            : 0
        )
      );
      return Array.from({ length: maxBacklinks }, (_, index) => ({
        headerName: `Backlink (${dayjs(
          data[0].ahref_domain_Backlink[index]?.log_date
        )
          .utc()
          .format("YYYY-MM-DD")})`,
      }));
    };

    const generatePositionColumns = (data) => {
      const maxPositions = Math.max(
        ...data.map((item) =>
          Array.isArray(item.pk_position_logs)
            ? item.pk_position_logs.length
            : 0
        )
      );
      return Array.from({ length: maxPositions }, (_, index) => ({
        headerName: `Position (${dayjs(data[0].pk_position_logs[index]?.on_date)
          .utc()
          .format("YYYY-MM-DD")})`,
      }));
    };

    const prepareExportData = (data) => {
      const maxBacklinks = generateBacklinkColumns(data).length;
      const maxPositions = generatePositionColumns(data).length;
      const maxDRatings = generateDRColumns(data).length;

      return data.map((record, index) => {
        const positionsArray = Array.isArray(record.pk_position_logs)
          ? record.pk_position_logs
          : [];
        const backlinksArray = Array.isArray(record.ahref_domain_Backlink)
          ? record.ahref_domain_Backlink
          : [];
        const drArray = Array.isArray(record.ahref_domain_DR)
          ? record.ahref_domain_DR
          : [];
        const positions = {};
        const backlinks = {};
        const domain_ratings = {};
        let positionDifference = "";
        let backlinkDifference = "";
        let drDifference = "";

        for (let i = 0; i < maxDRatings; i++) {
          domain_ratings[`DR_${i + 1}`] = drArray[i]?.DR || 0;
        }

        for (let i = 0; i < maxPositions; i++) {
          positions[`Position_${i + 1}`] = positionsArray[i]?.position || 0;
        }

        for (let i = 0; i < maxBacklinks; i++) {
          backlinks[`Backlink_${i + 1}`] = backlinksArray[i]?.Backlinks || 0;
        }

        if (drArray.length > 1) {
          const lastDR = drArray[drArray.length - 1].DR;
          const previousDR = drArray[drArray.length - 2].DR;
          drDifference = lastDR - previousDR;
        }
        if (positionsArray.length > 1) {
          const lastPosition =
            positionsArray[positionsArray.length - 1].position;
          const previousBeforeLastPosition =
            positionsArray[positionsArray.length - 2].position;
          positionDifference = previousBeforeLastPosition - lastPosition;
        }

        if (backlinksArray.length > 1) {
          const lastBacklink =
            backlinksArray[backlinksArray.length - 1].Backlinks;
          const previousBacklink =
            backlinksArray[backlinksArray.length - 2].Backlinks;
          backlinkDifference = lastBacklink - previousBacklink;
        }
        return {
          counter: index + 1,
          domain: record?.domain || "",
          phone: record?.phone ? "1" + record.phone : "",
          state: record?.state || "",
          city: record?.city || "",
          generate_domain_by: record?.generate_domain_by || "",
          population: record?.population || "",
          industry_name:
            industryDrd?.find((ind) => ind._id === record?.industry_id)?.name ||
            "N/A",
          latest_PK_ahref_keyword_difficulty:
            record?.latest_PK_ahref_keyword_difficulty?.difficulty || "N/A",
          latest_PK_ahref_keyword_volume:
            record?.latest_PK_ahref_keyword_volume?.volume || "N/A",
          ...domain_ratings,
          drDifference: maxDRatings >= 1 ? drDifference || 0 : "",
          ...backlinks,
          backlinkDifference: maxBacklinks >= 1 ? backlinkDifference || 0 : "",
          ...positions,
          positionDifference: maxPositions >= 1 ? positionDifference || 0 : "",
        };
      });
    };
    const Header = generateDynamicHeaders(selectedBatch.domain_ids);
    const items = prepareExportData(selectedBatch.domain_ids);
    const file_name = selectedBatch.batch_name || "SEO_Report";
    exportReport(items, Header, file_name, "SEO Batch Report");
  };

  const processDomains = (record) => {
    const updatedDomains = record?.domain_ids?.map((domain) => {
      const dateMap = new Map();

      if (domain?.ahref_domain_DR?.length) {
        domain?.ahref_domain_DR?.forEach((entry) => {
          if (entry && entry.log_date) {
            const date = new Date(entry.log_date).toISOString().split("T")[0];
            const existingEntry = dateMap.get(date);
            if (
              !existingEntry ||
              (entry.log_date &&
                new Date(entry.log_date) >
                  new Date(existingEntry.ahref_domain_DR?.log_date || 0))
            ) {
              dateMap.set(date, {
                ...existingEntry,
                ahref_domain_DR: entry,
              });
            }
          }
        });
      }

      if (domain?.ahref_domain_Backlink?.length) {
        domain?.ahref_domain_Backlink?.forEach((entry) => {
          if (entry && entry.log_date) {
            const date = new Date(entry.log_date).toISOString().split("T")[0];
            const existingEntry = dateMap.get(date);
            if (
              !existingEntry ||
              (entry.log_date &&
                new Date(entry.log_date) >
                  new Date(existingEntry.ahref_domain_Backlink?.log_date || 0))
            ) {
              dateMap.set(date, {
                ...existingEntry,
                ahref_domain_Backlink: entry,
              });
            }
          }
        });
      }
      if (domain?.pk_position_logs?.length) {
        domain?.pk_position_logs?.forEach((entry) => {
          if (entry && entry.on_date) {
            const date = new Date(entry.on_date).toISOString().split("T")[0];
            const existingEntry = dateMap.get(date);
            if (
              !existingEntry ||
              (entry.on_date &&
                new Date(entry.on_date) >
                  new Date(existingEntry.pk_position_logs?.on_date || 0))
            ) {
              dateMap.set(date, {
                ...existingEntry,
                pk_position_logs: entry,
              });
            }
          }
        });
      }
      const uniqueDates = Array.from(dateMap.keys()).sort(
        (a, b) => new Date(a) - new Date(b)
      );

      const latestDomainRating = uniqueDates
        .map((date) => dateMap.get(date).ahref_domain_DR)
        .filter(Boolean);
      const latestAhrefBacklinks = uniqueDates
        .map((date) => dateMap.get(date).ahref_domain_Backlink)
        .filter(Boolean);
      const latestPkPositionLogs = uniqueDates
        .map((date) => dateMap.get(date).pk_position_logs)
        .filter(Boolean);

      return {
        ...domain,
        ahref_domain_DR: latestDomainRating,
        ahref_domain_Backlink: latestAhrefBacklinks,
        pk_position_logs: latestPkPositionLogs,
      };
    });
    return {
      ...record,
      domain_ids: updatedDomains,
    };
  };
  const RefreshButton = () => {
    return (
      <button
        className="bg-primary-100 text-white ml-2 h-[35px] w-[35px] flex justify-center items-center rounded-sm"
        //onClick={getSERPReport}
      >
        <HiOutlineRefresh />
      </button>
    );
  };

  const getGscEmailList = async () => {
    try {
      const res = await api.get("/api/gsc/server");
      if (res.status === 200 || res.status === 201) {
        const filterData = res?.data?.records?.filter(
          ({ app_deployed }) => app_deployed
        );
        setGscEmilList(filterData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const openGscServerFormHandler = () => {
    if (selectedRows?.length) {
      setIsGscServer(true);
    } else {
      toast.error("Please select a domain.");
    }
  };
  const cancelFormHandler = () => {
    getGscEmailList();
    setIsGscServer(false);
    setSelectedRows([]);
  };
  return (
    <>
      <div className="bg-white my-3 rounded">
        {isLoading ? <DotsLoader /> : null}
        <PageHeader heading="SEO Status Report" addIcon="No" />
        {isBatch ? (
          <CreateBatchModal
            onClose={() => setIsBatch(false)}
            filters={queryOptions}
            totalItems={selectedBatch?.domain_ids?.length}
            selectedBatch={selectedBatch}
            getStatusReport={getStatusReport}
            paginationModel={paginationModel}
            setActiveTabIndex={setActiveTabIndex}
          />
        ) : null}

        {isGscServer ? (
          <AddUpdateToGscServer
            modalTitle="Add/Update To GSC"
            onCancelForm={cancelFormHandler}
            selectedData={selectedRows}
            gscEmaiList={gscEmaiList}
          />
        ) : null}
        {!statusRecords?.records?.length && !isLoading ? (
          <div className="bg-white my-3 p-2 border rounded text-center font-semibold mt-5">
            No Records Found.
          </div>
        ) : (
          <Tabs selectedIndex={activeTabIndex} onSelect={handleTabChange}>
            <TabList className="!flex !flex-wrap !pl-0 react-tabs__tab-list !border-b-white mt-3 ml-2">
              {statusRecords?.records?.map((batch, index) => (
                <Tab
                  key={batch._id}
                  className={`!flex !justify-between !rounded !mx-1 bg-[#f9f9f9] border border-[#dee2e6] -bottom-px relative py-1 px-3 cursor-pointer flex-[0_0_23%] w-full md:max-w-[18%] font-semibold h-[35px] ${
                    activeTabIndex === index
                      ? "bg-primary-100 text-white"
                      : "bg-gray-200"
                  } border border-[#dee2e6] -bottom-px relative`}
                >
                  {batch.batch_name} - {batch?.domain_ids?.length} domains
                  <button
                    className="mt-1 text-black"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDelete(batch?._id, batch?.batch_name);
                    }}
                    title={"Delete batch"}
                  >
                    <FaTrash />
                  </button>
                </Tab>
              ))}
              <button
                className="bg-primary-100 text-white ml-auto h-[35px] w-[35px] flex justify-center items-center rounded-sm mt-0"
                onClick={() => {
                  setActiveTabIndex(0);
                  getStatusReport({
                    ...paginationModel,
                    size: paginationModel.pageSize,
                  });
                  getGscEmailList();
                  setSelectedRows([]);
                }}
              >
                <HiOutlineRefresh />
              </button>
            </TabList>
            {viewBatchModal && (
              <BatchModal
                onCancelForm={() => setViewBatchModal(false)}
                selectedBatch={selectedBatch}
              />
            )}
            {statusRecords?.records?.map((batch, index) => {
              const sortedData = processDomains(batch);
              const dRColumns = generateDRColumns(sortedData.domain_ids);
              const positionColumns = generatePositionColumns(
                sortedData.domain_ids
              );
              const backlinkColumns = generateBacklinkColumns(
                sortedData.domain_ids
              );
              const columnDefs = [
                ...generateCheckboxColumns(sortedData.domain_ids),
                ...staticColumns,
                ...dRColumns,
                ...(dRColumns.length > 0
                  ? [
                      {
                        headerName: "DR Change",
                        field: "drDifference",
                        width: 150,
                      },
                    ]
                  : []),
                ...backlinkColumns,
                ...(backlinkColumns.length > 0
                  ? [
                      {
                        headerName: "Backlink Change",
                        field: "backlinkDifference",
                        width: 150,
                      },
                    ]
                  : []),
                ...positionColumns,
                ...(positionColumns.length > 0
                  ? [
                      {
                        headerName: "Position Change",
                        field: "positionDifference",
                        width: 150,
                      },
                    ]
                  : []),
              ];
              return (
                <TabPanel key={batch._id}>
                  {selectedBatch && selectedBatch._id === batch._id && (
                    <div className="bg-white my-2 border rounded">
                      <MUIDataTable
                        columnDefs={columnDefs}
                        items={transformData(sortedData.domain_ids)}
                        totalItems={batch.domain_ids.length}
                        searchable="No"
                        showCount="No"
                        height="auto"
                        isLoading={isLoading}
                        paginationMode="client"
                        onPaginationModelChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        paginationModel={paginationModel}
                        CustomToolbar={CustomToolbar}
                        columnHeaderHeight="80"
                        AfterSearch={RefreshButton}
                        disableColumnMenu="No"
                      />
                    </div>
                  )}
                </TabPanel>
              );
            })}
          </Tabs>
        )}
      </div>
    </>
  );
};

export default SeoStatusReport;
