import React, { useCallback, useState } from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TagsInput from "../../../components/atoms/CustomTagsInput";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import api from "../../../services/api";
import { MdCancel } from "react-icons/md";
import CampaignGroup from "./CampaingGroup";
function ImportDIDsForm({
  editingRecord,
  onCancelForm,
  modalTitle,
  callManu,
  affiliates,
}) {
  const { dialerUsers } = useSelector((state) => state.users);
  const inbound = useSelector((state) => state.inbound);
  const { record } = inbound;
  const [errors, setErrors] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loader, setLoader] = useState(false);
  const [dids, setDids] = useState([]);
  const [provider, setProvider] = useState("Intel");
  const [failed, setFailed] = useState([]);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  let initialValues = {
    did_route: "EXTEN",
    group_id: "",
    menu_id: "",
    extension: "",
    did_active: "Y",
    user: "",
    voicemail_ext: "",
    user_group: user?.user_group?.user_group,
    prefix: "",
    cx: user?.conexcs_settings?.cx || "",
    destination_ip: user?.conexcs_settings?.destination_ip || "",
    dial_out_prefix: user?.conexcs_settings?.dial_out_prefix || "",
    route_external: "NO",
    custom_one: "",
    custom_two: "",
  };
  const onDrop = useCallback((acceptedFiles) => {
    let files = acceptedFiles[0];
    setSelectedFile(files);
    handleFile(files);
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });
  function validateForm(data) {
    const errors = [];
    if (data?.did_route === "EXTEN" && !data?.extension) {
      errors.push({ extension: "extension is required" });
    }
    if (data?.did_route === "IN_GROUP" && !data?.group_id) {
      errors.push({ group_id: "group_id is required" });
    }
    if (data?.did_route === "CALLMENU" && !data?.menu_id) {
      errors.push({ menu_id: "menu_id is required" });
    }
    setErrors(errors);
    return errors;
  }
  const handleSubmit = async (values) => {
    const errors = validateForm(formik.values);
    if (errors?.length) return;
    const payload = dids?.map((item) => ({
      ...item,
      ...values,
      custom_two: item.custom_two,
    }));
    setLoader(true);
    try {
      await api.post("/api/cc/dids/import_did", {
        dids: payload,
        provider,
      });
      toast.success("DID Successfully Coppied");
      onCancelForm();
    } catch (error) {
      toast.error(`DID couldn't be ${error.message}`);
    }

    setLoader(false);
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  const handleFile = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;

      const columnMappings = {
        did: "DID",
        siteName: "Site Name",
        industryId: "Industry Id",
        industryName: "Industry Name",
        domain: "Domain",
      };

      if (file.name.endsWith(".xlsx")) {
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (!excelData.length) return toast.error("No record in this file");

        const headerRow = excelData[0];

        // Find column indexes dynamically
        const columnIndexes = Object.keys(columnMappings).reduce((acc, key) => {
          acc[key] = headerRow.findIndex(
            (col) => col?.toLowerCase() === columnMappings[key].toLowerCase()
          );
          return acc;
        }, {});

        if (columnIndexes.did === -1)
          return toast.error("DID column not found");

        // Map data
        const dataArray = excelData.slice(1).map((row) => ({
          did_pattern: row[columnIndexes.did] ?? "",
          did_description: row[columnIndexes.siteName] ?? "",
          did_carrier_description: row[columnIndexes.industryId] ?? "",
          industry_name: row[columnIndexes.industryName] ?? "",
          custom_two: row[columnIndexes.domain] ?? "", 
        }));

        setDids(dataArray);
      } else if (file.name.endsWith(".csv")) {
        Papa.parse(file, {
          complete: (result) => {
            if (!result.data.length)
              return toast.error("No record in this file");

            const headerRow = result.data[0];

            const columnIndexes = Object.keys(columnMappings).reduce(
              (acc, key) => {
                acc[key] = headerRow.findIndex(
                  (col) =>
                    col?.toLowerCase() === columnMappings[key].toLowerCase()
                );
                return acc;
              },
              {}
            );

            if (columnIndexes.did === -1)
              return toast.error("DID column not found");

            const dataArray = result.data.slice(1).map((row) => ({
              did_pattern: row[columnIndexes.did] ?? "",
              did_description: row[columnIndexes.siteName] ?? "",
              did_carrier_description: row[columnIndexes.industryId] ?? "",
              industry_name: row[columnIndexes.industryName] ?? "",
              custom_two: row[columnIndexes.domain] ?? "", 
            }));

            setDids(dataArray);
          },
          skipEmptyLines: true,
        });
      }
    };

    if (file.name.endsWith(".xlsx")) {
      reader.readAsArrayBuffer(file);
    } else if (file.name.endsWith(".csv")) {
      reader.readAsText(file);
    }
  };

  const generateRandomData = () => {
    const headers = [
      "DID",
      "Site Name",
      "Industry Id",
      "Industry Name",
      "Domain",
    ];
    const randomData = [headers];
    const numEntries = 10;

    for (let i = 0; i < numEntries; i++) {
      const entry = [
        `did_${i}`,
        `Site Name_${i}`,
        `${i}`,
        `Industry Name_${i}`,
        `domain_${i}`,
      ];
      randomData.push(entry);
    }
    return randomData;
  };

  const exportToExcel = () => {
    const data = generateRandomData();
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "random_data.xlsx");
  };

  function copyInvalidData() {
    // Get all the badge elements that contain the invalid data
    const badges = document.querySelectorAll(".bg-danger");
    // Extract the text from each badge element and join them with a separator
    const dataToCopy = Array.from(badges)
      ?.map((badge) => badge.textContent)
      ?.join("\n");
    // Copy the text to the clipboard
    const textarea = document.createElement("textarea");
    textarea.value = dataToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.success("All invalid DIDs have been copied to the clipboard!");
  }

  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={loader}
      onClick={onCancelForm}
    >
      {loader ? <DotsLoader /> : null}
      <div className="grid md:grid-cols-3 gap-x-5 mt-3 ">
        <div className="text-base font-medium col-span-3">
          <span className="font-semibold text-lg font_pop text-red-600">
            Note:{" "}
          </span>{" "}
          {/* File must be " */}
          <span className="font-semibold text-lg font_pop text-violet-600">
            ".xlsx"
          </span>{" "}
          and
          <span className="font-semibold text-lg font_pop text-violet-600">
            {" "}
            ".csv"
          </span>{" "}
          files can be imported here which must contain column headers named
          <span className="font-semibold text-lg font_pop text-violet-600">
            {" "}
            "DID", "Site Name", "Industry Id", "Industry Name"
          </span>{" "}
          and
          <span className="font-semibold text-lg font_pop text-violet-600">
            {" "}
             "Domain".
          </span>
          <span
            className="font-normal text-normal font_pop text-blue-600 underline cursor-pointer ml-3"
            onClick={exportToExcel}
          >
            Sample
          </span>
        </div>
        <div className="col-span-3 !my-3">
          <div
            className={`w-full flex justify-center border-2 border-gray-300 border-dashed rounded-md !p-2 hover:border-gray-400 focus:outline-none ${
              isDragActive ? "bg-gray-100" : "bg-white"
            }`}
            {...getRootProps()}
          >
             <input {...getInputProps()} />
            <label
              className={`flex justify-center w-full ${"h-[80px]"} px-4 transition appearance-none cursor-pointer`}
            >
              {selectedFile ? (
                <span className="flex items-center space-x-2">
                  <div className="flex flex-col text-center">
                    <span className="font-medium text-gray-600">
                      File Name: {selectedFile?.name}
                    </span>
                    <span className="text-gray-600" onClick={open}>
                      Size:{" "}
                      {selectedFile?.size > 1000 && selectedFile.size < 1000000
                        ? (selectedFile.size / 1000).toFixed(2) + " KB"
                        : (selectedFile.size / 1000000).toFixed(2) + " MB"}
                    </span>
                    <span className="font-medium text-red-600">
                      Total DIDs added: {dids?.length}
                    </span>
                  </div>
                </span>
              ) : (
                <span className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <span className="font-medium text-gray-600">
                    Drop files to Attach, or{" "}
                    <span className="text-blue-600 underline" onClick={open}>
                      browse
                    </span>
                  </span>
                </span>
              )}
             
            </label>
          </div>
        </div>

        <div className="mb-3">
          <FormSelectInput
            errors={errors}
            name="did_route"
            label="DID Route"
            formik={formik}
            options={[
              { value: "EXTEN", label: "External Number" },
              { value: "IN_GROUP", label: "Ring Group" },
              { value: "CALLMENU", label: "Call Menu" },
              { value: "AGENT", label: "Agent" },
            ]}
            valueProp="value"
            labelProp="label"
          />
        </div>

        {formik.values.did_route === "IN_GROUP" ? (
          <div>
            <FormSelectInput
              errors={errors}
              name="group_id"
              label="Ring Group"
              formik={formik}
              options={record}
              valueProp="group_id"
              labelProp="group_name"
            />
          </div>
        ) : null}
        {formik.values.did_route === "EXTEN" ? (
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="extension"
              label="External Number"
              formik={formik}
            />
          </div>
        ) : null}
        {formik.values.did_route === "CALLMENU" ? (
          <div>
            <FormSelectInput
              errors={errors}
              name="menu_id"
              label="Call Menu"
              formik={formik}
              options={callManu}
              valueProp="menu_id"
              labelProp="menu_name"
            />
          </div>
        ) : null}
        {formik.values.did_route === "AGENT" ? (
          <div>
            <FormSelectInput
              errors={errors}
              name="user"
              label="Agent"
              formik={formik}
              options={dialerUsers}
              valueProp="value"
              labelProp="label"
              onChange={(e) => {
                formik?.setFieldValue("user", e);
                formik?.setFieldValue(
                  "voicemail_ext",
                  dialerUsers?.find(({ value }) => value === e)?.dialer_phone
                );
              }}
            />
          </div>
        ) : null}
        {/* {formik.values.did_route === "EXTEN" ? ( */}
        <div className="mb-3">
          <CampaignGroup
            errors={errors}
            name="custom_one"
            label="Campaign Name"
            formik={formik}
            options={affiliates}
          />
        </div>
        {/* ) : null} */}
        {formik.values.did_route === "CALLMENU" ? (
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="route_external"
              label="External Route"
              formik={formik}
              options={[
                { value: "YES", label: "YES" },
                { value: "NO", label: "NO" },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>
        ) : null}
        <div className="mb-3">
          <FormInput
            errors={errors}
            name="prefix"
            label="Prefix"
            formik={formik}
          />
        </div>
        <div className="">
          <FormSelectInput
            name="provider"
            label="Carrier"
            onChange={(value) => setProvider(value)}
            value={provider}
            options={[
              { value: "Intel", label: "Intel" },
              { value: "BVS", label: "BVS" },
            ]}
            valueProp="value"
            labelProp="label"
          />
        </div>
      </div>
      <div className="col-span-3 !mt-3">
        <TagsInput
          tags={dids?.map(({ did_pattern }) => did_pattern)}
          setTags={(tag) => {
            setDids((prevDids) =>
              prevDids?.filter((item) => tag?.includes(item?.did_pattern))
            );
          }}
          placeholder=" "
          readOnly
        />
      </div>
      {failed?.length > 0 && (
        <div className="flex flex-row">
          {/* <div className="flex flex-col w-full mt-2">
            <small>Successfully saved: {success?.length}</small>
            <div className="w-full form-control h-auto d-inline-flex flex-wrap min-h-[38px] mr-2">
              {success?.map((val, index) => {
                return (
                  <div
                    className="badge me-1 pe-1 justify-content-between badge me-1 pe-1 justify-content-between bg-success !text-white !mr-2 my-0.5 h-[26.39px]"
                    key={index}
                  >
                    {val}
                    <button className="border-0 bg-transparent ps-auto pe-0">
                      <MdCancel
                        size={20}
                        className="ml-1 cursor-pointer text-white"
                        // onClick={() => {
                        //   dispatch(removeFromValidLoc(val));
                        // }}
                      />
                    </button>
                  </div>
                );
              })}
            </div>
          </div> */}
          <div className="flex flex-col w-full mt-2 ml-2">
            <div className="flex felx-row justify-between">
              <div>
                <small>Failed to save: {failed?.length}</small>
              </div>
              <div>
                <small
                  onClick={copyInvalidData}
                  className="cursor-pointer hover:underline text-blue-600"
                >
                  Copy
                </small>
                <small
                  onClick={() => setFailed([])}
                  className="ml-2 cursor-pointer hover:underline text-blue-600"
                >
                  Clear
                </small>
              </div>
            </div>

            <div className="form-control h-auto d-inline-flex flex-wrap min-h-[44.38px]">
              {failed?.map((val, index) => {
                return (
                  <div
                    className="badge me-1 pe-1 justify-content-between badge me-1 pe-1 justify-content-between bg-danger !text-white !mr-2 my-0.5 h-[26.39px]"
                    key={index}
                  >
                    {val}
                    <button className="border-0 bg-transparent ps-auto pe-0">
                      <MdCancel
                        size={20}
                        className="ml-1 cursor-pointer text-white"
                        // onClick={() =>
                        //   setInValidData((prevState) =>
                        //     prevState.filter((item) => item !== val)
                        //   )
                        // }
                      />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default ImportDIDsForm;
