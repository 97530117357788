import {
  FaBook,
  FaBriefcase,
  FaBusinessTime,
  FaCog,
  FaCommentDollar,
  FaEnvelope,
  FaFacebookMessenger,
  FaLaptopHouse,
  FaMoneyCheck,
  FaTags,
  FaUser,
  FaUserFriends,
  FaUserTie,
  FaUserCog,
  FaCreditCard,
  FaPhoneAlt,
  FaMap,
  FaCcMastercard,
  FaList,
  FaGoogle,
  FaSms ,
} from "react-icons/fa";
import { TbSeo } from "react-icons/tb";
import { MdCancel } from "react-icons/md";
import { RxTimer } from "react-icons/rx";
export const sideMenu = [
  {
    to: "/dashboard",
    label: "Dashboard",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["All"],
  },
  {
    to: "/communication",
    label: "Communications",
    Icon: FaSms,
    task: "Communications",
    permission: ["All"],
  },
  {
    to: "/vendor_jobs",
    label: "Vendor Jobs",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["All"],
  },
  {
    to: "/vendor_invoices",
    label: "Vendor Invoices",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["All"],
  },

  {
    to: "/conversations",
    label: "Conversations",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["All"],
  },
  {
    to: "/leads",
    label: "Lead",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["All"],
  },
  {
    to: "/beta_leads",
    label: "Beta Leads",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["Creative IT"],
  },
  {
    to: "/lead_source",
    label: "Affiliates",
    Icon: FaUserFriends,
    task: "List Lead",
    permission: ["All"],
    children: [
      {
        to: "list",
        label: "Lead Source",
        Icon: FaUserFriends,
        task: "List Lead",
        permission: ["All"],
      },
      {
        to: "webquery",
        label: "Web Form",
        Icon: FaBook,
        task: "List Lead",
        permission: ["All"],
      },
      {
        to: "affiliate_inbound_reports",
        label: "Affiliate Inbound Report",
        Icon: FaBook,
        task: "Affiliate Inbound Report",
        permission: ["All"],
      },
      // {
      //   to: "lead_campaigns",
      //   label: "Lead Campaigns",
      //   Icon: FaBook,
      //   task: "Lead Campaigns",
      //   permission: ["All"],
      // },
      {
        to: "lead_source_report",
        label: "Lead Source Report",
        Icon: FaUserFriends,
        task: "Lead Source Report",
        permission: ["All"],
      },
    ],
  },
  {
    to: "/jobs",
    label: "Jobs",
    Icon: FaBusinessTime,
    task: "List Job",
    permission: ["All"],
  },
  {
    to: "/reports/job_progress_report",
    label: "Progress",
    Icon: FaBusinessTime,
    task: "List Job",
    permission: ["All"],
  },
  {
    to: "/industry_database",
    label: "Industry Database",
    Icon: FaBusinessTime,
    task: "Industry Database",
    permission: ["Creative IT"],
  },
  {
    to: "/dids_inventory",
    label: "DIDs Inventory",
    Icon: FaBusinessTime,
    task: "Industry Database",
    permission: ["Creative IT"],
  },
  {
    to: "/settings",
    label: "Settings",
    Icon: FaCog,
    task: "Settings",
    permission: ["All"],
    children: [
      {
        to: "quote_pricing",
        label: "Quoting",
        Icon: FaCommentDollar,
        task: "Service Cost",
        permission: ["All"],
      },
      {
        to: "bill_cards",
        label: "Bill Cards",
        Icon: FaCcMastercard,
        task: "User List",
        permission: ["All"],
      },
      {
        to: "script_status",
        label: "Script Status",
        Icon: RxTimer,
        task: "Script Status",
        permission: ["All"],
      },
      {
        to: "allowedIp",
        label: "Allowed IP",
        Icon: FaLaptopHouse,
        task: "List Allowed IP",
        permission: ["All"],
      },
      {
        to: "merchants",
        label: "Merchants",
        Icon: FaUserTie,
        task: "List Merchant",
        permission: ["All"],
      },
      {
        to: "users",
        label: "User",
        Icon: FaUser,
        task: "List User",
        permission: ["All"],
      },
      {
        to: "api_users",
        label: "API Users",
        Icon: FaUser,
        task: "List User",
        permission: ["Creative IT"],
      },
      {
        to: "vendors",
        label: "Vendors",
        Icon: FaUser,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "roles",
        label: "User Roles",
        Icon: FaUserCog,
        task: "Administration",
        permission: ["All"],
      },
      {
        to: "permissions",
        label: "Permissions",
        Icon: FaUserCog,
        task: "Administration",
        permission: ["All"],
      },
      {
        to: "tags",
        label: "Tags",
        Icon: FaTags,
        task: "List Tag",
        permission: ["All"],
      },
      {
        to: "emailTemplates",
        label: "Email Templates",
        Icon: FaEnvelope,
        task: "List Email Template",
        permission: ["All"],
      },
      {
        to: "smsTemplates",
        label: "SMS Templates",
        Icon: FaFacebookMessenger,
        task: "List SMS Template",
        permission: ["All"],
      },
      {
        to: "paymentGateway",
        label: "Payment Gateway",
        Icon: FaMoneyCheck,
        task: "List Payment Gateway",
        permission: ["All"],
      },
      {
        to: "paymentProviders",
        label: "Payment Providers",
        Icon: FaMoneyCheck,
        task: "List Provider",
        permission: ["All"],
      },
      {
        to: "jobStatus",
        label: "Job Status",
        Icon: FaBriefcase,
        task: "List Job Status",
        permission: ["All"],
      },
      {
        to: "system_job_status",
        label: "System Job Status",
        Icon: FaBriefcase,
        task: "List Job Status",
        permission: ["All"],
      },
      {
        to: "migrate_job_status",
        label: "Migrate Job Status",
        Icon: FaBriefcase,
        task: "List Job Status",
        permission: ["All"],
      },
      {
        to: "declineReasons",
        label: "Decline Reason",
        Icon: MdCancel,
        task: "List Decline Reason",
        permission: ["All"],
      },

      {
        to: "cardtype",
        label: "Card Types",
        Icon: FaCreditCard,
        task: "Card Types",
        permission: ["All"],
      },
      {
        to: "industries",
        label: "Industry",
        Icon: FaCreditCard,
        task: "List Industry",
        permission: ["All"],
      },
      {
        to: "jobCategory",
        label: "Industry Category",
        Icon: FaCreditCard,
        task: "List Job Category",
        permission: ["All"],
      },
      {
        to: "generalSettings",
        label: "General Settings",
        Icon: FaCreditCard,
        task: "Administration",
        permission: ["All"],
      },
      {
        to: "restricted_words",
        label: "Restricted Words",
        Icon: FaCreditCard,
        task: "Administration",
        permission: ["All"],
      },
      {
        to: "google_map_keys",
        label: "Google Map Keys",
        Icon: FaGoogle,
        task: "Administration",
        permission: ["All"],
        users: ["Vishnu", "Faaiz", "Alan"],
      },
      {
        to: "communication_numbers",
        label: "Communication No",
        Icon: FaPhoneAlt,
        task: "Communication No",
        permission: ["All"],
      },
    ],
  },
  // {
  //   to: "/calendar",
  //   label: "Calendar",
  //   Icon: FaCommentDollar,
  //   task: "Scheduler",
  //   permission: ["All"],
  // },
  {
    to: "/google_mails",
    label: "Google Mails",
    Icon: FaCommentDollar,
    task: "Map",
    permission: ["Creative IT"],
  },
  {
    to: "/gmail_accounts",
    label: "Gmail Accounts",
    Icon: FaCommentDollar,
    task: "Map",
    permission: ["All"],
  },
  {
    to: "/google_accounts",
    label: "Google Accounts",
    Icon: FaGoogle,
    task: "Google Accounts",
    permission: ["All"],
  },
  {
    to: "/realtime",
    label: "Real Time",
    Icon: FaCommentDollar,
    task: "Get Real Time Job Recording",
    permission: ["All"],
  },
  {
    to: "/reports",
    label: "Reports",
    Icon: FaBook,
    task: "Reports",
    permission: ["All"],
  },
  {
    to: "/callcenter",
    label: "Call Center",
    Icon: FaPhoneAlt,
    task: "List Call Center",
    permission: ["All"],
    children: [
      {
        to: "call_centers",
        label: "Call Centers",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "campaigns",
        label: "Campaigns",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "inbound_groups",
        label: "Ring Groups",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "dids",
        label: "DIDs",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "outbound_dids",
        label: "Outbound DIDs",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "purchase_dids",
        label: "Purchase DIDs",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "audio_files",
        label: "Audio Files",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "call_menu",
        label: "Call Menu",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "spam_numbers",
        label: "Spam Numbers",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "reports",
        label: "Report",
        Icon: FaBook,
        task: "Geo Location",
        permission: ["All"],
        children: [
          {
            to: "agent_performance_report",
            label: "Agent Performance",
            Icon: FaBook,
            task: "Geo Location",
            permission: ["All"],
          },
          {
            to: "call_summary_report",
            label: "Call Summary",
            Icon: FaBook,
            task: "Geo Location",
            permission: ["All"],
          },
          {
            to: "call_logs",
            label: "Call Logs",
            Icon: FaBook,
            task: "Geo Location",
            permission: ["All"],
          },
          {
            to: "inbound_forwarding_report",
            label: "Inbound Forwarding",
            Icon: FaBook,
            task: "Geo Location",
            permission: ["All"],
          },
          {
            to: "affiliate_report",
            label: "Affiliate Report",
            Icon: FaBook,
            task: "Geo Location",
            permission: ["All"],
          },
          {
            to: "missedcall_report",
            label: "Missed Call Report",
            Icon: FaBook,
            task: "Geo Location",
            permission: ["All"],
          },
          {
            to: "lead_buyer_call_logs",
            label: "Lead Buyer Call Logs",
            Icon: FaBook,
            permission: ["All"],
          },
        ],
      },
    ],
  },
  {
    to: "/geolocation",
    label: "Geo Location",
    Icon: FaCog,
    task: "Geo Location",
    permission: ["All"],
    children: [
      {
        to: "geo_countries",
        label: "Countries",
        Icon: FaMap,
        task: "Geo Countries",
        permission: ["All"],
      },
      {
        to: "geo_states",
        label: "States",
        Icon: FaMap,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "geo_cities",
        label: "Cities",
        Icon: FaMap,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "geo_zips",
        label: "Zips",
        Icon: FaMap,
        task: "Geo Zips",
        permission: ["All"],
      },
    ],
  },
  {
    to: "/nation_site",
    label: "National Sites",
    Icon: FaCog,
    task: "List National Site",
    permission: ["All"],
    children: [
      {
        to: "sites",
        label: "Site Listing",
        Icon: FaCommentDollar,
        task: "List National Site",
        permission: ["All"],
      },
      {
        to: "vendors",
        label: "Vendors",
        Icon: FaCommentDollar,
        task: "List National Site",
        permission: ["All"],
      },
      {
        to: "service_hours",
        label: "Service Hours",
        Icon: FaCommentDollar,
        task: "List National Site",
        permission: ["All"],
      },
      {
        to: "rating",
        label: "Rating",
        Icon: FaCommentDollar,
        task: "List National Site",
        permission: ["All"],
      },
      {
        to: "nation_site_zips",
        label: "Nation Site Zips",
        Icon: FaCommentDollar,
        task: "List National Site",
        permission: ["All"],
      },
    ],
  },
  {
    to: "/domain_center",
    label: "Domain Center",
    Icon: FaCog,
    task: "Domain Center",
    permission: ["All"],
    children: [
      {
        to: "domains",
        label: "Domains",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "seo_report",
        label: "SEO Status Report",
        Icon: TbSeo,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "seo_data_report",
        label: "SEO Data Report",
        Icon: TbSeo,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "uptime_monitors",
        label: "Uptime Monitors",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "inteliquent_numbers",
        label: "Inteliquent Numbers",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "gsc_servers",
        label: "GSC Servers",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "runners",
        label: "Domain Runners",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "pull_to_server",
        label: "Pull to Server",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "cloudflare_accounts",
        label: "Cloudflare Accounts",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "domain_numbers",
        label: "Domain Numbers",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "pending_domains_phone",
        label: "Pending Phones",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "list_alternate_area_codes",
        label: "Alternate Area Codes",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "servers",
        label: "Server",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "purchase_registrar",
        label: "Purchased Registrar",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "tlds",
        label: "TLDs",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "buying",
        label: "Buying",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },

      {
        to: "testing_domains",
        label: "Testing Domains",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "testing_links",
        label: "Testing Links",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "multiple_domains",
        label: "Multiple Domains",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
      {
        to: "domain_location_report",
        label: "Domains Location",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },

      {
        to: "batch_report",
        label: "Batches",
        Icon: FaCommentDollar,
        task: "Domain Center",
        permission: ["All"],
      },
    ],
  },
  {
    to: "/domain_rank",
    label: "Domain Rank",
    Icon: FaCommentDollar,
    task: "Domain Center",
    permission: ["Creative IT"],
    children: [
      {
        to: "domains",
        label: "Domains",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "keywords",
        label: "Keywords",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "batches_list",
        label: "Batches",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
      {
        to: "searches",
        label: "Searches",
        Icon: FaUserFriends,
        task: "Geo Location",
        permission: ["All"],
      },
    ],
  },
  {
    to: "/connecx",
    label: "Connecx",
    Icon: FaCog,
    task: "Connecx",
    permission: ["All"],
    children: [
      {
        to: "customers",
        label: "Customers",
        Icon: FaUser,
        task: "Customers",
        permission: ["All"],
      },
    ],
  },
  {
    to: "/cloudflare",
    label: "Cloudflare",
    Icon: FaCog,
    task: "Cloudflare",
    permission: ["Creative IT"],
    children: [
      {
        to: "whitelisted_ips",
        label: "Whitelisted IPs",
        Icon: FaList,
        task: "Whitelisted IPs",
        permission: ["All"],
      },
    ],
  },
  {
    to: "/google_console_list",
    label: "Google Credentials",
    Icon: FaCog,
    task: "Domain Center",
    permission: ["Creative IT"],
  },
];
