import api from "./api";

export const getGeoVendors = async (data) => {
  return await api.post("/api/vendors/vendor_list", data);
};
export const getGeoParentVendorsDrd = async () => {
  return await api.get(
    "https://api.cmsdeployer.com/api/call_center/drd_parent_vendor"
  );
};
export const createGeoVendors = async (data) => {
  return await api.post("/api/vendors/add", data);
};
export const updateGeoVendors = async (data) => {
  return await api.put(`/api/vendors/update/${data._id}`, data);
};
export const deleteGeoVendors = async (id) => {
  return await api.delete(`/api/vendors/delete/${id}`);
};
export const getStates = async (data) => {
  return await api.get(`/api/vendors/domain_prime_time/get_states`, {
    params: data,
  });
};
export const getCities = async ({ state_id, city, alias }) => {
  return await api.get(
    `/api/vendors/domain_prime_time/get_cities/${state_id}`,
    { params: { city: city, alias: alias } }
  );
};
export const getZips = async (city_id) => {
  return await api.get(
    `https://api.cmsdeployer.com/api/geo_locations/get_zips/${city_id}`
  );
};
export const getChildVendors = async (id) => {
  return await api.get(
    `https://api.cmsdeployer.com/api/domain-prime-time/vendor_child_list/${id}`
  );
};

export const getVendorsDrdNew = async (type) => {
  return await api.get(`/api/vendors/drd_vendor_list`, {
    params: type,
  });
};
export const getVendorIndustriesLocations = async (id) => {
  return await api.get(`/api/vendors/vendor/${id}`);
};
export const deleteVendorIndustriesLocations = async (data) => {
  return await api.delete(
    `/api/vendors/vendor_industries_locations/${data?.vendor_id}/${data?.industry_id}`
  );
};
export const getVendorsDrd = async () => {
  return await api.get("/api/vendors");
};