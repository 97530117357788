import React, { useEffect } from "react";
import { Button } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { get_closing_report } from "../../features/reportsSlice";
import { useReactToPrint } from "react-to-print";
import ReportHead from "../Components/Header";
import Picker from "../Components/Picker";
import Select from "react-select";
import { get_users } from "../../features/usersSlice";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";

const ClosingReport = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);
  const componentRef = React.useRef(null);
  const currentDate = new Date();
  const initialFilters = {
    from_date:
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
        0
      )
        .toISOString()
        .slice(0, 23) + "Z",
    to_date: new Date().toISOString().slice(0, 23) + "Z",
  };
  const [filters, setFilters] = React.useState(initialFilters);
  const { isLoading, closingnReport } = useSelector((state) => state.reports);
  // eslint-disable-next-line
  useEffect(() => {
    dispatch(get_closing_report(filters));
    // eslint-disable-next-line
  }, []);
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const numberOfLines = data?.complete_pickup_address
      ? Math.ceil(data?.complete_pickup_address?.length / 40)
      : 0;
    const addressHeight = rowHeight + numberOfLines * 30 + 20;
    if (data?.tags && data?.tags.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = data.tags.length;
      if (addressHeight > rowHeight + tagHeight * numTags + cellContentHeight) {
        return addressHeight;
      } else {
        return rowHeight + tagHeight * numTags + cellContentHeight;
      }
    }
    if (addressHeight > rowHeight + cellContentHeight) {
      return addressHeight;
    } else {
      return rowHeight + cellContentHeight;
    }
  };
  const columnDefs = [
    {
      field: "Job #",
      renderCell: (params) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/jobs/update/${params.row._id}`}
          className="text-blue-700 hover:no-underline"
        >
          {params.row.job_number}
        </a>
      ),
      width: 60,
    },

    {
      headerName: "Client",
      field: "full_name",
      width: 100,
      cellClassName: "multiline-cell",
    },
    {
      headerName: "Address",
      field: "complete_pickup_address",
      width: 180,
      wrap: true,
      cellClassName: "multiline-cell",
    },
    { headerName: "Status", field: "job_status_id" },
    { headerName: "Job Total", field: "jobTotal" },

    { headerName: "Tech Comments", field: "status", width: 100 },
    { headerName: "Actions", field: "closeStatus", width: 150 },

    { headerName: "Closed", field: "is_closed" },
    { headerName: "Closed Date", field: "closeDate", width: 150 },
    { headerName: "Industry", field: "industry_name", flex: 1, minWidth: 100 },
    { headerName: "Service Cost", field: "total", flex: 1, minWidth: 100 },
    {
      headerName: "Parts Charge",
      field: "parts_charge",
      flex: 1,
      minWidth: 130,
    },
    {
      headerName: "Net Amount",
      field: "net_amount",
      flex: 1,
      minWidth: 130,
    },
    { headerName: "Tech Profit", field: "tech_profit", flex: 1, minWidth: 100 },
    {
      headerName: "Company Share",
      field: "company_profit",
      flex: 1,
      minWidth: 130,
    },
  ];
  const exportOption = [
    {
      label: "Export CSV",
      value: "csv",
    },
  ];

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Login Report",
  });
  useEffect(() => {
    dispatch(get_users());
    // eslint-disable-next-line
  }, []);
  const multiSelectHandler = (e) => {
    let arr = [];
    // eslint-disable-next-line
    e?.map((val) => {
      arr = [...arr, val?.value];
    });
    // formik.setFieldValue("user_ids", arr.toString());
  };
  const style = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#ffe7c7",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused || state.hover ? "#a9a9a9" : null,
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  return (
    <>
      <div className="container-fluid">
        <ReportHead route="Reports > Closing Report" heading="Login IP" />

        <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
          <div className="md:col-span-8">
            <div className="grid grid-cols-1 gap-2 md:grid-cols-12">
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <Select
                  styles={style}
                  placeholder="Close Report"
                  isMulti
                  options={users}
                  getOptionLabel={(options) => options?.first_name}
                  getOptionValue={(options) => options?._id}
                  onChange={(e) => {
                    multiSelectHandler(e);
                  }}
                  className="w-full"
                />
              </div>
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <Select
                  styles={style}
                  placeholder="Payment"
                  isMulti
                  options={users}
                  getOptionLabel={(options) => options?.first_name}
                  getOptionValue={(options) => options?._id}
                  onChange={(e) => {
                    multiSelectHandler(e);
                  }}
                  className="w-full"
                />
              </div>
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <Select
                  styles={style}
                  placeholder="Type"
                  isMulti
                  options={users}
                  getOptionLabel={(options) => options?.first_name}
                  getOptionValue={(options) => options?._id}
                  onChange={(e) => {
                    multiSelectHandler(e);
                  }}
                  className="w-full"
                />
              </div>
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <Select
                  styles={style}
                  placeholder="GOA Payment"
                  isMulti
                  options={users}
                  getOptionLabel={(options) => options?.first_name}
                  getOptionValue={(options) => options?._id}
                  onChange={(e) => {
                    multiSelectHandler(e);
                  }}
                  className="w-full"
                />
              </div>
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <Select
                  styles={style}
                  placeholder="GOA Tech Fee"
                  isMulti
                  options={users}
                  getOptionLabel={(options) => options?.first_name}
                  getOptionValue={(options) => options?._id}
                  onChange={(e) => {
                    multiSelectHandler(e);
                  }}
                  className="w-full"
                />
              </div>
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <Select
                  styles={style}
                  placeholder="Confirmed CX"
                  isMulti
                  options={users}
                  getOptionLabel={(options) => options?.first_name}
                  getOptionValue={(options) => options?._id}
                  onChange={(e) => {
                    multiSelectHandler(e);
                  }}
                  className="w-full"
                />
              </div>
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <Select
                  styles={style}
                  placeholder="Confirmed Driver"
                  isMulti
                  options={users}
                  getOptionLabel={(options) => options?.first_name}
                  getOptionValue={(options) => options?._id}
                  onChange={(e) => {
                    multiSelectHandler(e);
                  }}
                  className="w-full"
                />
              </div>
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <Select
                  styles={style}
                  placeholder="Job Status"
                  isMulti
                  options={users}
                  getOptionLabel={(options) => options?.first_name}
                  getOptionValue={(options) => options?._id}
                  onChange={(e) => {
                    multiSelectHandler(e);
                  }}
                  className="w-full"
                />
              </div>
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <Select
                  styles={style}
                  placeholder="Closed By"
                  isMulti
                  options={users}
                  getOptionLabel={(options) => options?.first_name}
                  getOptionValue={(options) => options?._id}
                  onChange={(e) => {
                    multiSelectHandler(e);
                  }}
                  className="w-full"
                />
              </div>
              <div className="md:col-span-3 !px-0.5 !mb-3">
                <Select
                  styles={style}
                  placeholder="Created By"
                  isMulti
                  options={users}
                  getOptionLabel={(options) => options?.first_name}
                  getOptionValue={(options) => options?._id}
                  onChange={(e) => {
                    multiSelectHandler(e);
                  }}
                  className="w-full"
                />
              </div>
              <div className="md:col-span-6 !px-0.5">
                <Button
                  text="Search"
                  variant="btn_submit"
                  onClick={() => dispatch(get_closing_report(filters))}
                />
                <Button
                  className="ml-2"
                  text="Reset"
                  variant="btn_cancel"
                  onClick={() => {
                    setFilters(initialFilters);
                    dispatch(get_closing_report(initialFilters));
                  }}
                />
              </div>
            </div>
          </div>

          <div className="md:col-span-4 !px-0.5 !mb-3">
            <Picker
              filterData={filters}
              setFiltersData={setFilters}
              dropdown_menu={[
                { label: "Closed", value: "closed" },
                { label: "Created", value: "created" },
              ]}
              dropdown_label="Report by"
              valueKey={"report_by"}
            />
          </div>
        </div>

        <div className="grid grid-cols-6 gap-x-2 justify-betwee mx-0.5">
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2  hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Total Jobs</span>
            <span className="text-base font-medium font-pop">
              {closingnReport?.summary?.totalJobs
                ? closingnReport?.summary?.totalJobs
                : 0}
            </span>
          </div>
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2  hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Service Cost ($)</span>
            <span className="text-base font-medium font-pop">
              {closingnReport?.summary?.total
                ? closingnReport?.summary?.total
                : 0}
            </span>
          </div>

          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2  hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Parts charge ($)</span>
            <span className="text-base font-medium font-pop">
              {closingnReport?.summary?.parts_charge
                ? closingnReport?.summary?.parts_charge
                : 0}
            </span>
          </div>

          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2  hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Net Amount ($)</span>
            <span className="text-base font-medium font-pop">
              {(closingnReport?.summary?.total || 0) -
                (closingnReport?.summary?.parts_charge || 0)}
            </span>
          </div>

          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2  hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Tech Share ($)</span>
            <span className="text-base font-medium font-pop">
              {closingnReport?.summary?.tech_fee
                ? closingnReport?.summary?.tech_fee
                : 0}
            </span>
          </div>
          <div className="flex mb-2 flex-col justify-between items-center text-ml-3 !p-2  hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
            <span className="text-sm font-pop">Company Profit ($)</span>
            <span className="text-base font-medium font-pop">
              {(closingnReport?.summary?.total || 0) -
                (closingnReport?.summary?.parts_charge || 0) -
                (closingnReport?.summary?.tech_fee || 0)}
            </span>
          </div>
        </div>
        <div className="bg-white my-3 border rounded">
          <MUIDataTable
            exportOption={exportOption}
            handlePrint={handlePrint}
            columnDefs={columnDefs}
            items={closingnReport?.records?.map((record, index) => {
              const counter = index + 1;
              const {
                _id,
                closed_date,
                createdAt,
                createdBy,
                complete_pickup_address,
                goa_price,
                goa_tech_fee,
                industry_id,
                is_closed,
                job_number,
                job_status_id,
                paid_via,
                payment_type,
                performed_status,
                refund_amount,
                tags,
                tech_fee = 0,
                tech_profit = 0,
                total = 0,
                vendor_id,
                parts_charge = 0,
              } = record;
              let { first_name, last_name, username } = record?.createdBy;
              let full_name =
                first_name +
                " " +
                (last_name ? last_name : "") +
                " (" +
                username +
                ")";
              return {
                counter,
                _id,
                parts_charge,
                closed_date,
                full_name,
                createdAt,
                createdBy,
                complete_pickup_address,
                goa_price,
                goa_tech_fee,
                industry_name: industry_id ? industry_id?.name : "",
                is_closed: is_closed ? "Yes" : "No",
                job_number,
                job_status_id: job_status_id ? job_status_id?.name : "",
                paid_via,
                payment_type,
                performed_status,
                refund_amount,
                tags,
                tech_fee,
                tech_profit,
                jobTotal: total,
                total,
                vendor_id,
                net_amount: total - parts_charge,
                company_profit: (total - parts_charge - tech_fee)?.toFixed(2),
              };
            })}
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            totalItems={closingnReport?.totalItems}
            //   searchText={searchText}
            //   setSearchText={setSearchText}
            paginationModel={paginationModel}
            isLoading={isLoading}
            gridOptions={getRowHeight}
          />
        </div>
        <div className="d-none"></div>
      </div>
    </>
  );
};

export default ClosingReport;
