import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TagsInput from "../../../components/atoms/CustomTagsInput";
import { get_industry_drd } from "../../../features/IndustrySlice";
import { toast } from "react-toastify";
import api from "../../../services/api";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import errorMessage from "../../../util/errorMessage";
import { FaCheck, FaPlus, FaRegFileExcel, FaTrashAlt } from "react-icons/fa";
import { Button as MUIButton } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import FiltersDropdown from "./FiltersDropdown";
import _ from "lodash";
import SaveFilterModal from "./SaveFilterModal";
import PageHeader from "../../../components/molecules/PageHeader";
import exportReport from "../../../components/molecules/ExcelReport";
import { MdLocationOn } from "react-icons/md";
import BatchSaveModal from "../DomainsLocationDetail/saveBatch";

function SEOReport() {
  const initialPayload = {
    locations: [],
    industry_id: null,
  };
  const [payload, setPayload] = useState(initialPayload);
  const [record, setRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [savedFilters, setSavedFilters] = useState(null);
  const [editingRecord, setEditingRecord] = useState({});
  const [isSaveBatch, setIsSaveBatch] = React.useState(false);

  const [isFilterSave, setIsFilterSave] = useState(false);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "AND",
    rules: [],
  });

  const [tags, setTags] = useState([]);
  const { industryDrd } = useSelector((state) => state.industry);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_industry_drd());
    get_filters();
    //eslint-disable-next-line
  }, []);
  const handlePaste = (event) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    const formattedText = text
      .replaceAll(", ", "-")
      .replaceAll(",", "-")
      .replace(/\n+/g, ",")
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item.length > 0);
    const uniqueTags = [...new Set(formattedText)];
    setTags([...tags, ...uniqueTags]);
    /*  const locations = ([...tags, ...uniqueTags] || [])
      ?.filter((tag) => typeof tag === "string" && tag.includes("-")) // Ensure valid strings
      ?.map((tag) => {
        const [city, state] = tag.split("-").map((part) => part.trim()); // Trim both parts
        return { city, state };
      }); */
    // setPayload((prev) => ({ ...prev, locations }));
  };
  const isInvalidTag = (tag) => {
    const parts = tag.split("-");
    if (parts.length !== 2 || !parts[0] || !parts[1]) return true; // Case 2: Missing city or state code

    return false;
  };
  const handleSearchDomains = async (filter) => {
    if (!filter.industry_id) return toast.error("Industry is required");
    setIsLoading(true);
    const locations = (filter?.tags || [])
      ?.filter((tag) => typeof tag === "string" && tag.includes("-")) // Ensure valid strings
      ?.map((tag) => {
        const [city, state_code] = tag.split("-").map((part) => part.trim()); // Trim both parts
        return { city, state_code };
      });
    const newPayload = { ...filter, locations };
    try {
      const res = await api.post("/api/domains/seo_domains", newPayload);
      if (res.status === 200) {
        setRecord(res?.data?.data || []);
      } else {
        toast.error(res?.data?.message || "SEO data couldn't be fetched");
      }
    } catch (err) {
      console.log("👊 ~ handleSearchDomains ~ err:", err);
      toast.error(
        err?.response?.data?.message || "SEO data couldn't be fetched"
      );
    }
    setIsLoading(false);
  };
  const clearAll = () => {
    const c = window.confirm("Are you sure want to clear all search data?");
    if (c) {
      setPayload(initialPayload);
      setTags([]);
      setRecord([]);
      setFilterModel({
        items: [],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      });
      setQueryOptions({
        groupOp: "AND",
        rules: [],
      });
    }
  };
  const getNestedValue = (obj, path) => {
    return path
      .split(".")
      .reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
        obj
      );
  };

  const applyFilters = (data, filters) => {
    if (!Array.isArray(filters)) return data;
    return data.filter((item) => {
      return filters.every((filter) => {
        const { field, op, data } = filter;

        if (!field || data === undefined) return true;

        const itemValue = getNestedValue(item, field);

        switch (op) {
          case "cn":
            return String(itemValue)
              .toLowerCase()
              .includes(String(data).toLowerCase());
          case "eq":
            return itemValue === data;
          case "startsWith":
            return String(itemValue)
              .toLowerCase()
              .startsWith(String(data).toLowerCase());
          case "endsWith":
            return String(itemValue)
              .toLowerCase()
              .endsWith(String(data).toLowerCase());
          case "gt":
            return Number(itemValue) > Number(data);
          case "lt":
            return Number(itemValue) < Number(data);
          case "gte":
            return Number(itemValue) >= Number(data);
          case "lte":
            return Number(itemValue) <= Number(data);
          case "isEmpty":
            return !itemValue || itemValue.length === 0;
          case "isNotEmpty":
            return itemValue && itemValue.length > 0;
          default:
            return true;
        }
      });
    });
  };

  const filteredData = applyFilters(record, queryOptions?.rules);
  const isSelected = (data) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(
          (item) => item?.domainData?.domain === data?.domainData?.domain
        ).length > 0
      ) {
        return true;
      }
      if (
        selectedData?.filter(
          (item) =>
            !item?.domainData &&
            item?.city === data?.city &&
            item?.state_code === data?.state_code
        ).length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ checked, data }) => {
    if (checked) {
      setSelectedData([...selectedData, data]);
    } else {
      setSelectedData(
        selectedData?.filter(
          (item) =>
            item?.domainData?.domain !== data?.domainData?.domain ||
            !(
              item?.city === data?.city && item?.state_code === data?.state_code
            )
        )
      );
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      //const filterArray = (record || [])?.filter((item) => item?.domainData);
      const updatedArray = (filteredData || [])?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const deleteRecordHandler = async (domain) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await api.post("/api/domains/remove_seo_domains", {
          domains: domain,
        });
        if (res?.status === 200) {
          toast.success(res?.data?.message || "SEO data deleted successfully");
        } else {
          errorMessage({
            payload: res?.data,
            action: "SEO data",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({
          payload: error?.response?.data?.message,
          action: "SEO data",
          msg: "deleted",
        });
      }
    }
  };
  const statusOption = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const TagsCellRenderer = (data) => {
    const tags = data?.value?.map((tag, index) => (
      <span
        key={tag?._id}
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      >
        <a
          href={`/settings/vendors/update/${tag?._id}`}
          target="_blank"
          rel="noreferrer"
          className="text-blue-600 no-underline hover:underline cursor-pointer"
        >
          {`${index + 1}. ${tag?.first_name || ""} ${tag?.last_name || ""}`}
        </a>
      </span>
    ));

    return (
      <div style={{ display: "flex", flexDirection: "column", width: "auto" }}>
        {tags}
      </div>
    );
  };
  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // you can set the height of the cell content if you want
    const data = params?.model;
    const vendors = data?.vendors;
    if (data?.vendors && vendors.length > 0) {
      const tagHeight = 20; // height of each tag
      const numTags = vendors.length;
      return rowHeight + tagHeight * numTags + cellContentHeight;
    }
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => {
       /*  const filterArray = (record || [])?.filter(
          (item) => item?.domainData?.domain
        ); */
        return (
          <input
            type="checkbox"
            onChange={(e) => onSelectAll(e.target.checked)}
            className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
            checked={
              (filteredData || [])?.length &&
              selectedData?.length === (filteredData || [])?.length
            }
            disabled={!(filteredData || [])?.length}
          />
        );
      },
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) =>
        params?.row?.domain ? (
          <input
            type="checkbox"
            checked={isSelected(params.row.records)}
            onChange={(e) =>
              onSingleSelect({
                checked: e.target.checked,
                data: params.row.records,
              })
            }
            className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
          />
        ) : null,
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 80, filterable: false },
    {
      headerName: "City",
      field: "city",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      headerName: "State Code",
      field: "state_code",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      headerName: "Industry",
      field: "industry_id",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      headerName: "Population",
      field: "population",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => {
        return params?.row?.domain ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.row?.domain}`}
            className="text-[#000] hover:text-blue-600 hover:underline no-underline"
          >
            {params?.row?.domain}
          </a>
        ) : (
          <span>N/A</span>
        );
      },
      flex: 1,
      minWidth: 200,
      filterable: false,
    },
    {
      headerName: "Expired",
      field: "domainData.is_expired",
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: statusOption,
      valueGetter: (params) => params.row?.["domainData.is_expired"],
      valueFormatter: (params) => params.value,
      renderCell: (params) => {
        return params?.row?.records?.domainData ? (
          params?.value === true ? (
            <span className="text-red-500 font-bold">Yes</span>
          ) : (
            <span className="text-green-500 font-bold">No</span>
          )
        ) : (
          <span>N/A</span>
        );
      },
    },
    { headerName: "In Area", field: "in_area", flex: 1, minWidth: 100 },
    {
      headerName: "Vendor",
      field: "vendors",
      flex: 1,
      minWidth: 350,
      renderCell: TagsCellRenderer,
      filterable: false,
    },
    {
      headerName: "Action",
      field: "action",
      flex: 1,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => {
        return params?.row?.domain ? (
          <div className="flex flex-row">
            <FaTrashAlt
              onClick={() => deleteRecordHandler([params.row.domain])}
              className="my_navIcon"
              title="Delete SEO data"
            />
          </div>
        ) : null;
      },
    },
  ];
  const operator = ({ operator, field }) => {
    const isSelect =
      columnDefs?.find((item) => item.field === field)?.type === "singleSelect";
    const isNumber =
      columnDefs?.find((item) => item.field === field)?.type === "number";
    const isDate =
      columnDefs?.find((item) => item.field === field)?.type === "date";
    return operator === "cn"
      ? "contains"
      : operator === "eq" && !isSelect && !isNumber && !isDate
      ? "equals"
      : operator === "eq" && isSelect && !isNumber && !isDate
      ? "is"
      : operator === "eq" && !isSelect && isNumber && !isDate
      ? "="
      : operator === "not" && !isSelect && isNumber && !isDate
      ? "!="
      : operator === "eq" && !isSelect && !isNumber && isDate
      ? "is"
      : operator === "gt" && isDate
      ? "after"
      : operator === "gte" && isDate
      ? "onOrAfter"
      : operator === "lt" && isDate
      ? "before"
      : operator === "lte" && isDate
      ? "onOrBefore"
      : operator === "gt"
      ? ">"
      : operator === "gte"
      ? ">="
      : operator === "lt"
      ? "<"
      : operator === "lte"
      ? "<="
      : operator;
  };
  const [filterModel, setFilterModel] = React.useState({
    items: queryOptions.rules?.map(({ field, op, data }) => ({
      field,
      operator: operator({ operator: op, field }),
      value: data,
      // data === true ? 1 : data === false ? 0 : data,
    })),
    logicOperator: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and",
  });
  const processFilters = (filters) => {
    return filters.map((filter) => {
      if (
        filter.operator === "isAnyOf" &&
        filter.value?.length &&
        filter.value[0]?.includes(",")
      ) {
        return {
          ...filter,
          value: filter.value[0].split(",").map((item) => item.trim()),
        };
      }
      return filter;
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    const item = processFilters(filterModel.items);
    setFilterModel({
      ...filterModel,
      items: item,
    });
    let ruless = [];
    // if (filterModel?.items?.length === 0) {
    //   handleSearchDomains({ ...payload, tags: tags || [] });
    // }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value === false
              ? rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /*   const handleNewFilter = () => {
    setFilterModel({
      items: queryOptions.rules?.map(({ field, op, data }) => ({
        field: field,
        operator: operator({ operator: op, field }),
        value: data,
      })),
      logicOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    });
    handleSearchDomains({
      ...payload,
      // filters: queryOptions,
      tags: tags || [],
    });
  }; */

  const get_filters = async () => {
    try {
      const res = await api.get("api/report_filter_columns/SEO_REPORT");
      if (res.status === 200) {
        setSavedFilters(res?.data);
      }
    } catch (err) {
      console.log("👊 ~ constget_filters=async ~ err:", err);
    }
  };
  const isAllObjectMatched = () => {
    return savedFilters?.some((obj) => {
      return areObjectsEqual(obj?.data);
      // if (obj?.data?.filters?.groupOp !== queryOptions?.groupOp) return false;
      // return queryOptions?.rules?.every((rule) =>
      //   obj?.data?.filters?.rules?.some(
      //     (objRule) =>
      //       objRule?.field === rule?.field &&
      //       objRule?.op === rule?.op &&
      //       objRule?.data === rule?.data
      //   )
      // );
    });
  };
  const findMatchingObject = () => {
    return _.find(savedFilters, (item) => {
      if (item?.data) {
        return areObjectsEqual(item?.data);
        // return _.isEqual(item.data.filters, queryOptions);
      } else {
        return _.isEqual(item?.data, queryOptions);
      }
    });
  };
  /* const areObjectsMatching = (obj1 = [], obj2 = []) => {
    return obj1.every((item1) => obj2.some((item2) => _.isEqual(item1, item2)));
  }; */

  const sortUserFilters = (filters) => {
    if (!Array.isArray(filters)) {
      return [];
    }
    return filters.sort((a, b) => {
      const aStartsWithAsterisk = a.note?.startsWith("**");
      const bStartsWithAsterisk = b.note?.startsWith("**");

      if (aStartsWithAsterisk && !bStartsWithAsterisk) return -1;
      if (!aStartsWithAsterisk && bStartsWithAsterisk) return 1;
      return 0;
    });
  };
  // const areObjectsEqual = (selectedFilter) => {
  //   return _.isEqual(selectedFilter, queryOptions);
  // };

  const areObjectsEqual = (selectedFilter) => {
    return (
      _.isEqual(selectedFilter?.filters, queryOptions) &&
      _.isEqual(selectedFilter?.locations, tags) &&
      _.isEqual(selectedFilter?.payload, payload)
    );
  };

  const handleDeleteFilters = async (id) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;

    setIsLoading(true);
    try {
      const res = await api.delete(`api/report_filter_columns/${id}`);
      if (res.status === 200) {
        toast.success(
          res?.data?.error || res?.data?.message || "Filter saved successfully"
        );
        await get_filters();
      } else {
        toast.error(
          res?.data?.error || res?.data?.message || "Filter couldn't saved"
        );
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          "Filter couldn't saved"
      );
      console.log("🚀 ~ getLatestPosition ~ err:", err);
      setIsLoading(false);
    }
  };
  const handleExport = () => {
    const Header = [
      "Sr",
      "City",
      "State Code",
      "Industry",
      "Population",
      "domain",
      "Expired",
      "In Area",
    ];
    const items = filteredData.map((record, index) => {
      const counter = index + 1;
      const { city, state_code, domainData = {}, in_area, vendors } = record;
      const {
        population = "N/A",
        is_expired = false,
        domain = "N/A",
      } = domainData || {};
      return {
        counter,
        city,
        state_code,
        industry_id:
          industryDrd?.find(({ _id }) => _id === payload?.industry_id)?.name ||
          "N/A",
        population,
        domain,
        is_expired: is_expired ? "Yes" : "No",
        in_area,
        vendors,
      };
    });
    exportReport(items, Header, "SEO Report", "SEO Report");
  };
  const handleExportLocations = () => {
    const Header = ["Location"];
    const data = filteredData?.filter(({ domainData }) => !domainData);
    const items = data.map((record, index) => {
      const { city, state_code } = record;
      return { location: `${city},${state_code}` };
    });
    exportReport(items, Header, "SEO Location Report", "SEO Location Report");
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />

        {selectedData?.length > 0 ? (
          <MUIButton
            variant="text"
            onClick={() => deleteRecordHandler(selectedData)}
            startIcon={<FaTrashAlt size={16} />}
            sx={{ fontSize: "0.8125rem" }}
            className={"!text-[#042a42]"}
          >
            Delete
          </MUIButton>
        ) : null}
        {selectedData?.length > 0 ? (
          <MUIButton
            variant="text"
            onClick={() => setIsSaveBatch(true)}
            startIcon={<FaPlus size={16} />}
            sx={{ fontSize: "0.8125rem" }}
            className={"!text-[#042a42]"}
          >
            Create Batch
          </MUIButton>
        ) : null}

        <MUIButton
          variant="text"
          startIcon={<FaRegFileExcel size={16} />}
          onClick={(e) => handleExport()}
          sx={{
            borderRadius: "6px",
            marginRight: "5px",
            border: "0px solid",
            borderColor: "#e8eaee",
            height: "26px",
            fontSize: "0.8125rem",
            paddingLeft: 1,
            paddingRight: 1,
            color: "#000",
          }}
        >
          Export Excel
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={handleExportLocations}
          startIcon={<MdLocationOn size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Export Locations
        </MUIButton>
        <FiltersDropdown
          options={sortUserFilters(savedFilters)}
          value={findMatchingObject()}
          areObjectsEqual={(data) => areObjectsEqual(data)}
          handleDeleteFilters={(id) => handleDeleteFilters(id)}
          onEdit={(data) => {
            setEditingRecord(data);
            setIsFilterSave(true);
          }}
          onRemove={() => {
            setFilterModel({
              items: [],
              logicOperator: "and",
              quickFilterValues: [],
              quickFilterLogicOperator: "and",
            });
            setQueryOptions({
              groupOp: "AND",
              rules: [],
            });
            handleSearchDomains({ ...payload, tags: tags || [] });
          }}
          onChange={(data) => {
            const filters =
              data?.filters?.groupOp && data?.filters?.rules?.length
                ? data?.filters
                : { groupOp: "AND", rules: [] };

            setFilterModel({
              items: filters?.rules?.map(({ field, op, data }) => ({
                field: field,
                operator: operator({ operator: op, field }),
                value: data,
              })),
              logicOperator: "and",
              quickFilterValues: [],
              quickFilterLogicOperator: "and",
            });
            setQueryOptions(filters);
            setTags(data?.locations || []);
            setPayload({ ...data?.payload });
            handleSearchDomains({
              tags: data?.locations || [],
              ...data?.payload,
              // filters: queryOptions,
            });
          }}
        />
        <div>
          {(queryOptions?.rules?.length ||
            tags?.length ||
            payload?.industry_id) &&
          !isAllObjectMatched() ? (
            <div className="flex flex-row space-x-1">
              <button
                className="bg-primary-100 text-white w-[25px] h-[25px] rounded-full flex justify-center items-center"
                onClick={() => setIsFilterSave(true)}
              >
                <FaCheck />
              </button>
            </div>
          ) : null}
        </div>
      </GridToolbarContainer>
    );
  }
  const getUniqueVendors = (data) => {
    const vendorMap = new Map();

    data.forEach(({ vendors }) => {
      vendors.forEach((vendor) => {
        if (!vendorMap.has(vendor._id)) {
          vendorMap.set(vendor._id, vendor);
        }
      });
    });

    return Array.from(vendorMap.values());
  };
  return (
    <div>
      {isSaveBatch && (
        <BatchSaveModal
          industry_id={payload.industry_id}
          country_id={"62e8fe5b8e0eefbeab7bacb3"}
          search_details={{
            industry: industryDrd?.find(
              ({ _id }) => _id === payload?.industry_id
            )?.name,
            filters: queryOptions ?? {},
            vendor_InOut: "All",
            domains: "All",
          }}
          totalItems={selectedData?.length}
          onSingleselect={onSingleSelect}
          locations={selectedData?.map(({ city, state_code, state_name }) => ({
            city,
            state_code,
            state: state_name,
          }))}
          setSelectedData={setSelectedData}
          title="Save Batch"
          onCancelForm={() => setIsSaveBatch(false)}
          newTab={true}
        />
      )}
      <PageHeader />
      {isFilterSave ? (
        <SaveFilterModal
          onClose={() => {
            setIsFilterSave(false);
          }}
          filters={{
            filters: queryOptions,
            locations: tags,
            payload: { ...payload },
          }}
          editingRecord={editingRecord}
          setFilters={(data) => {
            setSavedFilters((prevState) => {
              const index = prevState.findIndex(
                (item) => item._id === data._id
              );
              if (index !== -1) {
                const updatedFilters = [...prevState];
                updatedFilters[index] = data;
                return updatedFilters;
              } else {
                return [...prevState, data];
              }
            });
          }}
        />
      ) : null}
      <div className="">
        <div className="flex flex-col w-full bg-white !p-5 shadow-md mb-3">
          <div className="flex flex-row justify-between items-center mb-2">
            <div className="w-[80%] grid grid-cols-3 gap-x-3 bg-white">
              <div className="w-full">
                <Dropdown
                  value={payload.industry_id}
                  onChange={(e) =>
                    setPayload({ ...payload, industry_id: e.value })
                  }
                  options={industryDrd}
                  optionLabel="name"
                  optionValue="_id"
                  placeholder="Select an Industry"
                  filter
                  className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#a9a9a9] hover:border-[#a9a9a9] border-2"
                  showClear
                  showFilterClear
                />
              </div>
            </div>
            <div className="flex justify-end w-auto">
              {payload?.locations?.length > 0 ? (
                <button
                  className="flex items-center justify-center border rounded h-[38px] font-semibold bg-red-600 !bg-[#F86526] text-white p-2 ml-2"
                  onClick={clearAll}
                >
                  Clear all
                </button>
              ) : null}
              <button
                className="flex items-center justify-center border rounded h-[38px] font-semibold bg-[#7CCB0A] text-white p-2 ml-2"
                onClick={() =>
                  handleSearchDomains({
                    ...payload,
                    // filters: queryOptions,
                    tags: tags || [],
                  })
                }
              >
                Search
              </button>
            </div>
          </div>
          <h5 className="text-[#343434] pb-1.5 w-full border-b border-[#D9D9D9] flex flex-row justify- items-center">
            Please enter your location in the format
            <strong className="text-primary-100 mx-2">CITY, STATE CODE</strong>
            (e.g., Austin, TX).
          </h5>
          <div className="flex flex-row gap-x-2">
            <div className="flex flex-col w-full">
              {tags && tags?.length ? (
                <small className="mt-2">Locations: {tags?.length}</small>
              ) : null}
              <TagsInput
                tags={tags}
                setTags={(tag) => {
                  setTags(tag);
                }}
                placeholder={`Add new city and state`}
                tagBg={(tag) =>
                  isInvalidTag(tag)
                    ? "!bg-red-500 !text-white"
                    : "!bg-[#d8d8d8] !text-black"
                }
                onPaste={handlePaste}
              />
            </div>
          </div>
          <div>
            <div className="bg-white my-3 border rounded">
              <MUIDataTable
                columnDefs={columnDefs}
                items={filteredData?.map((record, index) => {
                  const counter = index + 1;
                  const {
                    city,
                    state_code,
                    domainData = {},
                    in_area,
                    vendors,
                  } = record;
                  const uniqueVendors = getUniqueVendors(vendors);
                  const {
                    population = "N/A",
                    is_expired = false,
                    domain = "N/A",
                  } = domainData || {};
                  return {
                    records: { ...record, _id: counter },
                    counter,
                    city,
                    state_code,
                    domainData,
                    in_area,
                    vendors: uniqueVendors,
                    domain,
                    industry_id:
                      industryDrd?.find(
                        ({ _id }) => _id === payload?.industry_id
                      )?.name || "N/A",
                    population,
                    "domainData.is_expired": is_expired,
                  };
                })}
                pagination="No"
                totalItems={record?.totalItems}
                filterModel={filterModel}
                searchable="No"
                showCount="No"
                // paginationModel={paginationModel}
                isLoading={isLoading}
                onFilterModelChange={onFilterChange}
                CustomToolbar={CustomToolbar}
                gridOptions={getRowHeight}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SEOReport;
