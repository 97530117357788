import React from "react";
import Button from "../../components/atoms/Button";
import { DotsLoader, FormInput } from "../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
import api from "../../services/api";
import * as Yup from "yup";
import CustomModal from "../../components/molecules/Modal/customModal";
const TokenModal = ({
  onCancelForm,
  modalTitle,
  selectedData,
  tokenSubmit,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const initialValues = {
    token: "",
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const res = await api.post(
        `/api/domain_runners/set_domains_on_lbServer`,
        {
          runner_id: selectedData?._id,
          token: values?.token,
        }
      );

      if (res.status === 200 || res.status === 201) {
        toast.success(
          res?.data?.message || "Domain set on the LBS server successfully."
        );
        tokenSubmit();
      } else {
        toast.error(
          res?.data?.message || "Domain could not be set on the LBS server."
        );
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          "Domain could not be set on the LBS server."
      );
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.object({
    token: Yup.string().required("Token is required"),
  });

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validationSchema,
    onSubmit: handleSubmit,
  });
  return (
    <CustomModal
      handleModal={onCancelForm}
      cancelButtonRef={onCancelForm}
      className="max-w-2xl"
      modalType={true}
      title={modalTitle}
      formIsValid={formik.isValid}
      isLoading={isLoading}
      hideButton
    >
      {isLoading ? <DotsLoader /> : null}
      <div
        className="flex items-center p-4 mb-4 text-lg text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
        role="alert"
      >
        <FaInfoCircle />
        <div>
          <span className="font-medium ml-2 text-sm">
            A Verification token has been sent to your email. Please check and
            enter it here.
          </span>
        </div>
      </div>

      <form className="mt-4">
        <div className="!mb-3">
          <FormInput name="token" label="Enter Token" formik={formik} />
        </div>
        <div className="!p-4 text-right">
          <Button
            text="Cancel"
            className="mx-2"
            onClick={onCancelForm}
            variant="btn_cancel"
          />
          <Button
            text={"Generate LB Server File"}
            className=""
            onClick={formik.handleSubmit}
            variant="btn_submit"
          />
        </div>
      </form>
    </CustomModal>
  );
};

export default TokenModal;
