import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "../../services/api";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Backdrop,
  FormInput,
  FormSelectInput,
  DotsLoader,
} from "../../components";
import { get_inbound_group } from "../../features/inboundGroupSlice";
import {
  didReset,
  get_cc_cards,
  get_cc_providers,
} from "../../features/didSlice";
import { get_industry_drd } from "../../features/IndustrySlice";
import { get_merchants } from "../../features/merchantSlice";
import { get_dialer_users } from "../../features/usersSlice";
import { get_lead_affiliates } from "../../features/leadSourceSlice";
import errorMessage from "../../util/errorMessage";
import Listing from "./Listing";
import AddUpdateAffiliate from "./AffiliateSettings/AddUpdateAffiliate";
import AddDidToCC from "./AddDidToCC";
import AffiliateFileUpload from "./LeadSourceReport/AffiliateFileUpload";
import AddUpdateForm from "./AddUpdateForm";
import userPermission from "../../util/userPermission";
import { getLeadComapignByid } from "../../services/leadCampaign";
function AddUpdateLeadPage({
  editingRecord,
  onCancelForm,
  modalTitle,
  reloadListing,
}) {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [record, setRecord] = useState([]);
  const [editCampaignRecord, setEditCampaignRecord] = useState(null);
  const [isSyncToInteqliquent, setSyncToInteqliquent] = useState(false);
  const { affiliates } = useSelector((state) => state.leadSource);
  const { industryDrd } = useSelector((state) => state.industry);
  const merchants = useSelector((state) => state.merchant?.record?.data || []);
  const { dialerUsers } = useSelector((state) => state.users);
  const inbound = useSelector((state) => state.inbound);
  const [callManu, setCallMenu] = useState([]);
  const [isAffiliateFileUpload, setAffiliateFileUpload] = useState(false);
  const [leadCampaigns, setLeadCampaigns] = useState([]);
  const paginationModel = {
    pageSize: 100,
    page: 1,
  };
  let initialValues = {
    full_name: "",
    company_name: "",
    email: "",
    active: true,
  };

  if (editingRecord) {
    const { _id, full_name, company_name, email, isActive } = editingRecord;
    initialValues = {
      id: _id,
      full_name,
      company_name,
      email,
      active: isActive,
    };
  }
  const validationSchema = Yup.object({
    full_name: Yup.string().required("Full Name is required"),
    company_name: Yup.string().required("Company Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    active: Yup.boolean().required("Please select Active status"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let payload = {
        title: values?.title,
        full_name: values?.full_name,
        company_name: values?.company_name,
        isActive: values?.active,
        ...(editingRecord
          ? {}
          : {
              email: values?.email,
            }),
      };

      try {
        setIsLoading(true);
        let res;
        if (editingRecord?._id) {
          res = await api.put(`api/lead_source/${editingRecord._id}`, payload);
        } else {
          res = await api.post("api/lead_source/add", payload);
        }

        if (res.status === 200 || res.status === 201) {
          toast.success(
            editingRecord?._id
              ? "Lead source successfully updated."
              : "Lead source successfully created."
          );
          reloadListing();
        }
      } catch (err) {
        console.error(err);
        const errorData = err?.response?.data;
        errorMessage({
          payload: errorData.error || errorData.message,
          setErrors: setErrors,
          action: "Lead Source",
          msg: "created",
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const activeOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const cancelFormHandler = () => {
    setSettingsModal(false);
    setEditCampaignRecord(null);
    setSyncToInteqliquent(false);
    setIsEditing(false)
  };

  const reloadCampignListing = () => {
    setSettingsModal(false);
    setEditCampaignRecord(null);
    setSyncToInteqliquent(false);
    getLeadSourceListing({
      ...paginationModel,
      size: paginationModel.pageSize,
      filters: {},
    });
  };

  const settingsData = record?.records?.find(
    (record) => record._id === editingRecord?._id
  );

  const getLeadSourceListing = async (filter) => {
    if (!editingRecord?._id) return;
    setIsLoading(true);
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const res = await api.post("api/lead_source", payload);
      if (res.status === 200) {
        setRecord(res.data);
      }
    } catch (err) {
      console.error("Error fetching lead sources:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLeadSourceListing({
      ...paginationModel,
      size: paginationModel.pageSize,
      filters: {},
    });
    getLeadCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const getLeadCampaigns = async () => {
      try {
        // setIsLoading(true);
        // const res = await getLeadComapigns();
        const res = await getLeadComapignByid(editingRecord._id);
        if (res.status === 200) {
          setLeadCampaigns(res.data);
        }
      } catch (err) {
        console.log(err);
      } finally {
        // setIsLoading(false);
      }
    };

  const openFormHandler = (record) => {
    if(!leadCampaigns.length){
      return toast.error("Before you can add setting, please ensure that you've created a lead campaign first.")  
    }
    setSettingsModal(true);
    if (record) {
      setEditCampaignRecord(record);
    }
  };

  const openDIDToCCHandler = (record) => {
    setSyncToInteqliquent(true);
    if (record) {
      setEditCampaignRecord(record);
    }
  };

  const get_call_menus = async () => {
    try {
      const res = await api.get("/api/cc/dids/options/call_menus");
      if (res.status === 200) {
        setCallMenu(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!editingRecord?._id) return;
    dispatch(
      get_lead_affiliates({
        page: 1,
        size: 100,
      })
    );
    dispatch(get_inbound_group());
    dispatch(get_industry_drd());
    dispatch(get_cc_cards());
    dispatch(get_cc_providers());
    dispatch(get_merchants());
    dispatch(get_dialer_users());
    get_call_menus();
    return () => {
      dispatch(didReset());
    };
    // eslint-disable-next-line
  }, []);

  const openAffiliateUpload = (record) => {
    setAffiliateFileUpload(true);
    if (record) {
      setEditCampaignRecord(record);
    }
  };

  const reloadCampaignListing = () => {
    setIsEditing(false);
    getLeadCampaigns()
  };
  return (
    <>
      {isLoading && <DotsLoader />}
      {isEditing && (
        <div className="absolute" style={{ zIndex: 999 }}>
          <AddUpdateForm
            lead_id={editingRecord._id}
            editingRecord={null}
            modalTitle="Add Lead Campaign"
            onCancelForm={cancelFormHandler}
            reloadListing={reloadCampaignListing}
          />
        </div>
      )}
      <div className="min-h-screen bg-white w-full z-[100] fixed top-0 left-0 overflow-y-auto">
        <div className="overflow-y-auto h-screen">
          <div className="flex items-center justify-between w-full text-client-50 py-2.5 px-5 bg-white shadow-client overflow-y-auto">
            <h4>
              {!!editingRecord
                ? modalTitle.replaceAll("Add", "Edit")
                : modalTitle}
            </h4>
            <Button
              text="X"
              className="mr-2"
              onClick={onCancelForm}
              variant="btn_danger"
            />
          </div>
          <Backdrop onClick={onCancelForm} />

          <div className="!p-5 grid gap-5  mb-0">
            <div className="!py-5 !px-3 md:px-10 bg-white rounded-3xl shadow-client">
              <h5 className="!mb-3 text-client-50 pb-2.5 w-full border-b border-client-50 flex justify-between items-center">
                Lead Source Information
                <div className="flex">
                {userPermission("Add Lead Campaign") ? (
                  <button
                    text="Add Note"
                    onClick={() => setIsEditing(true)}
                    className="border !px-2 w-auto h-[40px] text-base bg-primary-100 text-white flex items-center justify-center mr-2"
                  >
                    <FaPlus className="mr-2" /> Add Lead Campaign
                  </button>
                ) : null}
                {editingRecord ? (
                  <button
                    text="Add Note"
                    onClick={() => openFormHandler(null)}
                    className="border !px-2 w-auto h-[40px] text-base bg-primary-100 text-white flex items-center justify-center"
                  >
                    <FaPlus className="mr-2" /> Add Settings
                  </button>
                ) : null}
                </div>
              </h5>

              <div className="grid md:grid-cols-4 gap-x-5 !mt-5">
                <div className="!mb-3 w-full">
                  <FormInput
                    errors={errors}
                    name="full_name"
                    label="Full Name"
                    formik={formik}
                  />
                </div>
                <div className="!mb-3 w-full">
                  <FormInput
                    errors={errors}
                    name="company_name"
                    label="Company Name"
                    formik={formik}
                  />
                </div>
                <div className="!mb-3 w-full">
                  <FormInput
                    errors={errors}
                    name="email"
                    label="Email"
                    formik={formik}
                    disabled={!!editingRecord}
                  />
                </div>
                <div className="!mb-3 w-full">
                  <FormSelectInput
                    errors={errors}
                    name="active"
                    label="Active"
                    formik={formik}
                    options={activeOptions}
                    valueProp="value"
                    labelProp="label"
                    convertor={(value) => value === "true"}
                  />
                </div>
              </div>

              <div className="w-full flex justify-center !p-5 bg-white border-t mt-2">
                <Button
                  text="Cancel"
                  className="py-1.5 px-3 align-middle bg-menu text-white mr-2"
                  onClick={onCancelForm}
                />
                <Button
                  text={"Submit"}
                  className="py-1.5 px-3 align-middle bg-primary-100 text-white mr-2"
                  type="button"
                  onClick={formik.handleSubmit}
                />
              </div>
            </div>
          </div>
          {editingRecord ? (
            <Listing
              settingsData={settingsData}
              openFormHandler={openFormHandler}
              reloadCampignListing={reloadCampignListing}
              affiliates={affiliates}
              industryDrd={industryDrd}
              dialerUsers={dialerUsers}
              merchants={merchants}
              inbound={inbound}
              callManu={callManu}
              openDIDToCCHandler={openDIDToCCHandler}
              openAffiliateUpload={openAffiliateUpload}
            />
          ) : null}
        </div>
      </div>
      {settingsModal ? (
        <AddUpdateAffiliate
          modalTitle={
            editCampaignRecord
              ? "Edit Affiliate Settings"
              : "Add Affiliate Settings"
          }
          onCancelForm={cancelFormHandler}
          editingRecordId={editingRecord?._id}
          leadCampaigns={leadCampaigns}
          reloadCampignListing={reloadCampignListing}
          editCampaignRecord={editCampaignRecord}
        />
      ) : null}
      {isSyncToInteqliquent &&
      editCampaignRecord?.inboundPhoneNumbers?.length ? (
        <AddDidToCC
          modalTitle="Export DID To Call Center"
          onCancelForm={cancelFormHandler}
          selectedData={editCampaignRecord?.inboundPhoneNumbers?.map(
            (number) => ({ did: number })
          )}
          exportFormSubmit={reloadCampignListing}
          affiliates={affiliates}
          industryDrd={industryDrd}
          dialerUsers={dialerUsers}
          merchants={merchants}
          inbound={inbound}
          callManu={callManu}
        />
      ) : null}
      {isAffiliateFileUpload ? (
        <AffiliateFileUpload
          modalTitle="Upload Domain / Phone No"
          onCancelForm={(e) => {
            cancelFormHandler();
            setAffiliateFileUpload(false);
          }}
          selectedData={editCampaignRecord}
          formSubmit={reloadCampignListing}
        />
      ) : null}
    </>
  );
}

export default AddUpdateLeadPage;
