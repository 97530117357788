import React, { useCallback, useEffect } from "react";
import { DotsLoader, Modal } from "../../components";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import api from "../../services/api";
import { useFormik } from "formik";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_nation_site } from "../../features/nationSitesSlice";
import { useDispatch } from "react-redux";
const ImportVendors = ({ national_site_id, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const [importVendors, setImportVendors] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [existingVendors, setExistingVendors] = React.useState([]);
  const initialValues = {
    area_code: "",
  };
  const get_vendors = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`api/nation/vendor/${national_site_id?.id}`);
      if (res.status === 200) {
        setExistingVendors(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ VendorsModal ~ err:", err);
    }
  };
  useEffect(() => {
    get_vendors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onDrop = useCallback((acceptedFiles) => {
    let files = acceptedFiles[0];
    setFile(files);
    handleFile(files);
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });
  const mergeArrays = () => {
    const mergedArray = importVendors.map((importVendor) => {
      const existingVendor = existingVendors?.find(
        (vendor) =>
          vendor?.name.toLowerCase() === importVendor?.name.toLowerCase()
      );

      if (existingVendor) {
        return {
          ...importVendor,
          status: "",
        };
      } else {
        return {
          ...importVendor,
          status: "New",
        };
      }
    });
    existingVendors.forEach((existingVendor) => {
      const importVendor = importVendors.find(
        (vendor) =>
          vendor.name.toLowerCase() === existingVendor.name.toLowerCase()
      );

      if (!importVendor) {
        mergedArray.push({
          id: existingVendor.id,
          name: existingVendor.name,
          status: "Delete",
        });
      }
    });
    return mergedArray;
  };
  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const coulmnName = "Name";
      if (file.name.endsWith(".xlsx")) {
        // Handle Excel file
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        // Find the index of the column with the heading "zip"
        const headerRow = excelData[0];

        const didColumnIndex = headerRow.findIndex(
          (column) => column.toLowerCase() === coulmnName.toLowerCase()
        );
        // Create an array with the data from the "zip" column
        if (didColumnIndex > -1) {
          const zips = excelData
            ?.slice(1)
            ?.map((item) => {
              if (item.length > 0 && item[didColumnIndex]) {
                const name = item[didColumnIndex]?.trim(); // Trim to handle empty or undefined
                if (name) {
                  return { name };
                }
              }
              return null; // Skip items where name is empty, undefined, or if the row is empty
            })
            .filter(Boolean);
          setImportVendors([...zips]);
        } else {
          toast.error("Name column is missing");
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };
  const exportToExcel = () => {
    const data = [
      ["#", "Name"],
      ...existingVendors?.map(({ name }, index) => [index + 1, name]),
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(
      wb,
      `${national_site_id?.domain?.replaceAll(".", "-")}-vendors.xlsx`
    );
  };
  const handleSubmit = async () => {
    if (!importVendors) return toast.error("Please import a file");
    let c = null;
    if (importVendors?.length === 0) {
      c = window.confirm("Are you sure want to delete all vendors?");
    }
    if (importVendors?.length === 0 && !c) return;
    const payload = mergeArrays();
    const newRecord = payload
      ?.filter(({ status }) => status === "New")
      ?.map(({ name }) => name);
    const deleteRecord = payload
      ?.filter(({ status }) => status === "Delete")
      ?.map(({ id }) => id);
    if (!newRecord?.length && !deleteRecord?.length)
      return toast.error(
        "All these vendors are already added for this domain, please import another file."
      );
    setIsLoading(true);
    try {
      let res = null;
      if (newRecord?.length) {
        res = await api.post(`/api/nation/vendor`, {
          names: newRecord,
          national_site_id: national_site_id?.id,
        });
      }
      if (deleteRecord?.length) {
        res = await api.post(`/api/nation/delete_vendor`, {
          ids: deleteRecord,
          national_site_id: national_site_id?.id,
        });
      }
      if (res?.status === 200 || res?.status === 201) {
        toast.success(res?.data?.message || `Vendors import successfully`);
        dispatch(get_nation_site());
        onCancelForm();
      } else {
        toast.error(
          res?.data?.message ? res?.data?.message : `Vendors couldn't be import`
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
      setIsLoading(false);
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : `Vendors couldn't be import.`
      );
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    { headerName: "Name", field: "name", flex: 1 },
  ];
  return (
    <Modal
      isUpdate={false}
      title={`${modalTitle} to ${national_site_id?.domain}`}
      onCancelModal={onCancelForm}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={() => {}}
      modalClass="!max-w-[867px]"
    >
      {isLoading && <DotsLoader />}
      <div className="w-full !mr-2 grid grid-cols-1 gap-x-2">
        <div className="text-base font-medium">
          <span className="font-semibold text-lg font_pop text-red-600">
            Note:{" "}
          </span>{" "}
          <span className="font-semibold text-lg font_pop text-violet-600">
            ".xlsx"
          </span>{" "}
          file can be imported here which should have a column header named
          <span className="font-semibold text-lg font_pop text-violet-600">
            {" "}
            "Name".
          </span>{" "}
          <span
            className="font-normal text-normal font_pop text-blue-600 underline cursor-pointer !ml-2"
            onClick={exportToExcel}
          >
            Export
          </span>
        </div>
        <div className="!my-3">
          <div
            className={`w-full flex justify-center border-2 border-gray-300 border-dashed rounded-md !p-2 hover:border-gray-400 focus:outline-none ${
              isDragActive ? "bg-gray-100" : "bg-white"
            }`}
            {...getRootProps()}
          >
            <label
              className={`flex justify-center w-full ${"h-[80px]"} px-4 transition appearance-none cursor-pointer`}
            >
              {file ? (
                <span className="flex items-center space-x-2">
                  <div className="flex flex-col text-center">
                    <span className="font-medium text-gray-600">
                      File Name: {file?.name}
                    </span>
                    <span className="text-gray-600" onClick={open}>
                      Size:{" "}
                      {file?.size > 1000 && file.size < 1000000
                        ? (file.size / 1000).toFixed(2) + " KB"
                        : (file.size / 1000000).toFixed(2) + " MB"}
                    </span>
                  </div>
                </span>
              ) : (
                <span className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <span className="font-medium text-gray-600">
                    Drop files to Attach, or{" "}
                    <span className="text-blue-600 underline" onClick={open}>
                      browse
                    </span>
                  </span>
                </span>
              )}
              <input {...getInputProps()} />
            </label>
          </div>
        </div>
        {file && importVendors?.length === 0 ? (
          <span className="font-semibold text-lg font_pop text-red-600 !mb-2 -my-3">
            You are trying to import an empty file, it will delete all the
            vendors from this domain
          </span>
        ) : null}
        {file && importVendors ? (
          <div className="flex justify-between">
            <span className="text-base font-semibold font-pp">
              Total Vendors: {importVendors?.length}
            </span>
            <span
              className="text-blue-600 cursor-pointer hover:underline"
              onClick={() => {
                setFile(null);
                setImportVendors(null);
              }}
            >
              {importVendors?.length === 0 ? "Remove file?" : "Clear"}
            </span>
          </div>
        ) : null}
        <MUIDataTable
          searchable="No"
          pagination="No"
          columnDefs={columnDefs}
          totalItems={importVendors?.length}
          searchText={searchText}
          height="40vh"
          toolbar="No"
          displayCount="No"
          setSearchText={setSearchText}
          items={importVendors?.map((record, index) => {
            let counter = index + 1;
            const { name } = record;
            return {
              counter,
              name,
              id: index + 1,
            };
          })}
        />
      </div>
    </Modal>
  );
};

export default ImportVendors;
