// HeadManager.js
import { useEffect } from "react";

const HeadManager = ({ title, description, favicon }) => {
  useEffect(() => {
    document.title = title;

    let metaDescription = document.querySelector("meta[name='description']");
    if (metaDescription) {
      metaDescription.setAttribute("content", description);
    } else {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      metaDescription.content = description;
      document.head.appendChild(metaDescription);
    }
    let linkFavicon = document.querySelector("link[rel='icon']");
    if (linkFavicon) {
      linkFavicon.href = favicon;
    } else {
      linkFavicon = document.createElement("link");
      linkFavicon.rel = "icon";
      linkFavicon.href = favicon;
      document.head.appendChild(linkFavicon);
    }
  }, [title, description, favicon]);

  return null;
};

export default HeadManager;
