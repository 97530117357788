import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { Button, Loader } from "../../components";
import { DataGridPro } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
const CustomModal = ({
  onClose,
  selectedData,
  setSelectedData,
  reloadReport,
}) => {
  const [isLoader, setIsLoader] = useState(false);
  const [isJobListing, setIsJobListing] = useState(false);
  const [description, setDescription] = useState("");
  const [notify_vendor, setNotify_vendor] = useState(false);

  const handleConfirm = async () => {
    const leads = selectedData?.map(({ _id }) => _id);
    setIsLoader(true);
    try {
      const res = await api.post("api/jobs/create_invoice", {
        description,
        leads,
        notify_vendor,
      });
      if (res.status === 200) {
        toast.success("Invoice created successfully");
        setSelectedData([]);
        reloadReport();
        onClose();
      } else {
        toast.error(res?.data?.message || "Invoice couldn't be created");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message || "Invoice couldn't be created"
      );
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  const totalSum = selectedData.reduce(
    (sum, item) => sum + item.service_cost,
    0
  );
  const totalPartCharges = selectedData.reduce(
    (sum, item) => sum + item.parts_charge,
    0
  );
  const totalTechfee = selectedData.reduce(
    (sum, item) => sum + item.tech_fee,
    0
  );

  const columnDefs = [
    {
      field: "Job #",
      renderCell: (params) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/jobs/update/${params.row._id}`}
          className="text-blue-700 hover:no-underline"
        >
          {params.row.job_number}
        </a>
      ),
      width: 60,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 120,
      // cellClassName: "multiline-cell",
    },

    { headerName: "Client", field: "name", lex: 1, minWidth: 200 },
    { headerName: "Industry", field: "industry_id", flex: 1, minWidth: 100 },
    { headerName: "Status", field: "jobStatus" },
    { headerName: "Phone", field: "phone", flex: 1, minWidth: 120 },
    { headerName: "Tech Name", field: "vendor_name", flex: 1, minWidth: 120 },
    { headerName: "Price", field: "service_cost", flex: 1, minWidth: 120 },
    {
      headerName: "Address",
      field: "complete_pickup_address",
      width: 180,
      // wrap: true,
      // cellClassName: "multiline-cell",
    },
  ];
  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative min-w-[500px] max-w-[90%]">
        <h2 className="text-lg font-semibold mb-4">
          Create Vendor Invoice{" "}
          <span className="text-primary-100">{`(${
            selectedData[0]?.vendor_id?.first_name || ""
          } ${selectedData[0]?.vendor_id?.last_name || ""})`}</span>
        </h2>
        <div className="grid grid-cols-2 font-pop font-medium gap-x-2">
          <div className="grid grid-cols-6 justify-between p-1">
            <span className="col-span-3">Total Jobs:</span>
            <span
              className="text-left hover:underline text-blue-600 cursor-pointer"
              onClick={() => setIsJobListing(true)}
            >
              {selectedData?.length}
            </span>
          </div>
          <div className="grid grid-cols-6 justify-between p-1">
            <span className="col-span-3">Total Service Cost ($):</span>
            <span className="text-left">{totalSum?.toFixed(2)}</span>
          </div>
          <div className="grid grid-cols-6 justify-between p-1">
            <span className="col-span-3">Part Charges ($):</span>
            <span className="text-left">{totalPartCharges?.toFixed(2)}</span>
          </div>
          <div className="grid grid-cols-6 justify-between p-1">
            <span className="col-span-3">Net Amount ($):</span>
            <span className="text-left">
              {(totalSum - totalPartCharges)?.toFixed(2)}
            </span>
          </div>
          <div className="grid grid-cols-6 justify-between p-1">
            <span className="col-span-3">Tech Share ($):</span>
            <span className="text-left">{totalTechfee?.toFixed(2)}</span>
          </div>
          <div className="grid grid-cols-6 flex justify-between p-1">
            <span className="col-span-3">Company Share ($):</span>
            <span className="text-left">
              {(totalSum - totalPartCharges - totalTechfee)?.toFixed(2)}
            </span>
          </div>
        </div>

        <div className="flex mb-2 font-medium flex-col justify-between items-center text-ml-3 !p-2 w-full hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">
            Total Invoice Payable Amount ($):
          </span>
          <span className="text-base font-medium font-pop">
            {(
              (totalSum * 100) / 100 -
              (totalPartCharges * 100) / 100 -
              (totalTechfee * 100) / 100
            )
              ?.toFixed(2)
              ?.toLocaleString()}
          </span>
        </div>
        <div className="relative flex flex-row">
          <textarea
            className="bg-white border border-gray-300 text-sm rounded w-full p-2.5 "
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            name="description"
            placeholder={"Description"}
          />
        </div>
        <div className={`flex items-center !py-1.5 !px-2.5 mt-2`}>
          <input
            type="checkbox"
            checked={notify_vendor}
            onChange={(e) => setNotify_vendor(!notify_vendor)}
            className={`form-checkbox h-5 w-5 text-primary-100 rounded-xs focus:ring-0 cursor-pointer mr-2`}
          />
          <label className={`text-xs text-[#495057] cursor-pointer`}>
            Do you want to send this invoice to the Vendor?{" "}
            <span
              className={`${
                selectedData[0]?.vendor_id?.email ? "" : "text-red-600"
              }`}
            >
              {selectedData[0]?.vendor_id?.email
                ? `(
            ${selectedData[0]?.vendor_id?.email})`
                : "(Email is not exist for this vendor, Please add it from Vendors.)"}
            </span>
          </label>
        </div>

        {isJobListing ? (
          <div className="w-full h-screen z-[100] fixed top-0 left-0 opacity-100 bg-black/[0.75] flex items-center justify-center">
            <div className="relative">
              <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
                <button
                  onClick={() => setIsJobListing(false)}
                  className="bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="z-10 w-auto w-full md:max-w-[1224px] md:min-w-[730px] max-h-[calc(100vh-130px)] overflow-y-auto bg-neutral-100 p-3.5 border-[10px] border-white">
                <div className="pt-0 max-w-[1194px] h-full px-3.5 m-auto">
                  <header className="bg-primary-100 h-[60px] flex items-center justify-center shadow-lg">
                    <h1 className=" text-2xl text-white m-4 font-medium">
                      {selectedData?.length} Jobs
                    </h1>
                  </header>
                  <div className="md:p-4">
                    <div style={{ height: 425, width: 1111 }}>
                      <DataGridPro
                        rows={
                          selectedData?.length > 0
                            ? selectedData?.map((record, index) => {
                                const {
                                  _id,
                                  job_number,
                                  name,
                                  phone,
                                  job_category_id,
                                  createdBy,
                                  createdAt,
                                  complete_pickup_address,
                                  scheduled_on,
                                  industry_id,
                                  service_cost,
                                } = record;
                                const vendor_id = record?.vendor_id[0];
                                return {
                                  _id,
                                  counter: index + 1,
                                  records: record,
                                  job_number,
                                  job_type: job_category_id?.name,
                                  created_by: createdBy?.username,
                                  name,
                                  phone,
                                  service_cost,
                                  createdAt: dayjs(createdAt).format(
                                    "ddd, MMM D, YYYY h:mm A"
                                  ),
                                  scheduled_on:
                                    scheduled_on &&
                                    dayjs(scheduled_on).format(
                                      "ddd, MMM D, YYYY h:mm A"
                                    ),
                                  industry_id: industry_id?.name,
                                  complete_pickup_address,
                                  vendor_name: `${
                                    vendor_id?.first_name
                                      ? vendor_id?.first_name
                                      : ""
                                  } ${
                                    vendor_id?.last_name
                                      ? vendor_id?.last_name
                                      : ""
                                  }`,
                                };
                              })
                            : []
                        }
                        columns={columnDefs}
                        getRowId={(row) => row?.records?._id}
                        headerHeight={40} // Set the height of the header
                        classes={{
                          columnHeader: "bg-[#f8f8f8] shadow-sm",
                          columnHeaderTitle:
                            "tableHead font-bold text-md capitalize px-0",
                          row: "text-md",
                        }}
                        density="compact"
                      />
                    </div>
                  </div>

                  <div className="!p-4 text-right">
                    <Button
                      text="Cancel"
                      className="mx-2"
                      onClick={() => setIsJobListing(false)}
                      variant="btn_cancel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="flex justify-end !mt-4">
          {isLoader ? (
            <Loader />
          ) : (
            <>
              <Button text="Cancel" variant="btn_cancel" onClick={onClose} />
              <Button
                text="Create Invoice"
                variant="btn_submit"
                onClick={handleConfirm}
                className="ml-2"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
