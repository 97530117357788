import React, { useRef, useState } from "react";
import { BsFilter } from "react-icons/bs";
import FilterPopup from "./FilterPopup";
const CustomFilters = ({
  handleNewFilter,
  handleFilterChange,
  filterModel,
  columns = [],
  onRemove,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  function handleFilter(e) {
    setIsOpen(!isOpen);
  }
  function handleCLoseFilter() {
    setIsOpen(false);
  }
  const filterRef = useRef();
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      handleCLoseFilter();
    }
  };
  const handleClickOutside = (event) => {
    if (
      document.querySelector(".p-multiselect-panel")?.contains(event.target)
    ) {
      return; // Prevent closing
    }
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      handleCLoseFilter();
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);

  return (
    <div className="inline-block" ref={filterRef}>
      {filterModel?.rules?.length ? (
        <span className="absolute bg-blue-500 p- rounded-full w-5 h-5 -right-2 -top-2 flex justify-center items-center">
          <small className="text-white font-semibold">
            {filterModel?.rules?.length}
          </small>
        </span>
      ) : null}

      <button
        onClick={handleFilter}
        className="py-1.5 !px-3 align-middle !bg-menu border-menu !text-white font-semibold flex items-center"
      >
        <BsFilter className="!mr-1" size={20} /> Filter
      </button>
      {isOpen && (
        <FilterPopup
          onCloseFilter={handleCLoseFilter}
          columns={columns}
          handleNewFilter={handleNewFilter}
          filterChange={handleFilterChange}
          filterModel={filterModel}
          onRemove={onRemove}
        />
      )}
    </div>
  );
};

export default CustomFilters;
