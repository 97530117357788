import React from "react";
import { FormTextArea, FormSelectInput } from "../../../components";
import LocationSection from "../Common/Sections/LocationSection";
import SchedulerSection from "../Common/Sections/SchedulerSection";
import PropertyOccupancy from "../Common/Sections/PropertyOccupancy";
import TechNote from "../Common/Sections/TechNote";

const LocksmithSection = ({
  formik,
  setIsLoader,
  callSellLeadToServiceDirect,
  checkServiceMess,
  checkServiceDirect,
}) => {
  return (
    <>
      <div className="grid grid-cols-2 gap-3">
        <PropertyOccupancy formik={formik} fieldsToShow={[]} />
        <div>
          <LocationSection
            formik={formik}
            setIsLoader={setIsLoader}
            callSellLeadToServiceDirect={callSellLeadToServiceDirect}
            checkServiceMess={checkServiceMess}
            checkServiceDirect={checkServiceDirect}
          />
          <SchedulerSection formik={formik} />
        </div>

        <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
          <p className="text-[14px] text-[#052A42]">
            What type of lock or service do you need?
          </p>

          <FormSelectInput
            id="extra_fields.lock_type"
            name="extra_fields.lock_type"
            options={[
              { value: "Automotive", label: "Automotive" },
              { value: "Door Lock", label: "Door Lock" },
              { value: "Safe Lock", label: "Safe Lock" },
              { value: "Smart Lock", label: "Smart Lock" },
              { value: "Security System", label: "Security System" },

              // { value: "Deadbolt", label: "Deadbolt" },
              // { value: "Car Key", label: "Car Key" },
              // { value: "Door Knob", label: "Door Knob" },
            ]}
            valueProp="value"
            labelProp="label"
            formik={formik}
            value={formik.values?.extra_fields?.lock_type}
          />
        </div>

        <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
          <p className="text-[14px] text-[#052A42]">
            WHAT SERVICE DO YOU NEED ?
          </p>

          <FormSelectInput
            id="extra_fields.service_type"
            name="extra_fields.service_type"
            options={[
              { value: "LOCKOUT", label: "LOCKOUT" },
              { value: "REKEY", label: "REKEY" },
              { value: "REPAIR", label: "REPAIR" },
              { value: "LOCK REPLACEMENT", label: "LOCK REPLACEMENT" },
              { value: "OPEN SAFE", label: "OPEN SAFE" },
              {
                value: "SECURITY SYSTEM INSTALL",
                label: "SECURITY SYSTEM INSTALL",
              },
              { value: "KEY DUPLICATION", label: "KEY DUPLICATION" },
              { value: "FOB PROGRAMMATION", label: "FOB PROGRAMMATION" },
              { value: "NEW KEY FOB", label: "NEW KEY FOB" },
            ]}
            valueProp="value"
            labelProp="label"
            formik={formik}
            value={formik.values?.extra_fields?.service_type}
          />
        </div>

        <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
          <label className="pb-2 text-[15px]">
            If vehicle (Year / make/ model below)
          </label>
          {/* <p className="pb-2 text-[#6A6A6A]">Year / make/ model below</p> */}
          <FormTextArea
            id="extra_fields.vehicle_detail"
            rows={5}
            name="extra_fields.vehicle_detail"
            formik={formik}
            type="text"
            value={formik?.values?.extra_fields?.vehicle_detail}
          />
        </div>

        <TechNote formik={formik} />

        <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
          <label className="pb-2 text-[15px]">
            DESCRIBE ALL DETAILS IN ADDITIONAL NOTES
          </label>
          <FormTextArea
            id="special_instruction"
            rows={5}
            name="special_instruction"
            formik={formik}
            type="text"
            value={formik?.values?.special_instruction}
          />
        </div>
      </div>
    </>
  );
};

export default LocksmithSection;
