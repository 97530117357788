import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import ImportExcel from "./ImportExcel";
import { toast } from "react-toastify";
//import * as XLSX from "xlsx";
import {
  get_domain_list,
  set_test_domains,
} from "../../../features/domainCenterSlice";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import api from "../../../services/api";
import { DotsLoader, Loader } from "../../../components";
import PageHeader from "../../../components/molecules/PageHeader";
function TestingDomains() {
  const { isLoading, domainList } = useSelector((state) => state.domainCenter);
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [isImport, setIsImport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [editingRecord, setEditingRecord] = useState(null);
  useEffect(() => {
    dispatch(
      get_domain_list({
        page: 1,
        size: 100,
        filters: JSON.stringify({
          groupOp: "AND",
          rules: [{ field: "cpd.is_test_domain", op: "yes", data: null }],
        }),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openImportFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsImport(true);
  };
  const isSelected = (data) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(({ domain }) => domain === data?.domain)?.length >
        0
      ) {
        return true;
      }
    }
    return false;
  };
  const handleConfirm = async (domain) => {
    try {
      const res = await dispatch(
        set_test_domains({ domains: [domain], is_test_domain: 0 })
      );
      if (res.payload.status === 200) {
        dispatch(
          get_domain_list({
            page: 1,
            size: 100,
            filters: JSON.stringify({
              groupOp: "AND",
              rules: [{ field: "cpd.is_test_domain", op: "yes", data: null }],
            }),
          })
        );
        toast.success("Domains testing status updated");
      } else {
        toast.error(res?.payload||"Some error occurred");
      }
    } catch (error) {
      toast.error("Some error occurred");
      console.log("🚀 ~ file: index.jsx:163 ~ setTestDomains ~ error:", error);
    }
  };
  async function sendRequests(record) {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (record?.length === 1) {
      setLoading(true);
    }
    setIsLoader(true);
    const selectedDataCopy = [...record];
    for (const item of selectedDataCopy) {
      try {
        const response = await api.post(`/api/domains/rebuild_test_domains`, {
          domains: [item?.domain],
        });

        if (response.status === 200) {
          toast.success(`Domain "${item?.domain}" rebuild Successfully`);
          setSelectedData((prevState) =>
            prevState.filter(({ domain }) => domain !== item?.domain)
          );
        } else {
          toast.success(`Domain "${item?.domain}" couldn't be rebuild`);
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
        toast.success(`Domain "${item?.domain}" couldn't be rebuild`);
      }
    }
    setLoading(false);
    setIsLoader(false);
    // Clear the selectedData after processing all requests
    setSelectedData([]);
  }
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsImport(false);
  };
  const handleExport = async (dbName) => {
    setLoading(true);
    try {
      const response = await api.get(
        `/api/domains/export_database/db_${dbName?.replaceAll(".", "_")}`,
        {
          responseType: "blob", // Specify the response type as Blob
        }
      );
      if (response?.status === 200) {
        const blobData = response.data;
        saveBlobAsExcelFile(blobData, dbName);
        toast.success("Database export successfully");
      } else {
        toast.error("Database couldn't be export");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Database couldn't be export");
      console.error("Error fetching Excel data:", error);
    }
  };

  const saveBlobAsExcelFile = (blobData, dbName) => {
    const downloadLink = window.URL.createObjectURL(new Blob([blobData]));

    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute("download", `db_${dbName?.replaceAll(".", "_")}.xlsx`); // Set the filename

    document.body.appendChild(link);
    link.click();
    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 100 },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.value}`}
            className="text-black hover:underline no-underline"
          >
            {params?.value}
          </a>
        );
      },
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Industry Name",
      field: "industry_name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "runner_name",
      headerName: "Runner Name",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Export Excel",
      field: "excel",
      flex: 1,
      renderCell: (params) => (
        <span
          onClick={() => handleExport(params?.row?.domain)}
          className="underline text-blue-600 cursor-pointer"
        >
          Export
        </span>
      ),
    },
    {
      headerName: "Import Excel",
      field: "actions",
      renderCell: (params) => (
        <div className="flex items-center w-full">
          <span
            onClick={openImportFormHandler({
              domain: params?.row?.domain,
              db_name: params?.row?.records?.db_name,
            })}
            className="underline text-blue-600 cursor-pointer"
          >
            Import Complete Excel
          </span>
        </div>
      ),
      width: 150,
      flex: 1,
    },
    {
      headerName: "Rebuild UI",
      field: "rebuild",
      renderCell: (params) => (
        <div className="flex items-center w-full">
          {isSelected(params.row.records) && isLoader ? (
            <Loader />
          ) : (
            <span
              onClick={() => {
                if (isLoader) {
                  return toast.error(
                    "Please wait until the previous process is complete."
                  );
                }
                const c = window.confirm(
                  `Are you sure want to rebuild the domain "${params?.row?.domain}"?`
                );
                if (!c) {
                  return;
                }
                setSelectedData([params?.row?.domain]);
                sendRequests([{ domain: params?.row?.domain }]);
              }}
              className="underline text-blue-600 cursor-pointer"
            >
              Rebuild
            </span>
          )}
        </div>
      ),
      width: 150,
      flex: 1,
    },
    {
      headerName: "Delete",
      field: "delete",
      renderCell: (params) => (
        <button
          className="border rounded bg-slate-900 text-white p-2"
          onClick={() => {
            if (isLoader) {
              return toast.error(
                "Please wait until the previous process is complete."
              );
            }
            const c = window.confirm(
              `Are you sure want to revert this domain "${params?.row?.domain}" as original?`
            );
            if (!c) {
              return;
            }
            setSelectedData([...domainList?.records]);
            handleConfirm(params.row.domain);
          }}
        >
          Delete
        </button>
      ),
    },
  ];
  const data = domainList?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
/*   function exportToExcel() {
    const data = domainList?.records;
    const rows = [
      ["#", "Domains", "Industry Name", "Runner Name"], // Add headings
    ];

    data.forEach((item, index) => {
      const { domain, industry_name, runner_name } = item;
      const counter = index + 1;
      rows.push([counter, domain, industry_name, runner_name]); // Add a row without domains
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const fileName = "Testing domain.xlsx";

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  } */
  return (
    <>
      {isLoading || loading ? <DotsLoader text="Please Wait" /> : null}
      {isImport && (
        <ImportExcel
          editingRecord={editingRecord}
          modalTitle="Import Excel"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > Email Templates"
        heading="Testing Domains Listing"
        onClick={() => {
          if (isLoader) {
            return toast.error(
              "Please wait until the previous process is complete."
            );
          }
          const c = window.confirm(
            `Are you sure want to rebuild all the domains?`
          );
          if (!c) {
            return;
          }
          setSelectedData([...domainList?.records]);
          sendRequests(domainList?.records);
        }}
        buttonTitle="Rebuild All"
        isAllowed={true}
      />
      {/* <div className="flex flex-cols md:flex-row items-center justify-between !mt-3">
        <h1 className="text-2xl font-extrabold">Testing Domains</h1>
        <button
          className="border rounded bg-red-600 text-white p-2"
        >
          
        </button>
      </div> */}
      <div className="bg-white !my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={data?.map((item, index) => {
            let counter = index + 1;
            const { id, runner_name, industry_name, domain } = item;
            return {
              records: item,
              id,
              counter,
              industry_name,
              runner_name,
              domain,
            };
          })}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          totalItems={domainList?.totalItems}
          pagination="No"
          density="standard"
          rowHeight={40}
        />
      </div>
    </>
  );
}

export default TestingDomains;
