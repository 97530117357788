import React from "react";
import { FormInput, FormSelectInput, Modal } from "../../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { get_inbound_group } from "../../../features/inboundGroupSlice";
import { toast } from "react-toastify";
import FormMultiSelectInput from "../../../components/molecules/FormMultiSelectInput";
import {
  create_campaigns,
  update_campaigns,
} from "../../../features/campaignSlice";
function AddCampaignsForm({
  editingRecord,
  onCancelForm,
  modalTitle,
  onSave,
  newRecord,
}) {
  const { isLoading } = useSelector((state) => state.inbound);
  const inbound = useSelector((state) => state.inbound);
  const [inGroupOptions, setInGroupOptions] = React.useState([]);
  const [errors, setErrors] = React.useState([]);

  const { record } = inbound;
  const dispatch = useDispatch();
  let initialValues = {
    campaign_name: "",
    active: "Y",
    allow_closers: "Y",
    campaign_allow_inbound: "Y",
    dial_method: "RATIO",
    local_call_time: "24hours",
    dial_prefix: "",
    manual_dial_prefix: "",
    campaign_cid: "",
    campaign_recording: "ALLFORCE",
    closer_campaigns: "",
    xfer_groups: "",
    auto_dial_level: "1",
  };
  let xfer_groups_ids = [];
  let closer_campaigns_ids = [];
  if (editingRecord) {
    const {
      campaign_id,
      campaign_name,
      active,
      allow_closers,
      campaign_allow_inbound,
      dial_method,
      local_call_time,
      dial_prefix,
      manual_dial_prefix,
      campaign_cid,
      campaign_recording,
      closer_campaigns,
      xfer_groups,
      auto_dial_level,
    } = editingRecord;
    closer_campaigns_ids = closer_campaigns;
    xfer_groups_ids = xfer_groups;
    initialValues = {
      id: campaign_id,
      campaign_name,
      active,
      allow_closers,
      campaign_allow_inbound,
      dial_method,
      local_call_time,
      dial_prefix,
      manual_dial_prefix,
      campaign_cid,
      campaign_recording,
      closer_campaigns: closer_campaigns_ids,
      xfer_groups: xfer_groups_ids,
      auto_dial_level,
    };
  }
  const handleSubmit = async (values) => {
    const action = editingRecord ? update_campaigns : create_campaigns;
    const message = editingRecord ? "updated" : "created";
    try {
      const res = await dispatch(action(values));
      if (res.payload?.status === 200) {
        toast.success(`Campaign successfully  ${message}`);
        onSave(!newRecord);
        onCancelForm();
      } else {
        if (Array.isArray(res.payload)) {
          setErrors(res.payload);
        } else {
          toast.error(
            res.payload ? res.payload : `Campaign couldn't be ${message}`
          );
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload)) {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `Campaign couldn't be ${message}`
        );
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  React.useEffect(() => {
    if (record === 0) {
      dispatch(get_inbound_group());
    }

    if (record?.length > 0) {
      let arr = [];
      // eslint-disable-next-line
      record?.map((val) => {
        arr = [...arr, { label: val?.group_name, value: val?.group_id }];
        setInGroupOptions(arr);
      });
    }
    // eslint-disable-next-line
  }, []);
  const closer_campaigns_handler = (e) => {
    let arr = [];
    // eslint-disable-next-line
    e?.map((val) => {
      arr = [...arr, val?.value];
    });
    formik.setFieldValue("closer_campaigns", arr);
  };
  const xfer_groups_handler = (e) => {
    let arr = [];
    // eslint-disable-next-line
    e?.map((val) => {
      arr = [...arr, val?.value];
    });
    formik.setFieldValue("xfer_groups", arr);
  };

  let default_closer_campaigns = [];
  closer_campaigns_ids?.map((val) => {
    const selectedIndex = record.findIndex((item) => item.group_id === val);
    default_closer_campaigns = [
      ...default_closer_campaigns,
      {
        name: `${record[selectedIndex]?.group_id} - ${record[selectedIndex]?.group_name}`,
        _id: record[selectedIndex]?.group_id,
      },
    ];
    return { default_closer_campaigns };
  });

  let default_xfer_groups = [];
  xfer_groups_ids?.map((val) => {
    const selectedIndex = record.findIndex((item) => item.group_id === val);
    default_xfer_groups = [
      ...default_xfer_groups,
      {
        name: `${record[selectedIndex]?.group_id} - ${record[selectedIndex]?.group_name}`,
        _id: record[selectedIndex]?.group_id,
      },
    ];
    return { default_xfer_groups };
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        <form className="grid md:grid-cols-4 gap-x-5 mt-5 mx-2.5">
          <div className="mb-4">
            <FormInput
              errors={errors}
              name="campaign_name"
              label="Campaign Name"
              formik={formik}
            />
          </div>

          <div className="mb-4">
            <FormSelectInput
              errors={errors}
              name="active"
              label="Active"
              formik={formik}
              options={[
                { value: "Y", label: "Y" },
                { value: "N", label: "N" },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>

          <div className="mb-4">
            <FormSelectInput
              errors={errors}
              name="allow_closers"
              label="Allow Closer"
              formik={formik}
              options={[
                { value: "Y", label: "Y" },
                { value: "N", label: "N" },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>

          <div className="mb-4">
            <FormSelectInput
              errors={errors}
              name="campaign_allow_inbound"
              label="Allow Inbound and Blended"
              formik={formik}
              options={[
                { value: "Y", label: "Y" },
                { value: "N", label: "N" },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>

          <div className="mb-4">
            <FormSelectInput
              errors={errors}
              name="dial_method"
              label="Dial Method"
              formik={formik}
              options={[
                { value: "MANUAL", label: "MANUAL" },
                { value: "RATIO", label: "RATIO" },
                { value: "ADAPT_HARD_LIMIT", label: "ADAPT HARD LIMIT" },
                { value: "ADAPT_TAPERED", label: "ADAPT TAPERED" },
                { value: "ADAPT_AVERAGE", label: "ADAPT AVERAGE" },
                { value: "INBOUND_MAN", label: "INBOUND MAN" },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>

          <div className="mb-4">
            <FormInput
              errors={errors}
              name="dial_prefix"
              label="Dial Prefix"
              formik={formik}
            />
          </div>

          <div className="mb-4">
            <FormInput
              errors={errors}
              name="manual_dial_prefix"
              label="Manual Dial Prefix"
              formik={formik}
            />
          </div>

          <div className="mb-4">
            <FormInput
              errors={errors}
              name="campaign_cid"
              label="Campaign CallerID"
              formik={formik}
            />
          </div>

          <div className="mb-4 col-span-2">
            <FormMultiSelectInput
              errors={errors}
              name="closer_campaigns"
              label="Allowed Inbound Groups"
              defaultValue={editingRecord ? default_closer_campaigns : null}
              formik={formik}
              userOptions={inGroupOptions}
              multiSelectHandler={closer_campaigns_handler}
            />
          </div>

          <div className="mb-4 col-span-2">
            <FormMultiSelectInput
              errors={errors}
              name="xfer_groups"
              label="Allowed Transfer Groups"
              defaultValue={editingRecord ? default_xfer_groups : null}
              formik={formik}
              userOptions={inGroupOptions}
              multiSelectHandler={xfer_groups_handler}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}

export default AddCampaignsForm;
