import React, { useEffect, useRef, useState } from "react";
import ToggleSwitch from "../../../components/molecules/ToggleButton";
import FormInput from "../Common/FormInput";
import { BiRefresh } from "react-icons/bi";
import { useSelector } from "react-redux";
import { FaCirclePlus, FaCircleMinus } from "react-icons/fa6";
import Radar from "radar-sdk-js";
const LocationSection = ({ formik, handleSendLocationForm }) => {
  const { record } = useSelector((state) => state.jobCategory);
  const googleKey = localStorage.getItem("google_api_key");
  const isInitialized = React.useRef(false);

  React.useEffect(() => {
    if (!isInitialized.current && googleKey) {
      Radar.initialize(googleKey);
      isInitialized.current = true;
    }
  }, [googleKey]);
  const isDroped = record?.records?.find(
    (service) => service._id === formik?.values?.job_category_id
  )?.drop_location_required;
  const [autoAddress, setAutoAddress] = useState(
    googleKey && googleKey !== "null" ? isDroped : false
  );
  const autoPickupcompleteRefs = useRef(null);
  const autoDropcompleteRefs = useRef(null);
  const autoDrop2completeRefs = useRef(null);
  const [isCustomDistance, setIsCustomDistance] = useState(false);
  const [isAddress2, setIsAddress2] = useState(false);
  const [customDistance, setCustomDistance] = React.useState("");
  useEffect(() => {
    if (googleKey && googleKey !== "null") {
      setAutoAddress(isDroped);
    }
  }, [isDroped, googleKey]);

  async function calculateRoute() {
    const {
      pickup_latitude,
      pickup_longitude,
      drop_latitude,
      drop_longitude,
      drop_2_latitude,
      drop_2_longitude,
    } = formik.values;

    if (
      !pickup_latitude ||
      !pickup_longitude ||
      !drop_latitude ||
      !drop_longitude
    ) {
      formik.setFieldValue("distance", "");
      return;
    }

    try {
      let totalDistance = 0;
      const route1 = await Radar.distance({
        origin: { latitude: pickup_latitude, longitude: pickup_longitude },
        destination: { latitude: drop_latitude, longitude: drop_longitude },
        modes: ["car"],
        units: "imperial",
      });

      if (route1 && route1.routes && route1.routes.car) {
        totalDistance += route1.routes.car.distance.value; // Value is in miles
      }
      if (drop_2_latitude && drop_2_longitude) {
        const route2 = await Radar.distance({
          origin: { latitude: drop_latitude, longitude: drop_longitude },
          destination: {
            latitude: drop_2_latitude,
            longitude: drop_2_longitude,
          },
          modes: ["car"],
          units: "imperial",
        });

        if (route2 && route2.routes && route2.routes.car) {
          totalDistance += route2.routes.car.distance.value;
        }
      }

      // Set the total distance in formik
      const distanceInMiles = (totalDistance / 5280).toFixed(2);
      formik.setFieldValue("distance", distanceInMiles || 0);
    } catch (error) {
      console.error("Error calculating route:", error);
      formik.setFieldValue("distance", "");
    }
  }

  React.useEffect(() => {
    if (
      formik?.values?.drop_latitude &&
      formik?.values?.drop_longitude &&
      formik?.values?.pickup_latitude &&
      formik?.values?.pickup_longitude
    ) {
      calculateRoute();
    }
    // eslint-disable-next-line
  }, [
    formik?.values?.pickup_latitude,
    formik?.values?.drop_latitude,
    formik?.values?.drop_2_latitude,
  ]);

  const CustomButton = ({ prefix }) => {
    const resetAddress = () => {
      formik.setFieldValue(`${prefix}_zip`, null);
      formik.setFieldValue(`${prefix}_city`, null);
      formik.setFieldValue(`${prefix}_state`, null);
      formik.setFieldValue(`${prefix}_country`, null);
      formik.setFieldValue(`${prefix}_latitude`, null);
      formik.setFieldValue(`${prefix}_longitude`, null);
      formik.setFieldValue(`complete_${prefix}_address`, null);

      if (
        prefix === "pickup" &&
        autoPickupcompleteRefs.current &&
        autoPickupcompleteRefs.current.inputField
      ) {
        autoPickupcompleteRefs.current.inputField.value = null;
        autoPickupcompleteRefs.current.inputField.disabled = false;
      }
      if (
        prefix === "drop" &&
        autoDropcompleteRefs.current &&
        autoDropcompleteRefs.current.inputField
      ) {
        autoDropcompleteRefs.current.inputField.value = null;
        autoDropcompleteRefs.current.inputField.disabled = false;
      }
      if (
        prefix === "drop_2" &&
        autoDrop2completeRefs.current &&
        autoDrop2completeRefs.current.inputField
      ) {
        autoDrop2completeRefs.current.inputField.value = null;
        autoDrop2completeRefs.current.inputField.disabled = false;
      }
    };
    return formik.values[`complete_${prefix}_address`] ? (
      <button onClick={resetAddress}>
        <BiRefresh size={25} className="" cursor="pointer" />
      </button>
    ) : null;
  };
  const requiredLocation = ["pickup"].filter(Boolean);

  const selectradarAddress = (address, prefix) => {
    if (address.postalCode) {
      formik.setFieldValue(`${prefix}_zip`, address.postalCode);
    }
    if (address.city) {
      formik.setFieldValue(`${prefix}_city`, address.city);
    }
    if (address.stateCode) {
      formik.setFieldValue(`${prefix}_state`, address.stateCode);
    }
    if (address.country) {
      formik.setFieldValue(`${prefix}_country`, address.country);
    }

    formik?.setFieldValue(
      `complete_${prefix}_address`,
      address.formattedAddress
    );
    formik?.setFieldValue(`${prefix}_latitude`, address.latitude);
    formik?.setFieldValue(`${prefix}_longitude`, address.longitude);
  };
  useEffect(() => {
    if (document.getElementById("pickup")) {
      autoPickupcompleteRefs.current = Radar.ui.autocomplete({
        container: "pickup",
        countryCode: "US, CA",
        onSelection: (address) => {
          selectradarAddress(address, "pickup");
          autoPickupcompleteRefs.current.inputField.disabled = true;
        },
      });
    }
    if (document.getElementById("drop")) {
      autoDropcompleteRefs.current = Radar.ui.autocomplete({
        container: "drop",
        countryCode: "US, CA",
        onSelection: (address) => {
          selectradarAddress(address, "drop");
          autoDropcompleteRefs.current.inputField.disabled = true;
        },
      });
    }
    if (document.getElementById("drop_2")) {
      autoDrop2completeRefs.current = Radar.ui.autocomplete({
        container: "drop_2",
        countryCode: "US, CA",
        onSelection: (address) => {
          selectradarAddress(address, "drop_2");
          autoDrop2completeRefs.current.inputField.disabled = true;
        },
      });
    }

    if (
      formik?.values.complete_pickup_address &&
      autoPickupcompleteRefs.current &&
      autoPickupcompleteRefs.current.inputField
    ) {
      autoPickupcompleteRefs.current.inputField.value =
        formik?.values.complete_pickup_address;
      autoPickupcompleteRefs.current.inputField.disabled = true;
    }
    if (
      formik?.values.complete_drop_address &&
      autoDropcompleteRefs.current &&
      autoDropcompleteRefs.current.inputField
    ) {
      autoDropcompleteRefs.current.inputField.value =
        formik?.values.complete_drop_address;
      autoDropcompleteRefs.current.inputField.disabled = true;
    }
    if (
      formik?.values.complete_drop_2_address &&
      autoDrop2completeRefs.current &&
      autoDrop2completeRefs.current.inputField
    ) {
      autoDrop2completeRefs.current.inputField.value =
        formik?.values.complete_drop_2_address;
      autoDrop2completeRefs.current.inputField.disabled = true;
    }
    return () => {
      autoPickupcompleteRefs.current?.remove();
      autoDropcompleteRefs.current?.remove();
      autoDrop2completeRefs.current?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoAddress, isDroped, isAddress2]);

  return (
    <div className="bg-white border px-2 md:px-4 mt-3">
      <div className="pl-2 pt-3 !mb-2 flex justify-between">
        <div className="font-semibold text-lg">Location:</div>
        <div className="flex w-auto gap-x-2">
          <span className="font-semibold mr-">Auto complete address:</span>
          <ToggleSwitch
            checked={autoAddress}
            unique_by="auto_address"
            onChange={() => setAutoAddress(!autoAddress)}
          />
          <div className="flex justify-end space-x-2 text-sm">
            <button
              onClick={() => {
                const prefix = "drop_2";
                formik.setFieldValue(`${prefix}_zip`, null);
                formik.setFieldValue(`${prefix}_city`, null);
                formik.setFieldValue(`${prefix}_state`, null);
                formik.setFieldValue(`${prefix}_country`, null);
                formik.setFieldValue(`${prefix}_latitude`, null);
                formik.setFieldValue(`${prefix}_longitude`, null);
                formik.setFieldValue(`complete_${prefix}_address`, null);
                setIsAddress2(false);
              }}
              className="flex items-center justify-center w-24 h-6 text-menu border rounded-sm hover:bg-menu hover:text-white group"
            >
              <FaCircleMinus className="mr-1" />
              <span className="text-menu group-hover:text-white">Remove</span>
            </button>
            {}
            <button
              onClick={() => setIsAddress2(true)}
              className="flex items-center justify-center w-24 h-6 text-primary-100 border rounded-sm text-black hover:bg-primary-100 hover:text-white group"
            >
              <FaCirclePlus className="mr-1" />
              <span className="text-menu group-hover:text-white">Add</span>
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="w-full ">
          <div
            className={`grid ${
              isDroped && isAddress2
                ? "grid-cols-3"
                : isDroped || isAddress2
                ? "grid-cols-2"
                : "grid-cols-1"
            } gap-4 my-2`}
          >
            {requiredLocation?.map((prefix, index) => {
              return (
                <div key={prefix} className="bg-neutral-50 p-2 border shadow">
                  {autoAddress ? (
                    <div className="flex flex-col" id={prefix}>
                      <div className="flex justify-between">
                        <label
                          className="font-semibold text-xs text-gray-500 mb-1"
                          htmlFor={`complete_${prefix}_address`}
                        >
                          {`${
                            !isDroped
                              ? ""
                              : prefix === "pickup"
                              ? "Pickup"
                              : prefix === "drop"
                              ? "Drop"
                              : prefix === "drop_2"
                              ? "2nd Drop-Off"
                              : ""
                          } Address: ${index > 0 ? "(Only if needed)" : ""}`}
                        </label>
                        {`complete_${prefix}_address` ? (
                          <CustomButton prefix={prefix} />
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <FormInput
                      name={`complete_${prefix}_address`}
                      type="text"
                      label={`${
                        prefix === "pickup"
                          ? "Pickup"
                          : prefix === "drop"
                          ? "Drop"
                          : prefix === "drop_2"
                          ? "2nd Drop-Off"
                          : ""
                      } Address: ${index > 0 ? "(Only if needed)" : ""}`}
                      formik={formik}
                      CustomButton={
                        `complete_${prefix}_address` ? CustomButton : null
                      }
                      prefix={prefix}
                    />
                  )}
                  <div className="grid grid-cols-3 gap-4 mt-2">
                    <FormInput
                      name={`${prefix}_zip`}
                      type="text"
                      placeholder="Zip"
                      formik={formik}
                    />
                    <FormInput
                      name={`${prefix}_city`}
                      type="text"
                      placeholder="City"
                      formik={formik}
                    />
                    <FormInput
                      name={`${prefix}_state`}
                      type="text"
                      placeholder="State"
                      formik={formik}
                    />
                  </div>
                </div>
              );
            })}
            {isDroped ? (
              <div className="bg-neutral-50 p-2 border shadow">
                {autoAddress ? (
                  <div className="flex flex-col" id={"drop"}>
                    <div className="flex justify-between">
                      <label
                        className="font-semibold text-xs text-gray-500 mb-1"
                        htmlFor={`complete_drop_address`}
                      >
                        {`Drop Address: (Only if needed)`}
                      </label>
                      {`complete_drop_address` ? (
                        <CustomButton prefix="drop" />
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <FormInput
                    name={`complete_drop_address`}
                    type="text"
                    label={`Drop Address: (Only if needed)`}
                    formik={formik}
                    CustomButton={`complete_drop_address` ? CustomButton : null}
                    prefix={"drop"}
                  />
                )}
                <div className="grid grid-cols-3 gap-4 mt-2">
                  <FormInput
                    name={`drop_zip`}
                    type="text"
                    placeholder="Zip"
                    formik={formik}
                  />
                  <FormInput
                    name={`drop_city`}
                    type="text"
                    placeholder="City"
                    formik={formik}
                  />
                  <FormInput
                    name={`drop_state`}
                    type="text"
                    placeholder="State"
                    formik={formik}
                  />
                </div>
              </div>
            ) : null}
            {isAddress2 ? (
              <div className="bg-neutral-50 p-2 border shadow">
                {autoAddress ? (
                  <div className="flex flex-col" id={"drop_2"}>
                    <div className="flex justify-between">
                      <label
                        className="font-semibold text-xs text-gray-500 mb-1"
                        htmlFor={`complete_drop_2_address`}
                      >
                        {`Drop Address: (Only if needed)`}
                      </label>
                      {`complete_drop_2_address` ? (
                        <CustomButton prefix="drop_2" />
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <FormInput
                    name={`complete_drop_2_address`}
                    type="text"
                    label={`Drop Address: (Only if needed)`}
                    formik={formik}
                    CustomButton={
                      `complete_drop_2_address` ? CustomButton : null
                    }
                    prefix={"drop_2"}
                  />
                )}
                <div className="grid grid-cols-3 gap-4 mt-2">
                  <FormInput
                    name={`drop_2_zip`}
                    type="text"
                    placeholder="Zip"
                    formik={formik}
                  />
                  <FormInput
                    name={`drop_2_city`}
                    type="text"
                    placeholder="City"
                    formik={formik}
                  />
                  <FormInput
                    name={`drop_2_state`}
                    type="text"
                    placeholder="State"
                    formik={formik}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <button
            className="text-white bg-primary-100 font-semibold hover:underline text-sm shadow py-0.5 px-1 mb-1"
            onClick={handleSendLocationForm}
          >
            Send Location Link
          </button>
          {formik.values.complete_pickup_address &&
          formik.values.complete_drop_address ? (
            <div className="flex justify-between">
              <small className="font-pop">
                {formik?.values?.distance
                  ? `Distance is ${formik?.values?.distance} miles`
                  : ""}
              </small>
              {isCustomDistance ? (
                <div className="flex flex-row gap-x-2 items-centr justify-cente">
                  <input
                    className="bg-transparent border-b border-black"
                    value={customDistance}
                    onChange={(e) => setCustomDistance(e.target.value)}
                    name="distance"
                  />
                  <div className="flex flex-row gap-x-2 items-end">
                    <button
                      onClick={() => {
                        formik.setFieldValue("distance", customDistance);
                        setCustomDistance("");
                        setIsCustomDistance(false);
                      }}
                      className="text-sm text-blue-500"
                    >
                      Done
                    </button>
                    <button
                      onClick={() => {
                        setCustomDistance("");
                        setIsCustomDistance(false);
                      }}
                      className="text-sm text-red-500"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row gap-x-2">
                  <button
                    onClick={() => setIsCustomDistance(true)}
                    type="button"
                    className="text-blue-500"
                  >
                    Want to change the distance?
                  </button>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LocationSection;
