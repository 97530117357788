import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import AddUpdateForm from "./AddUpdateForm";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { getNumbers, deleteNumber } from "../../services/communicationNumbers";
import dayjs from "dayjs";
const CommNumbers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [records, setRecords] = useState([]);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await deleteNumber(record_id);
        if (res?.status === 200) {
          toast.success("Number deleted successfully.");
          getNumberLists();
        }
      } catch (error) {
        toast.error(error.message || "Something went wrong!");
      }
    }
  };

  const getNumberLists = async () => {
    try {
      setIsLoading(true);
      const res = await getNumbers();
      if (res.status === 200) {
        setRecords(res.data);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getNumberLists();
    // eslint-disable-next-line
  }, []);

  const columnDefs = [
    { headerName: "#", field: "counter" },
    {
      headerName: "DID",
      field: "did",
      flex: 1,
      minWidth: 200,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 200,
      filterable: false,
    },
    {
      field: "actions",
      filterable: false,
      renderCell: (params) =>
        params.row.records ? (
          <div>
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update Number"
            />
            <FaTrashAlt
              onClick={deleteRecordHandler(params.row.records._id)}
              className="my_navIcon"
              title="Delete Number"
            />
          </div>
        ) : null,
    },
  ];

  const reloadListing = () => {
    setEditingRecord(null);
    setIsEditing(false);
    getNumberLists();
  };

  const listings = records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle="Add Number"
          onCancelForm={cancelFormHandler}
          reloadListing={reloadListing}
        />
      )}
      <PageHeader
        route="Setting > Communication No."
        heading="Listing"
        onClick={openFormHandler(0)}
        isAllowed
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={listings?.map((record, index) => {
            const counter = index + 1;
            const { did, createdAt } = record;
            return {
              counter,
              records: { ...record },
              did,
              createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
            };
          })}
          totalItems={records?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default CommNumbers;
