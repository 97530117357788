import React, { useState, useEffect } from "react";
import axios from "axios";
import PageHeader from "../../components/molecules/PageHeader";
import { toast } from "react-toastify";
import ResponseModal from "./ResponseModal";

const RbtLink = () => {
  const baseUrl =
    "https://retreaver.com/webhook-configurator/v1/?call_key=[call_key]&call_uuid=[call_uuid]&output_tag_prefix=service_direct_93583&ping_url=https://api.servicedirect.com/partners/request&ping_method=POST&ping_headers={Content-Type:application/json}&ping_data={zip_code:[caller_zip],service_category:198,sd_api_key:E8358C6F6A}&ping_output_map={PingOutputMap:{available_buyer:data.available_buyer,request_id:data.request_id,timer:data.min_duration,bid:data.bid}}&return_ping_response=true&send_post=true&post_condition_key=available_buyer&post_condition_operator=is_equal&post_condition_value=true&post_url=https://api.servicedirect.com/partners/request/<podd_start>request_id<podd_end>/accept&post_method=POST&post_headers={Content-Type:application/x-www-form-urlencoded}&post_decode_url_parameters=true&post_data={sd_api_key:E8358C6F6A}&post_output_map={PostOutputMap:{number:data.phone_number}}&return_post_response=true";

  const [url, setUrl] = useState(baseUrl);
  const [orgUrl] = useState(baseUrl);
  const [params, setParams] = useState([]);
  const [method, setMethod] = useState("");
  const [response, setResponse] = useState(null);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    const fetchServiceCategories = async () => {
      try {
        const { data } = await axios.get(
          "https://api.servicedirect.com/resources/service_categories?is_marketplace=1"
        );
        setServiceCategories(data);
      } catch (error) {
        toast.error("Failed to fetch service categories.");
        console.error(error);
      }
    };

    fetchServiceCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      const parsedParams = parseUrl(url);
      setParams(parsedParams);
      extractMethod(parsedParams);
      updatePingData(selectedCategory);
      updateServiceCategory(selectedCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const updatePingData = (categoryId) => {
    const updatedUrl = url
      .replace(/service_category:\d+/, `service_category:${categoryId}`)
      .replace(
        /zip_code:\[caller_zip\]/,
        `zip_code:[caller_zip]` 
      );
    setUrl(updatedUrl);
  };

  const updateServiceCategory = (newCategory) => {
    const updatedParams = params?.map((param) => {
      if (param.key === "ping_data") {
        return {
          ...param,
          value: param.value.replace(
            /service_category:\d+/,
            `service_category:${newCategory}`
          ),
        };
      }
      return param;
    });
    setParams(updatedParams);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const parsedParams = parseUrl(url);
    setParams(parsedParams);
    extractMethod(parsedParams);
  };

  const parseUrl = (url) => {
    const queryStringIndex = url.indexOf("?");
    if (queryStringIndex === -1) return [];

    const queryString = url.substring(queryStringIndex + 1);
    const pairs = queryString.split("&");
    return pairs.map((pair) => {
      const [key, value] = pair.split("=");
      return { key: decodeURIComponent(key), value: decodeURIComponent(value) };
    });
  };

  const extractMethod = (parsedParams) => {
    const methodParam = parsedParams.find((param) => param.key === "method");
    if (methodParam) {
      setMethod(methodParam.value);
    } else {
      setMethod("");
    }
  };

  const handleValueChange = (index, newValue) => {
    const updatedParams = [...params];
    updatedParams[index].value = newValue;
    setParams(updatedParams);
    updateUrl(updatedParams);
  };

  const updateUrl = (updatedParams) => {
    const baseUrl = url.split("?")[0];
    const queryString = updatedParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");
    setUrl(`${baseUrl}?${queryString}`);
  };

  const handleApiCall = async () => {
    if (!method) {
      toast.error("Please choose an API method!");
      return;
    }

    const baseUrl = url.split("?")[0];
    const finalUrl =
      method.toUpperCase() === "GET"
        ? baseUrl +
          "?" +
          params.map((param) => `${param.key}=${param.value}`).join("&")
        : baseUrl;

    try {
      const options = {
        method: method.toUpperCase(),
        url: finalUrl,
        headers: {
          "Content-Type":
            method.toUpperCase() === "POST"
              ? "application/x-www-form-urlencoded"
              : "application/json",
        },
        data:
          method.toUpperCase() === "POST"
            ? params.reduce((obj, param) => {
                obj[param.key] = param.value;
                return obj;
              }, {})
            : null,
      };

      if (method.toUpperCase() === "POST") {
        const queryString = new URLSearchParams(options.data).toString();
        options.data = queryString;
      }

      const res = await axios(options);
      setResponse(res.data);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      setResponse({ error: error.message });
    }
  };

  const handleReset = () => {
    setUrl(orgUrl);
    setParams([]);
    setMethod("");
    setResponse(null);
    setSelectedCategory("");
  };

  return (
    <>
      <PageHeader heading="RTB Link" />
      {response ? (
        <ResponseModal onCancelForm={() => setResponse(null)} data={response} />
      ) : null}
      <div className="bg-white my-3 border rounded">
        <div className="mx-auto p-6 bg-gray-100 rounded-lg shadow-md mt-4 mb-4">
          <h1 className="text-2xl font-bold mb-4">URL Parameter Parser</h1>
          <form onSubmit={handleSubmit} className="flex mb-2">
            <textarea
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter URL here"
              className="flex-grow p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2"
              rows="3"
            />
          </form>
          <div className="flex justify-end mb-2">
            <button
              type="submit"
              className="bg-primary-100 text-white p-2 rounded-md hover:bg-blue-600 transition duration-200 mr-2"
              onClick={handleSubmit}
            >
              Parse URL
            </button>
            <button
              type="button"
              onClick={handleReset}
              className="bg-blue-500 text-white p-2 rounded-md hover:bg-red-600 transition duration-200"
            >
              Reset URL
            </button>
          </div>

          <div className="mb-4 mt-4">
            <h2 className="text-xl font-semibold mb-2">Parsed Parameters:</h2>
            {params.length > 0 ? (
              <div className="mt-2">
                <h2 className="text-xl font-semibold mb-2">
                  Service Category:
                </h2>
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2"
                >
                  <option value="">Select Service Category</option>
                  {serviceCategories?.data?.service_categories?.map(
                    (category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    )
                  )}
                </select>
              </div>
            ) : null}
            {params.map((param, index) => (
              <div key={index} className="mb-2">
                <label className="block font-medium mb-1">
                  <strong>{param.key}:</strong>
                </label>
                <input
                  type="text"
                  value={param.value}
                  onChange={(e) => handleValueChange(index, e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            ))}

            {params.length > 0 && (
              <div className="mb-2">
                <label className="block font-medium mb-1">
                  <strong>Method:</strong>
                </label>
                <div className="grid grid-cols-2 gap-2">
                  <select
                    value={method}
                    onChange={(e) => setMethod(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select Method</option>
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                  </select>
                  <button
                    onClick={handleApiCall}
                    className="bg-green-500 text-white p-1 rounded-md hover:bg-green-600 transition duration-200 w-[200px]"
                  >
                    Call API
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RbtLink;
