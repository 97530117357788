import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ContextWrapper from "./views/Scheduler/context/ContextWrapper";
import { LicenseInfo } from "@mui/x-license-pro";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "./index.css";
import HeadManager from "./util/headerManager";
LicenseInfo.setLicenseKey(
  "b572720c5111a0d0cf3d6c75a9f06220Tz02MTk2NixFPTE3MTAzOTYwOTIyNzIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

// sentry
// if (process.env.NODE_ENV === "production") {
//   Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_DSN,
//     integrations: [
//       new Sentry.BrowserTracing(),
//       new Sentry.Replay({
//         maskAllText: true,
//         blockAllMedia: true,
//       }),
//     ],
//     tracesSampleRate: 1.0,
//     replaysSessionSampleRate: 1.0,
//     replaysOnErrorSampleRate: 1.0,
//   });
// }
const root = ReactDOM.createRoot(document.getElementById("root"));
const hostname = window.location.hostname === "towingwiz.com";
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ContextWrapper>
      <HeadManager
        title={hostname ? "TowingWiz" : 'Logical CRM'}
        description="Customer Relation Management Solution"
        favicon={hostname ? "/favicon.png" : "/fav-1.png"}
      />
      <App />
    </ContextWrapper>
  </Provider>
  // </React.StrictMode>
);
reportWebVitals();
