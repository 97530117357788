import React from "react";
import { FormTextArea, FormSelectInput } from "../../../components";
import TechNote from "../Common/Sections/TechNote";
import PropertyOccupancy from "../Common/Sections/PropertyOccupancy";
import LocationSection from "../Common/Sections/LocationSection";
import SchedulerSection from "../Common/Sections/SchedulerSection";
import FormRadioGroup from "../../../components/molecules/FormRadioGroup";
import FormCheckbox from "../../../components/molecules/FormCheckbox";

const GaterepairSection = ({
  formik,
  setIsLoader,
  callSellLeadToServiceDirect,
  checkServiceMess,
  checkServiceDirect,
}) => {
  const issues = [
    {
      value: "Loud or unusual noises",
      label: "Loud or unusual noises",
    },
    {
      value: "Door opens or closes partially",
      label: "Door opens or closes partially",
    },
    { value: "Gate is misaligned", label: "Gate is misaligned" },
    {
      value: "Motor malfunction",
      label: "Motor malfunction",
    },
    {
      value: "Gate opener/keypad issue",
      label: "Gate opener/keypad issue",
    },

    { value: "Other", label: "Other (please describe)" },
  ];
  return (
    <div className="grid grid-cols-2 gap-3">
      <PropertyOccupancy formik={formik} fieldsToShow={[]} />
      <div>
        <LocationSection
          formik={formik}
          setIsLoader={setIsLoader}
          callSellLeadToServiceDirect={callSellLeadToServiceDirect}
          checkServiceMess={checkServiceMess}
          checkServiceDirect={checkServiceDirect}
        />
        <SchedulerSection formik={formik} />
      </div>

      <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
        <label className="pb-2 text-[15px] text-[#052A42]">
          What type of issue are you experiencing?
        </label>
        <div className="flex flex-wrap gap-x-3">
          {issues?.map((item, index) => {
            return (
              <FormCheckbox
                id="extra_fields.issue"
                name="extra_fields.issue"
                labelText={item?.label}
                formik={formik}
                onChange={() => {
                  const currentIssues = Array.isArray(
                    formik?.values?.extra_fields?.issue
                  )
                    ? formik.values.extra_fields.issue
                    : []; // Initialize as an empty array if not an array

                  formik.setFieldValue(
                    "extra_fields.issue",
                    !currentIssues.includes(item?.value)
                      ? [...currentIssues, item?.value]
                      : currentIssues.filter((i) => i !== item?.value)
                  );
                }}
                checked={formik?.values?.extra_fields?.issue?.includes(
                  item?.value
                )}
              />
            );
          })}
        </div>
        {/* <FormRadioGroup
          id="extra_fields.issue"
          name="extra_fields.issue"
          labelProp="label"
          valueProp="value"
          options={issues}
          isOnChange={(e) => {
            formik.setFieldValue("extra_fields.issue", e);
          }}
          isCheck={formik.values?.extra_fields?.issue || []}
          type="checkbox"
          isHorizontal={true}
          // isBorder={true}
        /> */}
        {formik.values?.extra_fields?.issue?.includes("Other") ? (
          <div className="mt-2">
            <FormTextArea
              id="extra_fields.issue_description"
              rows={3}
              name="extra_fields.issue_description"
              formik={formik}
              type="text"
              label="Description:"
            />
          </div>
        ) : null}
      </div>
      <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
        <label className="pb-2 text-[15px] text-[#052A42]">
          What type of gate do you have?
        </label>
        <FormRadioGroup
          id="extra_fields.gate_type"
          name="extra_fields.gate_type"
          labelProp="label"
          valueProp="value"
          options={[
            { value: "Single door", label: "Single door" },
            { value: "Swing gate", label: "Swing gate" },
            { value: "Arm gate", label: "Arm gate" },
            { value: "Sliding gate", label: "Sliding gate" },
          ]}
          isOnChange={(e) => {
            formik.setFieldValue("extra_fields.gate_type", e);
          }}
          isCheck={formik.values?.extra_fields?.gate_type || null}
          isRadio={true}
          isHorizontal={true}
          // isBorder={true}
        />
        <div className="flex w-full gap-x-2 justify-between">
          <div className=" border-[#F0F0F0] bg-[#FAFAFA] flex w-full flex-col mt-3">
            <p className="text-[14px] text-[#052A42]">
              Is the gate automatic or manual?
            </p>
            <div className="flex items-center pb-2 pt-2">
              <FormSelectInput
                name="extra_fields.gate_system"
                options={[
                  { value: "Automatic", label: "Automatic" },
                  { value: "Manual", label: "Manual" },
                ]}
                valueProp="value"
                labelProp="label"
                formik={formik}
                value={formik.values?.extra_fields?.gate_system}
              />
            </div>
          </div>
          <div className="pl-2border-[#F0F0F0] bg-[#FAFAFA] mt-3 flex w-full flex-col">
            <p className="text-[14px] text-[#052A42]">
              What material is the gate made of
            </p>
            <div className="flex items-center pb-2 pt-2">
              <FormSelectInput
                name="extra_fields.gate_material"
                options={[
                  { value: "Wood", label: "Wood" },
                  { value: "Metal", label: "Metal" },
                  { value: "Iron", label: "Iron" },
                  { value: "Other", label: "Other" },
                ]}
                valueProp="value"
                labelProp="label"
                formik={formik}
                value={formik.values?.extra_fields?.gate_material}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border border-[#F0F0F0] bg-[#FAFAFA] p-3">
        <label className="pb-2 text-[15px] text-[#052A42]">
          Any visible damage to the gate, frame, or opener?
        </label>
        <FormRadioGroup
          id="extra_fields.damage"
          name="extra_fields.damage"
          labelProp="label"
          valueProp="value"
          options={[
            { value: true, label: "Yes (describe below)" },
            { value: false, label: "No" },
          ]}
          isOnChange={(e) => {
            const value = e === "true";
            formik.setFieldValue("extra_fields.damage", value);
          }}
          isCheck={
            formik.values?.extra_fields?.damage ||
            formik.values?.extra_fields?.damage === false
              ? formik.values?.extra_fields?.damage
              : null
          }
          isRadio={true}
          isHorizontal={true}
          // isBorder={true}
        />
        {formik.values?.extra_fields?.damage ? (
          <div className="mt-2">
            <FormTextArea
              id="extra_fields.damage_description"
              rows={3}
              name="extra_fields.damage_description"
              formik={formik}
              type="text"
              label="Description:"
            />
          </div>
        ) : null}
      </div>
      <div>
        <TechNote formik={formik} />
      </div>
    </div>
  );
};

export default GaterepairSection;
