import React, { useState, useRef } from "react";
import { DotsLoader, FormSelectInput } from "../../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import api from "../../../services/api";
import * as Yup from "yup";
import CustomModal from "../../../components/molecules/Modal/customModal";
import { MdAttachFile } from "react-icons/md";
import * as XLSX from "xlsx";
import { FcDownload } from "react-icons/fc";
const AffiliateFileUpload = ({
  onCancelForm,
  modalTitle,
  selectedData,
  formSubmit,
}) => {
  const [selectedType, setSelectedType] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [fileSizeError, setFileSizeError] = useState("");
  const [fileTypeError, setFileTypeError] = useState("");

  // File input ref to reset file input after removal
  const fileInputRef = useRef(null);

  const handleSubmit = async (values) => {
    setUploading(true);
    setUploadError(null);
    const formData = new FormData();

    // Append the file to form data if a file is selected
    if (selectedFiles.length > 0) {
      formData.append("files", selectedFiles[0]);
    }

    formData.append("setting_id", values.setting_id);
    formData.append("type", values.type);

    try {
      const response = await api.post(
        "/api/lead_source/upload-inbound-phone-numbers",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        onCancelForm();
        toast.success("File uploaded successfully.");
        formSubmit();
      } else {
        toast.error("Failed to upload file. Please try again.");
        setUploadError("Failed to upload file. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to upload file. Please try again.");
      setUploadError("Failed to upload file. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  let initialValues = {
    type: "",
    setting_id: "",
    files: "",
  };

  if (selectedData) {
    const { _id } = selectedData;
    initialValues = {
      setting_id: _id,
      type: "",
      files: "",
    };
  }

  const validationSchema = Yup.object({
    type: Yup.string().required("Type is required"),
    files: Yup.mixed()
      .required("File is required")
      .test("fileSize", "File exceeds the size limit (1MB)", (value) => {
        if (value && value[0]) {
          return value[0].size <= 1024 * 1024; // 1MB max
        }
        return true;
      })
      .test("fileType", "Only .xlsx files are allowed", (value) => {
        if (value && value[0]) {
          return value[0].name.split(".").pop() === "xlsx";
        }
        return true;
      }),
  });

  const handleFileChange = (event) => {
    event.preventDefault();
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      let newFiles = [];
      let sizeError = "";
      let typeError = "";

      if (fileArray.length > 1) {
        toast.error("You can only upload one file.");
      } else {
        const file = fileArray[0];

        if (file && file.name.split(".").pop() !== "xlsx") {
          typeError = "Only .xlsx files are allowed.";
        } else if (file.size > 1024 * 1024) {
          sizeError = `File "${file.name}" exceeds 1 MB. Please choose a smaller file.`;
        } else {
          newFiles.push(file);
        }
      }

      setFileSizeError(sizeError);
      setFileTypeError(typeError);

      if (!sizeError && !typeError) {
        setSelectedFiles(newFiles);
        formik.setFieldValue("files", newFiles);
      }
    }
  };

  const removeFile = (event) => {
    event.preventDefault();
    setSelectedFiles([]);
    formik.setFieldValue("files", null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const generateDownloadFile = () => {
    const data = [
      selectedType === "phoneNumber" ? { phoneNumber: "" } : { webSource: "" },
    ];
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "affiliate.xlsx");
  };
  return (
    <CustomModal
      handleModal={onCancelForm}
      cancelButtonRef={onCancelForm}
      className="max-w-3xl"
      modalType={true}
      title={modalTitle}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
    >
      {uploading ? <DotsLoader /> : null}

      <form>
        <div className="flex justify-center w-full mt-2">
          <div className="w-full md:w-3/5">
            <FormSelectInput
              name="type"
              label="Type"
              formik={formik}
              options={[
                { label: "Inbound Call", value: "phoneNumber" },
                { label: "Web Source", value: "webSource" },
              ]}
              labelProp="label"
              valueProp="value"
              onChange={(e) => {
                formik.setFieldValue("type", e);
                setSelectedType(e);
              }}
            />
          </div>
          <button
            type="button"
            className={`ml-2 py-1.5 !px-3 align-middle float-right text-blue-500 hover:cursor-pointer text-sm font-semibold mt-2 ${
              !selectedType
                ? "text-gray-400 cursor-not-allowed"
                : "hover:text-blue-600"
            }`}
            onClick={generateDownloadFile}
            title="Download Sample file"
            disabled={!selectedType}
          >
            <FcDownload size="18" className="mt-0" /> Download Sample
          </button>
        </div>

        <div className="flex justify-center items-center space-y-4 mt-4">
          <label htmlFor="file-upload" className="cursor-pointer">
            <div className="flex items-center justify-center space-x-2 p-2 px-6 bg-primary-100 text-white rounded-full shadow-md hover:bg-gray-400 transition">
              <MdAttachFile size={24} color="black" />
              <span className="text-sm font-medium mr-2">Attach File</span>
            </div>
          </label>
          <input
            id="file-upload"
            ref={fileInputRef}
            type="file"
            accept=".xlsx"
            onChange={handleFileChange}
            className="hidden"
          />
        </div>

        {/* Display error message for files */}
        {formik.errors.files && formik.touched.files && (
          <div className="text-sm text-red-600 mt-2 text-center">
            {formik.errors.files}
          </div>
        )}

        {/* Display additional file errors */}
        {(fileSizeError || fileTypeError) && (
          <div className="flex items-center text-sm text-red-800 justify-center mt-2">
            <span className="font-medium">
              {fileSizeError && <p>{fileSizeError}</p>}
              {fileTypeError && <p>{fileTypeError}</p>}
            </span>
          </div>
        )}

        {/* Display selected files */}
        {selectedFiles.length > 0 && (
          <div className="grid grid-cols-4 gap-4 mt-4 border-2 border-dotted p-4 rounded-md">
            {selectedFiles.map((file, index) => (
              <div
                key={index}
                className="relative flex flex-col justify-center items-center border border-gray-300 p-2 rounded-md"
              >
                <div className="text-center flex flex-col justify-center items-center">
                  <p className="mt-2 text-sm text-gray-700 break-words">
                    {file.name}
                  </p>
                </div>

                <button
                  onClick={removeFile}
                  className="absolute top-0 right-0 text-white bg-red-500 p-1 rounded-full shadow-md hover:bg-red-600 transition h-6 w-6 flex justify-center items-center"
                >
                  <span className="text-lg font-bold">&times;</span>
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Display selected file count */}
        {selectedFiles.length ? (
          <div className="text-sm text-gray-500 mt-2 text-center">
            {selectedFiles.length} file selected
          </div>
        ) : null}

        {/* Display uploading text */}
        {uploading && (
          <div className="mt-4 text-center text-blue-500">
            Uploading File...{" "}
          </div>
        )}

        {/* Display upload error */}
        {uploadError && (
          <div className="mt-4 text-center text-red-500">{uploadError}</div>
        )}
      </form>
    </CustomModal>
  );
};

export default AffiliateFileUpload;
