import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { create_dids, update_dids } from "../../../features/didSlice";
import errorMessage from "../../../util/errorMessage";
import CampaignGroup from "./CampaingGroup";
function AddDidsForm({
  editingRecord,
  onCancelForm,
  modalTitle,
  onSave,
  newRecord,
  callManu,
  leadSource,
}) {
  const { dialerUsers } = useSelector((state) => state.users);
  const inbound = useSelector((state) => state.inbound);
  const { industryDrd } = useSelector((state) => state.industry);
  const [isLoading, setIsLoading] = React.useState(false);
  const { record } = inbound;
  const [errors, setErrors] = React.useState([]);
  const dispatch = useDispatch();
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  let initialValues = {
    did_pattern: "",
    did_description: "",
    record_call: "Y",
    did_route: "EXTEN",
    group_id: "",
    menu_id: "",
    extension: "",
    did_carrier_description: "",
    user: "",
    voicemail_ext: "",
    cx: user?.conexcs_settings?.cx || "",
    destination_ip: user?.conexcs_settings?.destination_ip || "",
    dial_out_prefix: user?.conexcs_settings?.dial_out_prefix || "",
    route_external: "NO",
    custom_one: "",
    provider: "Intel",
    custom_two:"",
  };

  if (editingRecord) {
    const {
      did_pattern,
      did_description,
      record_call,
      did_route,
      group_id,
      did_id,
      menu_id,
      extension,
      did_carrier_description,
      user,
      voicemail_ext,
      custom_one,
      route_external,
      provider,
      custom_two,
    } = editingRecord;
    initialValues = {
      id: did_id,
      did_pattern,
      did_description,
      record_call,
      did_route,
      group_id: group_id || "",
      menu_id: menu_id || "",
      extension: extension
        ? extension?.replace(user?.conexcs_settings?.dial_out_prefix, "")
        : "",
      user: user,
      voicemail_ext: voicemail_ext,
      did_carrier_description,
      cx: user?.conexcs_settings?.cx || "",
      destination_ip: user?.conexcs_settings?.destination_ip || "",
      dial_out_prefix: user?.conexcs_settings?.dial_out_prefix || "",
      custom_one: custom_one || "",
      route_external: route_external || "NO",
      provider,
      custom_two,
    };
  }
  function validateForm(data) {
    const errors = [];
    if (!data?.did_carrier_description) {
      errors.push({
        did_carrier_description: '"did_carrier_description" required',
      });
    }
    if (data?.did_route === "EXTEN" && !data?.extension) {
      errors.push({ extension: "extension is required" });
    }
    if (data?.did_route === "IN_GROUP" && !data?.group_id) {
      errors.push({ group_id: "group_id is required" });
    }
    if (data?.did_route === "CALLMENU" && !data?.menu_id) {
      errors.push({ menu_id: "menu_id is required" });
    }
    setErrors(errors);
    return errors;
  }
  const handleSubmit = async (values) => {
    const errors = validateForm(formik.values);
    const action = editingRecord ? update_dids : create_dids;
    const message = editingRecord ? "updated" : "created";
    if (errors?.length > 0) {
      return errorMessage({
        payload: errors,
        setErrors: setErrors,
        action: "DID",
        msg: message,
      });
    }
    setIsLoading(true);
    try {
      const res = await dispatch(
        action({
          ...values,
          extension:
            user?.conexcs_settings?.dial_out_prefix + values?.extension
              ? values?.extension
              : "",
        })
      );
      if (res.payload?.status === 200) {
        toast.success(`DID successfully  ${message}`);
        onSave(!newRecord);
        onCancelForm();
      } else {
        if (Array.isArray(res.payload) || typeof res.payload === "object") {
          setErrors(res.payload);
        } else {
          toast.error(res.payload ? res.payload : `DID couldn't be ${message}`);
        }
      }
    } catch (error) {
      if (Array.isArray(error.payload) || typeof error.payload === "object") {
        setErrors(error.payload);
      } else {
        toast.error(
          error.payload ? error.payload : `DID couldn't be ${message}`
        );
      }
    }
    setIsLoading(false);
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });
  return (
    <Modal
      isUpdate={!!editingRecord}
      title={modalTitle}
      onCancelModal={onCancelForm}
      formIsValid={formik.isValid}
      onSubmit={formik.handleSubmit}
      isLoading={isLoading}
      onClick={onCancelForm}
    >
      {isLoading ? <DotsLoader /> : null}
      <form className="grid md:grid-cols-4 gap-x-5 mt-5 mx-2.5">
        <div className="mb-3">
          <FormInput
            errors={errors}
            name="did_pattern"
            label="DID Number"
            formik={formik}
          />
        </div>
        <div className="mb-3">
          <FormInput
            errors={errors}
            name="did_description"
            label="Site Name"
            formik={formik}
          />
        </div>
        <div className="mb-3">
          <FormSelectInput
            errors={errors}
            name="did_carrier_description"
            label="Industry"
            formik={formik}
            options={industryDrd}
            valueProp="_id"
            labelProp="name"
          />
        </div>

        <div className="mb-3">
          <FormSelectInput
            errors={errors}
            name="did_route"
            label="DID Route"
            formik={formik}
            options={[
              { value: "EXTEN", label: "External Number" },
              { value: "IN_GROUP", label: "Ring Group" },
              { value: "CALLMENU", label: "Call Menu" },
              { value: "AGENT", label: "Agent" },
            ]}
            valueProp="value"
            labelProp="label"
          />
        </div>
        {formik.values.did_route === "AGENT" ? (
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="user"
              label="Agent"
              formik={formik}
              options={dialerUsers}
              valueProp="value"
              labelProp="label"
              onChange={(e) => {
                formik?.setFieldValue("user", e);
                formik?.setFieldValue(
                  "voicemail_ext",
                  dialerUsers?.find(({ value }) => value === e)?.dialer_phone
                );
              }}
            />
          </div>
        ) : null}
        {formik.values.did_route === "IN_GROUP" ? (
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="group_id"
              label="Ring Group"
              formik={formik}
              options={record}
              valueProp="group_id"
              labelProp="group_name"
            />
          </div>
        ) : null}
        {formik.values.did_route === "EXTEN" ? (
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="extension"
              label="External Number"
              formik={formik}
            />
          </div>
        ) : null}
        <div className="mb-3">
          <CampaignGroup
            errors={errors}
            name="custom_one"
            label="Campaign Name"
            formik={formik}
            options={leadSource}
          />
        </div>
        {formik.values.did_route === "CALLMENU" ? (
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="menu_id"
              label="Call Menu"
              formik={formik}
              options={callManu}
              valueProp="menu_id"
              labelProp="menu_name"
            />
          </div>
        ) : null}
        {formik.values.did_route === "CALLMENU" ? (
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="route_external"
              label="External Route"
              formik={formik}
              options={[
                { value: "YES", label: "YES" },
                { value: "NO", label: "NO" },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>
        ) : null}
        <div className="">
          <FormSelectInput
            name="provider"
            label="Carrier"
            formik={formik}
            options={[
              { value: "Intel", label: "Intel" },
              { value: "BVS", label: "BVS" },
            ]}
            valueProp="value"
            labelProp="label"
          />
        </div>
        <FormInput
            errors={errors}
            name="custom_two"
            label="Domain Name"
            formik={formik}
          />
      </form>
    </Modal>
  );
}

export default AddDidsForm;
