import React, { useState, useEffect } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { FaPlus, FaSearch } from "react-icons/fa";
import { Box, Button, Button as MUIButton } from "@mui/material";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { DotsLoader, FormSelectInput } from "../../components";
import api from "../../services/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import { toast } from "react-toastify";
import CustomModal from "./Modal";
import { get_users_drd } from "../../features/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { get_industry_drd } from "../../features/IndustrySlice";
import JobsList from "./JobsList";
import { NumericFormat } from "react-number-format";
import exportVendorBalanceReport from "../VendorBalance/exportSaleOrderToExcel";
import { generateYearOptions } from "../../util/common";
import Breadcrumbs from "../../components/molecules/Breadcrumbs";

function VendorBalance() {
  // Get current year
  const currentYear = new Date().getFullYear();
  const { usersDrd } = useSelector((state) => state.users);
  const { industryDrd } = useSelector((state) => state.industry);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [selectedData, setSelectedData] = React.useState([]);
  const [isModal, setIsModal] = React.useState(false);
  const dispatch = useDispatch();
  dayjs.extend(relativeTime);
  dayjs.extend(utc);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  // Function to get ISO week number for a date
 /*  const getISOWeek = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 3 - ((d.getDay() + 6) % 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  };
 */
  const [selectedWeek, setSelectedWeek] = useState(1);

  // Update selected week when year changes
  useEffect(() => {
    setSelectedWeek(1);
    const weeks = generateWeeks(selectedYear);
    const firstWeekData = weeks[0];
  
    setFilters({
      ...filters,
      start_date: firstWeekData.startDate.toISOString().slice(0, 23) + "Z",
      end_date: firstWeekData.endDate.toISOString().slice(0, 23) + "Z",
    });

    getSERPDomainReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
      start_date: firstWeekData.startDate.toISOString().slice(0, 23) + "Z",
      end_date: firstWeekData.endDate.toISOString().slice(0, 23) + "Z",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  // Function to get the Monday of the week containing January 1st
  const getFirstWeekMonday = (year) => {
    const jan1 = new Date(year, 0, 1);
    const dayOfWeek = jan1.getDay();
    const mondayOffset = dayOfWeek === 0 ? -6 : (1 - dayOfWeek);
    const firstMonday = new Date(year, 0, 1);
    firstMonday.setDate(1 + mondayOffset);
    return firstMonday;
  };

  // Function to get date range for a week
  const getDateRangeForWeek = (startDate) => {
    const weekStart = new Date(startDate);
    const weekEnd = new Date(startDate);
    weekEnd.setDate(weekStart.getDate() + 6);
    weekEnd.setHours(23, 59, 59, 999);
    return { startDate: weekStart, endDate: weekEnd };
  };

  // Function to format date range for display
  const formatDateRange = (startDate, endDate) => {
    return `${startDate.toDateString()} - ${endDate.toDateString()}`;
  };

  // Function to generate weeks of the year along with date ranges
  const generateWeeks = (year) => {
    const weeks = [];
    let weekNumber = 1;
  
    // Get the Monday of the week containing January 1st
    let currentDate = getFirstWeekMonday(year);
  
    // Get the last day of the year
    const lastDay = new Date(year, 11, 31);
  
    // Keep generating weeks until we pass December 31st
    while (currentDate <= lastDay) {
      const { startDate, endDate } = getDateRangeForWeek(currentDate);
      weeks.push({
        weekName: `Week ${weekNumber}: ${formatDateRange(startDate, endDate)}`,
        weekNumber: weekNumber,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });
    
      // Move to next Monday
      currentDate.setDate(currentDate.getDate() + 7);
      weekNumber++;
    }
  
    return weeks;
  };

  // Generate weeks for the current year
  const weeksOfYear = generateWeeks(selectedYear);
  const selectedWeekData = weeksOfYear.find(
    (week) => week.weekNumber === selectedWeek
  ) || weeksOfYear[0];

  const initialFilters = {
    start_date: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
    end_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
  };
  const [filters, setFilters] = React.useState(initialFilters);
  const handleWeekChange = (e) => {
    const weekNumber = parseInt(e);
    setSelectedWeek(weekNumber);
    const selectedWeekData = weeksOfYear.find(
      (week) => week.weekNumber === weekNumber
    );
    setFilters({
      ...filters,
      start_date: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
      end_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
    });
    getSERPDomainReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
      start_date: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
      end_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
    });
  };

  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const getSERPDomainReport = async (filter) => {
    setIsLoading(true);
    if (!filter?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    try {
      const res = await api.post("/api/reports/vendor_balance_report", {
        ...filter,
      });
      if (res.status === 200) {
        const dataArray = Object.entries(
          res.data?.data ? res.data?.data : {}
        ).map(([key, value]) => ({
          id: key, // Assuming you want to keep the original keys as IDs
          ...value,
        }));
        const sortedDataArray = [...(dataArray || [])]?.sort((a, b) =>
          a?.vendor?.first_name.localeCompare(b?.vendor?.first_name)
        );
        const rows = sortedDataArray?.flatMap((entry, index) => {
          const vendorName = `${index + 1}. ${entry.vendor?.first_name || ""} ${
            entry.vendor?.last_name || ""
          }`.trim();
          const vendorId = entry.vendor._id;
          const summary = entry.summary;

          // Create the vendor row
          const vendorRow = {
            id: vendorId,
            path: [vendorName], // Path only contains the vendor's name
            ...entry.vendor, // Include all vendor details
            ...summary,
          };

          // Create job rows with unique paths
          const jobs = entry.jobs.map((job) => ({
            id: job._id,
            path: [vendorName, job.job_number], // Path includes vendor name and job number
            ...job, // Include all job details
            summary: { ...summary },
          }));

          // Return the vendor row followed by the job rows
          return [vendorRow, ...jobs];
        });

        const total_job_amount = dataArray.reduce(
          (sum, item) => sum + item?.summary?.job_amount,
          0
        );
        const total_pending_job_amount = dataArray.reduce(
          (sum, item) => sum + item?.summary?.pending_invoiced,
          0
        );
        const total_invoiced_amount = dataArray.reduce(
          (sum, item) => sum + item?.summary?.total_invoiced,
          0
        );
        const total_vendor_owe = dataArray.reduce(
          (sum, item) => sum + item?.summary?.vendor_owe,
          0
        );
        const total_vendor_profit = dataArray.reduce(
          (sum, item) => sum + item?.summary?.vendor_profit,
          0
        );
        const total_pending_jobs = dataArray.reduce(
          (sum, item) =>
            sum +
            item?.jobs?.filter(({ vendor_invoice_id }) => !vendor_invoice_id)
              ?.length,
          0
        );
        const total_jobs = dataArray.reduce(
          (sum, item) => sum + item?.jobs?.length,
          0
        );
        setRecord({
          data: rows,
          totalItems: res.data.totalItems,
          summary: {
            total_jobs,
            total_pending_jobs,
            total_job_amount,
            total_pending_job_amount,
            total_invoiced_amount,
            total_vendor_owe,
            total_vendor_profit,
          },
        });
      } else {
        toast.error(res.data.message || "Record couldn't be loaded");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || "Record couldn't be loaded");
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    getSERPDomainReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
      ...filters,
    });
    dispatch(get_users_drd());
    dispatch(get_industry_drd());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const reloadReport = () => {
    getSERPDomainReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
      ...filters,
    });
  };
  const columnDefs = [
    {
      headerName: "Jobs Total",
      field: "service_cost",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">{params.row.job_amount?.toFixed(2)}</span>
        ) : (
          <span>{params.row.service_cost?.toFixed(2)}</span>
        ),
    },
    {
      headerName: "Pending Invoiced",
      field: "pending_invoiced",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.pending_invoiced?.toFixed(2)}
          </span>
        ) : params.row.vendor_invoice_id === "Yes" ? (
          <span>{0?.toFixed(2)}</span>
        ) : params.row.vendor_invoice_id === "No" ? (
          <span>{params.row.service_cost?.toFixed(2)}</span>
        ) : null,
    },
    {
      headerName: "Invoiced",
      field: "total_invoiced",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.total_invoiced?.toFixed(2)}
          </span>
        ) : params.row.vendor_invoice_id === "No" ? (
          <span>{0?.toFixed(2)}</span>
        ) : params.row.vendor_invoice_id === "Yes" ? (
          <span>{params.row.service_cost?.toFixed(2)}</span>
        ) : null,
    },
    {
      headerName: "Part Charge",
      field: "parts_charge",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.parts_charge?.toFixed(2)}
          </span>
        ) : (
          <span>{params.row.parts_charge?.toFixed(2)}</span>
        ),
    },
    {
      headerName: "Tech %",
      field: "tech_percent",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.techPercent?.toFixed(2)}
          </span>
        ) : (
          <span>
            {(
              (params.row.tech_fee /
                (params.row.service_cost - params.row.parts_charge)) *
              100
            )?.toFixed(2)}
          </span>
        ),
    },
    {
      headerName: "Tech Share",
      field: "tech_fee",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) => {
        return params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.vendor_profit?.toFixed(2)}
          </span>
        ) : (
          <span>{params.row.tech_fee?.toFixed(2)}</span>
        );
      },
    },
    {
      headerName: "Company Share",
      field: "company_profit",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">{params.row.vendor_owe?.toFixed(2)}</span>
        ) : (
          <span>
            {(
              Math.round(
                params.row.service_cost * 100 - params.row.tech_fee * 100
              ) / 100
            )?.toFixed(2)}
          </span>
        ),
    },
    {
      headerName: "Invoice Created",
      field: "vendor_invoice_id",
      flex: 1,
      type: "singleSelect",
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        vendor_invoice_id: value?.value,
      }),
      filterOperators: [
        {
          label: "Yes",
          value: "!=",
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
        {
          label: "No",
          value: "=",
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
    },
    {
      field: "createdBy",
      headerName: "Created By",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) =>
        `${value.first_name || ""} ${value.last_name || ""}`,
      valueOptions: usersDrd,
      renderCell: (params) => params.value?.createdBy,
      valueGetter: (params) => params.row?.createdBy,
      valueFormatter: (params) => params.value?.createdBy,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 120,
      sortable: true,
      type: "date",
      valueGetter: (params) => new Date(params.row.createdAt),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.createdAt;
        if (params.rowNode.type !== "group" && rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    { headerName: "Status", field: "job_status_id" },
    {
      headerName: "Scheduled On",
      field: "scheduled_on",
      sortable: true,
      valueGetter: (params) => new Date(params.row.scheduled_on),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      type: "date",
      renderCell: (params) => {
        const rawDate = params.row.scheduled_on;
        if (params.rowNode.type !== "group" && rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    {
      headerName: "Closed",
      field: "is_closed",
      renderCell: (params) =>
        params.rowNode.type === "group" ? null : <span>{params.value}</span>,
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        is_closed: value?.value,
      }),
      filterOperators: [
        {
          label: "Yes",
          value: true,
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
        {
          label: "No",
          value: false,
          getApplyFilterFn: (filterItem) => {
            // Assuming vendor_invoice_id is stored as 1 for "Yes" and 0 for "No"
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
    },
    {
      headerName: "Closed At",
      field: "closed_date",
      flex: 1,
      minWidth: 120,
      sortable: false,
      valueGetter: (params) => new Date(params.row.closed_date),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      type: "date",
      renderCell: (params) => {
        const rawDate = params.row.closed_date;
        if (params.rowNode.type !== "group" && rawDate) {
          return rawDate;
        }
        return "";
      },
    },
    // { headerName: "Industry", field: "industry_id", flex: 1, minWidth: 100 },
    {
      field: "industry_id",
      headerName: "Industry",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value?.name,
      valueOptions: industryDrd,
      renderCell: (params) => params.value?.industry_id,
      valueGetter: (params) => params.row?.industry_id,
      valueFormatter: (params) => params.value?.industry_id,
    },
    {
      headerName: "Client Type",
      field: "payment_type",
      flex: 1,
      minWidth: 120,
    },
  ];
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getSERPDomainReport({
      filters: queryOptions,
      ...sortingModel,
      page: +params.page + 1,
      size: params.pageSize,
      ...filters,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getSERPDomainReport({
        ...sortingModel,
        ...filters,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field === "domain_count" ? "domain" : rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getSERPDomainReport({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      ...filters,
    });
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        {selectedData?.length ? (
          <span className="text-sm font-semibold bg-[#fcebc5] py-1 px-1.5">
            {`${selectedData?.length} items are selected `}
            <span
              className="text-blue-600 hover:underline cursor-pointer ml-2"
              onClick={() => setSelectedData([])}
            >
              Clear
            </span>
          </span>
        ) : null}
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
        {selectedData?.length ? (
          <MUIButton
            variant="text"
            onClick={() => setIsModal(true)}
            startIcon={<FaPlus size={16} />}
            sx={{ fontSize: "0.8125rem" }}
            className="!text-[#042a42]"
          >
            Create Invoice
          </MUIButton>
        ) : null}
      </GridToolbarContainer>
    );
  }
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    getSERPDomainReport({
      filters: queryOptions,
      page: 1,
      ...filters,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };
  const years = generateYearOptions(2024);
  const LineOne = () => {
    return (
      <div className="flex grid grid-cols-4 gap-x-2 w-full">
        <div className="col-span-1">
          <FormSelectInput
            name="selectedYear"
            label="Select a year"
            value={selectedYear}
            options={years}
            onChange={(value) => setSelectedYear(value)}
            valueProp="value"
            labelProp="label"
            id={"selectedYear"}
          />
        </div>
        <div className="col-span-3">
          <FormSelectInput
            name="industry_id"
            label="Select a week"
            value={selectedWeek}
            options={weeksOfYear}
            onChange={handleWeekChange}
            valueProp="weekNumber"
            labelProp="weekName"
          />
        </div>
      </div>
    );
  };
  const onSelectAll = (checked, vendorId) => {
    if (checked) {
      const vendorJobs = record?.data?.filter(
        ({ vendor_id, vendor_invoice_id }) =>
          vendor_id?._id === vendorId && !vendor_invoice_id
      );
      const updatedArray = vendorJobs?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (job) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ _id }) => _id === job?._id).length > 0) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ event, checked, data }) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      if (checked) {
        if (selectedData?.length) {
          const firstVendorId = selectedData[0].vendor_id._id;
          if (firstVendorId !== data.vendor_id._id)
            return toast.error("Vendor are not the same as pre-selected jobs.");
        }
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
        event.stopPropagation();
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (job) => job?._id !== data?._id
          );
          return updatedSelectedRecord;
        });
        event.stopPropagation();
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
    event.stopPropagation();
  };
  function CustomGridTreeDataGroupingCell(props) {
    const { id, rowNode } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector
    );
    const filteredDescendantCount =
      filteredDescendantCountLookup[rowNode.id] ?? 0;
    React.useEffect(() => {
      const rowToExpand =
        selectedData?.length && selectedData[0].vendor_id?._id;
      if (rowToExpand) {
        apiRef.current.setRowChildrenExpansion(rowToExpand, true);
      }
    }, [props.row, id, apiRef]);
    const handleClick = (vendorId) => {
      const vendorJobs = record?.data?.filter(
        ({ vendor_id, vendor_invoice_id }) => vendor_id?._id === vendorId
      );
      const updatedArray = vendorJobs?.map((item) => item);
      setJobList(updatedArray);
      // if (rowNode.type !== "group") {
      //   return;
      // }
      // apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      // apiRef.current.setCellFocus(id, field);
      // event.stopPropagation();
    };
    const getCount = (vendorId) => {
      return (
        record?.data?.filter(
          ({ vendor_id, vendor_invoice_id }) =>
            vendor_id?._id === vendorId && !vendor_invoice_id
        )?.length || 0
      );
    };
    return (
      <Box sx={{ ml: rowNode.depth * 4 }}>
        <div>
          {filteredDescendantCount > 0 ? (
            <div className="">
              <input
                type="checkbox"
                onChange={(e) => onSelectAll(e.target.checked, props.row._id)}
                className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
                checked={
                  record?.data?.length &&
                  selectedData?.length &&
                  selectedData[0].vendor_id?._id === props.row._id &&
                  selectedData?.length ===
                    record?.data?.filter(
                      ({ vendor_id, vendor_invoice_id }) =>
                        vendor_id?._id === props.row._id && !vendor_invoice_id
                    )?.length
                }
                disabled={!record?.data?.length}
              />
              <Button
                onClick={() => handleClick(props.row._id)}
                tabIndex={-1}
                size="small"
              >
                {/* {props?.value} ({filteredDescendantCount}) */}
                {props?.value} ({getCount(props.row._id)})
              </Button>
            </div>
          ) : (
            <div>
              {!props.row.records?.vendor_invoice_id ? (
                <input
                  type="checkbox"
                  onChange={(e) =>
                    onSingleSelect({
                      event: e,
                      checked: e.target.checked,
                      data: props.row?.records,
                    })
                  }
                  className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
                  checked={isSelected(props.row.records)}
                />
              ) : null}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/jobs/update/${props.row._id}`}
                className={`text-[#1976D2] hover:underline ${
                  props.row.records?.vendor_invoice_id ? "!ml-7" : ""
                }`}
              >
                {props.row.job_number}
              </a>
              {props?.row.records?.vendor_invoice_id ? (
                <small className="text-red-600 ml-2 font-medium">
                  Invoice Created
                </small>
              ) : null}
            </div>
          )}
        </div>
      </Box>
    );
  }

  const getTreeDataPath = (row) => row.path;
  const groupingColDef = {
    headerName: "Tech",
    flex: 1,
    minWidth: 400,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  const exportToExcel = () => {
    // Create a mapping of vendor IDs to their corresponding data objects
    const createVendorMap = (data) => {
      return data.reduce((map, item) => {
        map[item._id] = item;
        return map;
      }, {});
    };
    // Push items into the `job_details` array of their corresponding vendors and remove them from the main array
    const pushAndRemoveItems = (data, vendorMap) => {
      // Track indices of items to be removed
      const indicesToRemove = [];
      data.forEach((item, index) => {
        if (item.vendor_id && vendorMap[item.vendor_id._id]) {
          if (!vendorMap[item.vendor_id._id].job_details) {
            vendorMap[item.vendor_id._id].job_details = [];
          }
          vendorMap[item.vendor_id._id].job_details.push(item);
          indicesToRemove.push(index);
        }
      });
      // Remove matched items from the main array
      for (let i = indicesToRemove.length - 1; i >= 0; i--) {
        data.splice(indicesToRemove[i], 1);
      }
    };
    // Main function to modify the data array
    const modifyDataArray = (data) => {
      const vendorMap = createVendorMap(data);
      pushAndRemoveItems(data, vendorMap);
      return data;
    };
    const modifiedData = modifyDataArray(record?.data || []);
    const parentHeader = [
      "Sr.",
      "Tech",
      "Jobs Total",
      "Pending Invoice",
      "Invoiced",
      "Part Charge",
      "Tech %",
      "Tech Share",
      "Company Share",
    ];
    const items = modifiedData.map(
      (
        {
          company_name,
          job_amount,
          pending_invoiced,
          total_invoiced,
          parts_charge,
          vendor_profit,
          vendor_owe,
          job_details,
        },
        index
      ) => {
        const sr = (index + 1)?.toString();
        return {
          sr,
          company_name,
          service_cost: job_amount?.toFixed(2),
          pending_invoiced: pending_invoiced?.toFixed(2),
          total_invoiced: total_invoiced?.toFixed(2),
          parts_charge: parts_charge ? parts_charge?.toFixed(2) : 0?.toFixed(2),
          tech_percent: (
            (vendor_profit / (job_amount - parts_charge)) *
            100
          )?.toFixed(2),
          tech_fee: vendor_profit?.toFixed(2),
          company_profit: vendor_owe,
          jobs: [
            ...(job_details || [])?.map(
              ({
                job_number,
                service_cost,
                vendor_invoice_id,
                parts_charge,
                tech_fee,
              }) => {
                return {
                  job_number: job_number?.toString(),
                  service_cost: service_cost?.toFixed(2),
                  pending_invoiced: vendor_invoice_id
                    ? 0?.toFixed(2)
                    : service_cost?.toFixed(2),
                  total_invoiced: !vendor_invoice_id
                    ? 0?.toFixed(2)
                    : service_cost?.toFixed(2),
                  parts_charge: parts_charge
                    ? parts_charge?.toFixed(2)
                    : 0?.toFixed(2),
                  tech_percent: (
                    (tech_fee / (service_cost - parts_charge)) *
                    100
                  )?.toFixed(2),
                  tech_fee: tech_fee?.toFixed(2),
                  company_profit: (
                    Math.round(service_cost * 100 - tech_fee * 100) / 100
                  )?.toFixed(2),
                };
              }
            ),
          ],
        };
      }
    );
    const newRecord = [];
    items.forEach(
      (
        {
          sr,
          company_name,
          service_cost,
          pending_invoiced,
          total_invoiced,
          parts_charge,
          tech_percent,
          tech_fee,
          company_profit,
          jobs,
        },
        index
      ) => {
        // Add the parent header
        // newRecord.push(parentHeader)
        // Add the parent row (vendor details)
        newRecord.push([
          sr,
          company_name,
          service_cost,
          pending_invoiced,
          total_invoiced,
          parts_charge,
          tech_percent,
          tech_fee,
          company_profit,
        ]);
        // Add the child rows (job details)
        jobs.forEach(
          ({
            job_number,
            service_cost,
            pending_invoiced,
            total_invoiced,
            parts_charge,
            tech_percent,
            tech_fee,
            company_profit,
          }) => {
            newRecord.push([
              "",
              job_number,
              service_cost,
              pending_invoiced,
              total_invoiced,
              parts_charge,
              tech_percent,
              tech_fee,
              company_profit,
            ]);
          }
        );
        // Add an empty row for separation
        newRecord.push([]);
      }
    );
    exportVendorBalanceReport(items, parentHeader, "vendor_balance_report");
  };
  return (
    <>
      {/* <PageHeader
        route="Setting > Industries"
        heading="Vendor Balance"
        CustomButtons={LineOne}
        className="!col-span-3"
        className2="!col-span-8"
      /> */}
      <div className="ml-0.5 mt-[0.75rem] sm:mt-0.5">
        <div className="!mb-2 flex justify-between">
          <Breadcrumbs />
        </div>

        <div className="flex flex-col-reverse items-center md:flex-row  md:justify-between gap-2">
          <div
            className={` flex w-full flex-row justify-between w-1/ items-center`}
          >
            <div className="font-semibold font-mont text-heading capitalize">
              {"Vendor Balance"}
            </div>
          </div>
          <LineOne />
        </div>
      </div>
      {isModal ? (
        <CustomModal
          onClose={() => setIsModal(false)}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          filters={filters}
          paginationModel={paginationModel}
          reloadReport={reloadReport}
        />
      ) : null}
      {jobList?.length ? (
        <JobsList
          jobs={jobList}
          onCancelForm={() => setJobList([])}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setIsModal={setIsModal}
          setJobList={setJobList}
        />
      ) : null}
      {isLoading ? <DotsLoader /> : null}
      <div className="grid grid-cols-5 gap-2 justify-between mx-0.5">
        <div className="flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Total Job</span>
          <span className="text-base font-medium font-pop">
            {Math.round((record?.summary?.total_jobs || 0) * 100) / 100} (
            <NumericFormat
              displayType="text"
              value={(
                Math.round((record?.summary?.total_job_amount || 0) * 100) / 100
              ).toFixed(2)}
              prefix={`$`}
              thousandSeparator={true}
            />
            )
          </span>
        </div>

        <div className="flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Pending Invoice Total</span>
          <span className="text-base font-medium font-pop">
            {Math.round((record?.summary?.total_pending_jobs || 0) * 100) / 100}{" "}
            (
            <NumericFormat
              displayType="text"
              value={(
                Math.round(
                  (record?.summary?.total_pending_job_amount || 0) * 100
                ) / 100
              ).toFixed(2)}
              prefix={`$`}
              thousandSeparator={true}
            />
            )
          </span>
        </div>

        <div className="flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Total Invoiced</span>
          <span className="text-base font-medium font-pop">
            <NumericFormat
              displayType="text"
              value={(
                Math.round(
                  (record?.summary?.total_invoiced_amount || 0) * 100
                ) / 100
              ).toFixed(2)}
              prefix={`$`}
              thousandSeparator={true}
            />
          </span>
        </div>

        <div className="flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Tech Percentage</span>
          <span className="text-base font-medium font-pop">
            {record?.summary?.total_vendor_profit &&
            record?.summary?.total_job_amount
              ? (
                  (record?.summary?.total_vendor_profit /
                    record?.summary?.total_job_amount) *
                  100
                ).toFixed(2)
              : 0}{" "}
            %
          </span>
        </div>

        <div className="flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Total Tech Share</span>
          <span className="text-base font-medium font-pop">
            <NumericFormat
              displayType="text"
              value={(
                Math.round((record?.summary?.total_vendor_profit || 0) * 100) /
                100
              ).toFixed(2)}
              prefix={`$`}
              thousandSeparator={true}
            />
          </span>
        </div>
        <div className="flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Total Company Share</span>
          <span className="text-base font-medium font-pop">
            <NumericFormat
              displayType="text"
              value={(
                Math.round((record?.summary?.total_vendor_owe || 0) * 100) / 100
              ).toFixed(2)}
              prefix={`$`}
              thousandSeparator={true}
            />
          </span>
        </div>

        <div className="flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Avg. Total per Job</span>
          <span className="text-base font-medium font-pop">
            <NumericFormat
              displayType="text"
              value={(
                +record?.summary?.total_job_amount /
                +record?.summary?.total_jobs
              ).toFixed(2)}
              prefix={`$`}
              thousandSeparator={true}
            />
          </span>
        </div>
        <div className="flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Avg. Company Share per Job</span>
          <span className="text-base font-medium font-pop">
            <NumericFormat
              displayType="text"
              value={(
                +record?.summary?.total_vendor_owe /
                +record?.summary?.total_jobs
              ).toFixed(2)}
              prefix={`$`}
              thousandSeparator={true}
            />
          </span>
        </div>
        <div className="flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all">
          <span className="text-sm font-pop">Avg. Tech Share per Job</span>
          <span className="text-base font-medium font-pop">
            <NumericFormat
              displayType="text"
              value={(
                +record?.summary?.total_vendor_profit /
                +record?.summary?.total_jobs
              ).toFixed(2)}
              prefix={`$`}
              thousandSeparator={true}
            />
          </span>
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={(record?.data || [])?.map((record, index) => {
            const {
              _id,
              job_number,
              company_profit,
              complete_pickup_address,
              createdAt,
              createdBy,
              industry_id,
              is_closed,
              job_status_id,
              payment_type,
              refund_amount,
              tags,
              tech_fee,
              service_cost,
              goa_price,
              closed_date,
              scheduled_on,
              path,
              vendor_invoice_id,

              job_amount,
              vendor_owe,
              vendor_profit,
              pending_invoiced,
              total_invoiced,
              parts_charge = 0,
            } = record;
            return {
              _id,
              // counter: index + 1,
              records: { ...record, _id: _id },
              job_number,
              createdBy: createdBy?.username,
              job_status_id: job_status_id?.name,
              createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
              industry_id: industry_id?.name,
              complete_pickup_address,
              tags,
              path,
              company_profit: company_profit ? company_profit?.toFixed(2) : 0,
              is_closed: is_closed ? "Yes" : "No",
              payment_type,
              refund_amount: refund_amount ? refund_amount : 0,
              tech_fee: tech_fee ? tech_fee : 0,
              service_cost: service_cost ? service_cost : 0,
              goa_price: goa_price ? goa_price : 0,
              closed_date: closed_date
                ? dayjs(closed_date).format("ddd, MMM D, YYYY h:mm A")
                : "",
              scheduled_on: scheduled_on
                ? dayjs(scheduled_on).format("ddd, MMM D, YYYY h:mm A")
                : "",
              job_amount,
              vendor_owe,
              vendor_profit,
              vendor_invoice_id: vendor_invoice_id ? "Yes" : "No",
              pending_invoiced,
              total_invoiced,
              parts_charge,
              techPercent: (vendor_profit / (job_amount - parts_charge)) * 100,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          paginationModel={paginationModel}
          totalItems={record?.totalItems}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          topPagination
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          columnVisibilityModel={{
            domain_count: false,
            vendor_invoice_id: false,
            createdBy: false,
            createdAt: false,
            job_status_id: false,
            scheduled_on: false,
            is_closed: false,
            industry_id: false,
            closed_date: false,
            payment_type: false,
          }}
          treeData={true}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          exportButton={exportToExcel}
        />
      </div>
    </>
  );
}

export default VendorBalance;
