import React from "react";
import { Button, DotsLoader, FormSelectInput } from "../../components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaReply } from "react-icons/fa";

// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import api from "../../services/api";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import errorMessage from "../../util/errorMessage";
import SubPermissions from "./SubRole";
const UserPermissions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [selectedData, setSelectedData] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [role_id, setRole_id] = React.useState();
  const getPermissions = () => {
    setIsLoading(true);
    api
      .get(`/api/permissions`)
      .then((response) => {
        setPermissions(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getRoles = () => {
    setIsLoading(true);
    api
      .get(`/api/roles`)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getRolePermission = (id) => {
    const permissions = state?.find(({ _id }) => _id === id)?.menu_permissions;
    if (permissions) {
      return setSelectedData(permissions);
    }
    setSelectedData([]);
  };
  const cancelFormHandler = () => {
    navigate(`/settings/roles`);
  };
  React.useEffect(() => {
    getPermissions();
    getRoles();
    // eslint-disable-next-line
  }, []);
  const searchNestedPermissions = () => {
    const searchInPermissions = (permissionList) => {
      return permissionList
        ?.map((permission) => {
          const matchesMain = permission?.name
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase());
          const matchedSubPermissions =
            permission?.sub_permissions &&
            searchInPermissions(permission?.sub_permissions);
          if (matchesMain || matchedSubPermissions?.length > 0) {
            return {
              ...permission,
              sub_permissions: matchesMain
                ? matchedSubPermissions
                : matchedSubPermissions?.filter(Boolean),
            };
          }
          return null; // No match found, return null
        })
        .filter(Boolean); // Filter out null values
    };
    return searchInPermissions(permissions);
  };
  const tasks = searchNestedPermissions();
  const assignTask = async () => {
    if (role_id) {
      setIsLoading(true);
      try {
        const res = await api.post(
          `/api/roles/${role_id}/update_role_permissions`,
          { menu_permissions: selectedData }
        );
        if (res?.status === 200) {
          toast.success("Permission updated");
          // dispatch(get_roles());
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.data?.message,
            action: "Roles Permissions",
            msg: "assigned",
          });
        }
        setIsLoading(false);
      } catch (error) {
        console.error(
          "🚀  file: NewSelectTaskForm.jsx:101  assignTask ~ error:",
          error
        );
        setIsLoading(false);
        errorMessage({
          payload: error?.response?.data?.message,
          action: "Roles Permissions",
          msg: "assigned",
        });
      }
    } else {
      toast.error("Please Select a role");
    }
  };
  function handleCheckboxSelection(name) {
    const findMenuItem = (menu, currentLabel) => {
      for (const item of menu) {
        if (item.name === currentLabel) {
          return item;
        } else if (item.sub_permissions) {
          const foundInChildren = findMenuItem(
            item.sub_permissions,
            currentLabel
          );
          if (foundInChildren) {
            return foundInChildren;
          }
        }
      }
      return null;
    };

    const menuItem = findMenuItem(permissions, name);
    if (!menuItem) {
      console.error("Menu item not found");
      return selectedData;
    }
    const getParentLabel = (menu, currentLabel) => {
      const parentItem = menu.find((item) => {
        if (
          item.sub_permissions &&
          item.sub_permissions.some((child) => child.name === currentLabel)
        ) {
          return true;
        }
        return false;
      });
      return parentItem ? parentItem.name : null;
    };

    const getDescendantLabels = (menu, currentLabel) => {
      const foundItem = findMenuItem(menu, currentLabel);
      if (!foundItem) {
        return [];
      }
      const descendantLabels = [currentLabel];
      if (foundItem.sub_permissions) {
        for (const child of foundItem.sub_permissions) {
          descendantLabels.push(
            ...getDescendantLabels(foundItem.sub_permissions, child.name)
          );
        }
      }
      return descendantLabels;
    };
    const parentLabel = getParentLabel(permissions, name);
    const descendantLabels = getDescendantLabels(permissions, name);
    if (menuItem.sub_permissions) {
      if (selectedData.includes(name)) {
        // Deselecting checkbox for a parent item
        const remainingSelectedLabels = selectedData.filter(
          (selectedLabel) => !descendantLabels.includes(selectedLabel)
        );
        // // If all children and sub-children are deselected, remove the parent name too
        // if (
        //   remainingSelectedLabels.includes(name) &&
        //   parentLabel &&
        //   !remainingSelectedLabels.some(
        //     (selectedLabel) =>
        //       getParentLabel(permissions, selectedLabel) === parentLabel
        //   )
        // ) {
        //   remainingSelectedLabels.push(parentLabel);
        // }

        return remainingSelectedLabels.filter(
          (selectedLabel) => selectedLabel !== name
        );
      } else {
        // Selecting checkbox for a parent item
        const updatedSelectedLabels = Array.from(
          new Set([...selectedData, name, ...descendantLabels])
        );

        // If any child or sub-child is selected, select the parent name
        if (parentLabel && !updatedSelectedLabels.includes(parentLabel)) {
          updatedSelectedLabels.push(parentLabel);
        }

        return updatedSelectedLabels;
      }
    } else {
      // Simple name without sub_permissions
      if (selectedData.includes(name)) {
        const remainingLabels = selectedData.filter(
          (selectedLabel) => !descendantLabels.includes(selectedLabel)
        );

        // // If a child or sub-child name is deselected and no names of its parent remain, remove the parent name
        // if (
        //   parentLabel &&
        //   !remainingLabels.some(
        //     (selectedLabel) =>
        //       getParentLabel(permissions, selectedLabel) === parentLabel
        //   )
        // ) {
        //   return remainingLabels.filter(
        //     (selectedLabel) => selectedLabel !== parentLabel
        //   );
        // }

        return remainingLabels;
      } else {
        // If a child or sub-child name is selected, select its parent name
        const updatedSelectedLabels = [...selectedData, name];

        // Add descendant names only if the name has sub_permissions or sub-children
        if (descendantLabels.length > 1) {
          updatedSelectedLabels.push(...descendantLabels.slice(1));
        }

        // Add parent name if it exists and is not already in the list
        if (parentLabel && !updatedSelectedLabels.includes(parentLabel)) {
          updatedSelectedLabels.push(parentLabel);
        }

        return updatedSelectedLabels;
      }
    }
  }
  const onSingleselect = (item) => {
    if (!role_id) return toast.error("Please select the role");
    setSelectedData(handleCheckboxSelection(item));
  };
  const handleRoleChange = (role_id) => {
    getRolePermission(role_id);
    setRole_id(role_id);
    // const selectedRole = roles?.find((item) => +role_id === item?.id);
    // if (selectedRole) {
    //   setRole_id(role_id);
    //   setSelectedData(selectedRole?.role_permissions || []);
    // }
  };

  function getRecursivePermissions(data) {
    const nameSet = new Set();

    function traverse(permission) {
      if (permission?.name) {
        nameSet.add(permission.name);
      }
      if (permission?.sub_permissions) {
        permission?.sub_permissions.forEach(traverse);
      }
    }

    data?.forEach(traverse);
    const namesArray = Array.from(nameSet);
    const quotedNames = namesArray.map((name) => `${name}`);
    return quotedNames;
  }

  const onSelectAll = (checked) => {
    if (role_id) {
      if (checked) {
        const recursiveItems = getRecursivePermissions(permissions);
        setSelectedData(recursiveItems);
      } else {
        setSelectedData([]);
      }
    } else {
      toast.error("Please Select a role");
    }
  };
  return (
    <div>
      <PageHeader
        // heading={title}
        breadcrumb={"/settings/roles/permissions"}
        // CustomButtons={CustomButtons}
      />
      {isLoading ? <DotsLoader /> : null}
      <hr className="my-2" />
      <div className="flex items-center justify-center bg-white my-3 rounded">
        <div
          className={`bg-white my- rounded w-aut w-full md:max-w-[100%] md:min-w-[730px] overflow-y-auto`}
        >
          <div className={`w-full pt-0 h-full px-2`}>
            <div className={` w-full`}>
              <div className="flex justify-between relative mt-2 mb-4 items-center">
                <Link to={"/settings/roles"} className="font-semibold">
                  <FaReply /> Return to Roles
                </Link>
                <div className="flex gap-2">
                  <FormSelectInput
                    value={role_id}
                    onChange={(e) => handleRoleChange(e)}
                    name="roleId"
                    label="Select Role"
                    options={roles}
                    valueProp={"_id"}
                    labelProp={"name"}
                  />
                  {/* <button
              onClick={assignTask}
              className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white flex items-center rounded-0"
            >
              Save
            </button> */}
                </div>
              </div>
              <div className="border border-neutral-200 rounded-md">
                <div className="flex flex-col gap-2 md:flex-row justify-between items-center p-2 bg-primary-100 w-full">
                  <input
                    type="checkbox"
                    onChange={(e) => onSelectAll(e.target.checked)}
                    className={`form-checkbox h-5 w-5 text-inherit roundd focus:ring-0 cursor-pointer mr-2`}
                    checked={
                      selectedData?.length ===
                      getRecursivePermissions(permissions)?.length
                    }
                  />
                  <span className="text-white">Manage Role Permissions</span>

                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        // aria-hidden="true"
                        className="w-4 h-4 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      className="h-[28px] bg-white border border-gray-300 text-gray-900 text-sm rounded-[0.2rem] pl-10 p-2.5 "
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* <div className="px-4 bg-neutral-100 customAccord pt-2 py-0 m-0 text-sm">
                  {tasks.map((item, key) => (
                    <SubRole
                      key={key}
                      label={item.name}
                      subPermissions={item.sub_permissions}
                      selectedData={selectedData}
                      onSingleselect={onSingleselect}
                    />
                  ))}
                </div> */}
                <div className="px-4 bg-neutral-100 customAccord pt-2 py-0 m-0 text-sm">
                  {tasks.map((item, key) => {
                    const { name, sub_permissions } = item;
                    return (
                      <div key={key}>
                        {item?.sub_permissions?.length ? (
                          <SubPermissions
                            children={sub_permissions}
                            label={name}
                            onSingleselect={onSingleselect}
                            selectedData={selectedData}
                          />
                        ) : (
                          <div
                            className="flex items-center gap-2 border- !py-3 bg-white !my-3 !px-4 rounded shadow-accordion"
                            title={name}
                          >
                            <input
                              className="h-5 w-5 focus:ring-0 cursor-pointer form-checkbox text-primary-100 rounded-sm"
                              type={"checkbox"}
                              onChange={() => onSingleselect(name)}
                              checked={selectedData?.includes(name)}
                            />
                            <span>{name}</span>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="!p-4 text-right">
              <Button
                text="Cancel"
                className="mx-2"
                onClick={cancelFormHandler}
                variant="btn_cancel"
              />

              <Button
                text={"Submit"}
                className=""
                onClick={assignTask}
                isLoading={isLoading}
                variant="btn_submit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPermissions;

// function SubRole({ label, subPermissions, selectedData, onSingleselect }) {
//   const [isExpanded, setIsExpanded] = useState(false);

//   const handleAccordionChange = () => {
//     setIsExpanded(!isExpanded);
//   };

//   const renderSubPermissions = (items) => {
//     return items.map((item, index) => (
//       <div key={index}>
//         {item.sub_permissions ? (
//           <Accordion expanded={isExpanded}>
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls={`panel-${index}-content`}
//               id={`panel-${index}-header`}
//               onClick={handleAccordionChange}
//               className="py-0 m-0 text-sm"
//             >
//               <FormCheckbox
//                 labelText={item.name}
//                 checked={selectedData?.includes(item.name)}
//                 onChange={() => onSingleselect(item.name)}
//               />
//             </AccordionSummary>
//             <AccordionDetails className="bg-neutral-100 text-sm">
//               {/* {renderSubPermissions(item.sub_permissions)} */}
//               {item.sub_permissions &&
//               hasSubPermissions(item.sub_permissions) ? (
//                 <div className="innerAccord text-sm">
//                   {renderSubPermissions(item.sub_permissions)}
//                 </div>
//               ) : (
//                 renderInlinePermissions(item.sub_permissions)
//               )}
//             </AccordionDetails>
//           </Accordion>
//         ) : (
//           <div
//             className="flex flex-wrap items-center gap-2 pt-2 w-auto"
//             title={item.name}
//           >
//             <FormCheckbox
//               labelText={item.name}
//               checked={selectedData?.includes(item.name)}
//               onChange={() => onSingleselect(item.name)}
//             />
//           </div>
//         )}
//       </div>
//     ));
//   };

//   const renderInlinePermissions = (items) => {
//     return (
//       <div className="flex flex-wrap items-center gap-2 py-0 w-auto text-sm">
//         {items.map((item, index) => (
//           <FormCheckbox
//             key={index}
//             labelText={item.name}
//             checked={selectedData?.includes(item.name)}
//             onChange={() => onSingleselect(item.name)}
//           />
//         ))}
//       </div>
//     );
//   };

//   const hasSubPermissions = (items) => {
//     return items.some((item) => item.sub_permissions);
//   };
//   return (
//     <div>
//       <Accordion className="mb-3" expanded={isExpanded}>
//         <AccordionSummary
//           expandIcon={<ExpandMoreIcon />}
//           aria-controls="panel-content"
//           id="panel-header"
//           onClick={handleAccordionChange}
//         >
//           <FormCheckbox
//             labelText={label}
//             checked={selectedData?.includes(label)}
//             onChange={() => onSingleselect(label)}
//             onClick={(e) => e.stopPropagation()} // Prevent accordion toggle on checkbox click
//           />
//         </AccordionSummary>
//         <AccordionDetails>
//           {subPermissions && hasSubPermissions(subPermissions) ? (
//             <div className="innerAccord">
//               {renderSubPermissions(subPermissions)}
//             </div>
//           ) : (
//             renderInlinePermissions(subPermissions)
//           )}
//         </AccordionDetails>
//       </Accordion>
//     </div>
//   );
// }
// function FormCheckbox({ labelText, checked, onChange }) {
//   return (
//     <div className="flex items-center gap-2">
//       <input
//         type="checkbox"
//         checked={checked}
//         onChange={onChange}
//         className="form-checkbox h-5 w-5 text-primary-100 rounded-sm focus:ring-0"
//         onClick={(e) => e.stopPropagation()}
//       />
//       <label>{labelText}</label>
//     </div>
//   );
// }
