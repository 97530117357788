import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import UpdateNumber from "./UpdateNumberModal";
import * as XLSX from "xlsx";
import { get_domain_numbers } from "../../../features/domainCenterSlice";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import { domain_number_set_pending } from "../../../services/domainCenter";
import { DotsLoader } from "../../../components";
import PageHeader from "../../../components/molecules/PageHeader";
const DomainNumbers = () => {
  const dispatch = useDispatch();
  const { isLoading, domainNumbers } = useSelector(
    (state) => state.domainCenter
  );
  const [searchInput, setSearchInput] = React.useState("");
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [filterConditions, setFilterConditions] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoader] = useState(false);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const openFormHandler = () => {
    setEditingRecord(selectedData);
    setIsEditing(true);
  };
  React.useEffect(() => {
    dispatch(get_domain_numbers());
    // eslint-disable-next-line
  }, []);
  const customOperatorsYesorNo = [
    {
      value: "yes",
      label: "Yes",
      getApplyFilterFn: (filterItem, column) => {
        return (params) =>
          String(params.value)
            .toLowerCase()
            .includes(String(filterItem.operator).toLowerCase());
      },
    },
    {
      value: "no",
      label: "No",
      getApplyFilterFn: (filterItem, column) => {
        return (params) =>
          String(params.value)
            .toLowerCase()
            .includes(String(filterItem.operator).toLowerCase());
      },
    },
    // Add more custom operators as needed
  ];
  const onSelectAll = (checked) => {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (checked) {
      const updatedArray = filteredData
        ?.filter(({ phone, callCenter_phone }) => phone !== callCenter_phone)
        ?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (data) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(({ domain }) => domain === data?.domain)?.length >
        0
      ) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ checked, data }) => {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (checked) {
      setSelectedData([...selectedData, data]);
    } else {
      setSelectedData(
        selectedData?.filter(({ domain }) => domain !== data?.domain)
      );
    }
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            selectedData?.length ===
            filteredData?.filter(
              ({ phone, callCenter_phone }) => phone !== callCenter_phone
            )?.length
          }
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      disableColumnMenu: true, // Add this line to disable the column menu
      renderCell: (params) =>
        params?.row.isMatched === "No" ? (
          <input
            type="checkbox"
            checked={isSelected(params.row.records)}
            onChange={(e) =>
              onSingleSelect({
                checked: e.target.checked,
                data: params.row.records,
              })
            }
            className={`form-checkbox h-5 w-5 text-[#6366F1] roundd focus:ring-0 cursor-pointer mr-2`}
          />
        ) : null,
    },
    { headerName: "#", field: "counter", filterable: false, sortable: false },
    {
      field: "domain",
      headerName: "Domain",
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.value}`}
            className="text-[#000] hover:text-blue-600 no-underline hover:underline"
          >
            {params?.value}
          </a>
        );
      },
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: "Phone",
      field: "phone",
      flex: 1,
      // filterable: true,
      renderCell: (params) => {
        return <span>{params?.row.phone}</span>;
      },
      // filterOperators: customOperatorsDropdown,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: [
        { value: "purchased_phone", label: "Purchased Phone" },
        { label: "CallCenter Phone", value: "callCenter_phone" },
      ],
    },
    { headerName: "Purchased Phone", field: "purchased_phone", flex: 1 },
    { headerName: "Call Center Phone", field: "callCenter_phone", flex: 1 },
    {
      headerName: "Has Match",
      field: "isMatched",
      flex: 1,

      renderCell: (params) => {
        return (
          <span
            className={
              params.row.isMatched === "Yes" ? "text-green-700" : "text-red-600"
            }
          >
            {params?.row.isMatched}
          </span>
        );
      },
      filterOperators: customOperatorsYesorNo,
    },
    { headerName: "Industry Name", field: "industry_name", flex: 1 },
    // {
    //   field: "actions",
    //   renderCell: (params) =>
    //     params?.row.isMatched === "No" ? (
    //       <div className="flex items-center w-full">
    //         <FaEdit
    //           onClick={openFormHandler}
    //           className="my_navIcon cursor-pointer"
    //           size={20}
    //         />
    //       </div>
    //     ) : null,
    //   width: 100,
    //   filterable: false,
    // },
  ];
  const data = domainNumbers?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchInput?.toLowerCase())
    );
  });
  const updateNumber = async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await domain_number_set_pending();
        if (res.status === 200) {
          toast.success(res?.data?.message);
        }
      } catch (err) {
        console.log(
          "🚀 ~ file: UpdateNumberModal.jsx:45 ~ updateNumber ~ err:",
          err
        );
        toast.success("Getting an error");
      }
    }
  };
  const onFilterChange = (e) => {
    setFilterConditions(e?.items);
  };
  function applyFilters(data, filterConditions) {
    return data?.filter((item) => {
      return filterConditions?.every((condition) => {
        const { field, operator, value } = condition;
        const itemValue = item[field];
        // Implement filtering logic based on the operator
        switch (operator) {
          case "contains":
            return itemValue.includes(value);

          case "equals":
            return itemValue === value;

          case "startsWith":
            return itemValue.startsWith(value);

          case "endsWith":
            return itemValue.endsWith(value);

          case "isEmpty":
            return itemValue === "";

          case "isNotEmpty":
            return itemValue !== "";

          case "isAnyOf":
            if (Array.isArray(value)) {
              return value.includes(itemValue);
            }
            return false; // If value is not an array, it cannot be "isAnyOf."
          case "is":
            if (value === "purchased_phone") {
              return itemValue !== item?.purchased_phone;
            } else if (value === "callCenter_phone") {
              return itemValue === item?.callCenter_phone;
            }
            return false;
          case "not":
            if (value === "purchased_phone") {
              return itemValue !== item?.purchased_phone;
            } else if (value === "callCenter_phone") {
              return itemValue !== item?.callCenter_phone;
            }
            return false;

          default:
            return true; // No filter is applied for unknown operators
        }
      });
    });
  }
  const filteredData = applyFilters(data, filterConditions);
  function exportToExcel() {
    const data = filteredData;
    const rows = [
      [
        "#",
        "Domains",
        "Phone",
        "Purchased Phone",
        "Call Center Phone",
        "Has Match",
        "Industry Name",
      ], // Add headings
    ];

    data.forEach((item, index) => {
      const {
        domain,
        industry_name,
        callCenter_phone,
        phone,
        purchased_phone,
      } = item;
      const isMatched = phone === callCenter_phone ? "Yes" : "No";
      const counter = index + 1;
      rows.push([
        counter,
        domain,
        phone,
        purchased_phone,
        callCenter_phone,
        isMatched,
        industry_name,
      ]); // Add a row without domains
    });

    const worksheet = XLSX.utils.aoa_to_sheet(rows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const fileName = "domain numbers.xlsx";

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // For IE browser
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  }
  return (
    <>
      {isEditing && (
        <UpdateNumber
          editingRecord={editingRecord}
          modalTitle="Update Domain Number"
          onCancelForm={cancelFormHandler}
        />
      )}
      {isLoading && <DotsLoader text="Please Wait" />}
      <PageHeader
        heading="Domain Numbers"
        onClick={openFormHandler}
        isAllowed={selectedData?.length || false}
        buttonTitle={`Update Selected Numbers (${selectedData?.length})`}
        extraButton={true}
        extraButtonClick={updateNumber}
        additionalBtnTitle={"Update Domain Numbers"}
        additionalBtnAllow={true}
        addIcon="No"
        editIcon="No"
      />
      <div className="bg-white !my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filteredData?.map((record, index) => {
            let counter = index + 1;
            const {
              domain,
              industry_name,
              callCenter_phone,
              phone,
              purchased_phone,
            } = record;
            const isMatched = phone === callCenter_phone ? "Yes" : "No";
            return {
              records: { ...record, id: index + 1 },
              counter,
              domain,
              industry_name,
              callCenter_phone,
              phone,
              isMatched,
              purchased_phone,
            };
          })}
          totalItems={filteredData?.length}
          isLoading={isLoading}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          onFilterChange={onFilterChange}
          exportButton={exportToExcel}
          pagination="No"
        />
      </div>
    </>
  );
};

export default DomainNumbers;
