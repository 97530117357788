import * as React from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

const CustomGroupCampaignSelect = ({ item, applyValue, options }) => {
  const [selectValue, setSelectValue] = React.useState(item?.value || "");
  const [operator, setOperator] = React.useState(item?.operator || "eq");
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (item?.operator !== operator) {
      setOperator(item?.operator);
      setSelectValue("");
    }
  }, [item?.value, item?.operator, operator]);

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectValue(newValue);

    if (newValue !== "") {
      applyValue({
        ...item,
        value:
          newValue === "true" ? true : newValue === "false" ? false : newValue,
      });
    } else {
      applyValue({
        ...item,
        value: null,
      });
    }

    setOpen(false);
  };

  // Render the select box for exists operator
  const renderSelectField = () => {
    if (item?.operator === "exists") {
      return (
        <FormControl
          fullWidth
          variant="standard"
          sx={{ marginTop: "0.2625rem" }}
        >
          <InputLabel>Filter Value</InputLabel>
          <Select
            value={selectValue}
            onChange={handleSelectChange}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            label="Filter Value"
            sx={{
              fontSize: "0.8rem",
            }}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </FormControl>
      );
    }

    return (
      <FormControl fullWidth variant="standard" sx={{ marginTop: "0.2625rem" }}>
        <InputLabel>Filter Value</InputLabel>
        <Select
          value={selectValue}
          onChange={handleSelectChange}
          open={open} 
          onClose={() => setOpen(false)} 
          onOpen={() => setOpen(true)} 
          label="Filter Value"
          sx={{
            fontSize: "0.8rem",
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: "400px", 
                overflowY: "auto", 
              },
            },
          }}
        >
          {options.length > 0 &&
            options.map((company) => {
              if (company.campaigns?.length > 0) {
                const companyItems = [
                  <MenuItem
                    disabled
                    key={company.company_name}
                    sx={{ color: "#000000", fontWeight: "bold" }}
                  >
                    {company.company_name}
                  </MenuItem>,
                  ...company.campaigns.map((campaign) => (
                    <MenuItem
                      key={campaign.campaign_id.affiliate_id}
                      value={campaign.campaign_id.affiliate_id}
                      selected={
                        selectValue === campaign.campaign_id.affiliate_id
                      }
                      sx={{
                        fontSize: "0.875rem",
                        "&:hover": {
                          backgroundColor: "#3B82F6", 
                          color: "white", 
                        },
                      }}
                    >
                      {campaign.campaign_id.title}
                    </MenuItem>
                  )),
                ];
                return companyItems;
              }
              return null;
            })}
        </Select>
      </FormControl>
    );
  };

  return <>{renderSelectField()}</>;
};

export default CustomGroupCampaignSelect;
