import api from "./api";
export const getLeadComapigns = async (data) => {
  return await api.get("/api/leadSourceCampaigns", data);
};

export const getLeadComapignByid = async (id, data) => {
  return await api.get(`/api/leadSourceCampaigns/${id}`, data);
};

export const createLeadCampaign = async (data) => {
  return await api.post(`/api/leadSourceCampaigns`, data);
};

export const updateLeadCampaign = async (data) => {
  let payload = { ...data };
  delete payload.id;
  return await api.put(`/api/leadSourceCampaigns/${data.id}`, payload);
};

export const deleteLeadCampaign = async (id) => {
  return await api.delete(`/api/leadSourceCampaigns/${id}`);
};
