import React, { useState, useRef } from "react";
import { DotsLoader, FormInput, FormTextArea } from "../../../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
import CustomModal from "../../../../components/molecules/Modal/customModal";
import api from "../../../../services/api";
import InputMask from "react-input-mask";
import { MultiSelect } from "primereact/multiselect";
import * as Yup from "yup";
function AddPurchaseForm({ onCancelForm, modalTitle, onFormSubmit }) {
  const multiselectRef = useRef(null);
  const [isLoader, setIsLoader] = React.useState(false);
  const [sections, setSections] = useState({
    did_pattern: "XXXXXXXXXX",
    quantity: 5,
  });
  const [didsList, setDidsList] = useState({});

  const handleInputChange = (field, value) => {
    setSections((prevSections) => ({
      ...prevSections,
      [field]: value,
    }));
  };

  const validationSchema = Yup.object({
    description: Yup.string().required("Description is required"),
    dids: Yup.array()
      .min(1, "At least one DID must be selected")
      .required("DID details are required"),
  });
  const formik = useFormik({
    initialValues: {
      dids: [],
      description: "",
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setIsLoader(true);
      try {
        const res = await api.post("/api/cc/dids/buy_selected_dids", values);
        if (res.status === 200 || res.status === 201) {
          toast.success("DIDs successfully purchased.");
          onFormSubmit();
        } else {
          toast.error("DID couldn't be purchased!");
        }
      } catch (err) {
        if (err?.response?.data?.error) {
          toast.error(err?.response?.data?.error);
        } else {
          toast.error(err?.response?.data);
          return;
        }
        console.log(err);
      }
      setIsLoader(false);
    },
  });

  const handlePattern = async () => {
    formik.setFieldValue("dids", []);
    const customError = validateForm(sections);
    if (Object.keys(customError).length !== 0) {
      if (customError.quantity) {
        toast.error(customError.quantity);
      }
      return;
    }
    setIsLoader(true);
    try {
      const payload = {
        did_pattern: sections?.did_pattern,
        quantity: sections?.quantity,
      };
      const res = await api.post("/api/cc/dids/get_suggested_dids", payload);
      if (res.status === 200 || res.status === 201) {
        setDidsList(res.data);
      } else {
        toast.error("DID couldn't be search!");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      setIsLoader(false);
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      } else {
        toast.error(err?.response?.data);
        return;
      }
      console.log(err);
    }
  };
  const validateForm = (values) => {
    const errors = {};
    if (!values.quantity) {
      errors.quantity = "Quantity is required";
    } else if (values.quantity > 2000) {
      errors.quantity = "Quantity cannot be more than 2000";
    }

    return errors;
  };

  const handleReset = () => {
    setSections({
      did_pattern: "XXXXXXXXXX",
      quantity: 5,
    });
    setDidsList({});
    formik.setFieldValue("dids", []);
  };

  return (
    <CustomModal
      multiple
      handleModal={() => {
        onCancelForm();
        setDidsList({});
      }}
      title={modalTitle}
      onSubmit={formik.handleSubmit}
      onClick={onCancelForm}
      className="max-w-5xl"
      modalType={false}
      formIsValid={formik.isValid && formik.dirty}
    >
      {isLoader ? <DotsLoader /> : null}
      <div className="mb-0 p-2 mt-3">
        <div className="flex grid grid-cols-3 items-center space-x-4 border-b mb-4">
          <div className="mb-2 relative">
            <InputMask
              mask="XXXXXXXXXX"
              value={sections.did_pattern}
              onChange={(e) => handleInputChange("did_pattern", e.target.value)}
              maskChar="X"
              formatChars={{
                X: "[0-9]",
              }}
            >
              {(inputProps) => (
                <FormInput
                  {...inputProps}
                  name="did_pattern"
                  label="DID Pattern"
                  className="text-xs"
                />
              )}
            </InputMask>
          </div>
          <div className="mb-2 relative">
            <FormInput
              name="quantity"
              label="Quantity"
              type="number"
              onChange={(e) => handleInputChange("quantity", e.target.value)}
              formik={formik}
              value={sections.quantity}
            />
          </div>
          <div className="mb-2 flex gap-x-2 justify-end">
            <button
              type="button"
              onClick={handlePattern}
              className="bg-primary-100 text-white text-sm rounded px-2 py-2 font-semibold"
            >
              Search DIDs
            </button>
            <button
              type="button"
              onClick={handleReset}
              className="bg-black text-white text-sm rounded px-4 py-2 font-semibold"
            >
              Reset
            </button>
          </div>
        </div>

        {didsList?.data?.length ? (
          <div className="grid md:grid-cols-2 ">
            <div className="space-y-4 mt-2 ">
              <div className=" mb-2 w-full">
                <MultiSelect
                  ref={multiselectRef}
                  placeholder="Select DIDs"
                  options={didsList?.data}
                  optionLabel="telephoneNumber"
                  optionValue="telephoneNumber"
                  filter
                  maxSelectedLabels={2}
                  value={formik?.values?.dids || []}
                  onChange={(e) => {
                    const selectedIds = e.value;
                    formik.setFieldValue("dids", selectedIds);
                  }}
                  className={`h-[35px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da] ${
                    formik.errors.dids && formik.touched.dids
                      ? "border-red-500"
                      : ""
                  }`}
                  panelClassName="max-h-50 overflow-y-auto"
                />
                {formik.errors.dids && formik.touched.dids ? (
                  <div className="text-xs text-red-500">
                    {formik.errors.dids}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-4 h-full">
                <div className="w-full">
                  <label className="font-semibold text-sm">Description</label>
                  <FormTextArea
                    rows={3}
                    name="description"
                    formik={formik}
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div
              className={`mt-0 bg-gray-10 p-2 ${
                formik?.values?.dids?.length ? "border-l ml-3 mr-2" : ""
              }`}
            >
              {formik?.values?.dids?.length ? (
                <>
                  <div
                    class="flex items-center p-2 text-lg text-yellow-800  dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
                    role="alert"
                  >
                    <FaInfoCircle />
                    <div>
                      <span class="font-medium ml-2">
                        You have selected {formik?.values?.dids?.length} DIDs
                      </span>
                    </div>
                  </div>
                  <div
                    className="overflow-y-auto pl-2"
                    style={{ maxHeight: "calc(40vh - 2rem - 2.5rem)" }}
                  >
                    {formik?.values?.dids?.map((item, index) => (
                      <div key={item._id} className="mb-1 text-blue-600">
                        {item}
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : null}

        {didsList?.success === true && !didsList?.data?.length ? (
          <div
            class="flex items-center p-2 mb-3 text-sm text-yellow-800 border border-yellow-300 rounded-sm bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800"
            role="alert"
          >
            <FaInfoCircle />
            <div>
              <span class="font-medium text-sm ml-2">
                No DIDs available for the specified pattern!
              </span>
            </div>
          </div>
        ) : null}
        {didsList?.data?.length ? (
          <div className="mt-3 flex justify-end space-x-2">
            <button
              type="button"
              onClick={formik.handleSubmit}
              className="bg-primary-100 text-white text-sm rounded px-4 py-2 font-semibold"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={onCancelForm}
              className="bg-black text-white text-sm rounded px-4 py-2 font-semibold ml-2"
            >
              Cancel
            </button>
          </div>
        ) : null}
      </div>
    </CustomModal>
  );
}

export default AddPurchaseForm;
