import React from "react";
import { FormControl, InputLabel, Select } from "@mui/material";

const CampaignGroup = ({
  name,
  label,
  formik,
  options = [],
  convertor,
  disabled,
  onChange,
  errors,
  nullOption,
  value,
  required,
  onFocus,
  onClick,
}) => {
  const handleChange = ({ target: { value } }) => {
    if (convertor) {
      value = convertor(value);
    }

    if (formik && !onChange) {
      formik.setFieldValue(name, value);
    }

    if (onChange) {
      onChange(value);
    }
  };

  const renderError = (errorMessage) => (
    <small
      style={{
        fontSize: 12,
        marginTop: 0,
        paddingLeft: 16,
        color: "#D32F2F",
      }}
    >
      {errorMessage}
    </small>
  );

  return (
    <FormControl
      sx={{
        mt: 0,
        minWidth: 120,
        borderColor: "#a9a9a9",
        background: "transparent",
      }}
      fullWidth
      variant="outlined"
    >
      <InputLabel htmlFor={label}>{required ? `${label} *` : label}</InputLabel>
      <Select
        sx={{
          borderColor: "#a9a9a9",
          mt: 0,
          background: "#fff",
        }}
        value={value ?? formik?.values[name] ?? ""}
        onChange={handleChange}
        onFocus={onFocus}
        onClick={onClick}
        onBlur={formik?.handleBlur}
        disabled={disabled}
        error={formik?.errors[name] && formik?.touched[name]}
        label={label}
        id={name}
        name={name}
        size="small"
        fullWidth
        variant="outlined"
        native
      >
        {nullOption !== "No" && <option value="" label=""></option>}

        {/* Render grouped options */}
        {options.length > 0 &&
          options.map((company) =>
            company.campaigns?.length > 0 ? (
              <optgroup key={company.company_name} label={company.company_name}>
                {company.campaigns.map((campaign) => (
                  <option
                    key={campaign.campaign_id.affiliate_id}
                    value={campaign.campaign_id.affiliate_id}
                  >
                    {campaign.campaign_id.title}
                  </option>
                ))}
              </optgroup>
            ) : null
          )}
      </Select>

      {formik?.errors[name] &&
        formik?.touched[name] &&
        renderError(formik.errors[name])}

      {Array.isArray(errors) &&
        errors.length > 0 &&
        errors.map(
          (error, index) =>
            error[name] && (
              <React.Fragment key={index}>
                {renderError(
                  error[name]
                    .replaceAll(`"${name}"`, label)
                    .replaceAll(`${name}`, label)
                )}
              </React.Fragment>
            )
        )}
      {typeof errors === "object" &&
        errors[name] &&
        renderError(errors[name][0])}
    </FormControl>
  );
};

export default CampaignGroup;
