import React from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  create_job_category,
  update_job_category,
} from "../../features/jobCategorySlice";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import { MultiSelect } from "primereact/multiselect";
import labels from "../Lead/Components/leads_labels.json";

const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.jobCategory);
  const { industryDrd } = useSelector((state) => state.industry);
  const [errors, setErrors] = React.useState([]);
  const [selectedFields, setSelectedFields] = React.useState(
    editingRecord ? editingRecord?.form_fields?.map(({ value }) => value) : []
  );
  let initialValues = {
    industry_id: "",
    name: "",
    drop_location_required: false,
    priority: 0,
    dark_img: "",
    light_img: "",
    active: true,
    form_fields: [],
  };
  if (editingRecord) {
    const {
      _id,
      industry_id,
      name,
      priority,
      dark_img,
      light_img,
      drop_location_required,
      active,
    } = editingRecord;
    initialValues = {
      id: _id,
      industry_id: industry_id?._id,
      name,
      drop_location_required,
      priority,
      dark_img: dark_img ? dark_img : "",
      light_img: light_img ? light_img : "",
      active,
    };
  }
  function validateForm(data) {
    const errors = [];
    if (!data.industry_id) {
      errors.push({ industry_id: "Industry is required" });
    }
    if (!data.name) {
      errors.push({ name: '"name" is required' });
    }
    if (!data.priority && !data.priority === 0) {
      errors.push({ priority: '"priority" is required' });
    }

    return errors;
  }
  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    // validationSchema: JobCategorySchema,
    onSubmit: async (values) => {
      const errors = validateForm(formik.values);
      if (errors?.length === 0) {
        const form_fields = selectedFields?.map((val) => ({
          label: labels[val],
          value: val,
        }));

        if (!editingRecord) {
          try {
            const res = await dispatch(
              create_job_category({ ...values, form_fields })
            );
            if (res?.payload?.status === 201) {
              toast.success("Industry Category created");
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Industry Category",
                msg: "created",
              });
            }
          } catch (error) {
            console.error(
              "🚀 ~ file: AddUpdateForm.jsx:102 ~ onSubmit: ~ error:",
              error
            );
            toast.error("Industry Category couldn't be created");
          }
        } else {
          try {
            const res = await dispatch(
              update_job_category({ ...values, form_fields })
            );
            if (res?.payload?.status === 200) {
              toast.success("Industry Category updated");
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                setErrors: setErrors,
                action: "Industry Category",
                msg: "updated",
              });
            }
          } catch (error) {
            console.error(
              "🚀 ~ file: AddUpdateForm.jsx:86 ~ onSubmit: ~ error:",
              error
            );
            toast.error("Industry Category couldn't be updated");
          }
        }
      } else {
        errorMessage({
          payload: errors,
          setErrors: setErrors,
          action: "Industry Category",
          msg: `${editingRecord ? "updated" : "created"}`,
        });
      }
    },
  });
/*   function createArrayFromObject() {
    const result = Object.entries(labels).map(([key, value]) => {
      return {
        value: key,
        label: value,
        type: "", // Determine the type of the value
      };
    });

    return result;
  } */
  const fieldOptions = [
    {
      key: "car_year",
      label: "Year",
      type: "short",
    },
    {
      key: "car_make",
      label: "Make",
      type: "short",
    },
    {
      key: "car_model",
      label: "Model",
      type: "short",
    },
    {
      key: "is_neutral",
      label: "Is neutral",
      type: "short",
    },
    {
      key: "car_color",
      label: "Color",
      type: "short",
    },
    {
      key: "drivetrain",
      label: "Drivetrain",
      type: "short",
    },
    {
      key: "duty_type",
      label: "Type Of Duty",
      type: "short",
    },
    {
      key: "is_tires_ok",
      label: "Are the tires okay",
      type: "medium",
    },
    {
      key: "car_type",
      label: "Vehicle type",
      type: "short",
    },
    {
      key: "is_running",
      label: "Is the vehicle still operable/running",
      type: "",
    },
    {
      key: "tow_type",
      label: "Type of tow",
      type: "",
    },
    {
      key: "has_spare_tire",
      label: "Does the client has a good spare tire",
      type: "",
    },
    {
      key: "which_tire_to_be_change",
      label: "Which Tire needs to be change",
      type: "",
    },
    {
      key: "tire_size",
      label: "Tire size",
      type: "",
    },
    {
      key: "used_or_new_tire",
      label: "Does the client used or new tire",
      type: "",
    },
    {
      key: "needs_to_be_jump",
      label: "How many better needs to be jump",
      type: "",
    },
    {
      key: "tire_count_needs_to_inflate",
      label: "How many tires need to inflate",
      type: "",
    },
    {
      key: "which_tire_needs_to_inflate",
      label: "Which tire needs to be inflate",
      type: "",
    },
    {
      key: "days_to_stored",
      label: "How many days needs to be stored",
      type: "",
    },
    {
      key: "fuel_type",
      label: "What type of fuel do they need",
      type: "",
    },
    {
      key: "gallons",
      label: "How many gallons",
      type: "",
    },
    {
      key: "is_payout",
      label: "Is there a payout",
      type: "",
    },
    {
      key: "payout_amount",
      label: "Amount of Payout",
      type: "",
    },
    {
      key: "is_paperwork_needed",
      label: "Are there paperwork needed",
      type: "",
    },
    {
      key: "is_trailer",
      label: "Does it have a trailer attached",
      type: "",
    },
    {
      key: "weight",
      label: "Weight (lb)",
      type: "",
    },
    {
      key: "height",
      label: "Height (ft)",
      type: "",
    },
    {
      key: "length",
      label: "Length (ft)",
      type: "",
    },
    {
      key: "width",
      label: "Width (ft)",
      type: "",
    },
    {
      key: "axles_count",
      label: "How many axles does it have",
      type: "",
    },
    {
      key: "is_dually",
      label: "Is it Dually",
      type: "",
    },
    {
      key: "loaded_with",
      label: "What is it loaded with",
      type: "",
    },
    {
      key: "lockout_type",
      label: "Is it a door lockout or trunk lockout",
      type: "",
    },
    {
      key: "locksmith_type",
      label: "What type of Locksmith Service",
      type: "",
    },
    {
      key: "locksmith_specify",
      label: "Specify",
      type: "",
    },
    {
      key: "to_be_towed",
      label: "What is the equipment or item that needs to be towed",
      type: "",
    },
    {
      key: "lugnuts_type",
      label: "Does the client has regular lugnuts or locking lugnuts",
      type: "",
    },
    {
      key: "lugnuts_keys_to_remove",
      label: "If its locking lugnuts, do they the keys to remove it",
      type: "",
    },
    {
      key: "is_replacement_vehicle_same",
      label: "Same replacement vehicle",
      type: "",
    },
    {
      key: "replacement_car_year",
      label: "Replacement vehicle year",
      type: "",
    },
    {
      key: "replacement_car_make",
      label: "Replacement vehicle Make",
      type: "",
    },
    {
      key: "replacement_car_model",
      label: "Replacement vehicle Modal",
      type: "",
    },
    {
      key: "replacement_car_color",
      label: "Replacement vehicle Color",
      type: "",
    },
    {
      key: "replacement_license_number",
      label: "License Plate Number",
      type: "",
    },
    {
      key: "far_from_road",
      label: "Far from the road or solid pavement",
      type: "",
    },
    {
      key: "stucked_depth",
      label: "How deep is the vehicle stuck",
      type: "",
    },
    {
      key: "stucked_part",
      label: "Which part of the vehicle is stuck",
      type: "",
    },
  ];
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={() => {
          return null;
        }}
      >
        {isLoading && <DotsLoader />}
        <form>
          <div className="mb-3 mt-4">
            <FormSelectInput
              errors={errors}
              name="industry_id"
              label="Industry"
              formik={formik}
              options={industryDrd}
              valueProp="_id"
              labelProp="name"
            />
          </div>

          <div className="mb-3">
            <FormInput
              errors={errors}
              name="name"
              label="Name"
              formik={formik}
            />
          </div>

          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="drop_location_required"
              label="Drop Location Required"
              formik={formik}
              nullOption="No"
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              valueProp="value"
              labelProp="label"
              convertor={(value) => value === "true"}
            />
          </div>

          <div className="mb-3">
            <FormInput
              errors={errors}
              name="priority"
              label="Priority"
              formik={formik}
              type="number"
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="dark_img"
              label="Dark Image"
              formik={formik}
            />
          </div>
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="light_img"
              label="Light Image"
              formik={formik}
            />
          </div>
          <div className="">
            <FormSelectInput
              errors={errors}
              name="active"
              label="Active"
              formik={formik}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              valueProp="value"
              labelProp="label"
              nullOption="No"
              convertor={(value) => value === "true"}
            />
          </div>
          <div className="mt-3">
            <MultiSelect
              value={selectedFields}
              onChange={(e) => {
                setSelectedFields(e.value);
              }}
              options={fieldOptions}
              optionLabel="label"
              optionValue="key"
              placeholder="Form Fields"
              filter
              className="h-[38px] w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
