import * as React from "react";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const CustomMuiTextInput = ({ item, applyValue }) => {
  const [inputValue, setInputValue] = React.useState(item?.value || "");
  const [operator, setOperator] = React.useState(item?.operator || "eq");

  React.useEffect(() => {
    if (item?.operator !== operator) {
      setOperator(item?.operator);
      setInputValue(""); 
    }
    
  }, [item?.value, item?.operator, operator]);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    if (newValue.trim() !== "") {
      applyValue({
        ...item,
        value: newValue,
      });
    } else {
      applyValue({
        ...item,
        value: null,
      });
    }
  };

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    applyValue({
      ...item,
      value: newValue === "true" ? true : newValue === "false" ? false : null, 
    });
  };

  const renderInputField = () => {
    if (operator === "exists") {
      return (
        <FormControl fullWidth variant="standard" sx={{ marginTop: "0.2625rem" }}>
          <InputLabel>Filter Value</InputLabel>
          <Select
            value={inputValue}
            onChange={handleSelectChange}
            label="Filter Value"
            sx={{
              fontSize: "0.8rem",
            }}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </FormControl>
      );
    }

    return (
      <TextField
        label="Filter Value"
        variant="standard"
        fullWidth
        value={inputValue}
        onChange={handleInputChange}
        InputProps={{
          style: { fontSize: "0.8rem" },
        }}
        sx={{
          "& .MuiInputBase-input": {
            fontSize: "0.8rem",
            marginTop: "0.2625rem",
          },
        }}
      />
    );
  };

  return <>{renderInputField()}</>;
};

export default CustomMuiTextInput;
