import React, { useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { FaPhone } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../../components/atoms/SelectInput";
import { get_industry_drd } from "../../features/IndustrySlice";
import Picker from "../../reports/Components/Picker";
import GraphModal from "./GraphModal";
import { FiMaximize } from "react-icons/fi";
import { get_industry_calls_by_date } from "../../features/dashboardSlice";
import dayjs from "dayjs";
import { get_users } from "../../features/usersSlice";
import ByAgent from "./ByAgent";
//import { Loader } from "../../components";
import ByVendor from "./ByVendor";
import StatsLoader from "../../components/organisms/Statsloader";
import GraphLoader from "../../components/organisms/GraphLoader";
import PieChartLoader from "../../components/organisms/PieChartLoader";
const days = [
  { value: 2, label: "Monday" },
  { value: 3, label: "Tuesday" },
  { value: 4, label: "Wednesday" },
  { value: 5, label: "Thursday" },
  { value: 6, label: "Friday" },
  { value: 7, label: "Saturday" },
  { value: 1, label: "Sunday" },
];
const months = [
  {
    label: "January",
    value: "Jan",
  },
  {
    label: "February",
    value: "Feb",
  },
  {
    label: "March",
    value: "Mar",
  },
  {
    label: "April",
    value: "Apr",
  },
  {
    label: "May",
    value: "May",
  },
  {
    label: "June",
    value: "Jun",
  },
  {
    label: "July",
    value: "Jul",
  },
  {
    label: "August",
    value: "Aug",
  },
  {
    label: "September",
    value: "Sep",
  },
  {
    label: "October",
    value: "Oct",
  },
  {
    label: "November",
    value: "Nov",
  },
  {
    label: "December",
    value: "Dec",
  },
];

const pieOptions = {
  responsive: true, // Allow chart to be responsive
  maintainAspectRatio: false, // Prevent chart from maintaining aspect ratio
  plugins: {
    legend: {
      display: true,
      position: "top",
      labels: {
        usePointStyle: true,
      },
    },
    datalabels: {
      display: true, // Show datalabels
      color: "black",
      formatter: (value, context) => {
        return (
          (
            (value * 100) /
            context.dataset.data.reduce((a, b) => a + b, 0)
          ).toFixed(2) + "%"
        );
      },
      font: {
        weight: "bold",
      },
    },
  },
};

const Dasboard = () => {
  const { isIndustryLoading, industryCallsOverAll } = useSelector(
    (state) => state.dashboard
  );

  const getTimezoneString = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  };
  const localDate = new Date();

  // Get the time zone offset in minutes
  const timeZoneOffsetMinutes = localDate.getTimezoneOffset();

  // Convert the offset to hours and minutes
  const offsetHours = Math.floor(Math.abs(timeZoneOffsetMinutes) / 60);
  const offsetMinutes = Math.abs(timeZoneOffsetMinutes) % 60;

  // Determine the sign of the offset
  const offsetSign = timeZoneOffsetMinutes > 0 ? "-" : "+";

  // Format the offset in the desired format
  const offsetString = `${offsetSign}${offsetHours
    .toString()
    .padStart(2, "0")}:${offsetMinutes.toString().padStart(2, "0")}`;

  // const fromDate = dayjs().startOf("month").toDate();
  const fromDate =
    new Date(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      0,
      0,
      0,
      0
    )
      .toISOString()
      .slice(0, 23) + "Z";
  const toDate = new Date().toISOString().slice(0, 23) + "Z";
  const [industryFilter, setIndustryFilter] = React.useState({
    start_date: fromDate,
    end_date: toDate,
    day: localDate.getDay() + 1,
    industry_id: "all",
    timezone: offsetString,
    timezone_name: getTimezoneString(),
  });

  const [industryRecord, setIndustryRecord] = useState({
    byDays: {
      labels: [],
      datasets: [
        {
          label: "Calls",
          data: [], // Values for Total Calls
          backgroundColor: "rgba(4, 42, 66, 1)",
          borderColor: "rgba(4, 42, 66, 1)",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
        {
          label: "Bookings",
          data: [], // Values for Answered Calls
          backgroundColor: "rgba(255, 183, 0, 1)",
          borderColor: "rgba(255, 183, 0, 1)",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
      ],
    },
    byDates: {
      labels: [],
      datasets: [
        {
          label: "Calls",
          data: [], // Values for Total Calls
          backgroundColor: "rgba(4, 42, 66, 1)",
          borderColor: "rgba(4, 42, 66, 1)",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
        {
          label: "Bookings",
          data: [], // Values for Answered Calls
          backgroundColor: "rgba(255, 183, 0, 1)",
          borderColor: "rgba(255, 183, 0, 1)",
          borderWidth: 2,
          pointRadius: 5,
          fill: false,
        },
      ],
    },
    overAll: {
      labels: ["Calls", "Bookings"],
      datasets: [
        {
          data: [],
          backgroundColor: ["rgba(4, 42, 66,1)", "rgba(255, 183, 0, 1)"],
          borderColor: ["rgba(4, 42, 66,1)", "rgba(255, 183, 0, 1)"],
          borderWidth: 1,
        },
      ],
    },
  });

  const getDashboardData = async () => {
    let payload = { ...industryFilter };
    if (industryFilter?.industry_id === "all") {
      payload = { ...payload, industry_id: "" };
    }
    const res = await dispatch(get_industry_calls_by_date({ ...payload }));

    if (res?.payload?.status === 200) {
      const data = res?.payload.data?.by_overall;
      // if (!data?.calls && !data?.sales) {
      //   // toast.info("No Record found by industry");
      // }
      const newData = [data?.calls, data?.sales];

      setIndustryRecord((prevState) => ({
        ...prevState,
        overAll: {
          ...prevState.overAll,
          datasets: [
            {
              ...prevState.overAll.datasets[0],
              data: newData,
            },
          ],
        },
      }));
    }

    if (res?.payload?.status === 200) {
      const data = res?.payload?.data?.by_date;

      const callsData = [];
      const bookingsData = [];
      const callsByDays = [];
      const bookingsByDays = [];

      industryRecord.byDates.labels.forEach((label) => {
        const foundData = data?.find(
          (item) => `${item.Month}-${item.Day}` === label
        );

        if (foundData) {
          callsData.push(foundData.total);
          bookingsData.push(foundData.sale_status);
        } else {
          callsData.push(0);
          bookingsData.push(0);
        }
      });
      industryRecord.byDays.labels.forEach((label) => {
        const foundData = data?.find(
          (item) => `${item.Month}-${item.Day}` === label
        );

        if (foundData) {
          callsByDays.push(foundData.total);
          bookingsByDays.push(foundData.sale_status);
        } else {
          callsByDays.push(0);
          bookingsByDays.push(0);
        }
      });

      const updatedDatasets = industryRecord?.byDates.datasets.map((item) => {
        if (item.label === "Calls") {
          return { ...item, data: callsData };
        } else if (item.label === "Bookings") {
          return { ...item, data: bookingsData };
        }
        return item;
      });

      const updatedDatasetsByDay = industryRecord.byDays.datasets.map(
        (item) => {
          if (item.label === "Calls") {
            return { ...item, data: callsByDays };
          } else if (item.label === "Bookings") {
            return { ...item, data: bookingsByDays };
          }
          return item;
        }
      );

      setIndustryRecord((prevState) => ({
        ...prevState,
        byDates: {
          ...prevState.byDates,
          datasets: updatedDatasets,
        },
        byDays: {
          ...prevState.byDays,
          datasets: updatedDatasetsByDay,
        },
      }));
    }
  };

  // Get the current date and time in the user's local time zone

  React.useEffect(() => {
    handleGetDatesClick(
      days?.find(({ value }) => value === +industryFilter.day)?.label
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryFilter]);

  React.useEffect(() => {
    if (
      industryRecord?.byDays?.labels?.length > 0 ||
      industryRecord?.byDates?.labels?.length > 0
    ) {
      getDashboardData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryRecord?.byDays?.labels, industryRecord?.byDates?.labels]);

  const [isGraphModal, setIsGraphModal] = useState(false);
  const [graphData, setGraphData] = useState({});
  const industry = useSelector((state) => state.industry);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(get_industry_drd());
    dispatch(
      get_users({
        page: 1,
        size: 1000,
        role_id: "638f8eed03f8ed286006b526",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const industryOption = industry?.industryDrd?.map(({ name, cms_id }) => ({
    value: cms_id,
    label: name,
  }));

  const getDatesForDay = (start, end, day) => {
    const dates = [];
    const days = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);
    while (currentDate <= endDate) {
      if (currentDate.getDay() === getDayIndex(day)) {
        days.push(new Date(currentDate));
      }
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return { days, dates };
  };

  const getDayIndex = (day) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days.indexOf(day);
  };

  const handleGetDatesClick = (selectedDay) => {
    const startDate = dayjs(industryFilter?.start_date).format("YYYY-MM-DD");
    const endDate = dayjs(industryFilter?.end_date).format("YYYY-MM-DD");
    if (startDate && endDate) {
      const { days, dates } = getDatesForDay(
        industryFilter?.start_date,
        industryFilter?.end_date,
        selectedDay
      );
      const dayLabels = days?.map(
        (date) => `${date?.getMonth() + 1}-${date?.getDate()}`
      );
      const dateLabels = dates?.map(
        (date) => `${date?.getMonth() + 1}-${date?.getDate()}`
      );
      setIndustryRecord({
        ...industryRecord,
        byDays: {
          ...industryRecord?.byDays,
          labels: dayLabels,
        },
        byDates: {
          ...industryRecord?.byDates,
          labels: dateLabels,
        },
      });
    } else {
      // Handle error, both start date and end date must be selected
    }
  };
  const barOptions = {
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        display: true, // Show datalabels
        color: "black",
        formatter: (value, context) => {
          return (
            (
              (value * 100) /
              context.dataset.data.reduce((a, b) => a + b, 0)
            ).toFixed(2) + "%"
          );
        },
        font: {
          weight: "bold",
        },
      },
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          // Customize the title, e.g., show the date here
          const dateIndex = tooltipItem[0].index;
          return `Date: ${data.labels[dateIndex]}`;
        },
      },
    },
  };
  const [selectedSearchType, setSelectedSearchType] = useState("Days");

  const searchTypes = ["Days"];
  return (
    <div className="w-full bg-white flex justify-between p-2">
      {isGraphModal ? (
        <GraphModal
          onClose={() => {
            setIsGraphModal(false);
            setGraphData({});
          }}
          title={graphData?.title}
          graphOptions={graphData?.graphOptions}
          data={graphData?.data}
          graphType={graphData?.graphType}
          industry={
            industry?.industryDrd?.find(
              ({ cms_id }) => cms_id === industryFilter?.industry_id
            )?.name
          }
          isVendors={graphData?.isVendors}
        />
      ) : null}

      <div className="w-full">
        <div className="relative border p-2">
          <div className="flex flex-col md:flex-row justify-between gap-3">
            <div className="flex flex-col">
              <h2>By Industry</h2>
              <div className="grid grid-cols-2 mt-3">
                {isIndustryLoading ? (
                  <StatsLoader />
                ) : (
                  <div className="flex flex-row w-full items-center p-1">
                    <div className="rounded-full border flex justify-center items-center h-[40px] w-[40px] bg-[#042A42]/20">
                      <FaPhone color="#042A42" />
                    </div>
                    <div className="flex flex-col ml-2 w-auto">
                      <small className="font-pop text-lg font-bold">
                        {industryCallsOverAll?.calls || 0}
                      </small>
                      <small className="font-pop">Total Calls</small>
                    </div>
                  </div>
                )}
                {isIndustryLoading ? (
                  <StatsLoader />
                ) : (
                  <div className="flex flex-row w-full items-center p-1">
                    <div className="rounded-full border border-[#FFB700] flex justify-center items-center h-[40px] w-[40px] bg-[#FFB700]/20">
                      <FaPhone color="#FFB700" />
                    </div>
                    <div className="flex flex-col ml-2 w-auto">
                      <small className="font-pop text-lg font-bold">
                        {industryCallsOverAll?.sales}{" "}
                        {industryCallsOverAll?.calls &&
                        industryCallsOverAll?.sales
                          ? `(${(
                              (+industryCallsOverAll?.sales /
                                +industryCallsOverAll?.calls) *
                              100
                            )?.toFixed(2)})%`
                          : "(0%)"}
                      </small>
                      <small className="font-pop">Booking</small>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* {isIndustryLoading ? <Loader /> : null }*/}
            <div className="flex max-w-1/4">
              <Picker
                filterData={industryFilter}
                setFiltersData={setIndustryFilter}
                dropdown_label="Industry"
                dropdown_menu={[
                  { value: "all", label: "All" },
                  ...industryOption,
                ]}
                valueKey="industry_id"
                value
                fromKey={"start_date"}
                toKey={"end_date"}
                rangeValue={"Today"}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-3 mt-3 gap-x-1">
            <div className="flex flex-col border shadow-md min-h-[286px] justify-center relative">
              <div className="flex flex-row justify-between p-2">
                <div className="space-x-2 flex flex-row">
                  {searchTypes.map((type, index) => (
                    <div key={index} className="flex items-center">
                      <input
                        type="checkbox"
                        id={`search-type-${index}`}
                        name="searchType"
                        value={type}
                        checked={true}
                        onChange={() => setSelectedSearchType(type)}
                      />
                      <label htmlFor={`search-type-${index}`} className="ml-1">
                        {type}
                      </label>
                    </div>
                  ))}
                </div>

                <FiMaximize
                  className="cursor-pointer"
                  onClick={() => {
                    setGraphData({
                      title: `Calls Compare View By ${selectedSearchType}`,
                      graphOptions: barOptions,
                      data: {
                        ...industryRecord,
                        labels: industryRecord?.byDays.labels,
                        datasets: industryRecord?.byDays.datasets,
                      },
                      graphType: "Bar",
                    });
                    setIsGraphModal(true);
                  }}
                />
              </div>

              <div className="flex justify-between items-center px-2">
                <span className="text-lg font-semibold">
                  Calls Compare View By {selectedSearchType}
                </span>
                {selectedSearchType !== "Years" ? (
                  <SelectInput
                    className={
                      "h-[31px] bg-white border border-gray-300 float-right rounded-[0.2rem]"
                    }
                    onChange={(e) => {
                      const day = days?.find(
                        ({ value }) => value === +e.target.value
                      )?.label;
                      handleGetDatesClick(day);
                      setIndustryFilter({
                        ...industryFilter,
                        day: e.target.value,
                      });
                    }}
                    options={selectedSearchType === "Days" ? days : months}
                    value={industryFilter.day}
                  />
                ) : null}
              </div>
              {/* {
                !isIndustryLoading?
                <BarChartSkeleton/>:
                
              } */}
              {isIndustryLoading ? (
                <GraphLoader />
              ) : (
                <Bar
                  data={{
                    ...industryRecord,
                    labels: industryRecord?.byDays.labels,
                    datasets: industryRecord?.byDays.datasets,
                  }}
                  options={barOptions}
                />
              )}
            </div>

            <div className="flex flex-col border shadow-md min-h-[286px] justify-center relative">
              <div className="flex justify-between items-center p-2">
                <span className="text-lg font-semibold">
                  Calls Compare View By Date
                </span>
                <FiMaximize
                  className="cursor-pointer"
                  onClick={() => {
                    setGraphData({
                      title: `Calls Compare View By Date`,
                      graphOptions: barOptions,
                      data: {
                        labels: industryRecord?.byDates.labels,
                        datasets: industryRecord?.byDates.datasets,
                      },
                      graphType: "Bar",
                    });
                    setIsGraphModal(true);
                  }}
                />
              </div>
              {isIndustryLoading ? (
                <GraphLoader />
              ) : (
                <Bar
                  data={{
                    labels: industryRecord?.byDates.labels,
                    datasets: industryRecord?.byDates.datasets,
                  }}
                  options={barOptions}
                />
              )}
            </div>
            <div className="flex flex-col border shadow-md min-h-[286px] justify-center relative">
              <div className="flex justify-between items-center p-2">
                <span className="text-lg font-semibold">Inbound Calls</span>
              </div>
              <div className="h-60 flex items-center justify-center pb-3">
                {isIndustryLoading ? (
                  <PieChartLoader />
                ) : (
                  <Doughnut
                    data={industryRecord?.overAll}
                    options={pieOptions}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <ByAgent
          searchTypes={searchTypes}
          selectedSearchType={selectedSearchType}
          setSelectedSearchType={setSelectedSearchType}
          setGraphData={setGraphData}
          setIsGraphModal={setIsGraphModal}
          barOptions={barOptions}
          pieOptions={pieOptions}
          days={days}
          months={months}
        />
        <ByVendor
          barOptions={barOptions}
          pieOptions={pieOptions}
          days={days}
          searchTypes={searchTypes}
          selectedSearchType={selectedSearchType}
          setSelectedSearchType={setSelectedSearchType}
          setGraphData={setGraphData}
          setIsGraphModal={setIsGraphModal}
        />
      </div>
    </div>
  );
};

export default Dasboard;
