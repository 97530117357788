import React, { useState } from "react";
import { Modal } from "../../components";
import { IoMdClose } from "react-icons/io";

function VendorsListModal({
  vendorList,
  getTotalStatusCount,
  selectedVendors,
  onClose,
  handleSearchByVendors,
  rules,
  setSelectedVendors,
}) {
  const [techSearchText, setTechSearchText] = useState("");

  const filteredTech = vendorList?.filter(
    (record) =>
      (record?.vendor?.first_name || "")
        ?.toLowerCase()
        ?.includes(techSearchText.toLowerCase()) ||
      techSearchText ===
        (record?.vendor?.last_name || "")?.includes(
          techSearchText.toLowerCase()
        )
  );

  return (
    <Modal
      onClick={onClose}
      onCancelModal={onClose}
      hideHeader={true}
      hideButtons={true}
      CloseOnly={true}
    >
      <div className="flex justify-between w-full items-center !mb-3 border-b pb-1 border-b-gray-700">
        <input
          type="text"
          className="h-[31px] bg-white border border-gray-300 text-gray-900 w-1/3 text-sm rounded-[0.2rem] w-ful p-2.5 "
          placeholder="Search"
          value={techSearchText}
          onChange={(e) => {
            setTechSearchText(e.target.value);
          }}
        />
        <span className="text-lg font-semibold">
          {`Total Record: `} {vendorList?.length}
        </span>
      </div>
      <div className="flex flex-wrap text-sm font-medium font-pop !mt-1 gap-2">
        {(filteredTech || [])?.map((item, vendorIndex) => {
          const isVendorSelected = selectedVendors?.includes(item?.vendor?._id);

          return (
            <button
              className={`flex justify-between items-center border py-0.5 px-2 text-xs gap-x-2 ${
                isVendorSelected
                  ? "text-white bg-menu "
                  : "text-black bg-gray-200"
              }`}
              onClick={() => {
                setSelectedVendors([...selectedVendors, item?.vendor?._id]);
                handleSearchByVendors([...selectedVendors, item?.vendor?._id]);
              }}
            >
              {`${item?.vendor?.first_name} ${
                item?.vendor?.last_name
              } (${getTotalStatusCount(item?.job_status)})`}
              {isVendorSelected ? (
                <div className="!ml-1.5 flex items-center">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      const vendors = selectedVendors?.filter(
                        (id) => id !== item?.vendor?._id
                      );
                      setSelectedVendors(vendors);
                      handleSearchByVendors(vendors);
                    }}
                    type="button"
                    className="flex items-center"
                  >
                    <IoMdClose />
                  </button>
                </div>
              ) : null}
            </button>
          );
        })}
      </div>
    </Modal>
  );
}

export default VendorsListModal;
