import dayjs from "dayjs";
import React from "react";
import { FaChevronLeft, FaChevronRight, FaCopy, FaPhone } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { get_missedcall_count } from "../../../features/dialerSlice";
import MUIDataTable from "../../molecules/DataTable/muigrid";
import styles from "./style.module.css";
import {
  BsTelephoneOutboundFill,
  BsTelephoneXFill,
  BsTelephoneFill,
} from "react-icons/bs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { sendIframeMsg } from "../../../util/iFrame";
import StatsLoader from "../Statsloader";
function Misscalls(props) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const { isLoading, missedCallsCount } = useSelector((state) => state.dialer);
  const dispatch = useDispatch();
  const dialer = useSelector((state) => state.dialer);
  const [searchText, setSearchText] = React.useState("");
  const callOn = async (number) => {
    const c = window.confirm(`Are you sure want to call on ${number}?`);
    if (!c) {
      return;
    }
    if (dialer?.status === "PAUSE") {
      try {
        sendIframeMsg({
          action: "ManualDial_Call",
          user: user?.dialer_data?.dialer_user,
          pass: user?.dialer_data?.dialer_pass,
          MDPhonENumbeR: number,
        });
        props.setIsMisscall(false);
        toast.success("Call Successfully");
      } catch (err) {
        console.error("🚀 ~ file: index.jsx:68 ~ callOn ~ err:", err);
      }
    } else if (dialer?.status === "") {
      toast.error("Please login to dialer");
    } else {
      toast.error("Please Pasue your dialer before call");
    }
  };
  React.useEffect(() => {
    const currentDate = new Date();
    const fromDate =
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
        0
      )
        .toISOString()
        .slice(0, 23) + "Z";

    const toDate = new Date().toISOString().slice(0, 23) + "Z";
    const initialFilters = {
      from_date: new Date(fromDate).getTime() / 1000,
      to_date: new Date(toDate).getTime() / 1000,
    };
    dispatch(get_missedcall_count(initialFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.info("Phone Number copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60 },
    {
      headerName: "Call Date",
      field: "call_date",
      width: 250,
      sortable: true,
      valueGetter: (params) => params.row.call_date,
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
    },
    { headerName: "Site Name", field: "did_description", flex: 1 },
    {
      headerName: "Phone Number",
      field: "phone_number",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="flex flex-row justify-between items-center">
            <span>{params?.row.phone_number}</span>
            <span
              className="ml-3 cursor-pointer"
              onClick={() => handleCopy(`1${params.row.records.phone_number}`)}
            >
              <FaCopy size={16} />
            </span>
          </div>
        );
      },
    },
    { headerName: "status", field: "status", width: 80 },
    {
      field: "actions",
      renderCell: (params) =>
        params.row.status === "DROP" ? (
          <div>
            <FaPhone
              onClick={() => callOn(`1${params.row.records.phone_number}`)}
              className="my_navIcon"
            />
          </div>
        ) : null,
    },
  ];
  // const filteredData = missedCallsCount?.records?.filter(
  //   ({ status }) => status !== searchText
  // );
  const filteredMisscall = () => {
    if (searchText === "SALE") {
      return missedCallsCount?.records?.filter(
        ({ status }) => status !== "DROP"
      );
    } else if (searchText === "DROP") {
      return missedCallsCount?.records?.filter(
        ({ status }) => status === "DROP"
      );
    } else {
      return missedCallsCount?.records;
    }
  };
  const filteredData = filteredMisscall();
  const handlePrevClick = () => {
    const newDate = new Date(props.misscallDate);
    newDate.setDate(props.misscallDate.getDate() - 1);
    props.setMisscallDate(newDate);
  };

  const handleNextClick = () => {
    const newDate = new Date(props.misscallDate);
    newDate.setDate(props.misscallDate.getDate() + 1);
    props.setMisscallDate(newDate);
  };
  const SummaryComponent = () => {
    return (
      <div className="w-full flex justify-center items-center">
        <div className="flex-col md:flex-row flex justify-center md:justify-between items-center mx-0.5 w-full mb-3 mt-2">
          <span className="text-base font-normal font-mont text-heading mb-2 md:mb-0">
            {`Displaying ${filteredData?.length > 0 ? "1" : "0"}-${
              filteredData?.length
            } of ${missedCallsCount?.records?.length} result`}
          </span>
          {isLoading ? null : (
            <div className="flex flex-row items-center">
              <FaChevronLeft
                onClick={handlePrevClick}
                className="cursor-pointer"
              />

              <div className=" font-semibold font-pop text-base mx-2">
                {dayjs(props.misscallDate).format("MMM D, YYYY")}
              </div>
              <FaChevronRight
                onClick={handleNextClick}
                className="cursor-pointer"
              />
            </div>
          )}

          <div className="grid grid-cols-2 md:grid-cols-3 gap-x-3">
            {isLoading ? (
              <StatsLoader />
            ) : (
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border !border-red-600 flex justify-center items-center h-[40px] w-[40px] bg-red-600/20">
                  <BsTelephoneXFill color="#dc2626" />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">
                    {missedCallsCount?.records?.length}
                  </small>
                  <small
                    className={`font-pop cursor-pointer ${
                      searchText === "" ? "border-b-2 border-primary-100" : ""
                    }`}
                    onClick={() => setSearchText("")}
                  >
                    Missed Calls
                  </small>
                </div>
              </div>
            )}
            {isLoading ? (
              <StatsLoader />
            ) : (
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border !border-green-600 flex justify-center items-center h-[40px] w-[40px] bg-green-600/20">
                  <BsTelephoneOutboundFill color="#16a34a" />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">
                    {missedCallsCount?.records?.length -
                      missedCallsCount?.totalItems}
                  </small>
                  <small
                    className={`font-pop cursor-pointer ${
                      searchText === "SALE"
                        ? "border-b-2 border-primary-100"
                        : ""
                    }`}
                    onClick={() => setSearchText("SALE")}
                  >
                    Call Back
                  </small>
                </div>
              </div>
            )}
            {isLoading ? (
              <StatsLoader />
            ) : (
              <div className="flex flex-row w-full items-center p-1">
                <div className="rounded-full border !border-[#FFB700] flex justify-center items-center h-[40px] w-[40px] bg-[#FFB700]/20">
                  <BsTelephoneFill color="#FFB700" />
                </div>
                <div className="flex flex-col ml-2 w-auto">
                  <small className="font-pop text-lg font-bold">
                    {missedCallsCount?.totalItems}
                  </small>
                  <small
                    className={`font-pop cursor-pointer ${
                      searchText === "DROP"
                        ? "border-b-2 border-primary-100"
                        : ""
                    }`}
                    onClick={() => setSearchText("DROP")}
                  >
                    Remaining
                  </small>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  function formatPhoneNumber(number) {
    if (number && number.length === 10) {
      const areaCode = number.slice(0, 3);
      const middlePart = number.slice(3, 6);
      const lastPart = number.slice(6);
      return `(${areaCode}) ${middlePart}-${lastPart}`;
    } else {
      return number;
    }
  }
  return (
    <>
      <div className="fixed w-[90%] md:w-[1024px] z-[101] left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]">
        <div
          className="absolute -right-3.7 -top-3.7 w-[34px] h-[34px] bg-white rounded-full flex justify-center items-center cursor-pointer drop-shadow"
          onClick={() => {
            props.setIsMisscall(false);
            props.setMisscallDate(new Date());
          }}
        >
          X
        </div>
        <div className="max-w-[1024px] h-auto p-2 bg-[#E6EAEA] rounded flex justify-center items-center text-xl font-bold">
          <div className="bg-white my-3 border rounded w-full">
            <MUIDataTable
              columnDefs={columnDefs}
              items={filteredData?.map((record, index) => {
                let counter = index + 1;
                let campaign_name = record.campaign_name;
                let call_date = dayjs(record?.call_date).format(
                  "ddd, MMM D, YYYY h:mm A"
                );
                let did = record.did;
                let phone_number = `${formatPhoneNumber(record?.phone_number)}`;
                let status = record.status;
                let did_description = record?.did_description;
                return {
                  records: { ...record, _id: counter },
                  counter,
                  campaign_name,
                  call_date,
                  did_description,
                  did,
                  phone_number,
                  status,
                };
              })}
              totalItems={missedCallsCount?.totalItems}
              pagination="No"
              searchable="No"
              showCount="No"
              CustomComponent={SummaryComponent}
              hideFooter
            />
          </div>
        </div>
      </div>
      <div
        className={[styles.backdrop].join(" ")}
        onClick={() => {
          props.setIsMisscall(false);
          props.setMisscallDate(new Date());
        }}
      ></div>
    </>
  );
}

export default Misscalls;
