import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "../../../services/api";
import { toast } from "react-toastify";
import {
  FormSelectInput,
  DotsLoader,
  Button,
  Backdrop,
} from "../../../components";
import errorMessage from "../../../util/errorMessage";
import TagsInput from "../../../components/atoms/CustomTagsInput";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
function AddUpdateAffiliate({
  onCancelForm,
  modalTitle,
  editingRecordId,
  leadCampaigns,
  reloadCampignListing,
  editCampaignRecord,
}) {
  const [errors, setErrors] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPhoneNumberFields, setShowPhoneNumberFields] = useState(false);
  const [isWebFormChecked, setIsWebFormChecked] = useState(false);
  const [isInboundCallChecked, setIsInboundCallChecked] = useState(false);
  const [isWebSource, setWebSource] = useState(false);
  const [showDomainFields, setShowDomainFields] = useState(false);

  const [isPhoneNumberSectionOpen, setIsPhoneNumberSectionOpen] = useState(
    editCampaignRecord ? false : true
  );
  const [isDomainSectionOpen, setIsDomainSectionOpen] = useState(
    editCampaignRecord ? false : true
  );

  let initialValues = {
    WebForm: false,
    phoneNumbers: [],
    inboundCall: false,
    webSourceCheck: false,
    webSource: [],
    lead_source_campaign_id: "",
  };

  if (editCampaignRecord) {
    const { _id, campaign_id, WebForm, webSource, inboundPhoneNumbers } =
      editCampaignRecord;
    initialValues = {
      id: _id,
      phoneNumbers: inboundPhoneNumbers?.length ? inboundPhoneNumbers : [],
      webSource: webSource?.length ? webSource : [],
      WebForm: WebForm,
      lead_source_campaign_id: campaign_id?._id,
    };
  }

  const validationSchema = Yup.object({
    lead_source_campaign_id: Yup.string().required("Campaign is required"),

    phoneNumbers: Yup.array().when("inboundCall", {
      is: true,
      then: Yup.array()
        .of(
          Yup.string()
            .test(
              "validPhone",
              "Phone number must be 10 or 11 digits",
              (value) => {
                if (!value) return false;
                const cleanedValue = value.replace(/\D/g, "");
                return cleanedValue.length === 10 || cleanedValue.length === 11;
              }
            )
            .required("Phone number is required")
        )
        .min(1, "At least one phone number is required"),
      otherwise: Yup.array().notRequired(),
    }),

    webSource: Yup.array().when("webSourceCheck", {
      is: true,
      then: Yup.array()
        .of(
          Yup.string()
            .matches(
              /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-]*)?$/,
              "Invalid domain URL format. Ensure it includes http://, https://, or is a valid domain."
            )
            .required("Domain URL is required")
        )
        .min(1, "At least one domain URL is required"),
      otherwise: Yup.array().notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let payload = {
        ...(editCampaignRecord
          ? {}
          : {
              lead_source_id: editingRecordId,
              campaign_id: values?.lead_source_campaign_id,
            }),
        WebForm: values?.WebForm,
        inboundPhoneNumbers: values?.inboundCall ? values?.phoneNumbers : [],
        webSource: values?.webSourceCheck ? values?.webSource : [],
      };

      try {
        setIsLoading(true);
        let res;
        if (editCampaignRecord?._id) {
          res = await api.put(
            `api/lead_source/updateSettings/${editCampaignRecord._id}`,
            payload
          );
        } else {
          res = await api.post(`api/lead_source/add_settings`, payload);
        }

        if (res.status === 200 || res.status === 201) {
          toast.success(
            editCampaignRecord?._id
              ? "Lead source settings successfully updated."
              : "Lead source settings successfully created."
          );
          reloadCampignListing();
        }
      } catch (err) {
        console.error(err);
        const errorData = err?.response?.data;
        errorMessage({
          payload: errorData.error || errorData.message,
          setErrors: setErrors,
          action: "Lead Source",
          msg: "created",
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    if (id === "web-form-checkbox") {
      setIsWebFormChecked(checked);
      formik.setFieldValue("WebForm", checked);
    } else if (id === "inbound-call-checkbox") {
      setIsInboundCallChecked(checked);
      setShowPhoneNumberFields(checked);
      formik.setFieldValue("inboundCall", checked);
    } else if (id === "websource-form") {
      setWebSource(checked);
      setShowDomainFields(checked);
      formik.setFieldValue("webSourceCheck", checked);
    }
  };

  useEffect(() => {
    if (editCampaignRecord?.inboundPhoneNumbers?.length > 0) {
      formik.setFieldValue(
        "phoneNumbers",
        editCampaignRecord?.inboundPhoneNumbers
      );
      setIsInboundCallChecked(true);
      setShowPhoneNumberFields(true);
      formik.setFieldValue("inboundCall", true);
    } else {
      formik.setFieldValue("inboundCall", false);
    }
    // eslint-disable-next-line
  }, [editCampaignRecord?.inboundPhoneNumbers?.length]);

  useEffect(() => {
    if (editCampaignRecord?.webSource?.length > 0) {
      formik.setFieldValue("webSource", editCampaignRecord?.webSource);
      setWebSource(true);
      setShowDomainFields(true);
      formik.setFieldValue("webSourceCheck", true);
    } else {
      formik.setFieldValue("webSourceCheck", false);
    }
    // eslint-disable-next-line
  }, [editCampaignRecord?.webSource?.length]);

  // Toggle handlers
  const togglePhoneNumberSection = () =>
    setIsPhoneNumberSectionOpen(!isPhoneNumberSectionOpen);
  const toggleDomainSection = () =>
    setIsDomainSectionOpen(!isDomainSectionOpen);

  return (
    <>
      <div className="min-h-screen bg-white w-full z-[100] fixed top-0 left-0 overflow-y-auto">
        <div className="overflow-y-auto h-screen">
          <div className="flex items-center justify-between w-full text-client-50 py-2.5 px-5 bg-white shadow-client overflow-y-auto">
            <h4>
              {!!editCampaignRecord
                ? modalTitle.replaceAll("Add", "Edit")
                : modalTitle}
            </h4>
            <Button
              text="X"
              className="mr-2"
              onClick={onCancelForm}
              variant="btn_danger"
            />
          </div>
          <Backdrop onClick={onCancelForm} />

          <div className="!p-5 grid gap-5  mb-0">
            <div className="!py-1 !px-3 md:px-10 bg-white rounded-3xl shadow-client">
              <div className="grid md:grid-cols-1 gap-x-5 !mt-1">
                {isLoading && <DotsLoader />}
                <div className="!py-1  md:px-12 w-full">
                  <div className="grid md:grid-cols-1 gap-5 !mt-1">
                    <div className="grid md:grid-cols-2 gap-5 !mt-1">
                      <div className="mb-2">
                        <FormSelectInput
                          errors={errors}
                          name="lead_source_campaign_id"
                          label="Lead Campaign"
                          formik={formik}
                          options={leadCampaigns}
                          valueProp="_id"
                          labelProp="title"
                          disabled={!!editCampaignRecord}
                        />
                      </div>
                      <div className="flex flex-col gap-4 md:flex-row md:items-center">
                        <div className="flex items-center">
                          <input
                            id="web-form-checkbox"
                            type="checkbox"
                            checked={isWebFormChecked || formik.values.WebForm}
                            onChange={handleCheckboxChange}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="web-form-checkbox"
                            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Web Form
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="inbound-call-checkbox"
                            type="checkbox"
                            checked={isInboundCallChecked}
                            onChange={handleCheckboxChange}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="inbound-call-checkbox"
                            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Inbound Call
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="websource-form"
                            type="checkbox"
                            checked={isWebSource}
                            onChange={handleCheckboxChange}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="websource-form"
                            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Web Source
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-1 gap-5 !mt-1">
                      <div className="grid lg:grid-cols-1 gap-4 mb-2">
                        {showPhoneNumberFields && (
                          <div>
                            <h6
                              className="!mb-1 text-client-50 w-full border-b border-client-50 flex justify-between items-center cursor-pointer"
                              onClick={togglePhoneNumberSection}
                            >
                              <span className="text-blue-500 font-semibold text-md flex items-center gap-2">
                                Phone Numbers
                              </span>
                              <button type="button" className="text-gray-500">
                                {isPhoneNumberSectionOpen ? (
                                  <FaChevronUp size={18} />
                                ) : (
                                  <FaChevronDown size={18} />
                                )}
                              </button>
                            </h6>

                            {isPhoneNumberSectionOpen && (
                              <div className="flex flex-col w-full">
                                <div className="">
                                  <TagsInput
                                    tags={formik?.values?.phoneNumbers}
                                    placeholder="Add Bulk Numbers"
                                    setTags={(e) =>
                                      formik.setFieldValue("phoneNumbers", e)
                                    }
                                  />
                                </div>
                                <div className="flex justify-between items-center text-sm text-yellow-800 rounded-lg">
                                  <span className="font-medium">
                                    Add numbers separated by commas or by
                                    pressing Enter (e.g., 1234567890,
                                    1234567890).
                                  </span>
                                  {formik?.values?.phoneNumbers?.length ? (
                                    <span class="mt-1 bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-md dark:bg-pink-900 dark:text-pink-300">
                                      Phone No.
                                      <span className="ml-1">
                                        {formik?.values?.phoneNumbers?.length ||
                                          0}
                                      </span>
                                    </span>
                                  ) : null}
                                </div>

                                {formik.errors.phoneNumbers &&
                                  formik.touched.phoneNumbers && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {formik.errors.phoneNumbers}
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="grid lg:grid-cols-1 gap-4 mb-2">
                        {showDomainFields && (
                          <div>
                            <h6
                              className="!mb-1 text-client-50 w-full border-b border-client-50 flex justify-between items-center cursor-pointer"
                              onClick={toggleDomainSection}
                            >
                              <span className="text-blue-500 font-semibold text-md flex items-center gap-2">
                                Domains
                              </span>
                              <button type="button" className="text-gray-500">
                                {isDomainSectionOpen ? (
                                  <FaChevronUp size={18} />
                                ) : (
                                  <FaChevronDown size={18} />
                                )}
                              </button>
                            </h6>

                            {isDomainSectionOpen && (
                              <div className="flex flex-col w-full">
                                <div className="overflow-y-auto max-h-60">
                                  <TagsInput
                                    tags={formik?.values?.webSource}
                                    setTags={(e) =>
                                      formik.setFieldValue("webSource", e)
                                    }
                                    placeholder="Add Bulk Domains"
                                    className="w-full"
                                  />
                                </div>
                                <div className="flex justify-between items-center text-sm text-yellow-800 rounded-lg">
                                  <span className="font-medium">
                                    Add domains separated by commas or by
                                    pressing Enter (e.g., abc.com, xyz.com).
                                  </span>
                                  {formik?.values?.webSource?.length ? (
                                    <span class="mt-1 bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-md dark:bg-pink-900 dark:text-pink-300">
                                      Domains
                                      <span className="ml-1">
                                        {formik?.values?.webSource?.length || 0}
                                      </span>
                                    </span>
                                  ) : null}
                                </div>
                                {formik.errors.webSource &&
                                  formik.touched.webSource && (
                                    <div className="text-red-500 text-xs mt-1">
                                      {formik.errors.webSource}
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full flex justify-center !p-5 bg-white border-t mt-2">
                <Button
                  text="Cancel"
                  className="py-1.5 px-3 align-middle bg-menu text-white mr-2"
                  onClick={onCancelForm}
                />
                <Button
                  text={"Submit"}
                  className="py-1.5 px-3 align-middle bg-primary-100 text-white mr-2"
                  type="button"
                  onClick={formik.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddUpdateAffiliate;
