
import React, { useState } from "react";
import { Button, DotsLoader, FormInput } from "../../../components";
import dayjs from "dayjs";
import api from "../../../services/api";
import EmailDetail from "./EmailDetailModal";
import { MdOutlineAddCircleOutline, MdOutlineDrafts } from "react-icons/md";
import { FiSend } from "react-icons/fi";
import { FaInbox } from "react-icons/fa";
import { toast } from "react-toastify";
import EmailComposerModal from "./ComposerModal";

const GoogleMails = ({ setEmailCount, emailCount }) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const isSynced =
    (user?.sync_with_google && user?.sync_with_google === true) ||
    user?.sync_with_google === "true";
  const [selectedEmail, setSelectedEmail] = React.useState(null);
  const [cc, setCc] = React.useState([]);
  const [bcc, setBcc] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [selectedfiles, setFiles] = useState([]);
  // Handle adding emails (on blur or pressing enter)
/*   const handleAddEmails = (input) => {
    // Split the input by commas and remove empty strings
    const newEmails = input?input.split(',').map(email => email.trim()).filter(email => email):[];
    return newEmails
  }; */
  // Function to convert the file to Base64 using FileReader
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // Resolves the Base64 string
      };
      reader.onerror = reject; // Rejects the promise if there's an error
      reader.readAsDataURL(file); // Start reading the file
    });
  };
  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    // Log form data for demonstration purposes
    let attachment = selectedfiles;
    const base64Promises = Array.from(attachment).map(async (file) => {
      let base64 = await fileToBase64(file);
      return {
        filename: file.name,
        content: base64.replace(/^data:([a-zA-Z0-9+-/]+);base64,/, ""),
        mimeType: file.type,
      };
    });
    const base64Results = await Promise.all(base64Promises);
    // Call Api
    try {
      const payload = {
        // toEmail: formData.get("to"),
        // ccEmails: [formData.get("cc")],
        // bccEmails: [formData.get("bcc")],
        // subject: formData.get("subject"),
        // body: formData.get("description"),
        toEmail: formData.get('to'),
        ccEmails: cc,
        bccEmails: bcc,
        subject: formData.get('subject'),
        body: formData.get('description'),
      };
      // if (base64Results.length) {
      //   payload["attachments"] = base64Results;
      // }
      // const res = await api["post"](
      //   "/api/emailParse/compose_and_send_email",
      //   payload
      // );
      if(base64Results.length){
        payload['attachments'] = base64Results
      }    
      setIsLoading(true)
      const res = await api["post"]("/api/google_email/compose_and_send_email", payload);
      if (res?.status === 200) {
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
    // Optionally, reset the form here
    event.target.reset();
    closeModal();
  };
  // Function to handle multiple file selection and convert each to Base64
  const handleFileChanged = (e) => {
    // setFiles(e.target.files);
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };
  // Remove file from selectedFiles
  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const getTimezoneString = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  };
  const initalData = {
    subjectText: null,
    fromEmail: null,
    numberOfMails: null,
    emailDate: dayjs(new Date()).format("YYYY-MM-DD"),
    timeZone: getTimezoneString(),
  };
  const [isLoader, setIsLoader] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState("Inbox");
  const [emailList, setEmailList] = React.useState([]);
  const [selectedPayload, setSelectedPayload] = React.useState(initalData);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSelectedPayload({ ...selectedPayload, [name]: value });
  };
  const get_list = async (filter) => {
    setIsLoader(true);
    try {
      const res = await api.post("/api/google_email/inbox", filter);
      if (res.status === 200) {
        setEmailList(Array.isArray(res.data?.messagesDetails) ? { ...res.data, draft: false } : []);
        setEmailCount(
          res.data?.messagesDetails?.filter(({ isRead }) => !isRead)?.length ||
            0
        );
      }
      setIsLoader(false);
    } catch (err) {
      console.log(err);
      setIsLoader(false);
    }
  };
  const get_sent_email = async (filter) => {
    setIsLoader(true);
    try {
      const res = await api.post("/api/google_email/sent_email_list", filter);
      if (res.status === 200) {
        setEmailList(Array.isArray(res.data?.messagesDetails) ? { ...res.data, draft: false } : []);
        setEmailCount(
          res.data?.messagesDetails?.filter(({ isRead }) => !isRead)?.length ||
            0
        );
      }
      setIsLoader(false);
    } catch (err) {
      console.log(err);
      setIsLoader(false);
    }
  };
  const get_draft = async (filter) => {
    setIsLoader(true);
    try {
      const res = await api.post("/api/google_email/draft_email_list", filter);
      if (res.status === 200) {
        setEmailList(prevState => {
          return Array.isArray(res.data?.messagesDetails)
            ? { ...res.data, draft: true }
            : [];
        });
        setEmailCount(
          res.data?.messagesDetails?.filter(({ isRead }) => !isRead)?.length ||
            0
        );
      }
      setIsLoader(false);
    } catch (err) {
      console.log(err);
      setIsLoader(false);
    }
  };
  React.useEffect(() => {
    get_list(selectedPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inboxDetails = (data) => {
    if (data && !data.isRead && emailList?.draft === false) {
      const payload = {
        uid: data.uid,
      };
      try {
        api.post("/api/google_email/mark_email_as_read", payload);
      } catch (err) {
        console.log(err);
      }
    }
    if (+emailCount) {
      setEmailCount(+emailCount - 1);
    }
    setSelectedEmail(data);
  };
  const onReset = () => {
    setSelectedPayload(initalData);
    get_list(initalData);
  };
  function decodeBase64UrlSafe(base64String) {
    let normalizedBase64 = base64String.replace(/-/g, "+").replace(/_/g, "/");
    while (normalizedBase64.length % 4 !== 0) {
      normalizedBase64 += "=";
    }
    try {
      const byteArray = Uint8Array.from(atob(normalizedBase64), (c) =>
        c.charCodeAt(0)
      );
      return new TextDecoder("utf-8").decode(byteArray);
    } catch (e) {
      console.error("Invalid Base64 input:", e);
      return null;
    }
  }
  const renderContent = (content) => {
    if (!content) return "";
    content = content.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "");
    content = content.replace(/<script[^>]*>[\s\S]*?<\/script>/gi, "");
    content = content.replace(/<[^>]+>/g, "");
    return content.trim();
  };
  const openFormHandler = (content) => {
    if (isSynced) {
      openModal(true);
    }else{
      toast.error("gmail account not synced!");
    }
  };
  return (
    <div>
      {isLoading ? <DotsLoader /> : null}
      {selectedEmail && (
        <EmailDetail
          selectedEmail={selectedEmail}
          modalTitle="Mail Content"
          onCancelForm={() => setSelectedEmail(null)}
        />
      )}
      {isLoader && <DotsLoader />}
      <div className="h-ful overflow-x-auto">
        <table className="table-auto text-black w-full bg-white ">
          <tr className="border-b">
            <td className="font-semibold !border-b">
              <div className="flex justify-between !border-b pb-1">
                <div className="grid grid-cols-6 gap-x-2 flex items-center">
                  <FormInput
                    placeholder={"Subject Text"}
                    onChange={handleChange}
                    name="subjectText"
                    value={selectedPayload?.subjectText}
                  />
                  <FormInput
                    placeholder={"From Email"}
                    onChange={handleChange}
                    name="fromEmail"
                    value={selectedPayload?.fromEmail}
                  />
                  <FormInput
                    placeholder={"No. of Mails"}
                    onChange={handleChange}
                    name="numberOfMails"
                    value={selectedPayload?.numberOfMails}
                  />
                  <FormInput
                    placeholder={"Email Date"}
                    onChange={handleChange}
                    name="emailDate"
                    value={selectedPayload?.emailDate}
                    type={"date"}
                  />
                  <div className="col-span-2">
                    <Button
                      text={"Search"}
                      className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
                      onClick={() =>
                        get_list(selectedPayload)
                      }
                    />
                    <Button
                      text="Reset"
                      className="py-1.5 px-3 align-middle bg-menu text-white"
                      onClick={onReset}
                    />
                  </div>
                </div>
              </div>
              <header className="px-2 pt-2 flex items-center justify-between">
                <div className="flex items-center gap-x-3">
                  <button
                    className="flex items-center border rounded gap-x-1 px-2 py-1 shadow hover:shadow-lg"
                    onClick={() => openFormHandler(null)}
                  >
                    <MdOutlineAddCircleOutline /> Compose
                  </button>
                  <button
                    className={`flex items-center border rounded gap-x-1 px-2 py-1 shadow hover:shadow-lg ${
                      selectedType === "Inbox" ? "bg-gray-600 text-white" : ""
                    }`}
                    onClick={() => {
                      setSelectedType("Inbox");
                      get_list(selectedPayload);
                    }}
                  >
                    <FaInbox /> Inbox
                  </button>
                  <button
                    className={`flex items-center border rounded gap-x-1 px-2 py-1 shadow hover:shadow-lg ${
                      selectedType === "Draft" ? "bg-gray-600 text-white" : ""
                    }`}
                    onClick={() => {
                      setSelectedType("Draft");
                      get_draft({ timeZone: getTimezoneString() });
                    }}
                  >
                    <MdOutlineDrafts /> Draft
                  </button>
                  <button
                    className={`flex items-center border rounded gap-x-1 px-2 py-1 shadow hover:shadow-lg ${
                      selectedType === "Sent" ? "bg-gray-600 text-white" : ""
                    }`}
                    onClick={() => {
                      setSelectedType("Sent");
                      get_sent_email(selectedPayload);
                    }}
                  >
                    <FiSend /> Sent
                  </button>
                </div>
              </header>
            </td>
          </tr>
          {isSynced && (
            <EmailComposerModal
              title={"Compose Email"}
              isOpen={isModalOpen}
              closeModal={closeModal}
              handleSubmit={handleSubmit}
              fileChange={handleFileChanged}
              removeFile={removeFile}
              selectedFiles={Array.from(selectedfiles)}
              onChangeCc={(data)=>{setCc(data)}}
              onChangeBcc={(data)=>{setBcc(data)}}
          >
          </EmailComposerModal>
          )}
          {emailList?.messagesDetails &&
          emailList?.messagesDetails?.length === 0 ? (
            <tr className="text-center border-0">
              <td className="text-center border-0">
                <strong>No Record found</strong>
              </td>
            </tr>
          ) : null}

          {emailList?.messagesDetails?.map((item) => {
            return emailList?.messagesDetails?.length ? (
              <tr
                className={`overflow-x-auto text-black flex cursor-pointer items-center hover:bg-whiten dark:hover:bg-boxdark-2 border-b border-neutral-300 shadow-sm ${
                  item?.isRead ? "bg-white" : "bg-[#f2f6fc]"
                }`}
                onClick={() => {
                  inboxDetails(item);
                }}
              >
                <td className=" py-2 pl-4 pr-4 lg:pl-10 border-0 w-fit">
                  <div className="w-[200px] flex cursor-pointer select-none items-center text-sm font-medium sm:text-base">
                    <span className="font-semibold ml-3 text-sm truncate text-black">
                      {item?.from || item?.to}
                    </span>
                  </div>
                </td>
                <td className="w-[calc(100vw-800px)] text-sm truncate flex py-2 pl-4 pr-1 lg:pl-10 border-0 text-black">
                  <span className="truncat font-medium text-nowrap mr-1">
                    {item?.subject}
                    {" - "}
                  </span>
                  <p className="truncate text-blac text-sm max">
                    {renderContent(decodeBase64UrlSafe(item?.mailcontent))}
                  </p>
                </td>
                <td className="min-w-[100px] py-2 pl-4 pr-4 lg:pr-10 border-0">
                  <p className="text-center text-xs xl:text-sm truncate">
                    {dayjs(item?.time).format("ddd, MMM D, YYYY h:mm A")}
                  </p>
                </td>
              </tr>
            ) : (
              <tr>
                <td>No Record found...</td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};
export default GoogleMails;
