import React, { useEffect, useState } from "react";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import api from "../../services/api";
import { useLocation, useParams } from "react-router-dom";
import AddDidForm from "./AddDidForm";
const NationDidListing = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  //const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [didsList, setDidList] = useState([]);

  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const domain = location.state;
  const cancelFormHandler = () => {
    //setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    //setEditingRecord(record);
    setIsEditing(true);
  };
  const get_dids = async () => {
    setisLoading(true);
    try {
      const res = await api.get(`api/nation/did/${id}`);
      if (res.status === 200) {
        setDidList(res.data);
      }
      setisLoading(false);
    } catch (err) {
      setisLoading(false);
      console.log("🚀 ~ VendorsModal ~ err:", err);
    }
  };
  useEffect(() => {
    get_dids();
    // eslint-disable-next-line
  }, []);
  const dids = didsList?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });

  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    { headerName: "Area Code", field: "area_code", minWidth: 150 },
    { headerName: "DID", field: "phone", flex: 1 },
  ];
  return (
    <>
      <PageHeader
        heading="National Site DIDs Listing"
        onClick={openFormHandler({ id })}
        isAllowed={userPermission("Add National Site DID")}
        breadcrumb={`/nation_site/sites/${domain}`}
      />
      {isEditing && (
        <AddDidForm
          national_site_id={id}
          modalTitle="Add National Site DIDs"
          onCancelForm={cancelFormHandler}
          get_dids={get_dids}
        />
      )}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={dids?.map((record, index) => {
            let counter = index + 1;
            const { phone, area_code } = record;
            return {
              counter,
              phone,
              area_code,
              id: index + 1,
            };
          })}
          totalItems={didsList?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default NationDidListing;
