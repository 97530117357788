import React from "react";

const ValueComponent = React.memo((props) => {
  const { columns, operators, filter, index, handleFilterChange } = props;

  const dataTypes =
    columns?.find(({ field }) => field === filter.field)?.type ?? "string";
  const selectedColumn = columns.find((col) => col.field === filter.field);
  const additionalOperators = selectedColumn?.additionalOperators || [];

  const allOperators = [...operators[dataTypes], ...additionalOperators];
  const selectedOperator = allOperators.find(
    (op) => op.value === filter.operator
  );
  if (!selectedOperator) {
    return null;
  }
  //const { InputComponent } = selectedOperator;
  if (
    selectedOperator?.InputComponent &&
    (selectedOperator?.type || selectedColumn?.type) &&
    (selectedOperator?.type === "multiSelect" ||
      selectedColumn?.type === "multiSelect")
  ) {
    return (
      <div className="custom-multiselect" id="custom-multiselect">
        <selectedOperator.InputComponent
          value={filter.value}
          onChange={(e) => handleFilterChange(index, "value", e.value)}
          options={selectedColumn?.valueOptions}
          className="!bg-white !border-0 !rounded-none !border-b !border-neutral-300 !focus:border-neutral-700 !w-[130px] !ring-0 !focus:ring-0 !focus:outline-none"
          optionLabel={selectedColumn?.label}
          optionValue={selectedColumn?.value}
          panelClassName="!p-0"
          maxSelectedLabels={1}
        />
      </div>
    );
  }
  if (
    (selectedOperator?.type || selectedColumn?.type) &&
    (selectedOperator?.type === "select" || selectedColumn?.type === "select")
  ) {
    const options = selectedColumn?.valueOptions || [];
    return (
      <select
        className="bg-white border-b border-neutral-300 focus:border-neutral-700 w-[130px]"
        value={filter.value}
        onChange={(e) => handleFilterChange(index, "value", e.target.value)}
      >
        <option value="">Select value</option>
        {options.map((option, idx) => (
          <option key={idx} value={option[selectedColumn?.value]}>
            {`${option[selectedColumn?.label]} ${
              option[selectedColumn?.additionallabel]
                ? option[selectedColumn?.additionallabel]
                : ""
            }`}
          </option>
        ))}
      </select>
    );
  }
  if (
    (selectedOperator?.type || selectedColumn?.type) &&
    (selectedOperator?.type === "multiSelect" ||
      selectedColumn?.type === "multiSelect")
  ) {
    const options = selectedColumn?.valueOptions || [];

    return (
      <select
        className="bg-white border-b border-neutral-300 focus:border-neutral-700 w-[130px]"
        value={filter.value}
        onChange={(e) => handleFilterChange(index, "value", e.target.value)}
      >
        <option value="">Select value</option>
        {options.map((option, idx) => (
          <option key={idx} value={option[selectedColumn?.value]}>
            {`${option[selectedColumn?.label]} ${
              option[selectedColumn?.additionallabel]
                ? option[selectedColumn?.additionallabel]
                : ""
            }`}
          </option>
        ))}
      </select>
    );
  }
  return (
    <input
      className="border-b border-neutral-300 focus:border-neutral-700 w-[130px] text-xs"
      type={filter.type}
      placeholder="Filter Value"
      value={filter.value}
      onChange={(e) => handleFilterChange(index, "value", e.target.value)}
    />
  );
});

export default ValueComponent;
