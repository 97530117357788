import { Dropdown } from "primereact/dropdown";
import React from "react";

function AutoRenewModal({
  onClose,
  setRenewStatus,
  handleConfirm,
  renewStatus,
}) {
  const statusOption = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];

  return (
    <>
      <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-50 bg-black/60">
        <div className="bg-white p-8 rounded-lg shadow-lg relative w-[400px]">
          <h2 className="text-lg font-semibold mb-4">Set auto renew domain</h2>
          <label className="block">Please select a status:</label>
          <Dropdown
            placeholder="Select an status"
            options={statusOption}
            optionLabel="label"
            optionValue="value"
            value={renewStatus}
            onChange={(e) => setRenewStatus(e.value)}
            className="w-full"
          />

          <div className="flex justify-end !mt-4">
            <button
              className="px-4 py-2 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={handleConfirm}
            >
              OK
            </button>
            <button
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default AutoRenewModal;
