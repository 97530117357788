import React, { useState, useEffect, useCallback, useRef } from "react";
import { FixedSizeList as List } from "react-window";
import Loader from "../../../components/atoms/Loader";

const VirtualizedMultiSelectBox = ({
  labelProp,
  valueProp,
  data,
  selected,
  onSelect,
  label,
  innerWidth = 400,
  innerHeight = 200,
  className,
  itemsPerRow = 1,
  truncateText = true,
}) => {
  const [localSelectedValues, setLocalSelectedValues] = useState(
    new Set(selected || [])
  );
  const [filterQuery, setFilterQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const selectAllRef = useRef(null);

  useEffect(() => {
    setLocalSelectedValues(new Set(selected || []));
  }, [selected]);

  const handleSelectionChange = useCallback(
    (selectedValue) => {
      setLocalSelectedValues((prevSelected) => {
        const newSelected = new Set(prevSelected);
        if (newSelected.has(selectedValue)) {
          newSelected.delete(selectedValue);
        } else {
          newSelected.add(selectedValue);
        }
        onSelect(Array.from(newSelected));
        return newSelected;
      });
    },
    [onSelect]
  );

  const filteredData = filterQuery
    ? data.filter((item) =>
        item[labelProp]?.toLowerCase().includes(filterQuery.toLowerCase())
      )
    : data;

  const handleSelectAllChange = () => {
    setIsLoading(true);

    setLocalSelectedValues((prevSelected) => {
      const allFilteredValues = new Set(
        filteredData.map((item) => item[valueProp])
      );
      const isAllSelected = filteredData.every((item) =>
        prevSelected.has(item[valueProp])
      );

      const newSelected = isAllSelected
        ? new Set(
            [...prevSelected].filter((val) => !allFilteredValues.has(val))
          )
        : new Set([...prevSelected, ...allFilteredValues]);

      onSelect(Array.from(newSelected));
      return newSelected;
    });
  };

  useEffect(() => {
    if (localSelectedValues) {
      setIsLoading(false);
    }
  }, [localSelectedValues]);

  useEffect(() => {
    if (selectAllRef.current) {
      const allFilteredValues = filteredData.map((item) => item[valueProp]);
      const selectedCount = allFilteredValues.filter((val) =>
        localSelectedValues.has(val)
      ).length;

      selectAllRef.current.checked =
        selectedCount === allFilteredValues.length && selectedCount > 0;
      selectAllRef.current.indeterminate =
        selectedCount > 0 && selectedCount < allFilteredValues.length;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSelectedValues, filteredData]);

  const groupItems = (items) => {
    let grouped = [];
    for (let i = 0; i < items.length; i += itemsPerRow) {
      grouped.push(items.slice(i, i + itemsPerRow));
    }
    return grouped;
  };

  const Row = React.memo(({ index, style }) => {
    const groupedData = groupItems(filteredData)[index];

    return (
      <div style={style} className="p-2 flex flex-wrap">
        {groupedData.map((option) => {
          const isChecked = localSelectedValues.has(option[valueProp]);

          return (
            <div
              key={option[valueProp]}
              className="flex items-center flex-wrap w-1/3 p-2"
            >
              <label className="flex items-center space-x-2 w-full">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => handleSelectionChange(option[valueProp])}
                  className="mr-1 h-4 w-4 cursor-pointer"
                />
                <span
                  className={`text-sm font-medium ${
                    truncateText ? "truncate" : "whitespace-normal break-words"
                  }`}
                  title={option[labelProp]}
                >
                  {option[labelProp]}
                </span>
              </label>
            </div>
          );
        })}
      </div>
    );
  });

  return (
    <div className={className}>
      <div className="mb-1 text-lg font-semibold">
        {label} ({data?.length})
      </div>
      <div className="border p-2 rounded-lg">
        <div className="mb-2">
          <input
            type="text"
            placeholder="Search..."
            value={filterQuery}
            onChange={(e) => setFilterQuery(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        {filteredData.length > 0 && (
          <div className="p-2 border-b mb-2">
            {isLoading ? (
              <span className="mb-4 flex justify-center items-center w-full">
                <Loader linear="linear" />
              </span>
            ) : null}
            <label className="font-medium">
              <input
                type="checkbox"
                ref={selectAllRef}
                onChange={handleSelectAllChange}
                className="mr-2 h-4 w-4 cursor-pointer"
              />
              Select All
            </label>
          </div>
        )}

        {filteredData.length === 0 ? (
          <div className="text-center text-gray-500 text-sm font-semibold">
            No records found
          </div>
        ) : (
          <div className="max-h-[300px]">
            <div className="overflow-x-auto">
              <List
                height={innerHeight}
                width={innerWidth}
                itemCount={Math.ceil(filteredData.length / itemsPerRow)}
                itemSize={35}
                itemData={filteredData}
                overscanCount={5}
              >
                {Row}
              </List>
            </div>
          </div>
        )}
      </div>

      {localSelectedValues.size > 0 && data?.length ? (
        <div className="mt-1 flex justify-between items-center text-sm text-yellow-800 rounded-lg ml-2 font-semibold">
          Selected {label} : {localSelectedValues.size}
        </div>
      ):null}
    </div>
  );
};

export default VirtualizedMultiSelectBox;
