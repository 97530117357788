import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const CustomSelectGroup = ({
  options = [],
  placeholder,
  value,
  name,
  onChange,
}) => {
  return (
    <div className="inline-flex text-left p-1 btnSecondary text-menu h-8  items-center">
      <ArrowDropDownIcon
        className={`mr-1 ${value ? "" : "text-gray-400"}`}
        fontSize="large"
      />
      <select
        className={`bg-transparent border-none py-2 px-2 w-40 cursor-pointer ${
          value ? "text-black" : "text-gray-400"
        }`}
        name={name}
        onChange={(e) => onChange(e)}
        value={value}
      >
        <option className="text-gray-400" value="">
          {placeholder}
        </option>
        {options.length > 0 &&
          options.map((company) =>
            company.campaigns?.length > 0 ? (
              <optgroup
                key={company.company_name}
                label={company.company_name || ""}
                style={{
                  color: company.company_name ? "black" : "gray", 
                }}
              >
                {company.campaigns.map((campaign) => (
                  <option
                    key={campaign.campaign_id.affiliate_id}
                    value={campaign.campaign_id.affiliate_id}
                    className={`${
                      value === campaign.campaign_id.affiliate_id
                        ? "text-black"
                        : "text-gray-400"
                    }`}
                  >
                    {campaign.campaign_id.title}
                  </option>
                ))}
              </optgroup>
            ) : null
          )}
      </select>
    </div>
  );
};

export default CustomSelectGroup;
