import React, { useEffect, useState } from "react";
import { FaEdit, FaTrashAlt, FaSearch } from "react-icons/fa";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton } from "@mui/material";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import AddUpdateLeadPage from "./AddUpdatePage";
import api from "../../services/api";
import { getLeadComapigns } from "../../services/leadCampaign";
const NationSiteRating = () => {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isCampaignArr, setCampaignArr] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [leadCampaigns, setLeadCampaigns] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });

  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const openFormHandler = (record) => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const getLeadSourceListing = async (filter) => {
    setIsLoading(true);
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const res = await api.post("api/lead_source", payload);
      if (res.status === 200) {
        setRecords(res.data);
      }
    } catch (err) {
      console.error("Error fetching lead sources:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLeadSourceListing({
      ...paginationModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
    });
    getLeadCampaigns();
    // eslint-disable-next-line
  }, []);

  const deleteRecordHandler = async (record_id) => {
    const confirmDelete = window.confirm(
      "Are you sure to perform this action?"
    );
    if (confirmDelete) {
      setIsLoading(true);
      try {
        const res = await api.delete(`api/lead_source/${record_id}`);
        if (res?.status === 200) {
          getLeadSourceListing();
          toast.success(
            res?.data?.message || "Lead source deleted successfully"
          );
        } else {
          toast.error("Failed to delete the lead source. Please try again.");
        }
      } catch (error) {
        toast.error(
          error?.response?.data?.message ||
            "An error occurred. Please try again."
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const LeadCampaignCellRenderer = (data) => {
    const isExpanded = isCampaignArr?.includes(data?.row?._id);
    const values = isExpanded ? data?.value : [];
    const tags = values?.map((tag, index) => (
      <span
        key={tag.id}
        className="cursor-pointer"
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      >
        {index + 1}. {tag?.campaign_id?.title}
      </span>
    ));

    return (
      <div className="flex flex-col">
        <div className="flex flex-col w-auto">{tags}</div>
        {data?.value?.length && !isExpanded ? (
          <div
            className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
            onClick={() => {
              setCampaignArr((prevArray) => [...prevArray, data.row._id]);
            }}
          >
            See More ({data.value.length})
          </div>
        ) : null}
        {!data?.value?.length ? (
          <div className="flex flex-col w-auto ml-3 cursor-pointer">N/A</div>
        ) : null}
        {isExpanded && (
          <div
            className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
            onClick={() => {
              setCampaignArr((prevArray) =>
                prevArray.filter((id) => id !== data.row._id)
              );
            }}
          >
            See Less
          </div>
        )}
      </div>
    );
  };

  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false, width: 20 },
    { headerName: "Full Name", field: "full_name", width: 150 },
    { headerName: "Company Name", field: "company_name", width: 150 },
    {
      headerName: "Active",
      field: "isActive",
      minWidth: 120,
      filterable: true,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
      ],
      renderCell: (params) => (
        <div className="capitalize">{params.row.isActive ? "Yes" : "No"}</div>
      ),
      valueGetter: (params) => params.row?.isActive,
      valueFormatter: (params) => params.value,
    },
    { headerName: "Email", field: "email", flex: 1, width: 150 },
    {
      headerName: "Lead Campaign",
      field: "lead_source_campaign_id",
      renderCell: LeadCampaignCellRenderer,
      flex: 1,
      minWidth: 200,
      filterable: false,
    },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          <FaEdit
            onClick={() => openFormHandler(params.row.records)}
            className="my_navIcon"
            title="Update Rating"
          />

          <FaTrashAlt
            onClick={() => deleteRecordHandler(params.row.records._id)}
            className="my_navIcon"
            title="Delete Rating"
          />
        </div>
      ),
      width: 100,
      filterable: false,
    },
  ];

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getLeadSourceListing({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      setPaginationModel({ ...paginationModel, page: 1 });
      getLeadSourceListing({
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });

    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadListing = () => {
    setEditingRecord(null);
    setIsEditing(false);
    getLeadSourceListing();
  };

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };

  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getLeadSourceListing({
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }

  const getRowHeight = (params) => {
    const rowHeight = 40; // minimum height of the row
    const cellContentHeight = 0; // height of the cell content
    const data = params?.model;

    // Handle height for lead_source_campaign_id
    if (
      data?.lead_source_campaign_id &&
      data?.lead_source_campaign_id.length > 0
    ) {
      if (!isCampaignArr?.includes(data?._id)) return rowHeight + 15;
      const tagHeight = 25; // height of each tag
      const numTags = data.lead_source_campaign_id.length;
      return rowHeight + tagHeight * numTags + cellContentHeight;
    }

    return rowHeight;
  };

  const getLeadCampaigns = async () => {
    try {
      // setIsLoading(true);
      const res = await getLeadComapigns();
      if (res.status === 200) {
        setLeadCampaigns(res.data);
      }
    } catch (err) {
      console.log(err);
    } 
  };

  return (
    <>
      <PageHeader
        heading="Leads Source Listing"
        onClick={() => openFormHandler(null)}
        isAllowed={true}
      />
      {isEditing && (
        <AddUpdateLeadPage
          editingRecord={editingRecord}
          modalTitle={editingRecord ? "Update Lead Source" : "Add Lead Source"}
          onCancelForm={cancelFormHandler}
          reloadListing={reloadListing}
          getLeadSourceListing={getLeadSourceListing}
          leadCampaigns={leadCampaigns}
        />
      )}

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={records?.records?.map((record, index) => {
            let counter = index + 1;
            let _id = record._id;
            const {
              full_name,
              company_name,
              email,
              isActive,
              affiliate_settings,
              webQueryCount,
              createdBy,
              phoneNumberCounts,
            } = record;
            const campaignData = [];
            affiliate_settings?.forEach((setting) => {
              if (setting?.campaign_id) {
                campaignData.push(setting?.campaign_id);
              }
            });
            return {
              _id,
              id: _id,
              counter,
              records: { ...record },
              full_name,
              company_name,
              email,
              isActive,
              inboundPhoneNumbers: affiliate_settings?.inboundPhoneNumbers,
              WebForm: affiliate_settings?.WebForm,
              webQueryCount,
              createdBy,
              phoneNumberCounts,
              webSource: affiliate_settings?.webSource,
              lead_source_campaign_id: affiliate_settings,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          searchable="No"
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          totalItems={records.totalItems}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          gridOptions={getRowHeight}
        />
      </div>
    </>
  );
};

export default NationSiteRating;
