import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { DotsLoader } from "../../components";
import PageHeader from "../../components/molecules/PageHeader";
import { get_industry } from "../../features/IndustrySlice";
import {
  get_nation_site_zips,
  nationSiteZipsReset,
} from "../../features/nationSitesSlice";
import AddUpdateZips from "./AddUpdateZips";
import ImportExcel from "./ImportData";

function NationSiteZips() {
  const { isLoading, nationZips } = useSelector((state) => state.nation);
  const { isLoading: loader, record } = useSelector((state) => state?.industry);
  const sortedIndustries = [...(record?.records || [])]?.sort((a, b) =>
    a?.name.localeCompare(b?.name)
  );
  const [isAddZip, setIsAddZip] = useState(false);
  const [isImportModal, setIsImportModal] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      get_industry({ data: { page: 1, size: 100, national_site_industry: 1 } })
    );
    return () => {
      dispatch(nationSiteZipsReset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openFormHandler = (record) => {
    setEditingRecord(record);
    setIsAddZip(true);
  };
  const closeFormHandler = () => {
    setEditingRecord(null);
    setIsAddZip(false);
    setIsImportModal(false);
  };
  return (
    <div>
      {isLoading || loader ? <DotsLoader text="Please Wait" /> : null}
      {isAddZip ? (
        <AddUpdateZips
          modalTitle="Add/Update Zips"
          onCancelForm={closeFormHandler}
          editingRecord={editingRecord}
          getData={get_nation_site_zips}
        />
      ) : null}
      {isImportModal ? (
        <ImportExcel
          modalTitle="Import Zips"
          onCancelForm={closeFormHandler}
          editingRecord={editingRecord}
          getData={get_nation_site_zips}
        />
      ) : null}
      <PageHeader
        heading="Nation Site Zips"
        onClick={() => setIsAddZip(true)}
        isAllowed={true}
        buttonTitle={`Add Zips`}
        extraButton={true}
        extraButtonClick={() => setIsImportModal(true)}
        additionalBtnTitle={"Import Zips"}
        additionalBtnAllow={true}
        addIcon="No"
        editIcon="No"
      />
      <div className="bg-white !my-3 p-3 border rounded ">
        <div className="items-center !m-2 bg-slate-50 border rounded p-3">
          <div className="flex flex-row items-center gap-x-4">
            <div className="w-full">
              <select
                onChange={(e) => dispatch(get_nation_site_zips(e.target.value))}
                className="border p-1 rounded h-[35px] ml-2 w-ful"
              >
                <option value="">Select Industry</option>
                {sortedIndustries.map((option) => (
                  <option
                    key={option.cms_id}
                    value={option.cms_id}
                    className="flex justify-between"
                  >
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {nationZips[0]?.industry_id && nationZips?.length > 0 ? (
          nationZips?.map((item, index) => {
            return (
              <details key={index} className="border mt-3 mb-3" open>
                <summary className="!flex !flex-row w-full items-center text-normal px-2 font-semibold font-pop justify-between border-b hover:cursor-pointer bg-gray-100 hover:bg-gray-300 py-2 px-1">
                  <span className="text-normal px-2 font-semibold font_pop">{`${
                    sortedIndustries?.find(
                      ({ cms_id }) => cms_id === item?.industry_id
                    )?.name
                  } | Total Zips: ${item.zips?.length}`}</span>
                  <FaEdit
                    className="cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      openFormHandler(item);
                    }}
                  />
                </summary>

                <div
                  className={`form-control !inline-flex !flex-wrap relative min-h-[40px] max-h-[430px] h-aut overflow-y-auto !rounded-t-none`}
                >
                  {item?.zips?.map((zip, ind) => {
                    return (
                      <div
                        className="badge !me-1 !pe-1 me-1 pe-1 !flex items-center justify-between bg-secondary !text-white !mr-2 my-0.5 h-[26.39px]"
                        key={ind}
                      >
                        {zip}
                      </div>
                    );
                  })}
                </div>
              </details>
            );
          })
        ) : (
          <div className="flex justify-center items-center w-full">
            <span className="text-center text-lg font_pop font-bold">
              No record found
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default NationSiteZips;
