import React, { useEffect, useState, useRef } from "react";
import { DotsLoader } from "../../components";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaChevronUp } from "react-icons/fa";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { TbListDetails } from "react-icons/tb";
import DidsListing from "./DidsListing";
import api from "../../services/api";
import errorMessage from "../../util/errorMessage";
import { get_dids } from "../../features/didSlice";
import { useSelector, useDispatch } from "react-redux";
const Listing = ({
  settingsData,
  openFormHandler,
  reloadCampignListing,
  affiliates,
  industryDrd,
  dialerUsers,
  merchants,
  inbound,
  callManu,
  openDIDToCCHandler,
  openAffiliateUpload,
}) => {
  const optionsRef = useRef();
  const dispatch = useDispatch();
  //const [sqlQuerry, setSqlQuerry] = useState("");
  const [idsArray, setIdsArray] = useState([]);
  const [showMenu, setShowMenu] = useState(null);
  const { record } = useSelector((state) => state.dids);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [affiliateId, setAffiliatedId] = useState("");
  const [didListingData, setDidListingData] = useState([]);
  const [isDomainArr, setDomainsArr] = useState([]);
  const paginationModel = {
    pageSize: 500,
    page: 1,
  };

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  const [visibleRecords, setVisibleRecords] = useState(new Set());
  const isOpen = (id) => visibleRecords.has(id);
  const toggleListVisibility = (id) => {
    setVisibleRecords((prevVisibleRecords) => {
      const newVisibleRecords = new Set(prevVisibleRecords);
      if (newVisibleRecords.has(id)) {
        newVisibleRecords.delete(id);
      } else {
        newVisibleRecords.add(id);
      }
      return newVisibleRecords;
    });
  };

  const testMenu = [
    {
      label: "Edit / View",
      action: (item) => {
        openFormHandler(item);
      },
      permission: ``,
    },
    {
      label: "Add DID To CC",
      action: (item) => {
        openDIDToCCHandler(item);
      },
      permission: ``,
    },
    {
      label: "Upload Domain / Phone No",
      action: (item) => {
        openAffiliateUpload(item);
      },
      permission: ``,
    },
    {
      label: "Send Email",
      action: (item) => {
        sendEmailHandler(item?._id);
      },
      permission: ``,
    },
    {
      label: "Delete",
      action: (item) => deleteRecordHandler(item?._id),
      permission: ``,
    },
  ];

  const renderItemHeader = (label, Icon) => (
    <div className="font-bold border-b border-neutral-300 pb-1 flex items-center gap-x-2 mb-1 text-black">
      {Icon ? <Icon size={18} /> : null}
      {label ?? ""}
    </div>
  );

  const handleHideDropdown = (event) => {
    if (showMenu && event.key === "Escape") {
      setShowMenu(null);
    }
  };

  const handleClickOutside = (event) => {
    if (
      showMenu &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target)
    ) {
      setShowMenu(null);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);

  const deleteRecordHandler = async (record_id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      setIsLoading(true);
      try {
        const res = await api.delete(
          `api/lead_source/delete_setting/${record_id}`
        );
        if (res?.status === 200) {
          toast.success(res?.data?.message || "Lead deleted successfully");
          reloadCampignListing();
        } else {
          errorMessage({
            payload: res.data,
            action: "Lead",
            msg: "deleted",
          });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const sendEmailHandler = async (record_id) => {
    try {
      setIsLoading(true);
      const res = await api.get(`/api/lead_source/sendmail/${record_id}`);

      if (res.status === 200 || res.status === 201) {
        toast.success(res?.data?.message || "Email sent successfully.");
      } else {
        toast.error("Failed to send email. Please try again.");
      }
    } catch (err) {
      console.error("Error sending email:", err);
      toast.error(
        "An error occurred while sending the email. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedNumber?.length > 0) {
      const queryString = {
        sqlwhere: `did_pattern IN ("${selectedNumber.join('","')}")`,
      };
      //setSqlQuerry(queryString.sqlwhere);
      dispatch(
        get_dids({
          page: paginationModel.page,
          size: selectedNumber?.length || paginationModel.pageSize,
          sqlwhere: queryString.sqlwhere,
        })
      );
    }
  }, [
    selectedNumber,
    paginationModel.page,
    paginationModel.pageSize,
    dispatch,
  ]);

  useEffect(() => {
    const didFilter = record?.data;

    if (affiliateId) {
      if (didFilter && Array.isArray(didFilter) && didFilter.length > 0) {
        setDidListingData((prevData) => ({
          ...prevData,
          [affiliateId]: [...(prevData[affiliateId] || []), ...didFilter],
        }));
      } else {
        setDidListingData((prevData) => ({
          ...prevData,
          [affiliateId]: [],
        }));
      }
    }
  }, [record, affiliateId]);

  const getDidListingData = (affiliateId, inboundPhoneNumbers) => {
    setSelectedNumber(inboundPhoneNumbers);
    setAffiliatedId(affiliateId);
  };

  const handleClick = (leadId) => {
    const batchUrl = `/lead_source/webquery?lead_id=${leadId}`;
    window.open(batchUrl, "_blank");
  };

  const handleOpenTab = (did) => {
    const url = new URL(
      "/lead_source/affiliate_inbound_reports",
      window.location.origin
    );
    url.searchParams.append("did_number", did);
    const tabName = `inboundReportTab`;
    let existingTab = window.open("", tabName);
    if (!existingTab || existingTab.closed) {
      existingTab = window.open(url, tabName);
    } else {
      existingTab.location.href = url;
      existingTab.focus();
    }
  };

  const TagsCellRenderer = (data, phoneNumberCounts) => {
    const values =
      data?.inboundPhoneNumbers && Array.isArray(data.inboundPhoneNumbers)
        ? data.inboundPhoneNumbers.slice(
            0,
            idsArray.includes(data._id) ? data.inboundPhoneNumbers.length : 1
          )
        : [];

    const tags = values?.map((tag, index) => (
      <span
        onClick={() => handleOpenTab(tag)}
        key={tag}
        className="cursor-pointer"
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      >
        {index + 1}. {tag} (
        <span className="text-blue-700">{phoneNumberCounts?.[tag]}</span>)
      </span>
    ));

    return (
      <div className="flex flex-col">
        <div className="flex flex-col w-auto">{tags}</div>
        {data?.inboundPhoneNumbers?.length > 1 && (
          <div
            className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
            onClick={() => {
              setIdsArray((prevArray) => {
                if (prevArray.includes(data._id)) {
                  return prevArray.filter((id) => id !== data._id);
                } else {
                  return [...prevArray, data._id];
                }
              });
            }}
          >
            {idsArray.includes(data._id)
              ? `See Less`
              : `See More (${data?.inboundPhoneNumbers?.length - 1})`}
          </div>
        )}
      </div>
    );
  };

  const DomainCellRenderer = (data) => {
    const values =
      data?.webSource && Array.isArray(data.webSource)
        ? data.webSource.slice(
            0,
            isDomainArr.includes(data._id) ? data.webSource.length : 1
          )
        : [];

    const tags = values?.map((tag, index) => (
      <span
        key={tag}
        className="cursor-pointer"
        style={{
          margin: "2px",
          color: "black",
          borderRadius: 2,
          padding: "0.2em 0.6em 0.3em",
          lineHeight: 1,
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      >
        {index + 1}. {tag}
      </span>
    ));

    return (
      <div className="flex flex-col">
        <div className="flex flex-col w-auto">{tags}</div>
        {data?.webSource?.length > 1 && (
          <div
            className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer"
            onClick={() => {
              setDomainsArr((prevArray) => {
                if (prevArray.includes(data._id)) {
                  return prevArray.filter((id) => id !== data._id);
                } else {
                  return [...prevArray, data._id];
                }
              });
            }}
          >
            {isDomainArr.includes(data._id)
              ? `See Less`
              : `See More (${data?.webSource?.length - 1})`}
          </div>
        )}
      </div>
    );
  };

  const handleOpenPhoneNumber = (affiliateId) => {
    const url = new URL("/callcenter/dids", window.location.origin);
    url.searchParams.append("campaign_id", affiliateId);
    const tabName = "leadSourceTab";
    let existingTab = window.open("", tabName);
    if (!existingTab || existingTab.closed) {
      existingTab = window.open(url, tabName);
    } else {
      existingTab.location.href = url;
      existingTab.focus();
    }
  };

  return (
    <>
      {isLoading ? <DotsLoader /> : null}

      <div>
        {settingsData?.affiliate_settings?.length ? (
          settingsData?.affiliate_settings?.map((item, index) => {
            const {
              _id,
              inboundPhoneNumbers,
              campaign_id,
              token,
              WebForm,
              publisher_id,
              createdAt,
            } = item;
            const webQueryCount = settingsData?.webQueryCount;
            return (
              <div className="mx-4 bg-white dark:bg-gray-800 last:border-none dark:border-white/20 !py-0 mb-2 shadow-sm border border-neutral-200 mt-2 ">
                <div className="px-6 !py-3 flex bg-white border-b border-neutral-200 items-center justify-between flex-wrap gap-2">
                  <div className="flex items-center justify-between text-sm gap-3 !text-black">
                    <p className="font-bold text-black w-5">
                      {offset + index + 1}.
                    </p>

                    <div className="w-80">
                      <b className="text-black">{"Campaign Name"}:</b>
                      <span className="ml-1">{campaign_id?.title}</span>
                    </div>

                    <div className="w-40">
                      <b className="text-black">{"Token"}:</b>
                      <span className="ml-1">{token}</span>
                    </div>
                    <div className="flex items-center w-auto">
                      <b className="text-black">{"Phone No"}:</b>

                      <button
                        onClick={() => handleOpenPhoneNumber(_id)}
                        className="flex flex-col w-auto ml-3 text-blue-600 cursor-pointer font-semibold"
                      >
                        See More
                      </button>
                    </div>
                    <div className="w-46 ml-4">
                      <b className="text-black">{"WEB Form"}:</b>
                      <span className="ml-1">
                        {WebForm && !webQueryCount ? (
                          <span className="py-1 px-2 !rounded hover:cursor-pointer ml-3">
                            Yes
                          </span>
                        ) : (
                          <span
                            className={`py-1 px-2 !rounded ml-3 ${
                              webQueryCount > 0
                                ? "bg-[#93c5fd] hover:cursor-pointer"
                                : ""
                            }`}
                            onClick={() => {
                              if (webQueryCount > 0) {
                                handleClick(settingsData?._id);
                              }
                            }}
                          >
                            {WebForm
                              ? `Yes${
                                  webQueryCount > 0 ? ` (${webQueryCount})` : ""
                                }`
                              : `No${
                                  webQueryCount > 0 ? ` (${webQueryCount})` : ""
                                }`}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="ml-auto flex items-center gap-3">
                    <div className="w-46 mr-5">
                      <b className="text-black">{"Publisher"}:</b>
                      <span className="ml-1">{publisher_id}</span>
                    </div>
                    <div className="w-46">
                      <b className="text-black">{"Date"}:</b>
                      <span className="ml-1">
                        {dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A")}
                      </span>
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isOpen(index)) {
                          getDidListingData(_id, inboundPhoneNumbers);
                        }
                        toggleListVisibility(index);
                      }}
                    >
                      <FaChevronUp
                        className={`w-5 transition-transform transform text-gray-400 ${
                          isOpen(index) && "rotate-180"
                        }`}
                      />
                    </button>
                    <div className="relative justify-end">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();

                          setShowMenu(item?._id);
                        }}
                      >
                        <BsThreeDotsVertical className="cursor-pointer hover:text-primary-100" />
                      </button>
                      {showMenu === item?._id ? (
                        <div
                          ref={optionsRef}
                          className="absolute w-auto right-1 min-w-[150px] rounded shadow !mt-1 bg-white z-20 border-x border-y border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150"
                        >
                          <ul className="!pl-0 !mb-0">
                            {testMenu?.map((option, index) => (
                              <li
                                key={index}
                                className="cursor-pointer px-3 !py-1.25 border- border-[#ddd] hover:bg-[#e1e1e1] text-xs"
                                onClick={() => option.action(item)}
                              >
                                {option.label}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div
                  className={`px-6 overflow-hidden transition-max-height duration-500 ease-in-out ${
                    isOpen(index) ? "max-h-screen" : "max-h-0"
                  }`}
                >
                  <div className="grid grid-cols-1 text-sm md:grid-cols-1 lg:grid-cols-1 gap-x-4 gap-y-2 border dark:border-white/20 pt-1 mt-1 pb-2">
                    {/* <div className="mb-2 max-h-[150px] overflow-y-auto">
                      {renderItemHeader("Phone Numbers", TbListDetails)}
                      {TagsCellRenderer(item, settingsData?.phoneNumberCounts)}
                    </div> */}

                    <div className="mb-2 max-h-[150px] overflow-y-auto">
                      {renderItemHeader("Domain", TbListDetails)}
                      {DomainCellRenderer(item)}
                    </div>
                  </div>

                  {/* {isOpen(index) && inboundPhoneNumbers?.length ? (
                    <DidsListing
                      campaignId={_id}
                      affiliates={affiliates}
                      industryDrd={industryDrd}
                      dialerUsers={dialerUsers}
                      merchants={merchants}
                      inbound={inbound}
                      callManu={callManu}
                      record={record}
                      didListingData={didListingData}
                    />
                  ) : null} */}
                </div>
              </div>
            );
          })
        ) : (
          <div className="w-full flex justify-center">
            <strong>No record found</strong>
          </div>
        )}
      </div>
    </>
  );
};

export default Listing;
