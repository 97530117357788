import api from "./api";
export const getNumbers = async (data) => {
  return await api.get("/api/fromNumbers", data);
};

export const createNumber = async (data) => {
  return await api.post(`/api/fromNumbers`, data);
};

export const updateNumber = async (data) => {
    let payload = { ...data };
    delete payload.id;
    return await api.put(`/api/fromNumbers/${data.id}`, payload);
  };

export const deleteNumber = async (id) => {
  return await api.delete(`/api/fromNumbers/${id}`);
};
