import { useState } from "react";
import { FormInput, FormSelectInput } from "../../components";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useDispatch } from "react-redux";
import {
  resetConversation,
  smsCenterResetComm,
} from "../../features/smsCenterCommSlice";
const ChatSection = ({ startConversation, didList }) => {
  const [isNewConversationOpen, setIsNewConversationOpen] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      to_number: "",
      from_number: "",
    },
    validationSchema: Yup.object({
      to_number: Yup.string()
        .required("Please select an SMS number")
        .matches(
          /^\d{1}-\d{3}-\d{3}-\d{4}$/,
          "Please enter a valid number in the format 1-XXX-XXX-XXXX"
        ),
      from_number: Yup.string().required("Please select an SMS number"),
    }),
    onSubmit: async (values) => {
      const formattedData = {
        from_number: "1" + values?.from_number,
        to_number: values.to_number.replace(/-/g, ""),
      };
      startConversation(formattedData);
    },
  });

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    if (cleaned.length === 10) {
      return `1 (${cleaned.substring(0, 3)}) ${cleaned.substring(
        3,
        6
      )}-${cleaned.substring(6)}`;
    }
    return phoneNumber;
  };

  return (
    <>
      <div className="bg-white p-2 rounded-lg shadow-md">
        <button
          onClick={() => setIsNewConversationOpen(!isNewConversationOpen)}
          className="text-blue-500 font-semibold text-md flex items-center gap-2"
        >
          New Conversation
          {isNewConversationOpen ? <FiChevronUp /> : <FiChevronDown />}
        </button>

        {isNewConversationOpen && (
          <form
            onSubmit={formik.handleSubmit}
            className="bg-gray-100 p-2 rounded-md mt-2 text-md"
          >
            <h6 className="font-semibold text-gray-700">
              NEW SMS CONVERSATION
            </h6>

            <div className="flex gap-4 mt-4 items-center">
              <div className="flex-1">
                <InputMask
                  mask="1-XXX-XXX-XXXX"
                  value={formik.values.to_number}
                  onChange={(e) =>
                    formik.setFieldValue("to_number", e.target.value)
                  }
                  name="to_number"
                  maskChar="X"
                  formatChars={{ X: "[0-9]" }}
                >
                  {(inputProps) => (
                    <FormInput
                      {...inputProps}
                      label="Start Conversation With"
                      value={formik.values.to_number}
                    />
                  )}
                </InputMask>

                <div className="text-red-500 text-xs mt-1 min-h-[1.25rem]">
                  {formik.errors.to_number &&
                    formik.touched.to_number &&
                    formik.errors.to_number}
                </div>
              </div>

              <div className="flex-1">
                <FormSelectInput
                  label="My SMS Number"
                  name="from_number"
                  placeholder="Select SMS Number"
                  options={didList?.map((item) => ({
                    value: item?.did,
                    label: formatPhoneNumber(item?.did),
                  }))}
                  labelProp={"label"}
                  valueProp={"value"}
                  value={formik.values.from_number}
                  onChange={(e) => formik.setFieldValue("from_number", e)}
                />
                <div className="text-red-500 text-xs mt-1 min-h-[1.25rem]">
                  {formik.errors.from_number &&
                    formik.touched.from_number &&
                    formik.errors.from_number}
                </div>
              </div>
              <div className="w-full sm:w-auto md:w-auto flex gap-2 mb-4">
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 text-sm"
                >
                  Start Conversation
                </button>
                <button
                  type="button"
                  onClick={() => {
                    formik.setValues({ to_number: "", from_number: "" });
                    startConversation("");
                    dispatch(resetConversation());
                    dispatch(smsCenterResetComm());
                  }}
                  className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-primary-100 text-sm ml-2"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default ChatSection;
