import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DotsLoader } from "../../../../components";
import MUIDataTable from "../../../../components/molecules/DataTable/muigrid";
import PageHeader from "../../../../components/molecules/PageHeader";
import AddUpdateForm from "./AddUpdateForm";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { FaTrashAlt } from "react-icons/fa";
import { FaPhoneSlash, FaDownload, FaFileExport } from "react-icons/fa6";
import { HiOutlineRefresh } from "react-icons/hi";
import { FaPlus, FaSync } from "react-icons/fa";
import { MdAddCall } from "react-icons/md";
import { Dropdown } from "react-bootstrap";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaSearch } from "react-icons/fa";
import { MdOutlineWebhook } from "react-icons/md";
import { Button as MUIButton } from "@mui/material";
import DownloadExcelForm from "./DownloadExcelForm";
import ExportDidToCallcenter from "./ExportDidToCallcenter";
import { get_cc_cards, get_cc_providers } from "../../../../features/didSlice";
import { get_industry_drd } from "../../../../features/IndustrySlice";
import { get_dialer_users } from "../../../../features/usersSlice";
import { useSelector, useDispatch } from "react-redux";
import { get_inbound_group } from "../../../../features/inboundGroupSlice";
import Swal from "sweetalert2";
import UnregisterDids from "../UnregisterDids";
import ImportDIDsModal from "./ImportDIDsModal";
import AddDidToMsgCenter from "./AddDidToMsgCenter";
import MessageModal from "../../../Merchant/MessageModal";
import AddDidToMerchant from "./AddDidToMerchant";
import CustomMuiTextInput from "./CustomMuiTextInput ";
import CustomMuiSelectInput from "./CustomMuiSelectInput";
import { get_lead_affiliates } from "../../../../features/leadSourceSlice";
function PurchaseDids() {
  let users = localStorage.getItem("user");
  users = users ? JSON.parse(users) : null;
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [callManu, setCallMenu] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoader, setIsLoading] = React.useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [didList, setDidList] = useState([]);
  const [isDownloadExcel, setDownloadExcel] = React.useState(false);
  const [isSyncToInteqliquent, setSyncToInteqliquent] = React.useState(false);
  const [isAddingToMS, setIsAddingToMS] = useState(false);
  const { isLoading } = useSelector((state) => state.dids);
  const [isUnregisterDids, setUnregisterDids] = React.useState(false);
  const [isImportForm, setIsImportForm] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const { affiliates } = useSelector((state) => state.leadSource);
  const [isCustomMessage, setCustomMessage] = React.useState({
    status: false,
    did: null,
  });
  const [isMerchantModalData, setMerchantModalData] = React.useState({
    status: false,
    did: null,
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({});
  const cancelFormHandler = () => {
    setIsEditing(false);
    setDownloadExcel(false);
    setSyncToInteqliquent(false);
    setIsAddingToMS(false);
    setUnregisterDids(false);
    setIsImportForm(false);
  };

  const get_call_menus = async () => {
    try {
      const res = await api.get("/api/cc/dids/options/call_menus");
      if (res.status === 200) {
        setCallMenu(res.data);
      }
    } catch (err) {
      console.log("🚀 ~ constget_call_menus=async ~ err:", err);
    }
  };
  const getDidList = async (filters) => {
    const payload = {
      page: filters.page,
      limit: filters.limit,
      ...(filters.filters || {}),
    };

    setIsLoading(true);
    try {
      const res = await api.post("/api/cc/dids/purchase_did_list", payload);
      if (res.status === 200) {
        setDidList(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    dispatch(
      get_lead_affiliates({
        page: 1,
        size: 100,
      })
    );
    dispatch(get_industry_drd());
    dispatch(get_cc_cards());
    dispatch(get_cc_providers());
    dispatch(get_dialer_users());
    dispatch(get_inbound_group());
    get_call_menus();
    getDidList({ page: 1, limit: paginationModel.pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openFormHandler = (record) => () => {
    setIsEditing(true);
  };

  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = didList?.data?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };

  const onSingleSelect = ({ checked, data }) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          if (!prevSelectedData.some(({ _id }) => _id === data._id)) {
            return [...prevSelectedData, data];
          }
          return prevSelectedData;
        });
      } else {
        setSelectedData((prevSelectedData) => {
          // Remove item from the selected list
          return prevSelectedData.filter(({ _id }) => _id !== data._id);
        });
      }
    } catch (err) {
      console.error("Error in onSingleSelect:", err);
    }
  };

  const isSelected = (data) => {
    return selectedData.some(({ _id }) => _id === data._id);
  };

  const generateFilterOperators = (
    operators,
    CustomInputComponent,
    options = []
  ) => {
    return operators.map((value) => ({
      label:
        value === "contains"
          ? "contains"
          : value === "eq"
          ? "equals"
          : value === "neq"
          ? "not equals"
          : value === "exists"
          ? "exists"
          : value,
      value,
      InputComponent: CustomInputComponent,
      InputComponentProps: {
        options,
      },
    }));
  };

  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            didList?.data?.length &&
            selectedData?.length === didList?.data?.length
          }
          disabled={!didList?.data?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.records)}
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            })
          }
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 10, filterable: false },
    {
      headerName: "DID",
      field: "did",
      flex: 1,
      minWidth: 100,
      filterOperators: generateFilterOperators(
        ["eq", "neq", "contains", "exists"],
        CustomMuiTextInput
      ),
    },
    {
      headerName: "DID Type",
      field: "phone_type",
      minWidth: 150,
      type: "singleSelect",
      filterOperators: generateFilterOperators(
        ["eq", "neq", "contains", "exists"],
        CustomMuiSelectInput,
        [
          { value: "Call", label: "Call" },
          { value: "SMS", label: "SMS" },
        ]
      ),
      renderCell: (params) => {
        return (
          <>
            <div className="flex flex-col">
              {params?.row?.phone_type === "SMS" ? (
                <>
                  <button
                    onClick={() => updateDIDToMerchant(params)}
                    className="w-full inline-flex items-center justify-start space-x-1"
                    title="Add DID to Merchant"
                  >
                    <span className="flex items-center hover:cursor-pointer text-sm">
                      <span>{params?.value}</span>
                      <MdAddCall className="w-5 h-5 ml-1" />
                    </span>
                  </button>

                  <small
                    onClick={() => handleTriggerSms(params)}
                    title="Trigger Test SMS"
                    className="text-blue-500 hover:cursor-pointer text-xs mt-2"
                  >
                    Click here to Trigger SMS
                  </small>
                </>
              ) : (
                <span>{params?.value}</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      headerName: "Status",
      field: "tnStatus",
      flex: 1,
      minWidth: 100,
      filterOperators: generateFilterOperators(
        ["eq", "neq", "contains", "exists"],
        CustomMuiTextInput
      ),
      renderCell: (params) => (
        <div className="flex flex-col">
          {params?.row?.deleted_on ? (
            <span>Deleted On</span>
          ) : (
            params.row.tnStatus
          )}
          {params?.row?.deleted_on ? (
            <span>
              {dayjs(params?.row?.deleted_on).format("ddd, MMM D, YYYY h:mm A")}
            </span>
          ) : null}
        </div>
      ),
    },
    {
      headerName: "Purch. For",
      field: "purchased_for",
      flex: 1,
      minWidth: 100,
      filterOperators: generateFilterOperators(
        ["eq", "neq", "contains", "exists"],
        CustomMuiTextInput
      ),
    },
    {
      headerName: "Call Center Status",
      field: "callCenter_status",
      flex: 1,
      minWidth: 130,
      type: "singleSelect",
      filterOperators: generateFilterOperators(
        ["eq", "neq", "contains", "exists"],
        CustomMuiSelectInput,
        [
          { value: "Synced", label: "Synced" },
          { value: "Not-Synced", label: "Not-Synced" },
        ]
      ),
      renderCell: (params) => {
        return (
          <>
            <div className="flex flex-col">
              {params?.row?.callCenter_status}
            </div>
          </>
        );
      },
    },
    {
      headerName: "Provider",
      field: "provider",
      flex: 1,
      minWidth: 100,
      filterable: true,
      filterOperators: generateFilterOperators(
        ["eq", "neq", "contains", "exists"],
        CustomMuiTextInput
      ),
    },
    {
      headerName: "Description",
      field: "description",
      minWidth: 150,
      filterOperators: generateFilterOperators(
        ["eq", "neq", "contains", "exists"],
        CustomMuiTextInput
      ),
      renderCell: (params) => {
        const brandName =
          campaignList?.find(
            (item) => item.externalCampaignId === params?.row?.message_campaign
          )?.messagingBrandName || "Not found";
        return (
          <>
            <div className="flex flex-col">
              {params?.row?.phone_type === "SMS" ? (
                <>
                  <span className="flex items-center hover:cursor-pointer text-sm">
                    <span>{params?.row?.description}</span>
                  </span>
                  <small
                    className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded mt-2 ${
                      params?.row?.message_campaign
                        ? "bg-indigo-100 text-indigo-800"
                        : ""
                    }`}
                  >
                    {params?.row?.message_campaign ? brandName : "N/A"}
                  </small>
                </>
              ) : (
                <span>{params?.row?.description}</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      headerName: "Message Campaign",
      field: "message_meta.campaign",
      minWidth: 120,
      filterable: true,
      filterOperators: generateFilterOperators(
        ["eq", "neq", "contains", "exists"],
        CustomMuiTextInput
      ),
      renderCell: (params) => {
        return (
          <>
            <div className="flex flex-col">
              {params?.row?.message_meta.campaign || "N/A"}
            </div>
          </>
        );
      },
    },
    {
      headerName: "Message Desc",
      field: "message_meta.description",
      minWidth: 150,
      filterable: true,
      filterOperators: generateFilterOperators(
        ["eq", "neq", "contains", "exists"],
        CustomMuiTextInput
      ),
      renderCell: (params) => {
        return (
          <>
            <div className="flex flex-col">
              {params?.row?.message_meta.description || "N/A"}
            </div>
          </>
        );
      },
    },
    {
      headerName: "Delete Desc",
      field: "delete_description",
      flex: 1,
      minWidth: 100,
      filterable: true,
      filterOperators: generateFilterOperators(
        ["eq", "neq", "contains", "exists"],
        CustomMuiTextInput
      ),
    },
    {
      headerName: "Created At",
      field: "created_at",
      flex: 1,
      minWidth: 120,
      filterable: false,
    },
  ];

  const onFormSubmit = () => {
    setIsEditing(false);
    getDidList({ page: 1, limit: paginationModel.pageSize });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel?.items?.length === 0) {
      getDidList({
        page: 1,
        limit: paginationModel.pageSize,
      });
    }

    const filters = filterModel.items?.map((rule) => {
      return {
        [`${rule.field}`]: {
          [rule.operator]: rule.value,
        },
      };
    });

    setQueryOptions((prevState) => {
      const newQueryOptions = filters.reduce((acc, filter) => {
        return { ...acc, ...filter };
      }, {});
      return newQueryOptions;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getDidList({
      filters: queryOptions,
      page: 1,
      limit: paginationModel.pageSize,
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
        <Dropdown drop="down">
          <Dropdown.Toggle
            id="dropdown-basic"
            className="rounded-0 w-full md:w-auto py-1. px- align-middle border-0 bg-transparent text-black focus:!shadow-none text-[13px] font-medium hover:text-black active:text-black"
            style={{ color: "black" }}
          >
            ACTIONS
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-fit min-w-[400px] max-h-[300px] overflow-y-auto transform -translate-x-1/2 flex flex-col px-1.5">
            <button
              className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-black"
              onClick={unregisteredDid}
            >
              <div className="flex flex-row items-center pl-1">
                <FaPhoneSlash size={18} />
                <span className="ml-2">
                  Unregister DID from service provider
                </span>
              </div>
            </button>
            <button
              className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
              onClick={downloadExcel}
            >
              <div className="flex flex-row items-center pl-1">
                <FaDownload size={18} />
                <span className="ml-2">Export</span>
              </div>
            </button>
            <button
              className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
              onClick={syncToInteliquent}
              title="Add DID To Call Center"
            >
              <div className="flex flex-row items-center pl-1">
                <FaFileExport size={18} />
                <span className="ml-2">Add DID to Call Center</span>
              </div>
            </button>
            <button
              className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
              onClick={handleAddToMS}
              title="Add DID to Message Center"
            >
              <div className="flex flex-row items-center pl-1">
                <FaFileExport size={18} />
                <span className="ml-2"> Add DID to Message Center</span>
              </div>
            </button>
            <button
              className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
              onClick={handleSyncCCstatus}
              title="Sync Call Center Status"
            >
              <div className="flex flex-row items-center pl-1">
                <FaSync size={18} />
                <span className="ml-2"> Sync Call Center Status</span>
              </div>
            </button>
            <button
              className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
              onClick={handleBulkDelete}
              title="Delete DIDs from Call Center"
            >
              <div className="flex flex-row items-center pl-1">
                <FaTrashAlt size={16} />
                <span className="ml-2"> Delete DIDs from Call Center</span>
              </div>
            </button>
            <button
              className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
              onClick={handleWebhookToRecSms}
              title="Enable web-hook to receive SMS"
            >
              <div className="flex flex-row items-center pl-1">
                <MdOutlineWebhook size={18} />
                <span className="ml-2"> Enable Webhook</span>
              </div>
            </button>
          </Dropdown.Menu>
        </Dropdown>
      </GridToolbarContainer>
    );
  }

  const unregisteredDid = async () => {
    if (selectedData?.length) {
      setUnregisterDids(true);
    } else {
      toast.error("Please select at least one DID.");
    }
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getDidList({
      filters: queryOptions,
      page: +params.page + 1,
      limit: params.pageSize,
    });
  };

  const downloadExcel = async () => {
    if (selectedData?.length) {
      setDownloadExcel(true);
    } else {
      toast.error("Please select at least one DID.");
    }
  };

  const syncToInteliquent = async () => {
    if (selectedData?.length) {
      setSyncToInteqliquent(true);
    } else {
      toast.error("Please select at least one DID.");
    }
  };

  const handleAddToMS = async () => {
    if (selectedData?.length) {
      setIsAddingToMS(true);
    } else {
      toast.error("Please select at least one DID.");
    }
  };

  const exportFormSubmit = () => {
    setSyncToInteqliquent(false);
    getDidList({ page: 1, limit: paginationModel.pageSize });
  };

  const handleBulkDelete = async () => {
    if (!selectedData?.length) {
      toast.error("Please select at least one DID.");
      return;
    }

    Swal.fire({
      title: "Are you sure to perform this action?",
      text: "DIDs will be deleted from the Call Center only.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        popup: "w-90 p-4 text-sm",
        title: "text-lg font-bold",
        content: "text-sm font-semibold",
        confirmButton: "text-sm py-2 px-4",
        cancelButton: "text-sm py-2 px-4",
      },
    }).then(async (result) => {
      if (!result.isConfirmed) return;
      setIsLoading(true);
      try {
        const updatedArray = selectedData.map((data) => ({
          did: "1" + data.did,
        }));

        let searchCondition = [];
        searchCondition.push(
          `did_pattern IN (${updatedArray.map((d) => `"${d.did}"`).join(",")})`
        );

        const searchStr =
          searchCondition.length > 0 ? searchCondition.join(` AND `) : "";

        const res = await api.post(`/api/cc/dids/get_dids`, {
          page: paginationModel?.page,
          size: paginationModel?.pageSize,
          sqlwhere: searchStr,
        });
        if (res?.status === 200 || res?.status === 201) {
          const [did_data, didPattern] = res.data?.data?.reduce(
            (acc, record) => {
              acc[0].push({ did_id: record?.did_id });
              acc[1].push(record?.did_pattern.slice(-10));
              return acc;
            },
            [[], []]
          );

          if (!did_data.length || !didPattern.length) {
            toast.error("No data available for deletion.");
            return;
          }

          try {
            const { data } = await api.post("/api/cc/dids/bulk_delete", {
              did_data: did_data,
              did_patterns: didPattern,
            });

            if (data?.status) {
              setSelectedData([]);
              toast.success(data?.message);
              getDidList({
                filters: queryOptions,
                page: 1,
                limit: paginationModel.pageSize,
              });
            } else {
              toast.error("Data couldn't be deleted");
            }
          } catch (err) {
            toast.error("An error occurred during deletion");
            console.error(err);
          }
        } else {
          toast.error("Failed to retrieve DIDs for deletion");
        }
      } catch (err) {
        toast.error("An error occurred while fetching DIDs");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    });
  };

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;

  const CustomButtons = () => {
    return (
      <div className="flex space-x-1">
        <button
          className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white float-right flex items-center rounded-0"
          onClick={openFormHandler(0)}
          // disabled={selectedData?.length > 0 ? false : true}
        >
          <FaPlus className="mr-2" />
          Add New
        </button>
        {/* <button
          className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white float-right flex items-center rounded-0"
          onClick={() => setIsImportForm(true)}
        >
          <FaFileImport className="mr-2" />
          Import DIDs
        </button> */}
        <button
          className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white float-right flex items-center rounded-0"
          onClick={() => {
            getDidList({
              filters: queryOptions,
              page: 1,
              size: paginationModel.pageSize,
            });
            setSelectedData([]);
          }}
        >
          <HiOutlineRefresh className="w-5 h-5" />
        </button>
      </div>
    );
  };

  const reloadListing = () => {
    setIsImportForm(false);
    getDidList({
      filters: queryOptions,
      page: 1,
      limit: paginationModel.pageSize,
    });
  };

  const getRowHeight = (params) => {
    const rowHeight = 40;
    const data = params?.model;
    const numberOfLines = data?.deleted_ondeleted_on
      ? Math.ceil(Object.keys(data?.deleted_on).length / 40)
      : 0;
    const auctionHeight = rowHeight + numberOfLines * 30 + 20;
    return auctionHeight;
  };

  const handleTriggerSms = (params) => {
    setCustomMessage({
      ...isCustomMessage,
      status: true,
      did: "1" + params?.row?.did,
    });
  };

  const updateDIDToMerchant = (params) => {
    getMerchantList();
    setMerchantModalData({
      ...isMerchantModalData,
      status: true,
      did: "1" + params?.row?.did,
    });
  };

  const getMerchantList = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/api/merchants/${users?.current_merchant_id}`);
      if (res.status === 200 || res.status === 201) {
        setMerchants(res?.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleSyncCCstatus = async () => {
    const c = window.confirm(`Are you sure want to sync Call Center status?`);
    if (!c) return;
    try {
      const res = await api.post("/api/cc/dids/syncDidsWithCC");
      if (res?.status === 200 || res?.status === 201) {
        toast.success(`Call Center syncing status has started successfully.`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleWebhookToRecSms = async () => {
    if (!selectedData?.length) {
      toast.error("Please select at least one DID.");
      return;
    }
    Swal.fire({
      title: "Are you sure want to Enable Webhook to receive SMS?",
      text: "DIDs will be enabled for the Webhook to receive SMS.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, enable it!",
      customClass: {
        popup: "w-90 p-4 text-sm",
        title: "text-lg font-bold",
        content: "text-sm font-semibold",
        confirmButton: "text-sm py-2 px-4",
        cancelButton: "text-sm py-2 px-4",
      },
    }).then(async (result) => {
      if (!result.isConfirmed) return;
      setIsLoading(true);
      const payload = {
        numbers: selectedData?.map((item) => "1" + item.did),
      };
      try {
        const res = await api.post(
          "/api/cc/dids/enable_did_to_recieve_sms",
          payload
        );
        if (res?.status === 200 || res?.status === 201) {
          toast.success(
            `Webhook to receive SMS has been enabled successfully.`
          );
        }
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await api.post("/api/cc/dids/getsmscampaign");
        if (res?.status === 200) {
          setCampaignList(
            res?.data?.result?.messagingBrandCampaignList
              ?.messagingBrandCampaignItem || []
          );
        }
      } catch (error) {
        toast.error("Failed to fetch DIDs");
        console.error(error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);
  return (
    <>
      {isLoader ? <DotsLoader /> : null}
      <PageHeader heading="Purchase DIDs" CustomButtons={CustomButtons} />
      {isEditing && (
        <AddUpdateForm
          modalTitle="Purchase DIDs"
          onCancelForm={cancelFormHandler}
          onFormSubmit={onFormSubmit}
        />
      )}

      {isDownloadExcel ? (
        <DownloadExcelForm
          modalTitle="Export Excel"
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
        />
      ) : null}
      {isUnregisterDids ? (
        <UnregisterDids
          modalTitle="Unregister DID from service provider"
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
          methodType="purchase_dids"
          onFormSubmit={onFormSubmit}
        />
      ) : null}
      {isSyncToInteqliquent ? (
        <ExportDidToCallcenter
          modalTitle="Export DID To Call Center"
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
          callManu={callManu}
          exportFormSubmit={exportFormSubmit}
          affiliates={affiliates}
        />
      ) : null}
      {isAddingToMS ? (
        <AddDidToMsgCenter
          modalTitle="Export DID To Message Center"
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
          campaignList={campaignList}
        />
      ) : null}

      {isImportForm && (
        <ImportDIDsModal
          modalTitle="Import DIDs"
          onCancelForm={cancelFormHandler}
          reloadListing={reloadListing}
        />
      )}
      {isCustomMessage?.status && (
        <MessageModal
          onClosemodal={() =>
            setCustomMessage({ ...isCustomMessage, status: false, did: null })
          }
          isCustomMessage={isCustomMessage}
        />
      )}
      {isMerchantModalData?.status && merchants?._id ? (
        <AddDidToMerchant
          modalTitle="Add DID To Merchant"
          onCancelForm={() => {
            setMerchantModalData({
              ...isMerchantModalData,
              status: false,
              did: null,
            });
            setMerchants([]);
          }}
          selectedData={isMerchantModalData}
          editingRecord={merchants}
        />
      ) : null}
      <div className="bg-white !my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={didList?.data?.map((item, index) => {
            let counter = index + offset + 1;
            let created_at = dayjs(item?.createdAt).format(
              "ddd, MMM D, YYYY h:mm A"
            );
            const {
              did,
              provider,
              phone_type,
              purchased_for,
              deleted_on,
              description,
              tnStatus,
              callCenter_status,
              message_campaign,
              delete_description,
              message_meta,
            } = item;
            return {
              records: { ...item },
              counter,
              did,
              provider,
              phone_type,
              purchased_for,
              deleted_on,
              description : typeof description === 'string' ? description : 'N/A',
              created_at,
              tnStatus,
              callCenter_status,
              message_campaign,
              delete_description,
              message_meta,
            };
          })}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          searchable="No"
          totalItems={didList?.total}
          //isLoading={isLoader}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          gridOptions={getRowHeight}
          height="calc(100vh - 260px)"
        />
      </div>
    </>
  );
}

export default PurchaseDids;
