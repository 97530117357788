import React, { useEffect, useState } from "react";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import api from "../../services/api";
import PageHeader from "../../components/molecules/PageHeader";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaSearch } from "react-icons/fa";
import { Button as MUIButton } from "@mui/material";
import { get_countries } from "../../features/countriesSlice";
import { getStateList } from "../../services/statesService";
import { useSelector, useDispatch } from "react-redux";
const ZipListing = () => {
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.countries);
  const [record, setRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [stateList, setStateList] = useState([]);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false, minWidth: 20 },
    { headerName: "Zip", field: "zip", flex: 1 },
    { headerName: "Area Code", field: "area_code", flex: 1 },
    {
      headerName: "Country",
      field: "country_id",
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?._id,
      getOptionLabel: (option) => option.name,
      valueOptions: countries,
      renderCell: (params) => params?.row?.country_id?.name,
    },
    {
      headerName: "State",
      field: "state_id",
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?._id,
      getOptionLabel: (option) => option.name,
      valueOptions: stateList,
      renderCell: (params) => params?.row?.state_id?.name,
    },
    {
      headerName: "City",
      field: "city_name",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => params?.row?.city_id?.name,
    },
    { headerName: "Zip Population", field: "zip_population", flex: 1 },
    { headerName: "Latitude", field: "latitude", flex: 1, type: "number" },
    { headerName: "Longitude", field: "longitude", flex: 1, type: "number" },
  ];

  const getCountriesList = async (filter) => {
    setIsLoading(true);
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const res = await api.post("api/location_db/zip_list", payload);
      if (res?.status === 200 || res?.status === 201) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error || "An error occurred");
      } else {
        toast.error(err?.response?.data || "An error occurred");
      }
    }
  };
  const fetchStateList = async () => {
    try {
      const response = await getStateList();
      setStateList(response?.data?.records);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        await Promise.all([getCountriesList(), fetchStateList()]);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    dispatch(get_countries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listing = record?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="flex items-center space-x-1 my-0">
        <div className="justify-between">
          <GridToolbarColumnsButton className="!text-[#042a42]" />
          <GridToolbarDensitySelector className="!text-[#042a42]" />
          <GridToolbarFilterButton
            ref={setFilterButtonEl}
            className="!text-[#042a42]"
          />
          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{ fontSize: "0.8125rem" }}
            className={"!text-[#042a42]"}
          >
            Apply filter
          </MUIButton>
        </div>
      </GridToolbarContainer>
    );
  }

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    let payload = {
      page: 1,
      size: paginationModel.pageSize,
    };
    if (queryOptions.groupOp && queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    }
    getCountriesList({ ...payload });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getCountriesList({
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getCountriesList({
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  return (
    <>
      <PageHeader heading="Zip Listing" />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={listing?.map((record, index) => {
            let counter = offset + index + 1;
            const {
              _id,
              zip,
              area_code,
              zip_population,
              latitude,
              longitude,
              country_id,
              state_id,
              city_id,
            } = record;
            return {
              counter,
              records: { ...record },
              _id,
              zip,
              area_code,
              zip_population,
              latitude,
              longitude,
              country_id: country_id || {},
              state_id: state_id || {},
              city_id: city_id || {},
            };
          })}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          CustomToolbar={CustomToolbar}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
        />
      </div>
    </>
  );
};

export default ZipListing;
