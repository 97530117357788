import React, { useState } from "react";
import api from "../../services/api";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { FaInfoCircle, FaSync, FaSearch } from "react-icons/fa";
import { Tooltip, IconButton, Button as MUIButton } from "@mui/material";
import dayjs from "dayjs";
import exportReport from "../../components/molecules/ExcelReport";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { toast } from "react-toastify";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
function DIDsInventory() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  dayjs.extend(duration);
  dayjs.extend(utc);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const getDidInventory = async (filter) => {
    setIsLoading(true);
    try {
      const res = await api.post("/api/connectcx/getCxDids", filter);
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getDidInventory ~ err:", err);
    }
  };
  const getSyncWithConnex = async () => {
    const c = window.confirm(`Are you sure want to sync data with Connex?.`);
    if (!c) return;
    try {
      const res = await api.get("/api/connectcx/syncCxDids");
      if (res?.status === 201) {
        toast.success(`Data syncing has started successfully.`);
        let payload = {
          page: 1,
          size: paginationModel.pageSize,
        };
        if (queryOptions.groupOp && queryOptions.rules.length > 0) {
          payload.filters = queryOptions;
        }
        getDidInventory({ ...payload });
      }
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    let payload = {
      page: 1,
      size: paginationModel.pageSize,
    };
    if (queryOptions.groupOp && queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    }
    getDidInventory({ ...payload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnDefs = [
    { headerName: "#", field: "counter", width: 100, filterable: false },

    {
      headerName: "DID",
      field: "did",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div className="flex justify-center items-center w-full">
            <Tooltip
              title={
                params?.row?.cc_data !== null ? (
                  <div className="text-xs">
                    {params?.row?.cc_data !== null &&
                      Object.entries(params?.row?.cc_data).map(
                        ([key, value]) => (
                          <div className="cc-data-item" key={key}>
                            <span className="cc-data-key">{key} :</span>
                            <span className="cc-data-value ml-2">
                              {value || "N/A"}
                            </span>
                          </div>
                        )
                      )}
                  </div>
                ) : (
                  <div>No Records.</div>
                )
              }
            >
              {params.row.did}
              <IconButton size="small" style={{ marginLeft: 8 }}>
                <FaInfoCircle />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      headerName: "Customer",
      field: "customer",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Destination Server IP",
      field: "destination_server_ip",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Destination",
      field: "destination",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "CC Call Count",
      field: "cc_data.total_count",
      renderCell: (params) => params.row?.total_count,
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "CC User Group",
      field: "cc_data.user_group",
      renderCell: (params) => params.row?.user_group,
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "CC Site Name",
      field: "cc_data.did_description",
      renderCell: (params) => params.row?.did_description,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "inteliquent_phone_status",
      column: "",
      headerName: "Inteliquent Phone Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: [
        { value: "InService", label: "InService" },
        { value: "Deleted", label: "Deleted" },
        { value: "Not Available", label: "Not Available" },
      ],
      renderCell: (params) => params.value?.inteliquent_phone_status,
      valueGetter: (params) => params.row?.inteliquent_phone_status,
      valueFormatter: (params) => params.value?.inteliquent_phone_status,
    },
    {
      headerName: "CC Industry",
      field: "cc_data.menu_name",
      renderCell: (params) => params.row?.menu_name,
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "CC DID Route",
      field: "cc_data.did_route",
      renderCell: (params) => params.row?.did_route,
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "CC Status",
      field: "cc_data.did_active",
      renderCell: (params) => params.row?.did_active,
      flex: 1,
    },
  ];
  const batchList = record?.data;

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
  };

  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });

    let payload = {
      page: +params.page + 1,
      size: params.pageSize,
    };
    if (queryOptions.groupOp && queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    }
    getDidInventory({ ...payload });
  };

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    let payload = {
      page: 1,
      size: paginationModel.pageSize,
    };
    if (queryOptions.groupOp && queryOptions.rules.length > 0) {
      payload.filters = queryOptions;
    }
    getDidInventory({ ...payload });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      let payload = {
        page: 1,
        size: paginationModel.pageSize,
      };
      if (queryOptions.groupOp && queryOptions.rules.length > 0) {
        payload.filters = queryOptions;
      }
      getDidInventory({ ...payload });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : // : rule.operator === "isEmpty"
                // ? "eq"
                rule.operator,
          data:
            rule.field === "latest_position"
              ? +rule.value
              : rule.value
              ? rule.value
              : rule.value === 0
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="flex items-center space-x-1 my-0">
        <div className="justify-between">
          <GridToolbarColumnsButton className="!text-[#042a42]" />
          <GridToolbarDensitySelector className="!text-[#042a42]" />
          <GridToolbarFilterButton
            ref={setFilterButtonEl}
            className="!text-[#042a42]"
          />
          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{ fontSize: "0.8125rem" }}
            className={"!text-[#042a42]"}
          >
            Apply filter
          </MUIButton>
          <MUIButton
            variant="text"
            onClick={getSyncWithConnex}
            sx={{ fontSize: "0.8125rem" }}
            className={"!text-[#042a42]"}
          >
            {" "}
            Sync With Connex <FaSync className="ml-1 w-5" />
          </MUIButton>
        </div>
        <div className="text-md text-gray-700 justify-between">
          <p className="text-orange-600 font-semibold">
            {record?.lastSyncDetail != null && record?.lastSyncDetail?.isRunning
              ? `running from ${displayTimeDifference(
                  record?.lastSyncDetail?.lastStartTime
                )}`
              : ""}
            {record?.lastSyncDetail != null &&
            record?.lastSyncDetail?.isRunning === false
              ? `${
                  record?.lastSyncDetail?.note
                    ? `${record?.lastSyncDetail?.note} `
                    : `Last synced on ${dayjs(
                        record.lastSyncDetail.lastEndTime
                      ).format("MMM, D YYYY h:mm A")}`
                } `
              : ""}
          </p>
        </div>
      </GridToolbarContainer>
    );
  }

  const displayTimeDifference = (endTime) => {
    const now = dayjs();
    const end = dayjs.utc(endTime); // Make sure to use utc() if your time is in UTC

    const diffHours = now.diff(end, "hour");
    const diffMinutes = now.diff(end, "minute");

    if (diffHours < 24) {
      // If the difference is less than 24 hours, but more than an hour, show "X hours ago"
      if (diffHours >= 1) {
        return diffHours === 1 ? "1 hour ago" : `${diffHours} hours ago`;
      } else {
        // If the difference is less than an hour, show "X minutes ago"
        return diffMinutes === 1
          ? "1 minute ago"
          : `${diffMinutes} minutes ago`;
      }
    } else {
      // Otherwise, format the date
      return end.format("ddd, MMM D, YYYY h:mm A");
    }
  };
  const [orderedColumns, setOrderedColumns] = useState(columnDefs);
  const onColumnOrderChange = React.useCallback(
    (params) => {
      const newOrder = [...orderedColumns];
      const [movedColumn] = newOrder.splice(params.oldIndex, 1);
      newOrder.splice(params.targetIndex, 0, movedColumn);
      setOrderedColumns(newOrder);
    },
    [orderedColumns]
  );

  const exportToExcel = () => {
    const initialColumns = [
      "DID",
      "Customer",
      "Destination Server IP",
      "Destination",
      "CC Call Count",
      "CC User Group",
      "CC Site Name",
      "Inteliquent Phone Status",
      "CC Industry",
      "CC DID Route",
      "CC Status",
    ];
    const getFilteredValues = (initialColumns, orderedColumns, key) => {
      return orderedColumns
        .filter((def) => initialColumns.includes(def.headerName))
        .map((def) => (def[key] ? def[key] : def.headerName));
    };
    const parentHeader = [
      "Sr",
      ...getFilteredValues(initialColumns, orderedColumns, "headerName"),
    ];

    const fieldValues = [
      "sr",
      ...getFilteredValues(initialColumns, orderedColumns, "field"),
    ];
    const items = batchList.map((records, index) => {
      const {
        did = "",
        customer = "",
        customer_id = "",
        destination = "",
        destination_server_ip = "",
        inteliquent_phone_status = "",
        cc_data = [],
      } = records;

      const addNAIfEmpty = (value) =>
        value === undefined || value === null || value === "" ? "" : value;
      return {
        sr: (index + 1).toString(),
        did: addNAIfEmpty(did),
        customer: addNAIfEmpty(customer ? customer : customer_id),
        destination_server_ip: addNAIfEmpty(destination_server_ip),
        destination: addNAIfEmpty(destination),
        "cc_data.total_count": addNAIfEmpty(cc_data?.total_count),
        "cc_data.user_group": addNAIfEmpty(cc_data?.user_group),
        "cc_data.did_description": addNAIfEmpty(cc_data?.did_description),
        inteliquent_phone_status: addNAIfEmpty(inteliquent_phone_status),
        "cc_data.menu_name": addNAIfEmpty(cc_data?.menu_name),
        "cc_data.did_route": addNAIfEmpty(cc_data?.did_route),
        "cc_data.did_active": addNAIfEmpty(cc_data?.did_active),
      };
    });
    const filteredItems = items.map((item) => {
      const filtered = {};
      fieldValues.forEach((key) => {
        if (item[key] !== undefined) {
          filtered[key] = item[key];
        }
      });
      return filtered;
    });
    exportReport(
      filteredItems,
      parentHeader,
      "DIDs_Inventory",
      "DIDs Inventor"
    );
  };
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  return (
    <>
      <PageHeader route="Setting > Industries" heading="DIDs Inventory List" />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={orderedColumns}
          items={batchList?.map((record, index) => {
            const counter = offset + index + 1;
            const {
              did,
              customer,
              customer_id,
              destination,
              destination_server_ip,
              cc_data,
              inteliquent_phone_status,
            } = record;
            return {
              records: { ...record },
              counter,
              did,
              customer: customer ? customer : customer_id,
              destination,
              destination_server_ip,
              cc_data,
              inteliquent_phone_status,
              total_count: cc_data?.total_count,
              user_group: cc_data?.user_group,
              did_description: cc_data?.did_description,
              menu_name: cc_data?.menu_name,
              did_route: cc_data?.did_route,
              did_active: cc_data?.did_active,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          totalItems={record?.totalItems}
          CustomToolbar={CustomToolbar}
          onFilterModelChange={onFilterChange}
          filterMode="server"
          paginationMode="server"
          exportButton={exportToExcel}
          onColumnOrderChange={onColumnOrderChange}
          searchable="No"
        />
      </div>
    </>
  );
}

export default DIDsInventory;
