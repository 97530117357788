import React, { useState } from "react";
import { DotsLoader, FormInput, Modal } from "../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import * as Yup from "yup";
import {
  createNumber,
  updateNumber,
} from "../../services/communicationNumbers";
import InputMask from "react-input-mask";
const AddUpdateForm = ({
  editingRecord,
  onCancelForm,
  modalTitle,
  reloadListing,
}) => {
  const [errors, setErrors] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let initialValues = {
    did: "",
  };

  if (editingRecord) {
    const { _id, did } = editingRecord;
    initialValues = { id: _id, did };
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validationSchema: Yup.object({
      did: Yup.string()
        .required("Number is Required")
        .matches(/^\d{10}$/, "Number must be exactly 10 digits"),
    }),

    onSubmit: async (values) => {
      setIsLoading(true);
      if (!editingRecord) {
        try {
          const res = await createNumber(values);
          if (res?.status === 200) {
            toast.success("Number added successfully");
            onCancelForm();
            reloadListing();
          } else {
            errorMessage({
              payload: res.data?.error,
              setErrors: setErrors,
              action: "Number",
              msg: "added",
            });
          }
        } catch (error) {
          errorMessage({
            payload: error.response.data?.error,
            setErrors: setErrors,
            action: "Number",
            msg: "added",
          });
        }
      } else {
        try {
          const res = await updateNumber(values);
          if (res?.status === 200) {
            toast.success("Number updated successfully");
            onCancelForm();
            reloadListing();
          } else {
            errorMessage({
              payload: res.data?.error,
              setErrors: setErrors,
              action: "Number",
              msg: "updated",
            });
          }
        } catch (error) {
          errorMessage({
            payload: error.response.data?.error,
            setErrors: setErrors,
            action: "Number",
            msg: "updated",
          });
        }
      }
      setIsLoading(false);
    },
  });
  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form className="mt-4">
          <InputMask
            mask="XXXXXXXXXX"
            value={formik.values.did}
            onChange={formik.handleChange}
            name="did"
            maskChar="X"
            formatChars={{ X: "[0-9]" }}
          >
            {(inputProps) => (
              <FormInput
                {...inputProps}
                label="Enter Number"
                className="text-xs"
                formik={formik}
                errors={errors}
              />
            )}
          </InputMask>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateForm;
