import { useState } from "react";
import { operators } from "../CustomFilters/filters.config";
import ValueComponent from "../CustomFilters/ValueComponent";
const logicOptions = [
  { value: "AND", label: "AND" },
  { value: "OR", label: "OR" },
];

const FilterPopup = ({
  onCloseFilter,
  columns,
  filterChange,
  handleNewFilter,
  filterModel,
  onRemove,
}) => {
  const [filters, setFilters] = useState(
    filterModel?.rules?.length
      ? filterModel?.rules?.map(({ data, field, op }) => ({
          field,
          operator: op,
          value: data,
          type: columns?.find((column) => column?.field === field)?.type,
        }))
      : [
          {
            field: columns[0]?.field || "",
            operator: operators[columns[0]?.type][0]?.value,
            value: "",
            logic: "",
            type: columns[0]?.type || "",
            logicEnabled: false,
          },
        ]
  );
  const [groupValue, setGroupValue] = useState(
    filterModel?.groupOp ? filterModel?.groupOp : "AND"
  );
  const handleFilterChange = (index, field, value) => {
    const newFilters = [...filters];
    const fieldType = columns.find((col) => col.field === value)?.type;
    newFilters[index] = {
      ...newFilters[index],
      [field]: value,
      type: field === "field" ? fieldType : newFilters[index]?.type,
      ...(field !== "value" && field !== "operator"
        ? { operator: operators[fieldType][0]?.value }
        : {}),
    };
    // Propagate logic change to child filters
    if (field === "logic" && index === 0) {
      newFilters.forEach((filter, idx) => {
        if (idx > 0) {
          newFilters[idx] = { ...newFilters[idx], logic: value };
        }
      });
    }
    const filterData = {
      groupOp: groupValue,
      rules: newFilters.map(({ field, operator, value }) => ({
        field,
        op: operator,
        data: value,
      })),
    };
    setFilters(newFilters);
    filterChange(filterData);
    //applyFilters(newFilters);
  };

  const handleGroupChange = (index, value) => {
    setGroupValue(value);
  };
  const applyFilters = (filters) => {
    handleNewFilter();
  };

  const addFilter = () => {
    const newFilters = [...filters];
    const logicEnabled = filters.length === 1;
    newFilters.push({
      field: columns[0]?.field || "",
      operator: operators[columns[0]?.type][0]?.value,
      value: "",
      type: columns[0]?.type || "",
      logicEnabled,
    });
    setFilters(newFilters);
  };

  const removeFilter = (index) => {
    if (index === 0) {
      filterChange({ groupOp: "AND", rules: [] });
      onCloseFilter();
      return;
    }
    const newFilters = filters.filter((_, i) => i !== index);
    const filterData = {
      groupOp: groupValue,
      rules: newFilters.map(({ field, operator, value }) => ({
        field,
        op: operator,
        data: value,
      })),
    };
    setFilters(newFilters);
    filterChange(filterData);
  };

  const removeAllFilters = () => {
    filterChange({ groupOp: "AND", rules: [] });
    onRemove();
    setFilters([
      {
        field: columns[0]?.field || "",
        operator: "contains",
        type: columns[0]?.type || "",
        value: "",
        logic: "",
        logicEnabled: false,
      },
    ]);
    onCloseFilter();
  };

  const handleApplyFilters = () => {
    applyFilters(filters);
  };
  return (
    <div className="absolute left-0 top-10 z-9 border border-neutral-100 p-3 bg-white shadow">
      <button
        className="text-xl font-bold absolute right-2 top-0"
        onClick={onCloseFilter}
      >
        &times;
      </button>
      <div className="max-h-[250px] overflow-y-auto">
        {filters.map((filter, index) => {
          return (
            <div key={index} className="flex gap-2 mb-2">
              <div className="flex flex-col">
                <label>&nbsp;</label>
                <button
                  className="text-lg leading-3"
                  onClick={() => removeFilter(index)}
                >
                  &times;
                </button>
              </div>
              <div className="flex flex-col">
                <label>&nbsp;</label>
              </div>{" "}
              {/* for add AND/OR select box */}
              {index > 0 && (
                <select
                  className="bg-white border-b border-neutral-300 focus:border-neutral-700"
                  value={groupValue}
                  onChange={(e) => handleGroupChange(index, e.target.value)}
                  disabled={!filter.logicEnabled}
                >
                  {logicOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
              {index === 0 && filters.length > 1 && (
                <div
                  className="bg-white border-neutral-300 focus:border-neutral-700 w-[47px]"
                  key={index}
                >
                  <label>&nbsp;</label>
                </div>
              )}
              <div className="flex flex-col">
                <label className="text-xs ml-1">Columns</label>
                <select
                  className="bg-white border-b border-neutral-300 focus:border-neutral-700 w-[130px]"
                  value={filter.field}
                  name="filter_column"
                  onChange={(e) => {
                    handleFilterChange(index, "field", e.target.value);
                  }}
                >
                  {columns.map((column) => (
                    <option key={column.field} value={column.field}>
                      {column.headerName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label className="text-xs ml-1">Operator</label>
                <select
                  name="operator"
                  className="bg-white border-b border-neutral-300 focus:border-neutral-700 w-[130px]"
                  value={filter.operator}
                  onChange={(e) => {
                    handleFilterChange(index, "operator", e.target.value);
                  }}
                >
                  {[
                    ...operators[filter.type],
                    ...(columns.find(({ field }) => field === filter.field)
                      ?.additionalOperators || []),
                  ].map((operator) => (
                    <option key={operator.value} value={operator.value}>
                      {operator.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label className="text-xs ml-1">Value</label>
                <ValueComponent
                  columns={columns}
                  operators={operators}
                  filter={filter}
                  index={index}
                  handleFilterChange={handleFilterChange}
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className="w-full flex justify-between text-sm mt-2">
        <button className="py-1 px-2 hover:bg-blue-200" onClick={addFilter}>
          + Add Filter
        </button>
        <button
          className="py-1 px-2 bg-primary-100 text-white"
          onClick={handleApplyFilters}
        >
          Apply Filter
        </button>
        <button
          className="py-1 px-2 hover:bg-blue-200"
          onClick={removeAllFilters}
        >
          - Remove All
        </button>
      </div>
    </div>
  );
};

export default FilterPopup;
