import React from "react";
import { Button, FormSelectInput, Loader } from "../../components";
import { useSelector } from "react-redux";
import api from "../../services/api";
import { toast } from "react-toastify";

function RolesAssignModal({ onCancelForm, formik }) {
  const merchant = useSelector((state) => state.merchant);
  const [roleOptions, setRoleOptions] = React.useState({});
  const [loader, setLoader] = React.useState(false);

  const handleRoleChange = (merchant_id, role_id) => {
    const updatedMerchants = formik.values.merchants.map((m) =>
      m.merchant_id === merchant_id ? { ...m, role_id } : m
    );
    formik.setFieldValue("merchants", updatedMerchants);
  };
  const get_roles_by_merchant = async (id) => {
    try {
      setLoader(true);
      const res = await api.get(`/api/roles`, { params: { merchant_id: id } });
      if (res.status === 200) {
        setLoader(false);
        return res.data;
      } else {
        toast.error("Roles not found for this merchant");
        setLoader(false);
        return [];
      }
    } catch (err) {
      setLoader(false);
      console.log("👊 ~ AddUpdateForm ~ err:", err);
    }
  };
  const loadRoleOptionsForAllMerchants = async () => {
    const newRoleOptions = { ...roleOptions };
    setLoader(true);

    for (const merchant of formik?.values?.merchants) {
      const merchantId = merchant.merchant_id;
      if (!newRoleOptions[merchantId]) {
    // console.log("👊 ~ loadRoleOptionsForAllMerchants ~ newRoleOptions:", newRoleOptions)

        const options = await get_roles_by_merchant(merchantId);
        newRoleOptions[merchantId] = options;
        setRoleOptions(newRoleOptions);
      }
    }
    setLoader(false);
    setRoleOptions(newRoleOptions);
  };
  React.useEffect(() => {
    loadRoleOptionsForAllMerchants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* const loadRoleOptions = async (merchant_id) => {
    if (!roleOptions[merchant_id]) {
      const options = await get_roles_by_merchant(merchant_id);
      setRoleOptions({ ...roleOptions, [merchant_id]: options });
    }
  }; */
  return (
    <div className="w-full h-screen z-[100] fixed top-0 left-0  opacity-100 bg-black/[0.75] flex items-center justify-center">
      <div className="relative">
        <div className="absolute top-0 right-0 p-2 z-20 -mt-6 -mr-6">
          <button
            onClick={onCancelForm}
            className="!bg-white rounded-full text-gray-700 hover:text-red-500 p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className=" w-full !w-[500px] max-h-[calc(100vh-64px)] overflow-y-auto bg-white rounded z-[100] shadow-[0_2px_8px_rgba(0,_0,_0,_0.26)]">
          <header className="border-b-2 border-[#3b0062]">
            <h1 className="text-2xl text-[#3b0062] !m-4">
              {"Assign Role to Merchants"}
            </h1>
          </header>
          <div className="p-4">
            <div className="col-span-2 !mt-3">
              {formik.values.merchants?.length
                ? formik.values.merchants?.map(({ merchant_id, role_id }) => {
                    return (
                      <div className="grid grid-cols-2 gap-x-3 flex items-center !mb-3">
                        <strong>
                          {
                            merchant?.record?.data?.find(
                              ({ _id }) => _id === merchant_id
                            )?.name
                          }
                        </strong>
                        <div className="flex gap-x-2">
                          <FormSelectInput
                            name="role_id"
                            label="Role"
                            value={role_id}
                            options={roleOptions[merchant_id] || []}
                            valueProp="_id"
                            labelProp="name"
                            onChange={(value) =>
                              handleRoleChange(merchant_id, value)
                            }
                          />
                          {!roleOptions[merchant_id] && loader ? (
                            <Loader />
                          ) : null}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          <div className="!p-4 text-right">
            <Button
              text="Save"
              className="mr-2"
              onClick={onCancelForm}
              variant="btn_submit"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RolesAssignModal;
