import React, { useEffect, useState } from "react";
import AddUpdateNationSitesModal from "./AddUpdateVendors";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../../components/molecules/PageHeader";
import userPermission from "../../../util/userPermission";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import {
  get_nation_site,
  get_nation_site_vendors,
  nationSiteReset,
  delete_vendor,
} from "../../../features/nationSitesSlice";
import { get_industry } from "../../../features/IndustrySlice";
import { toast } from "react-toastify";
import errorMessage from "../../../util/errorMessage";
const NationSiteVendors = () => {
  const dispatch = useDispatch();
  const { isLoading, nationVendors, nationSites } = useSelector(
    (state) => state?.nation
  );
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  useEffect(() => {
    dispatch(get_nation_site_vendors());
    dispatch(get_nation_site());
    dispatch(get_industry({ data: { page: 1, size: 100 } }));
    return () => {
      dispatch(nationSiteReset());
    };
    // eslint-disable-next-line
  }, []); const dtes = 1;
  const sites = nationVendors?.records?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_vendor(record_id));
        if (res?.payload?.status === 200) {
          dispatch(get_nation_site_vendors());
          toast.success("Vendor deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "Vendor",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "Vendor", msg: "deleted" });
      }
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false },
    { headerName: "Domain", field: "domain", flex: 1 },
    { headerName: "Vendor Name", field: "name", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div className="flex flex-row">
          {userPermission("Update National Site Vendor") ? (
            <FaEdit
              onClick={openFormHandler(params.row.records)}
              className="my_navIcon"
              title="Update National Sites Vendor"
            />
          ) : null}
          <FaTrashAlt
            onClick={deleteRecordHandler(params.row.records._id)}
            className="my_navIcon"
            title="Delete Vendor"
          />
        </div>
      ),
      width: 100,
      filterable: false,
    },
  ];
  return (
    <>
      {isEditing && (
        <AddUpdateNationSitesModal
          editingRecord={editingRecord}
          modalTitle="Add Nation Site Vendors"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        heading="Vendors Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Vendors")}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={sites?.map((record, index) => {
            let counter = index + 1;
            const { national_site_id, name } = record;

            return {
              counter,
              records: { ...record },
              domain: nationSites?.records?.find(
                ({ _id }) => _id === national_site_id
              )?.domain,
              name,
            };
          })}
          totalItems={nationVendors?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default NationSiteVendors;
