import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLeadSource } from "../services/leadSourceService";

const initialState = {
  isError: false,
  isLoader: false,
  affiliates: [],
  message: "",
};

const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};

// Async action to get lead affiliates
export const get_lead_affiliates = createAsyncThunk(
  "get_lead_source",
  async (data, thunkAPI) => {
    try {
      return await getLeadSource(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create the slice
export const leadSourceSlice = createSlice({
  name: "getLeadSource",
  initialState,
  reducers: {
    leadSourceReset: (state) => {
      state.isError = false;
      state.isLoader = false;
      state.affiliates = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_lead_affiliates.pending, (state) => {
        state.isLoader = true;
        state.affiliates = [];
      })
      .addCase(get_lead_affiliates.fulfilled, (state, action) => {
        state.isLoader = false;

        // Check if data exists and map it to affiliateSettingsGroups
        const affiliateSettingsGroups = action.payload?.data?.records?.length
          ? action.payload.data.records.map((record) => ({
              company_name: record.company_name,
              campaigns: record.affiliate_settings.map((affiliate) => ({
                campaign_id: {
                  ...affiliate.campaign_id,
                  affiliate_id: affiliate._id,
                },
              })),
            }))
          : [];

        state.affiliates = affiliateSettingsGroups;
      })
      .addCase(get_lead_affiliates.rejected, (state, action) => {
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.affiliates = [];
      });
  },
});

export const { leadSourceReset } = leadSourceSlice.actions;
export default leadSourceSlice.reducer;
