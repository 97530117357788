import { useState, useEffect } from "react";
import SmsCenter from "./SmsCenter";
import { getNumbers } from "../../services/communicationNumbers";
import { DotsLoader } from "../../components";
export default function Communicaton() {
  const [isSMSCenter, setIsSMSCenter] = useState(true);
  const [didList, setDidList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getDidList = async () => {
    setIsLoading(true);
    try {
      const res = await getNumbers();
      if (res.status === 200) {
        setDidList(res?.data || []);
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getDidList();
  }, []);

  return (
    <>
      {isLoading ? <DotsLoader /> : null}
      <div className="mt-2">
        <div className="border-b w-full mb-2">
          <span className="!ml-3 text-xl font-semibold">Communications</span>
        </div>
        <div className="flex flex-col lg:flex-row p-2 gap-2 bg-gray-100 min-h-screen">
          <div className="w-full lg:w-1/4 bg-white p-4 rounded-lg shadow-md">
            <h2 className="font-semibold text-lg">My DIDs</h2>
            <p className="text-sm text-gray-600 mt-1 font-semibold border-b border-gray-200 py-1">
              You have {didList?.length}{" "}
              {didList?.length === 1 ? "DID" : "DIDs"}
            </p>
            <span className="text-blue-500 font-semibold text-sm ml-auto block text-right py-1 cursor-pointer">
              DID Portal
            </span>

            {/* SMS Section */}
            <div className="mt-2 bg-gray-200 p-3 rounded-md">
              <h4 className="font-semibold text-gray-700 text-lg mb-2">SMS</h4>
              <p className="text-sm">You have 0 conversations</p>
              <span className="text-blue-500 text-xs cursor-pointer">
                SMS Portal
              </span>
            </div>

            {/*     <div className="mt-4 bg-gray-200 p-3 rounded-md">
              <h4 className="font-semibold text-gray-700 text-lg mb-2">
                VOICEMAILS
              </h4>
              <p className="text-sm">You don’t have a Voicemail</p>
              <span className="text-blue-500 text-xs cursor-pointer">
                Click here to order free Voicemail
              </span>
            </div>

         
            <div className="mt-4 bg-gray-200 p-3 rounded-md">
              <h4 className="font-semibold text-gray-700 text-lg mb-2">
                FAXES
              </h4>
              <p className="text-sm italic text-gray-500">There are no faxes</p>
            </div> */}

            {/* Calls Made Section */}
            <div className="mt-4 bg-gray-200 p-3 rounded-md">
              <h4 className="font-semibold text-gray-700 text-lg mb-2">
                CALLS MADE
              </h4>
              <ul className="text-sm">
                <li className="flex justify-between border-b border-white py-1">
                  <span className="text-blue-500">15735654437</span>
                  <span className="text-gray-600 text-xs">
                    Feb 12, 10:30 AM
                  </span>
                </li>
                <li className="flex justify-between mt-1 border-b border-white py-1">
                  <span className="text-blue-500">15512792008</span>
                  <span className="text-gray-600 text-xs">
                    Feb 10, 08:30 AM
                  </span>
                </li>
              </ul>
              <span className="text-blue-500 text-xs block mt-2 text-right cursor-pointer">
                View more
              </span>
              <span className="text-blue-500 text-xs block mt-2 text-right cursor-pointer">
                View complete report
              </span>
            </div>

            {/* Calls Received Section */}
            <div className="mt-4 bg-gray-200 p-3 rounded-md">
              <h4 className="font-semibold text-gray-700 text-lg mb-2">
                CALLS RECEIVED
              </h4>
              <ul className="text-sm space-y-4">
                <li className="flex justify-between border-b border-white py-1">
                  <span className="text-blue-500 text-sm">13048667418</span>
                  <div className="text-right text-sm">
                    <span className="font-semibold text-gray-700 block text-xs">
                      ZEITZ TOMMY
                    </span>
                    <span className="text-gray-600 text-xs">
                      Feb 11, 01:27 PM
                    </span>
                  </div>
                </li>

                <li className=" border-b border-white py-1">
                  <div className="flex justify-between items-start">
                    <span className="text-blue-500 text-sm ">18884055750</span>
                    <div className="text-right text-sm">
                      <span className="font-semibold text-gray-700 block text-xs">
                        TOWING SERVICES
                      </span>
                      <span className="text-gray-600 text-xs block">
                        Feb 10, 03:45 AM
                      </span>
                    </div>
                  </div>
                  <span className="bg-green-200 text-green-700 text-xs px-2 py-0.5 rounded-md block mt-1 w-full">
                    Spam probability:
                    <span className="font-semibold text-black ml-2">Low</span>
                  </span>
                </li>

                <li className="flex justify-between border-b border-white py-1">
                  <span className="text-blue-500 text-sm">13048667418</span>
                  <div className="text-right text-sm">
                    <span className="font-semibold text-gray-700 block text-xs">
                      ZEITZ TOMMY
                    </span>
                    <span className="text-gray-600 text-xs">
                      Feb 8, 09:57 AM
                    </span>
                  </div>
                </li>
              </ul>
              <span className="text-blue-600 text-xs mt-4 inline-block cursor-pointer">
                View complete report
              </span>
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-3/4 gap-4">
            {isSMSCenter && (
              <SmsCenter
                setIsSMSCenter={setIsSMSCenter}
                isSMSCenter={isSMSCenter}
                didList={didList}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
