import React, { useState } from "react";
import { DotsLoader, FormInput, FormSelectInput } from "../../../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import errorMessage from "../../../../util/errorMessage";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import CustomModal from "../../../../components/molecules/Modal/customModal";
import { update_merchant } from "../../../../features/merchantSlice";
import { filterPayload } from "../../../../util/filterPayload";
const AddDidToMerchant = ({
  onCancelForm,
  modalTitle,
  editingRecord,
  selectedData,
}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let initialValues = {
    name: "",
    email: "",
    fax: "",
    country_id: "",
    state_id: "",
    city: "",
    zip: "",
    street: "",
    address: "",
    phone: "",
    username: "",
    company_name: "",
    industry_id: [],
    call_center_id: "",
    merchant_logo: "",
    pswrd: "",
    cc_apikey: "",
    website: "",
    sms_number_for_vendor: "",
    sms_number_for_other: "",
    enable_opt_sms: true,
    message_type: "",
    sms_number: selectedData?.did || "",
  };
  let ids = [];
  if (editingRecord) {
    const {
      _id,
      name,
      email,
      fax,
      country_id,
      state_id,
      city,
      zip,
      street,
      address,
      username,
      company_name,
      industry_id,
      user_group_id,
      pswrd,
      cc_apikey,
      phone,
      conexcs_settings,
      website,
      sms_number_for_vendor,
      sms_number_for_other,
      sms_opt_settings,
      BVS_settings,
      third_party_setting,
    } = editingRecord;
    industry_id?.map((val) => {
      ids = [...ids, val?._id];
      return { ids };
    });

    initialValues = {
      id: _id,
      name,
      email,
      fax,
      country_id: country_id?._id,
      state_id: state_id?._id,
      city,
      zip,
      street,
      address,
      username,
      company_name,
      industry_id: ids,
      call_center_id: user_group_id?.call_center_id,
      pswrd,
      cc_apikey:
        cc_apikey === "undefined" || cc_apikey === "null" ? "" : cc_apikey,
      phone,
      cx: conexcs_settings?.cx || "",
      destination_ip: conexcs_settings?.destination_ip || "",
      dial_out_prefix: conexcs_settings?.dial_out_prefix || "",
      customer_card: conexcs_settings?.customer_card || "",
      provider: conexcs_settings?.provider || "",
      provider_card: conexcs_settings?.provider_card || "",
      website,
      sms_number_for_vendor,
      sms_number_for_other,
      enable_opt_sms: sms_opt_settings?.enable_opt_sms,
      bvs_settings: {
        cx: BVS_settings?.cx || "",
        destination_ip: BVS_settings?.destination_ip || "",
        dial_out_prefix: BVS_settings?.dial_out_prefix || "",
        customer_card: BVS_settings?.customer_card || "",
        provider: BVS_settings?.provider || "",
        provider_card: BVS_settings?.provider_card || "",
      },
      third_party_setting,
      sms_number: selectedData?.did || "",
    };
  }
  const validationSchema = Yup.object({
    message_type: Yup.string().required("Message Type is required"),
    sms_number: Yup.string()
      .required("SMS number is required")
      .matches(
        /^\+?\d{10,11}$/,
        "SMS number must be between 10 and 11 digits"
      ),
  });
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validationSchema,
    onSubmit: async (values) => {
      const {
        message_type,
        sms_number,
        enable_opt_sms,
        sms_number_for_vendor,
        sms_number_for_other,
        ...rest
      } = values;

      const sms_opt_settings = {
        enable_opt_sms,
      };

      const modifiedPayload = {
        ...rest,
        sms_opt_settings,
      };

      if (message_type && message_type === "for_vendors") {
        modifiedPayload.sms_number_for_vendor = sms_number;
        modifiedPayload.sms_number_for_other = sms_number_for_other;
      } else {
        modifiedPayload.sms_number_for_other = sms_number;
        modifiedPayload.sms_number_for_vendor = sms_number_for_vendor;
      }

      if (!isLoading) {
        const formattedPayload = filterPayload(modifiedPayload);
        delete formattedPayload.cx;
        delete formattedPayload.destination_ip;
        delete formattedPayload.dial_out_prefix;
        delete formattedPayload.customer_card;
        delete formattedPayload.provider;
        delete formattedPayload.provider_card;
        delete formattedPayload.bvs_settings;
        delete formattedPayload.third_party_setting;
        formattedPayload.bridging_dids = editingRecord?.bridging_dids || [];
        delete formattedPayload.username;
        delete formattedPayload.call_center_id;
        delete formattedPayload.message_type;
        delete formattedPayload.sms_number;
        try {
          setIsLoading(true);
          const res = await dispatch(update_merchant(formattedPayload));
          if (res?.payload?.status === 200) {
            toast.success("Merchant updated");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              action: "Merchant",
              msg: "update",
              setErrors: setErrors,
            });
          }
        } catch (error) {
          errorMessage({
            payload: error,
            action: "Merchant",
            msg: "update",
            setErrors: setErrors,
          });
        }
        setIsLoading(false);
      } else {
        toast.error("Please wait until previous action be completed");
      }
    },
  });

  return (
    <>
      <CustomModal
        handleModal={onCancelForm}
        cancelButtonRef={onCancelForm}
        className="max-w-2xl"
        modalType={true}
        title={modalTitle}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
      >
        {isLoading ? <DotsLoader /> : null}

        <form>
          <div className="grid md:grid-cols-1 gap-4 w-full px-4 py-4">
            <FormSelectInput
              errors={errors}
              name="message_type"
              label="Message Type"
              formik={formik}
              options={[
                {
                  _id: "for_vendors",
                  name: "For Vendor",
                },
                {
                  _id: "for_others",
                  name: "For Others",
                },
              ]}
              labelProp="name"
              valueProp="_id"
            />
            {formik.values.message_type ? (
              <FormInput
                errors={errors}
                name="sms_number"
                label={
                  formik.values.message_type === "for_vendors"
                    ? "For Vendor"
                    : "For Other"
                }
                formik={formik}
              />
            ) : null}
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default AddDidToMerchant;
