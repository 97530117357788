import { FormInput } from "../../../components";
import FormCheckbox from "../../../components/molecules/FormCheckbox";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

export const operators = {
  string: [
    {
      value: "cn",
      label: "Contains",
      type: "string",
      InputComponent: (props) => <FormInput {...props} />,
    },
    {
      value: "eq",
      label: "Equal to",
      type: "string",
      InputComponent: (props) => <FormInput {...props} />,
    },
    {
      value: "not",
      label: "Not equal to",
      type: "string",
      InputComponent: (props) => <FormInput {...props} />,
    },
    {
      value: "startsWith",
      label: "Starts With",
      type: "string",
      InputComponent: (props) => <FormInput {...props} />,
    },
    {
      value: "endsWith",
      label: "Does not start with",
      InputComponent: (props) => <FormInput {...props} />,
    },
  ],
  number: [
    {
      value: "eq",
      label: "Equals",
      InputComponent: (props) => <FormInput type="number" {...props} />,
    },
    {
      value: "not",
      label: "Not equal to",
      InputComponent: (props) => <FormInput type="number" {...props} />,
    },
    {
      value: "lt",
      label: "Less Than",
      InputComponent: (props) => <FormInput type="number" {...props} />,
    },
    {
      value: "lte",
      label: "Less than or equal to",
      InputComponent: (props) => <FormInput type="number" {...props} />,
    },
    {
      value: "gt",
      label: "Greater Than",
      InputComponent: (props) => <FormInput type="number" {...props} />,
    },
    {
      value: "gte",
      label: "Greater than or equal to",
      InputComponent: (props) => <FormInput type="number" {...props} />,
    },
  ],
  date: [
    {
      value: "eq",
      label: "Equals",
      InputComponent: (props) => <FormInput type="date" {...props} />,
    },
    {
      value: "not",
      label: "Not equal to",
      InputComponent: (props) => <FormInput type="date" {...props} />,
    },
    {
      value: "lt",
      label: "Less Than",
      InputComponent: (props) => <FormInput type="date" {...props} />,
    },
    {
      value: "lte",
      label: "Less than or equal to",
      InputComponent: (props) => <FormInput type="date" {...props} />,
    },
    {
      value: "gt",
      label: "Greater Than",
      InputComponent: (props) => <FormInput type="date" {...props} />,
    },
    {
      value: "gte",
      label: "Greater than or equal to",
      InputComponent: (props) => <FormInput type="date" {...props} />,
    },
    /*  {
      value: "within",
      label: "Within",
      InputComponent: (props) => (
        <>
          <FormInput type="date" {...props} placeholder="From" />
          <FormInput type="date" {...props} placeholder="To" />
        </>
      ),
    },
    {
      value: "notWithin",
      label: "Not within",
      InputComponent: (props) => (
        <>
          <FormInput type="date" {...props} placeholder="From" />
          <FormInput type="date" {...props} placeholder="To" />
        </>
      ),
    }, */
    {
      value: "matches",
      label: "Matches",
      InputComponent: (props) => <FormInput type="date" {...props} />,
    },
    /*
    {
      value: "before",
      label: "Before",
      InputComponent: (props) => <FormInput type="date" {...props} />,
    },
    {
      value: "after",
      label: "After",
      InputComponent: (props) => <FormInput type="date" {...props} />,
    },
    {
      value: "between",
      label: "Between",
      InputComponent: (props) => (
        <>
          <FormInput type="date" {...props} placeholder="From" />
          <FormInput type="date" {...props} placeholder="To" />
        </>
      ),
    }, */
  ],
  boolean: [
    {
      value: "eq",
      label: "Is True",
      InputComponent: (props) => <FormCheckbox checked {...props} />,
    },
    {
      value: "eq",
      label: "Is False",
      InputComponent: (props) => <FormCheckbox {...props} />,
    },
  ],
  select: [
    {
      value: "eq",
      label: "is",
      InputComponent: (props) => <Dropdown {...props} />,
    },
    {
      value: "not",
      label: "is not",
      InputComponent: (props) => <Dropdown {...props} />,
    },
    {
      value: "isAnyOf",
      label: "Is Any Of",
      type: "multiSelect",
      InputComponent: (props) => <MultiSelect {...props} />,
    },
  ],
};
