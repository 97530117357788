import React, { useState, useEffect } from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import api from "../../services/api";
import CampaignGroup from "../CallCenter/DIDs/CampaingGroup";
function AddDidToCC({
  onCancelForm,
  modalTitle,
  callManu,
  selectedData,
  exportFormSubmit,
  affiliates,
  industryDrd,
  dialerUsers,
  inbound,
}) {
  const [isLoader, setIsLoader] = useState(false);
  const { record } = inbound;
  const [isCopyInput, setIsCopyInput] = useState(false);
  const [errors, setErrors] = useState([]);
  const [customErrors, setCustomErrors] = useState({});
  const [selectedDMultiData, setSelectedMultiData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;

  const initialValues = {
    did_route: "EXTEN",
    group_id: "",
    menu_id: "",
    extension: "",
    did_active: "Y",
    prefix: "",
    user: "",
    voicemail_ext: "",
    user_group: user?.user_group?.user_group,
    cx: user?.conexcs_settings?.cx || "",
    destination_ip: user?.conexcs_settings?.destination_ip || "",
    dial_out_prefix: user?.conexcs_settings?.dial_out_prefix || "",
    route_external: "NO",
    custom_one: "",
    did_carrier_description: "",
  };

  useEffect(() => {
    const updatedArray = selectedData.map((data) => ({
      did_pattern: data.did,
      did_description: "",
      selected: false,
    }));
    setSelectedMultiData(updatedArray);
  }, [selectedData]);

  function validateForm(data) {
    const errors = [];

    if (!data?.did_carrier_description) {
      errors.push({ did_carrier_description: "Industry is required" });
    }
    if (data?.did_route === "EXTEN" && !data?.extension) {
      errors.push({ extension: "Extension is required" });
    }
    if (data?.did_route === "IN_GROUP" && !data?.group_id) {
      errors.push({ group_id: "Group ID is required" });
    }
    if (data?.did_route === "CALLMENU" && !data?.menu_id) {
      errors.push({ menu_id: "Menu ID is required" });
    }

    const isAnyCheckboxSelected = selectedDMultiData.some(
      (item) => item.selected
    );
    if (!isAnyCheckboxSelected) {
      toast.error("At least one DID must be selected for export to CC!");
      errors.push({
        checkbox: "At least one DID must be selected for export to CC",
      });
    }

    setErrors(errors);
    return errors;
  }

  const handleSubmit = async (values) => {
    const errors = validateForm(formik.values);
    const message = "created";

    if (errors?.length > 0) {
      return errorMessage({
        payload: errors,
        setErrors: setErrors,
        action: "DID",
        msg: message,
      });
    }

    const customError = validateCloneForm(selectedDMultiData);
    if (Object.keys(customError).length !== 0) {
      return false;
    }

    const selectedDataForApi = selectedDMultiData.filter(
      (item) => item.selected
    );

    const payload = selectedDataForApi?.map((item) => {
      const { selected, ...rest } = item;
      return {
        ...rest,
        ...values,
      };
    });

    setIsLoader(true);
    try {
      const res = await api.post("/api/cc/dids/import_to_callcenter", payload);
      if (res.status === 200 || res.status === 201) {
        toast.success("DIDs synced successfully.");
        exportFormSubmit();
      } else {
        toast.error("DID couldn't be synced!");
      }
    } catch (err) {
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      } else {
        toast.error(err?.response?.data);
        return;
      }
      console.log(err);
    }
    setIsLoader(false);
  };

  const validateCloneForm = (values) => {
    const errors = {};

    if (selectedRows.length === 0) {
      values.forEach((selectedDMultiData) => {
        if (!selectedDMultiData.did_description) {
          errors[`did_description_${selectedDMultiData.did_pattern}`] =
            "Site Name is required";
        }
      });
    } else {
      values.forEach((selectedDMultiData) => {
        if (
          selectedDMultiData.selected &&
          !selectedDMultiData.did_description
        ) {
          errors[`did_description_${selectedDMultiData.did_pattern}`] =
            "Site Name is required";
        }
      });
    }

    setCustomErrors(errors);
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  const handleInputChange = (did_pattern, field, value) => {
    setSelectedMultiData((prevSections) =>
      prevSections.map((section) =>
        section.did_pattern === did_pattern
          ? { ...section, [field]: value }
          : section
      )
    );
  };

  const handleCheckboxChange = (e) => {
    setIsCopyInput(e.target.checked);
    if (e.target.checked) {
      const firstDescription = selectedDMultiData[0]?.did_description;
      const updatedData = selectedDMultiData.map((item) => {
        if (item.selected && !firstDescription) {
          return item;
        }
        return {
          ...item,
          did_description: firstDescription || item.did_description,
        };
      });
      setSelectedMultiData(updatedData);
    } else {
      const updatedData = selectedDMultiData.map((item) => ({
        ...item,
        did_description: item.selected ? item.did_description : "",
      }));
      setSelectedMultiData(updatedData);
    }
  };

  const handleRowSelection = (did_pattern) => {
    setSelectedMultiData((prevSections) => {
      const updatedSections = prevSections.map((section) =>
        section.did_pattern === did_pattern
          ? { ...section, selected: !section.selected }
          : section
      );

      const selected = updatedSections.filter((item) => item.selected);
      setSelectedRows(selected);

      return updatedSections;
    });
  };

  return (
    <Modal
      onCancelModal={onCancelForm}
      title={modalTitle}
      onSubmit={formik.handleSubmit}
      isLoading={isLoader}
      onClick={onCancelForm}
      modalType={true}
    >
      {isLoader || isLoader ? <DotsLoader /> : null}
      <div className="grid md:grid-cols-4 gap-x-5 mt-5 mx-2.5">
        <div className="mb-3">
          <FormSelectInput
            errors={errors}
            name="did_carrier_description"
            label="Industry"
            formik={formik}
            options={industryDrd}
            valueProp="_id"
            labelProp="name"
          />
        </div>

        <div className="mb-3">
          <FormSelectInput
            errors={errors}
            name="did_route"
            label="DID Route"
            formik={formik}
            options={[
              { value: "EXTEN", label: "External Number" },
              { value: "IN_GROUP", label: "Ring Group" },
              { value: "CALLMENU", label: "Call Menu" },
              { value: "AGENT", label: "Agent" },
            ]}
            valueProp="value"
            labelProp="label"
          />
        </div>

        {formik.values.did_route === "AGENT" ? (
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="user"
              label="Agent"
              formik={formik}
              options={dialerUsers}
              valueProp="value"
              labelProp="label"
              onChange={(e) => {
                formik?.setFieldValue("user", e);
                formik?.setFieldValue(
                  "voicemail_ext",
                  dialerUsers?.find(({ value }) => value === e)?.dialer_phone
                );
              }}
            />
          </div>
        ) : null}
        {formik.values.did_route === "IN_GROUP" ? (
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="group_id"
              label="Ring Group"
              formik={formik}
              options={record}
              valueProp="group_id"
              labelProp="group_name"
            />
          </div>
        ) : null}
        {formik.values.did_route === "EXTEN" ? (
          <div className="mb-3">
            <FormInput
              errors={errors}
              name="extension"
              label="External Number"
              formik={formik}
            />
          </div>
        ) : null}
        <div className="mb-3">
          <CampaignGroup
            errors={errors}
            name="custom_one"
            label="Campaign Name"
            formik={formik}
            options={affiliates}
          />
        </div>

        {formik.values.did_route === "CALLMENU" ? (
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="menu_id"
              label="Call Menu"
              formik={formik}
              options={callManu}
              valueProp="menu_id"
              labelProp="menu_name"
            />
          </div>
        ) : null}
        {formik.values.did_route === "CALLMENU" ? (
          <div className="mb-3">
            <FormSelectInput
              errors={errors}
              name="route_external"
              label="External Route"
              formik={formik}
              options={[
                { value: "YES", label: "YES" },
                { value: "NO", label: "NO" },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>
        ) : null}
      </div>

      <div className="min-h-[100px] max-h-[300px] overflow-y-auto mt-2">
        {selectedDMultiData?.map((data, index) => (
          <div key={data.did_pattern} className="mb-0 border-t p-2 ">
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 items-start mt-2">
              <div className="col-span-1 mb-0 ">
                <FormInput
                  name={`did_pattern${data.did_pattern}`}
                  label="DID Pattern"
                  value={data.did_pattern}
                  disabled
                />
              </div>

              <div className="col-span-1 mb-0">
                <FormInput
                  name={`did_description_${data.did_pattern}`}
                  label="Site Name"
                  onChange={(e) =>
                    handleInputChange(
                      data.did_pattern,
                      "did_description",
                      e.target.value
                    )
                  }
                  value={data.did_description}
                  disabled={!data.selected}
                />
                {customErrors[`did_description_${data.did_pattern}`] && (
                  <span className="text-red-500 text-xs mt-1">
                    {customErrors[`did_description_${data.did_pattern}`]}
                  </span>
                )}
              </div>

              <div className="col-span-2 mb-0 flex items-center space-x-2">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={data.selected}
                    onChange={() => handleRowSelection(data.did_pattern)}
                    className="form-checkbox h-4 w-4 text-primary-100 rounded-sm focus:ring-0 cursor-pointer"
                  />
                  <label className="ml-[6px] text-black font-pop text-[12px] cursor-pointer font-semibold">
                    Select for export to CC
                  </label>
                </div>
                {index === 0 && selectedDMultiData?.length > 1 && (
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="showpass"
                      name="showpass"
                      className="!ml-3 form-checkbox h-4 w-4 text-primary-100 rounded-sm focus:ring-0 cursor-pointer"
                      onChange={handleCheckboxChange}
                      checked={isCopyInput}
                    />
                    <label
                      htmlFor="showpass"
                      className="ml-[6px] text-black font-pop text-[12px] cursor-pointer"
                    >
                      Copy same site name for all?
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default AddDidToCC;
