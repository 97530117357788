import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/molecules/PageHeader";
import api from "../../../services/api";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton } from "@mui/material";
/* import { get_merchants } from "../../../features/merchantSlice"; */
import { FaSearch } from "react-icons/fa";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import dayjs from "dayjs";
function VendorsInvoice() {
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [merchant, setMerchant] = useState([]);
  console.log("👊 ~ VendorsInvoice ~ merchant:", merchant);
  const [vendorInvoices, setVendorInvoices] = useState(null);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const get_vendor_jobs = async (filters) => {
    let payload = { ...filters };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    setIsLoading(true);
    try {
      const res = await api.post(`/vendor_api/vendor/merchant_invoices`, {
        ...payload,
      });
      if (res.status === 200) {
        if (payload?.merchant_id && merchant?.length) {
          setVendorInvoices(res.data);
        } else {
          setMerchant(res.data);
        }
      }
    } catch (err) {
      console.log("👊 ~ VendorJobs ~ err:", err);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    // dispatch(
    //   get_merchants({
    //     page: 1,
    //     size: 1000,
    //     ...sortingModel,
    //   })
    // );
    get_vendor_jobs({ page: 1, size: paginationModel?.pageSize });
    // eslint-disable-next-line
  }, []);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    get_vendor_jobs({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
      filters: queryOptions,
      merchant_id: selectedMerchant,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      get_vendor_jobs({
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
        merchant_id: selectedMerchant,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : // : rule.operator === "isEmpty"
                // ? "eq"
                rule.operator,
          data: rule.value ? rule.value : rule.value === 0 ? rule.value : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    get_vendor_jobs({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      merchant_id: selectedMerchant,
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const paymentStatus = [
    { value: "Created", label: "Created" },
    { value: "Sent", label: "Sent" },
    { value: "Partially Paid", label: "Partially Paid" },
    { value: "Paid", label: "Paid" },
    { value: "Cancelled", label: "Cancelled" },
  ];
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    get_vendor_jobs({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      merchant_id: selectedMerchant,
      sort_field: params[0]?.field?.includes("_in")
        ? params[0]?.field.replaceAll("_in", "")
        : params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 60, filterable: false },
    {
      headerName: "Invoice No.",
      field: "invoice_number",
      flex: 1,
      renderCell: (params) => (
        <>
          <span>{params?.row?.invoice_number}</span>
          <span className="text-xs ml-1">{`(${params?.row?.status})`}</span>
        </>
      ),
      disableColumnMenu: true,
      minWidth: 150,
    },
    // {
    //   field: "vendor_id",
    //   headerName: "Tech",
    //   type: "singleSelect",
    //   flex: 1,
    //   minWidth: 180,
    //   getOptionValue: (value) => value?._id,
    //   getOptionLabel: (value) =>
    //     `${value.first_name || ""} ${value.last_name || ""}`,
    //   valueOptions: CRMVendorsDrd,
    //   renderCell: (params) => params.value?.vendor_id,
    //   valueGetter: (params) => params.row?.vendor_id,
    //   valueFormatter: (params) => params.value?.vendor_id,
    // },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      headerName: "Jobs",
      field: "leads",
      renderCell: (params) =>
        params?.row?.leads?.length ? (
          <span
          // className="text-blue-600 cursor-pointer hover:underline"
          // onClick={() => setLeads(params?.row?.leads)}
          >
            {params?.row?.leads?.length}
          </span>
        ) : (
          <span>0</span>
        ),
      disableColumnMenu: true,
      minWidth: 80,
    },
    {
      headerName: "Service Cost",
      field: "total_service_cost",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Parts Charge",
      field: "total_parts_charge",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Net Amount",
      field: "net_amount",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Tech fee",
      field: "total_tech_fee",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Company Share",
      field: "company_profit",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 130,
    },
    {
      headerName: "Paid Amount",
      field: "paid_amount",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 120,
    },
    {
      headerName: "Balance",
      field: "balance",
      flex: 1,
      type: "number",
      disableColumnMenu: true,
      minWidth: 120,
      renderCell: (params) => (
        <span>
          {(
            Math.round(
              params.row.total_payable_amount * 100 -
                params.row.paid_amount * 100
            ) / 100
          )?.toFixed(2)}
        </span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 120,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value?.label,
      valueOptions: paymentStatus,
      renderCell: (params) => params.value?.status,
      valueGetter: (params) => params.row?.status,
      valueFormatter: (params) => params.value?.status,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      flex: 1,
      minWidth: 200,
      sortable: true,
      type: "date",
      valueGetter: (params) => new Date(params.row.createdAt),
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
      renderCell: (params) => {
        const rawDate = params.row.createdAt;
        if (rawDate) {
          return rawDate;
        }
        return "";
      },
    },
  ];
  return (
    <div>
      <PageHeader route="Setting > Jobs" heading="" />
      <div className="mt-2">
        <div className="flex grid grid-cols-5 gap-2">
          {(merchant?.filter(({ recordCount }) => recordCount > 0) || [])?.map(
            (merchant, index) => {
              return (
                <button
                  key={index}
                  className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          selectedMerchant === merchant?.merchant?._id
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
                  onClick={() => {
                    setSelectedMerchant(merchant?.merchant?._id);
                    get_vendor_jobs({
                      ...sortingModel,
                      filters: queryOptions,
                      page: 1,
                      size: paginationModel.pageSize,
                      merchant_id: merchant?.merchant?._id,
                    });
                  }}
                >
                  <span className="text-sm font-pop">
                    <span className="text-sm font-pop font-semibold mr-1">
                      {merchant?.recordCount}
                    </span>
                    {` ${merchant?.merchant?.company_name}`}
                  </span>
                </button>
              );
            }
          )}
        </div>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={vendorInvoices?.data?.map((record, index) => {
            const counter = index + 1;
            const {
              description,
              invoice_number,
              status,
              leads,
              vendor_id,
              createdAt,
              createdBy,
              paid_amount = 0,
              total_payable_amount = 0,
              total_service_cost = 0,
              total_tech_fee = 0,
              total_parts_charge = 0,
            } = record;
            const { first_name: v_name = "", last_name: v_last = "" } =
              vendor_id;
            const { first_name = "", last_name = "" } = createdBy;
            return {
              records: { ...record },
              counter,
              description,
              invoice_number,
              status,
              leads,
              total_parts_charge: total_parts_charge ? total_parts_charge : 0,
              net_amount: (total_service_cost - total_parts_charge)?.toFixed(2),
              paid_amount: (paid_amount || 0)?.toFixed(2),
              total_payable_amount: (total_payable_amount || 0)?.toFixed(2),
              total_service_cost: (total_service_cost || 0)?.toFixed(2),
              total_tech_fee: (total_tech_fee || 0)?.toFixed(2),
              vendor_id: `${v_name} ${v_last}`,
              createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
              createdBy: `${first_name} ${last_name}`,
              company_profit: (
                total_service_cost -
                total_parts_charge -
                total_tech_fee
              )?.toFixed(2),
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          paginationModel={paginationModel}
          totalItems={vendorInvoices?.totalItems}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          topPagination
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          columnVisibilityModel={{ "vendors.vendor_type": false }}
        />
      </div>
    </div>
  );
}

export default VendorsInvoice;
